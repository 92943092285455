import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { IMAGE_PATH } from "../constants";
import { gaEvent } from "../../../utils/ga";
import { StyledLeafletPaper } from "../styles";
import CopyToClipboard from "react-copy-to-clipboard";
import LauncherPopup from "./LauncherPopup";

function LeafletItemPaper(props) {
  const location = useLocation();

  const { imageInfoList, leafletInfo, isMcouponApp } = props;
  const [slideIdx, setSlideIdx] = useState(0);
  const [searchParams] = useSearchParams();
  const tabIndex = searchParams.get("tabIndex");

  //공유 링크 : https://www.mlotte.net/201?tabIndex=1
  const shareUrl = `${process.env.REACT_APP_LEAFLET_URL}${location.pathname}?tabIndex=${slideIdx}`;

  // 이전/다음 버튼 클릭시 슬라이드 세팅
  const changeSlide = (type) => {
    if (type === "next") {
      const nextIdx = slideIdx + 1;

      if (nextIdx < imageInfoList.length) {
        setSlideIdx(nextIdx);
      }
    } else if (type === "prev") {
      const prevIdx = slideIdx - 1;

      if (prevIdx > -1) {
        setSlideIdx(slideIdx - 1);
      }
    }
    window.scrollTo(0, 0);
  };

  //공유하기 클릭
  const handleClickShare = () => {
    gaEvent(leafletInfo.title, `공유하기(${leafletInfo.title})`);

    //브릿지로 copy
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setClipBoard.postMessage({
          text: shareUrl,
          message: "클립보드에 복사되었습니다.",
        });
      } else if (isAndroid) {
        window.binder?.setClipBoard(shareUrl);
      }
    } catch (error) {
      console.log("setClipBoard Bridge Error");
    }
  };

  //공유 진입 시 특정 슬라이드 진입 체크
  useEffect(() => {
    if (tabIndex) {
      let _tabIndex = tabIndex;
      if (_tabIndex < 0) _tabIndex = 0;
      if (_tabIndex >= imageInfoList.length)
        _tabIndex = imageInfoList.length - 1;

      setSlideIdx(Number(_tabIndex));
    }
  }, [imageInfoList.length, tabIndex]);

  return (
    <>
      {/* APP 다운로드 팝업 */}
      <LauncherPopup />
      {/* 전단 화면 */}
      {imageInfoList.length > 0 && (
        <StyledLeafletPaper>
          <div>
            {imageInfoList.map((data, i) => {
              // 이미지 로딩시간 단축. 전체 불러오고 숨김 처리

              return (
                <div key={i}>
                  <img
                    src={`${leafletInfo.imgPath}/${data.imgs}`}
                    alt=""
                    style={{ display: i === slideIdx ? "block" : "none" }}
                  />
                  {/* 장애인차별금지법 웹표준 퍼블리싱 figcaption */}
                  <div
                    className="alternate"
                    dangerouslySetInnerHTML={{
                      __html: `${data.title} ${data.texts}`,
                    }}
                  />
                </div>
              );
            })}
          </div>

          {/* 페이징 버튼 */}
          <div className="paper-btns">
            <div className="paper-paging">
              <p>
                <strong>{slideIdx + 1}</strong> / {imageInfoList.length}
              </p>
            </div>
            <button
              title="다음 페이지로"
              className={
                "swiper-button-next" +
                (slideIdx === imageInfoList.length - 1 ? " disabled" : "")
              }
              onClick={() => changeSlide("next")}
            ></button>
            <button
              title="이전 페이지로"
              className={
                "swiper-button-prev" + (slideIdx === 0 ? " disabled" : "")
              }
              onClick={() => changeSlide("prev")}
            ></button>
          </div>

          {/* 공유 버튼 */}
          <div className="paper-share">
            {isMcouponApp ? (
              <button title="공유하기" onClick={handleClickShare}>
                <img src={`${IMAGE_PATH}/common/btn_share_gray.png`} alt="" />
              </button>
            ) : (
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => {
                  if (isIOS) alert("클립보드에 복사되었습니다.");
                }}
              >
                <button title="공유하기">
                  <img src={`${IMAGE_PATH}/common/btn_share_gray.png`} alt="" />
                </button>
              </CopyToClipboard>
            )}
          </div>
        </StyledLeafletPaper>
      )}
    </>
  );
}

export default LeafletItemPaper;
