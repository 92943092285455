/* 701, 751 빅마켓(~6/12) */
const vicData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX

    수박/탄산음료/폴로,챔피언 패션잡화브랜드 20%
    추 가 할 인
    
    전용카드
    회원만!
    ▶ 기간 : 5/30(수) ~ 6/12(수)
    ▶ 해당카드 :
    롯데마트&MAXX카드,
    LOCA for 롯데마트카드/롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가

    수박 전품목 20% 추가 할인 12,784~16,784원
    당도선별 수박(6~10kg 미만/통/국산)
    ※점별 운영 중량 상이
    ※ 해당카드 미적용시
    17,980~22,980원
    ※ 롯데/신한카드 결제시
    15,980~20,980원

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    탄산음료 전품목
    (콜라/사이다/혼합탄산음료)
    ※트레비 토닉워터 얼그레이 제외

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    폴로/챔피언 브랜드 의류(성인/아동)
    ※해당카드 미적용시 9,980~79,800원

    61월 한달 내내 이 가격 ※기간 : 6/1(토) ~ 6/30(일)

    3,500원 할인 캐나다산 카무트(2kg/캐나다산) 18,980원
    2,000원 할인  롤링롤링 오징어(150g/원산지 별도표기) 7,980원
  </div>
  `,
  `
  <div>
    3,000원 할인 어포튀각 9,980원
    (400g/원산지 별도표기)

    하림 더 건강한 두마리 치킨 (팩 : 두마리/닭고기 : 국내산) 15,980원

    2,000원 할인 하림 용가리 치킨 9,980원
    (1.2kg)
    
    1,000원 할인 큐원 올리고당 7,380원
    (1.2kg×2입)
    
    1+1 자임 곤약젤리 사과/
    패션후르츠(150g×10입) 각16,980원
    
    1,500원 할인 삼립 뉴 젤리뽀 7,480원
    (2.4kg)
    
    2,500원 할인 유키앤러브 파인애플
    케이크(25g×20입) 13,480원

    롯데카드/신한카드 1,000원 할인 상쾌환(3g×12입) 14,980원
    
    10,000원 할인 블루밍가든 누들볼 29,800원
    (4P)
    
    롯데카드/신한카드 3,000원 할인 싸이닉 엔조이 선에센스 9,980원
    (50ml×2)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    시원한 할인가로
    슬기로운 여름 준비!

    롯데카드/신한카드 2,000원 할인 미국산 체리 12,980원
    (900g/팩)
    
    제스프리 한판 골드키위 14,980원
    (12입/박스/뉴질랜드산)

    단 8일간, 핫프라이스!
    롯데카드/신한카드 50% 할인  한우 등심(100g/냉장/국내산) 3,990원
    ※기간 : 5/30(목)~6/6(목) ※2등급

    L.POINT 25& 할인 1등급 한우 국거리/불고기 2,835원
    (각 100g/냉장/국내산)

    L.POINT 25& 할인 호주산 곡물비육 부채살 1,935원
    (100g/냉장/호주산)
    
    L.POINT 25& 할인 캐나다산 돼지 삼겹살/목심 각1,260원
    (각 100g/냉장/캐나다산)
  </div>
  `,
  `
  <div>
    1,000원 할인 청수 물냉면 5,280원
    (720g)
    
    3,000원 할인 풀무원 함흥비빔냉면 3,980원
    2인(460g)

    롯데카드/신한카드 2,000원 할인 티오 아이스티 복숭아맛 17,980원
    (13g×150입)
    
    스페쇼우 젤리스트로우
    플러스(1,254g) 10,980원

    4,020원 할인 트레비 토닉워터
    얼그레이 3,960원

    2,000원 할인 티젠 콤부차 매실 10,980원
    (5g×30입)

    롯데카드/신한카드 35% 할인 캐나다 활 랍스터 12,935원
    (마리/냉장)

    L.POINT 5,000원 할인 미국산 냉동 차돌박이 39,800원
    (1.2kg/냉동/미국산)

    10,000원 할인 젠텍 14인치 터보펜 에어 서큘레이터/
    젠텍 20인치 공업용 선풍기 각79,800원

    순면 누빔 편백메밀베개
    (블루/차콜) 각9,980원
     
    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    해양수산부와 함께하는 수산대전20%

    수산대전20% 프리미엄 광어회 29,984원
    (500g 내외/팩/광어 : 국산)

    수산대전20% ASC인증 완도 활전복 19,840원
    (대/14미/10마리)

    수산대전20% 원양산 오징어 2,384원
    (마리/해동)
 
    수산대전20% 영광 법성포 굴비 27,984원
    (1.4kg/팩/냉동/국산)

    수산대전20% 국산 고등어필렛 8,784원
    (1kg/팩/냉동)

    수산대전20% 황태채(400g/팩/러시아산)/
    볶음용, 조림용멸치 각9,984원
    (각 500g/팩/멸치:국산)

    완숙 토마토 10,980원
    (2.4kg/팩/국산)

    1,000원 할인 옛날방앗간 미숫가루 8,980원
    (1.2kg/원산지 별도표기) 

    1,500원 할인 냉장 다진마늘 9,480원
    (800g/국산)

    GAP 파프리카 980원
    (1개/국산) 

    다다기오이 4,480원
    (10입/봉/국산)

    
    </div>
  `,
  `
  <div>
    롯데카드/신한카드 1,800원 할인 신선한 계란
    (특/30입/1.8kg/국산) 5,480원

    3,500원 할인 귀한 쌀 26,480원
    (10kg/국산)

    각 2,000원 할인 구운아몬드/구운 점보캐슈넛 11,980/6,980원
    (1kg/400g, 원산지 별도표기)

    물가안정 갈치/참조기 각3,600/1,400원
    (각 32미/180미/마리, 해동, 국산)
 
    L.POINT 5,000원 할인 생새우살 31-40 16,980원
    (908g/팩/냉동/베트남산)

    L.POINT 20% 할인 호주산 곡물비육 살치살 3,664원
    (100g/냉장/호주산)
    
    L.POINT 25% 할인 호주산 찜갈비 1,860원
    (100g/냉장/호주산)

    L.POINT 3,000원 할인 정다운 허브 훈제오리
    (600g/냉장/원산지 별도표기) 11,980원
    
    L.POINT 1,500원 할인 하림 동물복지 닭볶음탕 8,480원
    (1.3kg/냉장/국내산)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    L.POINT 4,000원 할인 양념 목심 칼집구이 23,980원
    (1.7kg/원산지 별도표기) 

    L.POINT 5,000원 할인 수원식 양념돼지 왕갈비 구이 24,980원
    (1.7kg/원산지 별도표기)

    L.POINT 50% 할인 풀무원 국산콩 와사비 나또 5,700원
    (44.5g×8입/원산지 별도표기)

    패밀리팩 초밥 24,980원
    (40P/팩/원산지 별도표기)

    씨푸드 쉐이크 우동 3,980원
    (팩/원산지 별도표기)

    핫 스파이시 윙봉 13,980원
    (팩/원산지 별도표기)

    날치알 새꼬막 비빔밥 8,980원
    (팩/원산지 별도표기)

    2,000원 할인 피크닉 마들렌 10,900원
    (20입/1팩/원산지 별도표기)

    각 1,000원 할인 RTC 피자 2종/파스타 1종 11,900~4,900원
    (듬뿍불고기/핫 페퍼로니)
    ※원산지 별도표기 ※빅마켓 영등포점에 한함
  </div>
  `,
  `
  <div>
    3,000/1,000원 할인 하림 닭가슴살 블랙페퍼(510g)/
    사조대림 로얄크랩(100g×2입) 9,480/4,480원

    1,000원 할인 백종원의 홍콩반점 해물육교자 6,980원
    (350g×2입)

    6,000원 할인 하림 치킨쌀너겟 9,980원
    (1.2 kg)

    각 2,000원 할인 쉐푸드 등심통돈까스(600g)/
    롯데 라퀴진 모짜렐라 치즈스틱(800g) 12,980/11,980원

    2,000원 할인 남가네 설악 추어탕 14,980원
    (450g×3입)

    각 2,000원 할인 문옥례 명인 전통 고추장/된장 15,580/11,980원
    (각 900g)

    각 2,000/1,000원 할인 움트리 생와사비(120g×2입)/
    육류n생와사비랑(120g×2입)/
    양념 초고추장(1kg×2입) 7,480/8,380/5,980원

    1,000원 할인 고기엔 참소스
    (800g×2입) 8,480원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  `,
  `
 <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    테이스티 말보로
    소비뇽 블랑(750ml) 19,800원

    커티삭 오리지널/프로히비션(각 700ml) 24,800/42,800원

    1,000원 할인 풀무원 다논 그릭 플레인(90g×10입) 4,680원

    롯데카드/신한카드 1,500원 할인 대파&베이컨 크림치즈(50g×4입) 5,480원

    롯데카드/신한카드 5,000원 할인 덴마크 산양 프로틴 고단백 14,480원
    슬라이스 치즈(220g×4입) 

    3,500원 할인 밀카 초코무 비스킷(40g×24입) 11,980원

    1,000원 할인 해태 홈런볼 초코(46g×9입) 7,980원
 
    2,500원 할인 액트투 전자렌지용 팝콘 버터맛 14,480원
    (1,404g)
  </div> 
  `,
  `
  <div>
    2,000원 할인 켈로그 고소한 현미 그래놀라 11,680원
    (500g×2입)

    롯데카드/신한카드 5,000원 할인 베지밀 17곡 한끼두유 11,980원
    (190ml×24입)

    롯데카드/신한카드 1,500원 할인 MAXX아메리카노 리얼블랙 1,980원

    2,000/1,000원 할인 사과당 애플밀크티/애플크러쉬 4,980/3,980원
    (각 18g×20입)

    종근당건강 전품목 3만원 이상
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    L.POINT 5,000원 할인 종근당건강 생유산균 락토핏 코어
    더블세트(60포×2입) 27,800원

    롯데카드/신한카드 1,000원 할인 독일 비타하임 발포 멀티비타민
    (4,500mg×80정) 8,980원

    4,000원 할인 뉴트리원 장용성 초임계 알티지
    오메가3 트리플(1,200mg×60캡슐) 21,980원

    5,000원 할인 내추럴박스 산양유프로틴
    (15g×42포) 29,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    
    롯데카드/신한카드 2,000원 할인 MAXX 프리미엄 화장지
    (40m×30롤) 17,980원

    L.POINT 2.500원 할인 아우라 피오니 블러썸 16,480원
    (5.5L)

    L.POINT 4,000원 할인 테크 베이킹+구연산 액체세제 11,980원
    (4.8L)

    2,000원 할인 히말리야 핑크솔트 아이스 15,980원
    (160g×5)

    2,000원 할인 미쟝센 퍼펙트
    오리지널 세럼 8,980원
    샴푸(900ml)

    2,000원 할인 밀크바오밥
    세라 바디워시
    베이비 파우더 
    (1,200ml) 11,980원
    
    L.POINT 최대 4,000원 할인 INTEX 플라밍고 라이드온/
    미니프레임풀(블루) 17,980/32,800원

    2,000원 할인 애견 쿨아이스 방석(스카이블루) 10,980원
  </div>
  `,
  `
  <div>
    락앤락 저장용품
    ※세부 품목은 매장에서 확인하세요
    전품목 할인!
    7,000원 할인 락앤락 클래식 밀폐용기 12P 14,980원
    
    3,000원 할인 니트릴 장갑 100매 7,980원
    (소/중/대)
    2,000원 할인 냉감 일체형 베게 10,980원
    1,000원 할인 마작 대방석(내추럴) 7,980원
    9,820원 할인 블랙야크 워크웨어
    반소매 카라티셔츠 29,980원
    5,000원 할인 SOUP 슬리브리스 포인트 원피스 34,800원
    5,000원 할인 비비안 숨쉬는 브라
    (2매입, M/L/XL) 29,800원
    5,000원 할인 나이키 코르테즈 파우치(블랙) 39,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 738,739 maxx(~10/1) */
const maxxData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
   맥스 서프라이즈 푸드 페스티벌
   롯데카드/신한카드 3000원 할인
   캠벨포도(1.5kg/2kg, 박스, 국산) ※점별 운영상품 상이 FESTIVAL 12,980/17,980원

   롯데카드/신한카드 25% 항공직송 노르웨이 생연어 2,985원

   L.POINT 25% 호주산 곡물비육 척아이롤 1,695원

   롯데카드/신한카드 3500원 구 워만든
  곡물그대로 21곡(1,100g) 9,980원

  샤인머스캣 21,980원

  태추 단감(4~5입/팩/국산) 9,980원

   50% 풀무원 순두부찌개 3종 각2,190원

   3000원 할인 삼립 폭신폭신 플레인 치즈케익 5,480원

   롯데카드/신한카드 4000원 할인 복음자리 통 딸기잼(1.03kg) 10,980원

   3000원 할인 오뚜기 스낵면(20입)/오뚜기 맛있는 오뚜기밥(210g×24입) 8,780/19,980원

   L.POINT 15% 스페셜 모둠초밥 16,983원

   L.POINT 3000원 미국산 우삽겹구이/척아이롤 샤브샤브(각 1kg/냉동/미국산) 16,980/21,980원

   L.POINT 2000원 요리하다
  다리살로만
  훈제오리 8,980원
  (450g/원산지 별도표기)

   1500원 두번구워 더 구수한 누룽지(33g×15입) 10,480원

   3000원 오랑지나(420ml×10입) 8,980원

   4000원 다정헌 유기농작두콩차,
  유기농 캐모마일차 허브차(각 1.5g×50입)/
  다정한 슬림한데이(20g×30입, 허니베리 블랜딩 티백차 각8,980원

  새우 오븐구이 11,980원

   하림 치킨텐더 샐러드 7,980원

   흑마늘 치킨구이 9,980원

   5000원 마니커 바사삭 옛날치킨(1.1kg) 11,480원

   3000원  동원 그릴리 직화후랑크(700g) 6,980원

   5000원 뉴트리원 프리미엄
  유산균 맥스(2g×100입) 11,980원
  ※기간 : 9/19(목)~12/27(금)

   물가 걱정 뚝!
  기간 : 9/19(목) ~ 12 /27(금)
  딱 100일간
  이 가격

  스테비아 대추방울토마토 4,980원

  L.POINT 1500원 무항생제 동물
  복지 유정란 8,480원
  (대/30입/1.56kg/국산)

  GAP 친환경
  느타리 버섯 2,380원
  (500g/팩/국산)

  L.POINT 5000원 요리하다 양념돼지 고추장/
  간장 불고기(각 2kg/원산지 별도표기) 16,800원

  갱엿순살닭강정/갱엿&간장 닭강정(각 1kg/팩/원산지 별도표기) 각11,980원

  6000원 할인 아보케어 블렌드 프리미엄 오일(2L) 19,980원

  2개 이상 50% 맘으로 뼈없는 감자탕(500g) 각2,990원

  2000원 할인 MAXX 모짜렐라 피자치즈(1.8kg) 17,980원

  2000원 할인 굿매너 빨아쓰는 행주타월(80매×3) 7,980원

  2000원 할인 키친아트 세라믹 냄비 2종 21,980/22,980원
  
  2000원 할인 럭셔리 호텔 타올 19,980원

  놀라운 혜택을
  만나보세요

  ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드, 롯데마트맥스 신한카드

  인기 비스켓 8종
  롯데/신한 제휴 카드
  2개 이상 구매시
  50%
  기간 : 9/19(목)
  ~ 9/21(토)


  풀무원 요구르트 전품목
  롯데/신한 제휴 카드
  2개 이상 구매시
  50%
  기간 : 9/26(목)
  ~ 9/29(일)

   풀무원 다논
  그릭 플레인 外 3종
  (상품별 규격 상이)

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `<div>
  애플망고(2입/팩/브라질산) 13,980원

  머루포도(3kg/박스/국산) 19,980원
  
  씨없는 포도(적/청/블랙) 각9,980원

  감자(1.5kg/봉/국산) 3,980원

  귀한쌀 27,980원

  카무트 18,980원

  하루한줌 프라임믹스 12,980원

  롯데카드/신한카드 30% 서해안 가을 햇꽃게 945원

  L.POINT 5000원 할인 생칵테일 새우살 71-90 14,980원

  L.POINT 5000원 할인 광천 패래 식탁김 7,980원

  L.POINT 5000원 할인 육수한알 진한맛 11,980원

  L.POINT 3000원 할인 촉촉버터구이 오징어 11,980원

  성경 김가루 12,980원

  L.POINT 15% 호주산 곡물비육 부채살 2,278원

  L.POINT 25% 호주산 안심 3,735원

  L.POINT 4000원 춘천식 닭갈비구이 25,980원
  
  L.POINT 4000원 국내산 삼겹살 대패/한입구이 19,980원

  2개 이상 40% 하림 닭볶음탕(800g/냉장/국내산) 각4,740원

  3000원 할인 마들렌(20입/1세트/원산지 별도표기) 9,900원

  1200원 할인 화이트 볼테라(3입/원산지 별도표기) 4,790원

  단 4일간 이가격 기간 : 9/26(목) ~ 9/29(일)

  제스프리 골드키위 1,250원

  6000원 할인 펩시콜라, 펩시콜라 제로슈가/칠성사이다(각 245ml×24캔) 각9,980원

  15000원 할인 시그니처 올스텐 2단 식기건조대 63,800원

  6000원 할인 오뚜기 XO 교자 새우&홍게살(324g×3) 6,480원

  롯데카드/신한카드 2000원 풀무원 가쓰오우동(1,410g) 8,980원

  4500원 할인 매일 상하 체다 슬라이스 치즈 12,980원

  3000원 할인 프리차드 버터 무염/가염 11,480원

  롯데카드/신한카드 40% 디벨라 파스타면 5종(상품별 규격 상이)

  2개 이상 구매시 1000원 할인 청정원 파스타소스 4종 각4,980~7,980원

  1900원 할인 하인즈 토마토 케찹(650g×2입)/굿 마요네즈(295ml×2입) 각7,980원

  8500원 할인 사조해표 압착 올리브유(900ml) 18,000원

  1500원 할인 해태 맛동산(70g×12입) 7,980원

  4500원 할인 마즈 엠앤엠즈 펀사이즈(500g) 9,980원

  2000원 할인 동서 포스트 컵시리얼(30g×12입) 9,980원

  롯데카드/신한카드 5000원 할인 남양 루카스나인 더블샷라떼(14.9g×60입) 17,980원

  롯데카드/신한카드 5000원 할인 베지밀 검은콩과 검은참깨 두유(190ml×24입) 11,980원
  
  3500원 할인 칠성 밀키스 제로 5,980원

  L.POINT 1000원 오늘좋은 이뮨샷 멀티비타민 10,900원

  L.POINT 4000원 안국약품
  멜라굿나잇 16,980원
  (500mg×30정)

  파울라너(4입) 7,980원

  산페드로9 라이브스 리저브 소비뇽블랑 외 3종(각 750ml) 각9,880원

  5500원 + 롯데카드/신한카드 3000원 추가할인 잘풀리는집 명품 로션 티슈 에센셜(30m×30롤) 16,980원

  2000원 할인 파워브라이트 액체세제/아로마뷰 매직퍼품 섬유유연제(5L/4L) 각8,980원
  
  2000원 할인 헥스 터키츄 본S(20P) 9,980원
  
  2000원 할인 베이직엘르 남성 코튼 100 순면 티셔츠, 화이트(95~110) 19,980원

  1000원 할인 키친플라워 스텐 무선 포트 8,980원

  2000원 할인 + 롯데카드/신한카드 2000원 추가할인 가그린 후레쉬 라임 기획(820ml×3) 10,980원

  2000원 할인 스텐 다용도 2단 인덕션 찜냄비 19,980원

  UCLA 다운 베스트/블랙야크 워크웨어 기모 티셔츠 각34,800/49,800원

  생활 F/W 시즌

  부드러운 극세사 담요(그레이 리프)/인디언 양면 담요(화이트&블루) 각14,980원

  컬럼비아/시슬리 울 장목양말 2종 각14,980원

  환절기 보습아이템 8종(상품별 상이) 9,980~24,980원

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 703,753 빅마켓(~6/26) */
const vicNewData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX 전용카드 회원만!
    미국산 초이스 소고기/즉석밥 20% 추가할인

    ▶ 기간 : 6/13(목) ~ 6/26(수)
    ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외                                      
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    미국산 초이스 소고기 20% 추가 할인

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    미국산 초이스 부채살/살치살 (각 100g/냉장/미국산) 1,584/3,584원
    ※해당카드 미적용시 1,980/4,480원

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    즉석밥 전품목(상품별 상이) 20% 추가 할인

    딱!! 100일간 이 가격 ※기간 : 6/10(월)~9/17(화)

    동물복지 백색 유정란 (대 30입/1.56kg/국내산) 1,500원 할인 7,980원
    건망고 (400g/베트남산) 2,000원 할인 7,980원

    L.POINT 2,000원 할인
    롱스틱 직화육포 (90g×2입/원산지 별도표기) 6,980원

  </div>
  `,
  `
  <div>
    스위트 마운틴 바나나 (1.2kg 내외/봉/원산지 별도표기) 2,780원

    1등급 국내산 돼지 앞다리(100g/냉장/국내산) 980원

    왕새우튀김 (팩/원산지 별도표기) 8,980원

    MAXX 1A 저지방우유(2.3L) 400원 할인 4,980원

    롯데 설레임 밀크쉐이크 (160ml×12입) 3,000원 할인 8,980원

    뮤엘올리바 카놀라유(2L) 1,000원 할인 8,980원

    크라운 초코하임(142g×5입) 2,000원 할인 7,480원

    토레타/파워에이드 마운틴 블라스트 (각 900ml×12입) 3,500원 할인 각11,480원

    L.POINT 4,000원 할인
    비비랩 가르시니아 플러스 (1,000mg×112정) 15,980원

    에고라운드 두피쿨링 민트 샴푸(1,000ml) 2,000원 할인 11,980원

    MAXX 테이크아웃 아이스컵 80P 세트 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    슬기로운 여름생활!

    롯데카드/신한카드 2,000원 할인
    AI선별 수박(6~10kg 미만/통/국산) 14,980~19,980원

    천도 복숭아(2kg/팩/국산) 12,980원

    롯데카드/신한카드 25% 할인
    1등급 국내산 돼지 삼겹살/목심 (각 100g/냉장/국내산) 각1,860원

    L.POINT 7,000원 할인 
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산) 47,800/72,800원

    롯데카드/신한카드 50% 할인
    러시아 활 킹크랩 (100g/냉장/러시아산) 5,990원

    롯데카드/신한카드 2,000원 할인
    파스퇴르 쾌변 요구르트 (150ml×8입) 8,780원

  </div>
  `,
  `
  <div>
    1,000원 할인
    스페쇼우 젤리스트로우 플러스 (1,254g) 9,980원

    2팩 이상 20% 할인
    제주 삼다수 그린 (2L×6입/500ml×20입) 2팩 이상 구매시 1팩당 5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    풀무원 평양 물냉면(2,538g)/칠갑 얼음찬 메밀소바(804g) 2,000원 할인 8,480/5,480원

    롯데 스낵 버라이어티팩(894g) 2,000원 할인 11,980원

    기능성 반팔티셔츠/7부팬츠, 5부팬츠 3,000원 할인 12,980/14,980원

    MAXX 스탠다드 단목 양말 (6족, 남성/여성) 1,000원 할인 각7,980원
    ※일부 색상 조기 소진 가능
    ※기간 : 6/10(월)~9/17(화)

    주방 필수템!
    키친유 스텐 밀폐용기 2P(2.0L/3.6L) 최대 3,000원 할인 17,980/24,980원

    에찌모토 식과도 가위세트(3P) 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    6월 여름맞이 특별전 해양수산부와 함께하는 수산대전20%

    ※기간 : 6/13(목) ~ 6/19(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다 ※이미지 연출컷입니다
    ※L.POINT 회원 기간 중 1인 최대 1만원 할인 ※기간 內 주차별 1인/1만원 한도

    수산대전20%
    프리미엄 광어회 (500g 내외/팩/광어 : 국산) 29,984원

    수산대전20%
    ASC인증 완도 활전복 (대/14미/10마리) 19,840원

    수산대전20%
    원양산 오징어 (마리/해동) 2,384원

    수산대전20%
    영광 법성포 굴비 (1.4kg/팩/냉동/국산) 27,984원

    수산대전20%
    국산 고등어필렛 (1kg/팩/냉동) 8,784원

    수산대전20%
    황태채(400g/팩/러시아산)/볶음용, 조림용멸치(각 500g/팩/멸치:국산) 각9,984원

    제스프리 점보 골드키위(8~12입/팩/뉴질랜드산) 14,980원

    스윗탱고 토마토 (1.5kg/국산) 10,480원

    시즌컬러 채소모둠 (500g/팩/국내산) 4,680원

    양파(3kg/국내산) 5,380원
    ※기간 : 6/13(목)~6/19(수)

    새송이 버섯 (600g/팩/국내산) 2,780원

    양상추 (1봉/국내산) 1,980원

  </div>
  `,
  `
  <div>
    가바현미 7곡 (4.2kg/국내산) 2,000원 할인 14,980원

    귀한쌀(20kg/국내산) 7,000원 할인 49,800원

    하루한줌 고메넛츠 (30g×25봉/원산지 별도표기) 2,000원 할인 15,980원

    호두(1kg/미국산) 1,500원 할인 9,480원

    손질민물장어 (500g/팩/냉장/국내산) 34,980원

    데친 문어 (100g/냉장/베네수엘라산) 2,480원

    L.POINT 5,000원 할인
    생칵테일새우살 51-70 (908g/팩/냉동/베트남산) 14,980원

    L.POINT 3,000원 할인
    한판 모둠 소시지 (1.1kg/냉장/원산지 별도표기) 8,980원

    L.POINT 2,000원 할인
    요리하다 다리살로만 훈제오리(450g/냉장/원산지 별도표기) 8,980원

    L.POINT 각2,000원 할인
    하림 IFF 6개 품목 ※냉동/국내산 (상품별 상이/품목 2kg) 

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    L.POINT 15% 할인
    참한 새우초밥 (24P/팩/냉장/원산지 별도표기) 19,533원

    오징어볼/쉬림프칩 (240g/160g/원산지 별도표기) 2,000원 할인 각7,980원

    L.POINT 6,000원 할인
    100g당 1,290원
    양념 소불고기 (2kg/팩/냉장/원산지 별도표기) 25,800원

    서리태 콩국물/국산콩 콩국물(각 1L/국내산) 5,880/4,980원

    경양식 함박스테이크(9입/팩/원산지 별도표기) 13,980원

    일품 월남쌈 세트 (팩/원산지 별도표기) 13,980원

    가성비 식사/사이드 세트, 감탄박스 (1팩/원산지 별도표기) 8,900원

    오리지널 핫도그 (5입/1팩/원산지 별도표기) 2,000원 할인 8,900원

  </div>
  `,
  `
  <div>
    최대3,000원 할인 
    동원 고추참치(100g×10입)/동원 리챔 더블라이트(300g×6입) 15,480/21,980원
    
    2,740/4,000원 할인
    티아시아 골든 버터 난(200g)/쉐푸드 생크림버터 치킨카레,토마토 비프카레(각 170g×4입) 2,740/5,980원

    최대 40% 할인
    디벨라 파스타면 5종(상품별 규격 상이) 
    ※ 스파게티니, 스파게티, 링귀네, 푸실리, 펜네

    롯데카드/신한카드 4,000원 할인
    매일 상하 체다 슬라이스 치즈 (1.36kg/80매) 13,480원

    빙그레 딸기맛 우유 (240ml×8입) 1,000원 할인 7,480원

    5개 구매시 5,980원
    삼립 인기빵 10종(상품별 규격 상이) 5개 이상 구매시 1개당 각1,196원 
    ※주종발효 단팥크림빵, 주종발효 카스타드 소보루 外
    ※1개 구매시 각 1,480원

    트리코 고프레 프렌치 쿠키(450g) 14,980원

    트윅스 미니스 (1.02kg) 5,000원 할인 16,480원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    1+1 & 50% 할인

    1+1
    CJ 삼호 오뎅한그릇(360g)/구이한판 스모크 그릴(60g×4) 3,680/8,480원

    1+1
    사세 쏘스치킨 윙봉/순살 단짠갈릭맛(300g/375g) 각8,900원

    50%
    사조대림 국물떡볶이 (373g) 2,340원

    1+1
    풀무원 납작지짐만두(700g) 8,980원

    1+1
    동원 양반 수라 왕갈비탕/도가니설렁탕/보양추어탕/통다리삼계탕(각 460g) 각8,980원

    1+1
    담터 콤부차 샤인머스캣 포켓몬 기획(5g×30입) 15,980원

    1+1
    립톤 아이스티 피치(14g×120입) 37,980원
    ※ 점별 재고 한정

    1+1
    락티브 어린이 면역엔 멀티비타민 젤리(4g×60EA) 24,980원

  </div>
  `,
  `
  <div>
    크러시 쿨러백 (355ml×18입) 26,800원

    볼쓰 멜론×파쏘아 하이볼 패키지 (700ml×2ea+리유저블컵 3ea) 39,800원

    트레비 탄산수 3종 (각 300ml×20입) 각7,980원
    ※ 레몬/라임/플레인

    남양 프렌치카페 믹스 (10.9g×300입) 2,000원 할인 27,780원

    롯데카드/신한카드 5,000원 할인
    베지밀 검은콩과 검은참깨 두유 (190ml×24입) 11,980원

    멘토스 뉴레인보우 츄잉캔디 (미니/1kg) 1,800원 할인 9,480원

    뉴트리원 프리미엄 프리바이오틱스(5g×50포) 4,000원 할인 12,980원

    내추럴박스 카무트 브랜드 밀효소 (3g×15포×2입) L.POINT 2,000원 할인 19,980원

    롯데카드/신한카드 4,000원 할인
    바이탈슬림 리얼컷 아이돌물 녹차꿀맛 슬림(20ml×14포) 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    롯데카드/신한카드 3,000원 추가할인
    잘풀리는집 명품 로션티슈 에센셜(30m×30롤) 16,980원

    롯데카드/신한카드 2,000원 추가할인
    탑스텝 3in1 캡슐 세탁세제(100입) 15,980원

    옹달샘 습기제거제(18입) 2,000원 할인 12,980원

    롯데카드/신한카드 3,000원 할인
    시린메드 검케어(125g×5) 18,980원

    퍼펙트 건조대(대) 5,000원 할인 19,980원

    3M 이지 극세사 막대걸레 세트 (막대걸레 1P+극세사패드4매 구성) 6,000원 할인 18,980원

    제크롤 후면식 선풍기 (JK BF1400) 4,000원 할인 31,800원

    모리츠 메탈 20인치 선풍기 (MO 200MF) 4,000원 할인 55,800원

  </div>
  `,
  `
  <div>
    최대 70,000원 할인
    라코스테 클래식 카라/그래픽 반소매 티셔츠/블랙야크 웨크웨어 반소매 카라/라운드 티셔츠 24,980~59,800원
    
    나이키 브라질리아 더플백 (S, 41L) 3,000원 할인 32,800원

    NEW Arrival

    두툼한 손질 갈치 (400g/냉동/팩/모로코산) 6,880원

    MSC 짜먹는 냉동 명란 (30g×10팩/냉동/원산지 별도표기) 12,800원

    간편 고등어구이 (90g×4봉/냉동/원산지 별도표기) 13,800원

    뚜껑과 빨때를 제거해 환경까지 생각한 마이카페라떼 그린!
    매일카페라떼 마일드 컴포리드 (200ml×8입) 9,480원

    뉴트리 에버콜라겐 고백 (750mg×84정) 32,980원

    헤어플러스 아쿠아 워터 에센스 (200ml×2) 19,980원

    에이센트 3X 고농축 퍼퓸드 섬유유연제(4L) 15,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 718,719 maxx(~9/18) */
const maxxNewData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2024. 9. 5(목) ~ 2024. 9. 18(수)
    ·롯데마트맥스 : 금천, 목포, 상무, 송천, 영등포, 창원중앙점

    통큰 한가위 MAXX SURPRISE! 추석 명절 준비는 MAXX에서!

    알뜰하게 미리 준비하는 추석!
    신고 배(3kg/박스/국산) 12,980원

    롯데카드/신한카드 5,000원 할인
    햇사과 (3kg/봉/국산) 23,980원

    1+1 풀무원 국산부침두부(340g/국산) 4,990원

    동태전/노바시 새우 (1kg/300g, 냉동, 원산지 별도표기) 9,980/6,980원

    국산 데친문어 (100g/냉장/국산) 3,980원

    1등급 한우 국거리/불고기(각 100g/냉장/국내산) 각3,280원

    L.POINT 3,000원 할인
    호주산 육전용 홍두깨살 (800g/냉장/호주산) 21,980원

    동그랑땡(1.2kg/돈육 : 국내산)/삼색나물(100g/원산지 별도표기) 12,980/1,980원

    롯데카드/신한카드 2,000원 할인
    동원 해물모둠완자/한성해물경단(각 1kg) 각7,480원

    롯데카드/신한카드 1,000원 할인
    대림 오양 맛살(1,000g) 4,980원

    곰표 부침가루(2kg)/우리밀 부침가루(1kg) 각1,000원 할인 3,980/4,480원

    최대5,500원 할인
    사조해표 식용유(1.7L×2입)/베리오 엑스트라버진 올리브유(1L×2입) 9,980/39,980원

    몽고 송표 프라임 양조간장(900ml×2입) 50% 6,840원

    청정원 소갈비/소불고기/돼지갈비 양념(각 500g×2입) 각1,000원 할인 각6,180원

    호정 조청산자(220g)/조청유과(200g) 최대1,500원 할인 5,980/5,480원

    롯데카드/신한카드 4,000원 할인
    팔도 비락식혜(283ml×24) 8,980원

    썬 부탄가스(4P) 1,000원 할인 4,980원

    공구핫딜 잘풀리는집 키친타월 (150매×12롤) 8,900원

    L.POINT 2,000원 할인
    크리올 고농축 주방세제(1L×3입) 10,980원

    롯데/신한 제휴카드 결제 고객 대상
    놀라운 혜택을 만나보세요
    ▶ 해당카드 : 롯데마트&MAXX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드

    L.POINT 5,000원 할인 
    롯데마트&MAXX카드 LOCA for 롯데마트카드 롯데마트맥스 신한카드 적용가 5,000원 추가할인
    호주산/미국산 LA식 꽃갈비 (각 2k, 냉동, 호주산/미국산) 64,800원

    추석음식을 더 맛있게! 
    뉴 사각 만능 잔치팬(63cm) 10,000원 할인 57,800원

    홈파티 와이드그릴(48cm) 5,000원 할인 30,800원

    테팔 글로리 IH 프라이팬 (2P/20cm+28cm) 8,000원 할인 45,800원

    티타늄 코팅 인덕션 웍팬 3,000원 할인 13,980원

    MAXX 주방소모품 전품목 (비닐백/비닐장갑/지퍼백/종이호일) 10% 할인

    한달 내내 이 가격 
    기간 : 9/1(일) ~ 9/30(월)

    찹쌀(4.5kg/국산) 2,500원 할인 13,480원

    L.POINT 각2,000원 할인
    즉석포차 양념육 3종(각 400g×4입구성) 15,980~22,980원
    ※제육볶음/소불고기/한입칼집구이 

    구운 아몬드(1kg) 2,000원 할인 11,980원

    덴마크 산양우유 (750ml×2입) 2,700원 할인 9,980원

    마니커F&G 케이준치킨텐더(1kg) 5,000원 할인 9,980원

    L.POINT 13,000원 할인
    NEW 하이뮨 프로틴 밸런스 스틱(19g×20입) 19,800원

    린백 리클라이닝 게이밍 체어 20,000원 할인 129,800원

    단, 4일간 이 가격 
    기간 : 9/12(목) ~ 9/15(일)

    수산대전 30%
    황태채/속살 황태채(400g/250g, 원산지 별도표기) 8,320/7,340원

    남양 프렌치 카페 (10.9g×300입) 3,000원 할인 26,780원

    퀸센스 피크닉 인덕션 IH그리들(40cm) 5,000원 할인 24,980원

    롯데마트GO 앱 설치하고 9월 이벤트 참여하세요

    EVENT 1 신규가입 하면 7만원 이상 5천원 할인쿠폰 증정!
    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 롯데마트GO앱 신규가입 고객
    ※가입일자 : 9/1(일)~9/30(월)
    ▶쿠폰 사용기간 : 9/1(일)~10/6(일)
    ▶쿠폰 사용장소 : MAXX 6개점 및 롯데마트 매장

    EVENT 2 롯데마트GO앱 출석 체크하고 매일매일 L.POINT 최대 200p 적립!
    ▶기간 : 9/1(일) ~ 9/30(월)
    매일매일 앱에 접속할 때마다 L.POINT 최대 200P 바로 적립!

    ※정상가란? 2024년 8월 15일(목) ~ 9월 4일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    롯데카드/신한카드 각3,000원 할인
    햇사레/부드러운 복숭아 (각 3kg/박스/국산) 각26,980원

    롯데카드/신한카드 3,000원 할인
    샤인머스캣(2kg/박스/국산) 21,980원

    하우스 감귤(1.5kg/국산) 13,980원

    L.POINT 1,000원 할인
    깐 메추리알(1kg/국산) 6,280원

    풀무원 드레싱 전품목 2개 이상 구매시 30%
    그린믹스 채소모둠(500g/팩/국산) 3,980원

    GAP 표고버섯 (250g/팩/국산) 3,980원

    L.POINT 2,000원 할인
    껍질째 구운캐슈넛 (400g/원산지 별도표기) 6,980원

    고구마 말랭이 (400g/원산지 별도표기) 9,980원

    냉장 다진마늘 (800g/국산) 9,480원

    찰현미/귀한쌀(4.5kg/20kg, 국산) 최대5,000원 할인 14,480/44,800원

    2024 대한민국 수산대전 
    해양수산부와 함께하는 수산대전 20%
    ※기간 : 9/5(목) ~ 9/15(일) ※해당상품에 한함 ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인 ※이미지 연출컷입니다

    수산대전 20% 롯데카드/신한카드 10% 추가할인
    서해안 가을 햇꽃게 (100g/국산) 886원

    수산대전 20%
    볶음용 멸치/볶음조림용 멸치 (각 500g/팩) 각9,984원
    ※멸치 : 국산

    수산대전 20%
    ASC인증 완도활전복 (14미/10마리/국산) 19,840원
    ※기간 : 9/5(목) ~ 9/11(수)

    프리미엄 광어회 (500g 내외/팩/원산지 별도표기) 34,980원

    L.POINT 3,000원 할인
    조미 오징어 (600g/원산지 별도표기) 14,980원

    L.POINT 2,000원 할인
    대천곱창돌김 (5g×30봉/원산지 별도표기) 9,980원

    미국산 프라임 척아이롤 (100g/냉장/미국산) 2,480원

    L.POINT 각5,000원 할인
    호주산 곡물비육 치마살/토시살(각 1kg/냉장/호주산) 각34,800원

    L.POINT 40% 할인
    양념 한우 불고기(1kg/냉장/원산지 별도표기) 23,880원

    L.POINT 3,000원 할인
    정다운 허브 훈제오리 (600g/냉장/원산지 별도표기) 11,980원

    캐나다산 돼지 스페어립/등갈비(각 100g/냉장/캐나다산) 1,580/1,880원

    중화 해물 양장피 (규격/원산지 별도표기) 15,980원

    신선 밀키트 4종(팩/원산지 별도표기) 2,000원 할인
    ※우삼겹낙곱새/밀푀유나베/소고기버섯전골/사천식마라탕

    듀얼 카스텔라 세트 5,100원 할인 18,900원
    (2입 1set/원산지 별도표기) ※벌꿀카스텔라 1입, 녹차 카스텔라 1입

    18인치 콤비네이션 + 파스타+웨지감자(원산지 별도표기) 5,000원 할인 24,700원

    파스퇴르 무항생제 바른목장 멸균우유(190ml×24입) 5,000원 할인 15,980원

    풀무원 요거톡 스타볼(132g×8입)/액티비아 화이트 플레인(130ml×12입) 최대2,000원 할인 각5,980원

    삼립 옛날 꿀호떡(560g×2입) 1,500원 할인 4,980원

    목우촌 주부9단 살코기햄(1,000g) 2,000원 할인 8,980원

    MAXX 떡갈비(1,200g) 3,000원 할인 9,980원

    삼립 빚은 흰송편/모시송편(각 460g) 각1,000원 할인 각6,980원

    청정원 맛선생 국물내기 한알 50% 7,490원
    (멸치 디포리 160g)

    전통 전병(600g) 2,000원 할인 7,980원

    트리코 고프레 프렌치 쿠키(450g) 3,000원 할인 11,980원

    해태 연양갱(50g×24입) 2,000원 할인 7,980원

    롯데카드/신한카드 2,000원 할인
    크라운 설병선과(729g) 6,480원

    롯데카드/신한카드 5,000원 할인
    베지밀 17곡 한끼두유 (190ml×24입) 11,980원

    동서 맥심 카누 마일드/다크 미니(각 0.9g×160입) 4,000원 할인 각27,480원

    1+1
    티젠 콤부차 베리 30스틱 보틀기획(5g×30입) 13,480원

    종근당건강 해당품목 3만원 이상 구매시 5천원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    L.POINT 4,000원 할인
    아임비타 멀티비타민 이뮨 플러스(23g×7EA) 11,980원

    정성담은 흑염소 진액(70ml×30포) 4,000원 할인 15,980원

    크러시 초특가 기획팩(350ml×24입) 19,880원

    L.POINT 42,000원 할인
    글렌그란트 12년 (700ml) 67,800원

    컬럼비아 라이프 윈드자켓 39,800원

    나이키 짐 토트백 DR7217 5,000원 할인 39,800원
    ※매장 내 다양한 나이키 가방이 준비되어 있습니다

    탑스텝 3IN1 캡슐 세탁세제(100개입) 3,000원 할인 16,980원

    다우니 에이프릴 프레쉬(3.83L) 4,000원 할인 17,980원

    1,000원 할인 롯데카드/신한카드 3,000원 추가할인
    생활공감 ON 천연펄프 3겹 화장지 (30m×30롤) 9,880원

    롯데카드/신한카드 2,000원 할인
    2080시그니처 토탈블루(160g×8) 11,480원

    프로틴 927 리페어 샴푸(1.5L) 2,000원 할인 8,980원

    쫄깃한 한우 콜라겐껌 블루베리(700g) 15,980원

    잇츄러스 하프(190g) 1,000원 할인 5,480원

    사각방석(연그레이 2P)/메모리폼방석(그레이 2P) 2,000원 할인 각7,980원

    비비안 제로라인 브라 (2매입) ※S/M/L 2,000원 할인 각17,980원

    보덤 유리컵 3종 (300/350/450ml) 최대3,000원 할인 14,980~31,800원  

    ※정상가란? 2024년 8월 15일(목) ~ 9월 4일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getVicData = () => {
  const dataArray = [];
  vicData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxData = () => {
  const dataArray = [];
  maxxData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getVicNewData = () => {
  const dataArray = [];
  vicNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxNewData = () => {
  const dataArray = [];
  maxxNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L705: {
    title: "스마트전단지-빅마켓705",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L755: {
    title: "스마트전단지-빅마켓755",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L738: {
    title: "스마트전단지-맥스738",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202409_3/07",
    contents: getMaxxData(),
  },
  L739: {
    title: "스마트전단지-맥스739",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202409_3/07",
    contents: getMaxxData(),
  },

  L703: {
    title: "스마트전단지-빅마켓703",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L735: {
    title: "스마트전단지-빅마켓735",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L718: {
    title: "스마트전단지-맥스718",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202409_1/07",
    contents: getMaxxNewData(),
  },
  L719: {
    title: "스마트전단지-맥스719",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202409_1/07",
    contents: getMaxxNewData(),
  },
};

export default data;
