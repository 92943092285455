import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 9. 12(목) ~ 2024. 9. 18(수) < 비신선식품 적용기간 : 2024. 9. 5(목) ~ 9. 18(수) >

    통큰 한가위 장보기 솔루션

    L.POINT 40% 1등급 한우 국거리/불고기
    (각 100g/냉장/국내산 한우고기)

    각 1000원 할인 백설 고기양념 4종(각 500g)
    ※소갈비/소불고기/돼지갈비/돼지불고기 양념 각3,480원

    L.POINT 최대1,200원 오뚜기 옛날 자른당면 3,980/6,480원
    (300g/500g)

    제수용 황태포/용대리 황태포 (60g 내외/75g 내외, 원산지 별도표기) 4,990/5,990원

    L.POINT 20% 간편수제 명태살/대구살 7,920/14,320원
    (각 500g/냉동/원산지 별도표기)

    L.POINT 20% 껍질벗긴 새우 15,920원
    (750g/냉동/원산지 별도표기)

    2팩 이상 구매시 각 1000원 할인 데친 고사리(300g/팩/국산)/깐도라지(250g/팩/국산) 각8,990원

    우리쌀 송편(100g/원산지 별도표기) ※기간 : 9/14(토) ~ 9/16(월) 1,091~1,900원

    제수용 배(3입/팩/국산) 17,990원

    제수용 사과(3입/팩/국산) 17,990원

    경산 햇대추(300g/팩/국산) 6,990원

    밤/깐밤(700g/150g, 봉/국산) 7,990/7,490원

    2개 이상 구매시 1개 당 500원 할인 CJ 비비고 도톰 동그랑땡/해물완자(425g×2/795g) 각9,480원

    백화수복 4,620원

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라!
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    L.POINT 50% 한우 양념 불고기(800g/냉장/원산지 별도표기) 18,900원

    L.POINT 10% + 롯데/신한/NH농협카드 10% 추가할인 제수용 문어(100g/냉장/국산) 4,852원
    ※페이지 하단 카드할인 세부내용 참고

    양파(2kg/망/국산) 3,990원
 
    GAP 하우스감귤 13,990원

    롯데/신한/NH농협카드 500원 할인 목우촌 주부9단
    살코기햄(1kg) 9,980원
    ※페이지 하단 카드할인 세부내용 참고

    1040원 할인 팔도 왕뚜껑(4개입) 1,980원

    2920원 할인 + 롯데/신한/NH농협카드 1000원 추가할인 크리스피 허니눈꽃 쌀과자(920g) 7,980원
    ※ 원주점, 의왕점, 서대전점 별도 행사 진행

    명절 전통음료 4종 2,980~3,900원

    6950원 할인 메디안 잇몸과학 치약
    2종(각 120g×3입) 각5,950원

    2개 이상 구매시 1개당 50% 할인 크리넥스
    울트라클린
    카카오(30m×30롤) 각17,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
    이번주 핫프라이스

    4인 가족 한 팩이면 충분!
    2근에 1만원대! ※1인분 300g 기준
    국내산 한돈 갈비
    (1.2kg/냉장/국내산)
    ※제주점 제외 ※조기 품절될 수 있습니다

    롯데/신한/NH농협카드 30% 15,890원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div>
  `,
  `
  <div>
    L.POINT 10000원 할인미국산/호주산
    LA갈비
    (각 1kg/냉동)
    ※미국산, 호주산

    미국산/호주산 찜갈비 (각 100g/냉장)
    L.POINT 30%할인

    L.POINT 40% 호주산 소 불고기/육전용
    (상품별 상이/냉장, 냉동/호주산)

    브랜드 돼지고기
    삼겹살/목심 각3,890원
    (각 100g/냉장/국내산 돼지고기)
    ※점별 입점 브랜드 상이 ※제주점 제외 ※입점점포 한정

    L.POINT 20000원 할인 통큰 양념 LA식 꽃갈비 59,900원
    (1.5kg/냉장/원산지 별도표기)

    L.POINT 5000원 할인 요리하다
    날개살로만
    훈제오리 6,900원
    (350g/냉장/원산지 별도표기)

    L.POINT 25% 완도 활 전복(왕/대) 4,350/2,475원
    (마리/냉장/국산)

    부세조기(마리/해동/중국산) 3,990원

    L.POINT 7000원 프리미엄
    파타고니아 생연어 22,900원
    (500g/냉장/칠레산)

    L.POINT 10% 광어연어우럭도미 39,960원
    모둠회(400g 내외/냉장)
    ※원산지 별도표기

    GAP 친환경 새송이(300g×2봉/국산)/
    GAP 친환경 표고버섯(300g/팩/국산)/
    친환경(生) 목이버섯(320g/팩/국산) 4,990/6,490/5,990원

    부침용 고구마(100g/국산) 590원

    L.POINT 7000원 GAP 완전미
    고시히카리 39,900원
    (10kg/국산)

    머루포도(3kg/박스/국산) 19,990원

    제수용 곶감 (8입/팩/국산) 10,990원

    하늘청 식혜/수정과 각5,990원
    (각 1개/원산지 별도표기)

    L.POINT 30% 갱엿 순살 닭강정(대) 11,893원
    (1팩/원산지 별도표기)

    해물 양장피(1팩/원산지 별도표기) 15,990원

    한돈 수제 떡갈비 6,990원

    모듬전&모듬나물(원산지 별도표기) 각3,900원
    ※일부 품목에 한함 ※반찬운영점 한함 ※연출된 이미지컷입니다
    ※기간 : 9/14(토)~9/16(월)

    하나사면 하나 더 1+1

    1+1 CJ 맥스봉 오리지널(525g) 8,480원

    1+1 CJ 육개장/들깨 칼국수 (각 2인) ※교차구매 가능 각8,480원

    1+1 사세 바삭 튀긴 통새우튀김 (300g) 9,900원

    1+1 CJ 고메 크리스피 핫도그/
    치즈핫도그(400g/340g) 각8,480원
    ※기간 : 9/12(목)~9/15(일) ※교차구매 가능

    1+1 동원 양반 진국 사골 곰탕 (500g) 1,980원

    1+1 매일유업 이유식/유아간식 30종 (상품별 규격상이) ※동일가격 교차구매 가능 각1,500~4,900원

    1+1 청정원 순창 찰골드 고추장 (1.4kg) 14,980원

    1+1 이보노이토 수제 소면(300g) 6,400원

    1+1 매일 상하 스트링치즈(18g×10입) 12,980원

    1+1 아내의 착즙주스 배도라지 외 4종 각29,900원
    (각 100ml×21입) ※ 교차구매 가능
    ※사과/감귤사과/당근사과/양배추사과

    1+1 칠성사이다/펩시/펩시제로/밀키스 각5,480원
    (각 190ml×6입) ※교차구매 가능

    1+1 굿바이 썸머 스포츠 음료 10종 각1,980~3,750원
    (상품별 규격상이) ※동일시리즈 상품간 교차구매 가능

    1+1 구카 누가 필링/솔티커피 누가 크래커 각9,980원
    (각 400g) ※교차구매 가능

    1+1 켈로그 시리얼바 전품목 각4,680/5,880원
    (상품별 규격상이) ※동일가격 교차구매 가능

    2개 이상 구매시 더싸게

    2개 이상 구매시 한개당 50% 한성 프로틴 크랩 각3,490원
    
    2개 이상 구매시 한개당 500원 CJ 비비고 남도 떡갈비/
    한입떡갈비기획/잔칫집 모둠잡채 각8,980원
    (상품별 규격상이) ※점별 운영상품 상이

    2개 이상 구매시 한개당 50% 롯데 쉐푸드
    미니돈까스(380g) 각3,490원

    2+1 동원 양반
    비빔드밥 6종 각4,980원
    (상품별 규격상이) ※교차구매 가능
    
    2개 이상 구매시 한개당 1500원 할인 폰타나 파스타소스 8종 각5,480~7,480원
    (각 430g) ※카르니아 베이컨&머쉬룸 크림 外
    
    2개 이상 구매시 한개당 50% 파스퇴르 위편한/속편한 요구르트/
    제로 플레인 요거트(상품별 규격상이) 각2,240~3,340원
    ※기간 : 9/12(목)~9/15(일) ※ 교차구매 가능
    
    2개 이상 구매시 한개당 50% 매일 상하 뼈로 가는 칼슘치즈/
    지방을 낮춘 고단백치즈(각 270g) 각4,990원
    
    2개 이상 구매시 한개당 30% 농심 포테토칩 4종
    (상품별 규격상이) 각1,771원
    ※ 오리지날/올디스타코맛/
    먹태청양마요맛/먹태고추장마요맛
    
    2개 이상 구매시 한개당 10%
    3개 이상 구매시 한개당 15% CJ 쁘띠첼 10종 각1,870원
    ( 각 210g) ※교차구매 가능

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    씨제이제일제당 전품목
    4만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 "ⓒ"을 확인하세요

    1분링 50개입으로 아낌없이 넉넉하게! 백설 멸치디포리 가득
    육수에는 1분링(200g) 8,980원

    그릴비엔나 구매시 안심비엔나 200g 증정!
    CJ 더건강한 그릴 비엔나(560g)/비비고 왕교자(350g×3) 8,980/9,980원

    사조대림 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉦ "을 확인하세요

    L.POINT 1980원 해표 식용유(1.7L) 6,120원

    사조대림 게맛살(500g) 3,780원

    동원에프앤비 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉢ "을 확인하세요

    1+1 동원 참치액 진/순 각7,980원
    (각 500g) ※교차구매 가능

    1+1 동원 리챔 클래식/더블라이트 각15,480원
    (각 200g×3) ※교차구매 가능

    동원 그릴리 델리햄(450g)/ 양반 통살 고기완자(850g) 6,480/9,480원

    샘표식품 전품목
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ⓢ "을 확인하세요

    샘표 진간장 금F-3 3입 기획(500ml×3입) 7,900원


    오뚜기 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉧ "을 확인하세요

    각 420원 할인 오뚜기 부침/튀김가루(각 1kg) 각2,980원

    오뚜기 갈비산적(400g×2입)/오뚜기 해물완자(390g×2입) 각8,980원

    오뚜기 열김치우동(2인) 5,980원

    롯데웰푸드 해당상품
    2만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉣ "을 확인하세요
    ※분유, 냉장 밀키트, 아이스크림 제외

    롯데 의성마늘햄(440g+100g증정)/쉐푸드 떡갈비(570g) 8,580/8,480원

    대상주식회사 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " D "을 확인하세요

    각1,000원 할인
    청정원 올리고당 2종(각 1.2kg) 각2,980원

    종가 아삭하고 개운한 포기김치(3kg) 24,900원
    
    동원 소와나무
    순수우유 기획
    (900ml×2입) 3,980원

    L.POINT 3000원 뉴케어 3종(각 200ml×16입) ※구수한맛/검은깨맛/인절미맛 각26,900~29,900원

    각3,000원 할인 센트룸 전품목(상품별 상이) 각23,000~24,000원

    살림안정 물가안정 인기 생필품

    롯데/신한/NH농협카드 60% 온더바디 데일리퍼퓸
    바디워시 3종(각 1,100ml) 각5,560원
    ※로즈/라벤더/머스크향
    ※점별 운영상품 상이

    2개 이상 구매시 한개당 50% 해피바스 스마일 바디워시/
    오리지널컬렉션 비누 각5,950원 
    (각 900g/90g×4) ※점별 운영상품 상이

    롯데/신한/NH농협카드 1+1 페브리즈 용기 6종 각6,900원
    (각 370ml) ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    1+1 다우니 생화향기 리필 5종 각23,300원
    (각 2.6L) ※교차구매 가능
    ※미스티크/시트러스버베나/코튼/스프링가든/프리지아페어

    바세린 컬러
    립밤 2종(각 4.2g) 각6,900원
    ※키씽레드/그레이프프룻

    2개 이상 구매시 1개당 50% 프리메이 핑크콜라겐/
    올리브시카카밍앰플 
    마스크팩 2종 각4,500원
    (각 5매입) ※점별 운영상품 상이

    롯데/신한/NH농협카드 2개이상 구매시 1개당 50% 프로쉬 전품목(상품별 상이) ※일부품목 제외 각3,450~7,750원
    ※페이지 하단 카드할인 세부내용 참고

    1+1 다우니 실내건조 세탁세제(3.2L) 36,900원

    메디안 잇몸과학 치약 2종(각 120g×3입) ※스트롱민트/클린민트 각5,950원

    7030원 할인 리엔 보윤 샴푸/컨디셔너 3종 (각 1,000ml) ※점별 운영상품 상이 각7,950원

    2개 이상 2500원 할인 닥터그루트 7주년 기획팩
    집중케어 샴푸 2종/두피영양토닉 각20,500원
    (상품별 상이) ※점별 운영상품 상이 ※샴푸 : 힘없는모발용/지성두피용

    2000원 할인 깨끗한나라 클린프리미엄 3겹 화장지 (30m×30롤) 14,900원

    1+1 려 우아채 새치 염색약 5종(각 48g) ※점별 운영상품 상이 ※교차구매 가능 각11,900원

    공구핫딜 케라시스 단백질
    염색약 기획(각 120g×2) 각8,900원
    ※내추럴블랙/내추럴브라운

    자민경 신상품 2종
    1개 이상 구매시
    장민호 포토카드
    1매 랜덤 증정
    ※한정수량으로 조기품절될 수 있습니다
    ※포토카드 소진 시 행사 조기종료
    ※구분자 "㉮"를 확인하세요

    자민경 핑크 달팽이크림/프로폴리스 크림&앰플(상품별 상이) 각19,900원

    우르오스
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉺"를 확인하세요
    ※결제시 계산대에서 수령

    우르오스(상품별 상이) 각10,560~23,760원

    하기스 기저귀
    8만원 이상 구매시
    10,000원
    롯데상품권 증정
    ※기간 : 9/5(목)~9/18(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 구매시 35% 하기스 맥스드라이/매직컴포트/네이처메이드 29종(상품별 상이) 각19,890~34,320원

    헨켈(퍼울/퍼실/프릴/브레프) 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "ⓗ"를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 구매시 50% 프릴 전품목(용기 700ml, 리필 1L) ※일부품목 제외 각4,750원

    유한킴벌리 행사상품
    3만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "●"를 확인하세요
    ※결제시 계산대에서 수령 ※해당상품에 한함

    크리넥스 안심클래식 키친타월/칼로리라이트 키친타월(상품별 상이) ※점별 운영상품 상이 6,790/9,100원

    2개 이상 구매시 50% 유한킴벌리 최대 65종(상품별 상이)※점별 입점상품 상이 ※행사 상품에 한함 각1,300~11,550원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    50% 글라스락 센스 각2,950~9,950원
    시리즈(상품별 상이)
    ※행사상품에 한함 ※점별 재고 상이

    30% 코렐 식기류(상품별 상이) 각5,530~15,920원
    ※시나모롤/더블링그린티/누보스케치/스누피앤찰리/보라꽃 외
    ※점별 입점상품 상이 ※행사상품에 한함
 
    40% 덴비 엘레멘츠(상품별 상이) 각10,740~14,630원
    ※스톤화이트/핑크/펀그린
    ※점별 입점상품 상이 ※행사상품에 한함

    30% 썬터치 휴대용 가스렌지(상품별 상이) 각16,030~55,930원
    ※행사상품에 한함 ※점별 재고 상이 ※이미지 컷입니다

    1+1 기획팩 테이팩스 니트릴장갑(각 100매) 각8,900원
    ※화이트/블랙 ※행사상품에 한함 ※점별 재고 상이

    오늘좋은 데일리
    정전기 청소포 각4,900원
    (표준형 50매/대형 40매)

    롯데/신한/NH농협카드 10% 오늘좋은 데일리
    피치스킨 이불(S) 외 각13,500원
    ※S : 150×200cm
    ※점별 재고 상이 ※해당상품에 한함

    30% 룸바이홈 부드러운 침구(베개커버/싱글/퀸) 각7,630~41,930원

    롯데/신한/NH농협카드 20% 요리하다 보냉백 2,390원
    ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    테팔 브랜드 전품목
    5만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉫ "를 확인하세요
    ※'도와드리겠습니다'센터 증정

    40% 테팔 스타트이지 프라이팬 5종/
    퍼펙트쿡 프라이팬 5종/
    인텐시움 통 3중 냄비 3종 각24,480~43,080원

    50% 아이리스 시스템 수납함
    (3호)(40×50×23.5cm) 13,950원
    ※기간 : 9/1(일)~9/30(월)

    1+1 룸바이홈 프리미엄
    호텔 발매트 18,900원
    (82×50cm) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이

    2개 이상 구매시 1개당 30% 브랜드 양말(상품별 상이) ※점별 재고 상이 각6,930~7,630원

    불스원 전품목
    3만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정

    1+1 밴딩 기획팩 그라스 디퓨저(각 105ml) 각24,900원
    ※점별 재고 상이

    에탄올 그린워셔 기획 (1.5L×3) ※점별 재고 상이 7,590원

    ㆍTSA LOCK 장착!
    ㆍ확장지퍼로 수납력 확대!

    오늘좋은 기내용 여행가방 49,000원
 
    2개 이상 구매시 50% 오늘좋은 피치스킨 홈웨어(상품별 상이) 각4,950~7,950원

    토이저러스 인기완구 30% 
    ※행사기간 : 9/5(목)~9/25(수)
    ※입점점에 한함 ※점별 재고수량 상이
    ※매장에 더많은 행사상품이 준비되어 있습니다

    최대 30% 헬로카봇 80종 (상품별 상이) 각14,000~131,600원

    30000원 할인 + 롯데카드/신한카드 30000원 추가할인 스위치 OLED 마리오레드(전체 이용가) 355,000원

    20% 핑크퐁 아기상어 코딩컴퓨터 (3세 이상/38×8×27cm) 103,200원

    10000원 할인 코디열쇠로 말하는
    신데렐라 미미 옷장 39,900원
    (37개월 이상/61×14.5×38cm)
    ※입점점에 한함

    콜리올리 반려동물 식/용품 20%

    20% 콜리올리 도톰한
    3~4회용 패드 16,720/14,320/11,120원
    (M/L/XL) ※점별 재고 상이

    20% 하림 밥이보약, 더리얼
    강아지/고양이 식품 26종 각3,200~22,240원
    (상품별 상이) ※점별 재고 상이

    인기와인 L.POINT 3000원 할인 & 맥주 골라담기

    L.POINT 3000원 킬리카눈 슬라우치 쉬라즈/ 토레스 레세르바 델 솔(각 750ml) 9,900/8,900원

    롯데마트&슈퍼에서 단독으로 만나는 스페인식 레몬 맥주!
    4캔 구매시 클라라 레몬비어(500ml) 10,400원

    6캔 구매시 세계맥주 골라담기 12,000원
  
    요리하다 온가족 함께 즐기는 인기 먹거리 추천! 오늘좋은

    L.POINT 1000원 요리하다 흰 깨송편/쑥 깨송편(각 500g) 각5,990원
    
    L.POINT 1000원 요리하다×농가맛집
    마늘 돼지고기 김치찌개(760g)/
    고추장 애호박 찌개(580g) 각10,900원
    
    L.POINT 1000원 오늘좋은 우리쌀 미니약과(500g) 3,990원

    600원 할인 오늘좋은 참기름(450ml) 5,990원

    롯데마트 GO앱 9월 이벤트 안내
    ▶기간 : 9/1(일) ~ 9/30(월)

    이벤트 1 신규가입 하면 7만원 이상 구매시 5천원 할인쿠폰 증정!
    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 롯데마트GO앱 신규가입 고객
    ※가입일자 : 9/1(일) ~ 9/30(월)
    ▶쿠폰 사용기간 : 9/1(일) ~ 10/6(일)
    ▶쿠폰 사용장소 : 롯데마트 매장 및 MAXX 6개점

    이벤트 2 출석체크 하면 최대 L.POINT 200P/최대 5천원 할인쿠폰 증정!

    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 출석체크 참여 & 수신동의 고객
    ※마케팅 목적 정보이용 동의 및 광고성정보 (Push) 수신동의
    ▶당첨 안내 및 쿠폰 지급 : 10/7(월)
    ▶쿠폰 사용기간 : 10/7(월)~10/20(일)
    ▶쿠폰 사용장소 : 롯데마트 매장 및 MAXX 6개점

    GO앱 출석체크 하면 매일매일 L.POINT 200P 적립!

    Push 수신동의 고객 200명 추첨! 최대 5천원 할인쿠폰 증정!

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div> 
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 9. 12(목) ~ 2024. 9. 18(수) < 비신선식품 적용기간 : 2024. 9. 5(목) ~ 9. 18(수) >

    통큰 한가위 장보기 솔루션

    L.POINT 40% 1등급 한우 국거리/불고기
    (각 100g/냉장/국내산 한우고기)

    각 1000원 할인 백설 고기양념 4종(각 500g)
    ※소갈비/소불고기/돼지갈비/돼지불고기 양념 각3,480원

    L.POINT 최대1,200원 오뚜기 옛날 자른당면 3,980/6,480원
    (300g/500g)

    제수용 황태포/용대리 황태포 (60g 내외/75g 내외, 원산지 별도표기) 4,990/5,990원

    L.POINT 20% 간편수제 명태살/대구살 7,920/14,320원
    (각 500g/냉동/원산지 별도표기)

    L.POINT 20% 껍질벗긴 새우 15,920원
    (750g/냉동/원산지 별도표기)

    2팩 이상 구매시 각 1000원 할인 데친 고사리(300g/팩/국산)/깐도라지(250g/팩/국산) 각8,990원

    우리쌀 송편(100g/원산지 별도표기) ※기간 : 9/14(토) ~ 9/16(월) 1,091~1,900원

    제수용 배(3입/팩/국산) 17,990원

    제수용 사과(3입/팩/국산) 17,990원

    경산 햇대추(300g/팩/국산) 6,990원

    밤/깐밤(700g/150g, 봉/국산) 7,990/7,490원

    2개 이상 구매시 1개 당 500원 할인 CJ 비비고 도톰 동그랑땡/해물완자(425g×2/795g) 각9,480원

    백화수복 4,620원

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라!
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    L.POINT 50% 한우 양념 불고기(800g/냉장/원산지 별도표기) 18,900원

    L.POINT 10% + 롯데/신한/NH농협카드 10% 추가할인 제수용 문어(100g/냉장/국산) 4,852원
    ※페이지 하단 카드할인 세부내용 참고

    양파(2kg/망/국산) 3,990원
 
    GAP 하우스감귤 13,990원

    롯데/신한/NH농협카드 500원 할인 목우촌 주부9단
    살코기햄(1kg) 9,980원
    ※페이지 하단 카드할인 세부내용 참고

    1040원 할인 팔도 왕뚜껑(4개입) 1,980원

    2920원 할인 + 롯데/신한/NH농협카드 1000원 추가할인 크리스피 허니눈꽃 쌀과자(920g) 7,980원
    ※ 원주점, 의왕점, 서대전점 별도 행사 진행

    명절 전통음료 4종 2,980~3,900원

    6950원 할인 메디안 잇몸과학 치약
    2종(각 120g×3입) 각5,950원

    2개 이상 구매시 1개당 50% 할인 크리넥스
    울트라클린
    카카오(30m×30롤) 각17,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
    이번주 핫프라이스

    4인 가족 한 팩이면 충분!
    2근에 1만원대! ※1인분 300g 기준
    국내산 한돈 갈비
    (1.2kg/냉장/국내산)
    ※제주점 제외 ※조기 품절될 수 있습니다

    롯데/신한/NH농협카드 30% 15,890원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div>
  `,
  `
  <div>
    L.POINT 10000원 할인미국산/호주산
    LA갈비
    (각 1kg/냉동)
    ※미국산, 호주산

    미국산/호주산 찜갈비 (각 100g/냉장)
    L.POINT 30%할인

    L.POINT 40% 호주산 소 불고기/육전용
    (상품별 상이/냉장, 냉동/호주산)

    브랜드 돼지고기
    삼겹살/목심 각3,890원
    (각 100g/냉장/국내산 돼지고기)
    ※점별 입점 브랜드 상이 ※제주점 제외 ※입점점포 한정

    L.POINT 20000원 할인 통큰 양념 LA식 꽃갈비 59,900원
    (1.5kg/냉장/원산지 별도표기)

    L.POINT 5000원 할인 요리하다
    날개살로만
    훈제오리 6,900원
    (350g/냉장/원산지 별도표기)

    L.POINT 25% 완도 활 전복(왕/대) 4,350/2,475원
    (마리/냉장/국산)

    부세조기(마리/해동/중국산) 3,990원

    L.POINT 7000원 프리미엄
    파타고니아 생연어 22,900원
    (500g/냉장/칠레산)

    L.POINT 10% 광어연어우럭도미 39,960원
    모둠회(400g 내외/냉장)
    ※원산지 별도표기

    GAP 친환경 새송이(300g×2봉/국산)/
    GAP 친환경 표고버섯(300g/팩/국산)/
    친환경(生) 목이버섯(320g/팩/국산) 4,990/6,490/5,990원

    부침용 고구마(100g/국산) 590원

    L.POINT 7000원 GAP 완전미
    고시히카리 39,900원
    (10kg/국산)

    머루포도(3kg/박스/국산) 19,990원

    제수용 곶감 (8입/팩/국산) 10,990원

    하늘청 식혜/수정과 각5,990원
    (각 1개/원산지 별도표기)

    L.POINT 30% 갱엿 순살 닭강정(대) 11,893원
    (1팩/원산지 별도표기)

    해물 양장피(1팩/원산지 별도표기) 15,990원

    한돈 수제 떡갈비 6,990원

    모듬전&모듬나물(원산지 별도표기) 각3,900원
    ※일부 품목에 한함 ※반찬운영점 한함 ※연출된 이미지컷입니다
    ※기간 : 9/14(토)~9/16(월)

    하나사면 하나 더 1+1

    1+1 CJ 맥스봉 오리지널(525g) 8,480원

    1+1 CJ 육개장/들깨 칼국수 (각 2인) ※교차구매 가능 각8,480원

    1+1 사세 바삭 튀긴 통새우튀김 (300g) 9,900원

    1+1 CJ 고메 크리스피 핫도그/
    치즈핫도그(400g/340g) 각8,480원
    ※기간 : 9/12(목)~9/15(일) ※교차구매 가능

    1+1 동원 양반 진국 사골 곰탕 (500g) 1,980원

    1+1 매일유업 이유식/유아간식 30종 (상품별 규격상이) ※동일가격 교차구매 가능 각1,500~4,900원

    1+1 청정원 순창 찰골드 고추장 (1.4kg) 14,980원

    1+1 이보노이토 수제 소면(300g) 6,400원

    1+1 매일 상하 스트링치즈(18g×10입) 12,980원

    1+1 아내의 착즙주스 배도라지 외 4종 각29,900원
    (각 100ml×21입) ※ 교차구매 가능
    ※사과/감귤사과/당근사과/양배추사과

    1+1 칠성사이다/펩시/펩시제로/밀키스 각5,480원
    (각 190ml×6입) ※교차구매 가능

    1+1 굿바이 썸머 스포츠 음료 10종 각1,980~3,750원
    (상품별 규격상이) ※동일시리즈 상품간 교차구매 가능

    1+1 구카 누가 필링/솔티커피 누가 크래커 각9,980원
    (각 400g) ※교차구매 가능

    1+1 켈로그 시리얼바 전품목 각4,680/5,880원
    (상품별 규격상이) ※동일가격 교차구매 가능

    2개 이상 구매시 더싸게

    2개 이상 구매시 한개당 50% 한성 프로틴 크랩 각3,490원
    
    2개 이상 구매시 한개당 500원 CJ 비비고 남도 떡갈비/
    한입떡갈비기획/잔칫집 모둠잡채 각8,980원
    (상품별 규격상이) ※점별 운영상품 상이

    2개 이상 구매시 한개당 50% 롯데 쉐푸드
    미니돈까스(380g) 각3,490원

    2+1 동원 양반
    비빔드밥 6종 각4,980원
    (상품별 규격상이) ※교차구매 가능
    
    2개 이상 구매시 한개당 1500원 할인 폰타나 파스타소스 8종 각5,480~7,480원
    (각 430g) ※카르니아 베이컨&머쉬룸 크림 外
    
    2개 이상 구매시 한개당 50% 파스퇴르 위편한/속편한 요구르트/
    제로 플레인 요거트(상품별 규격상이) 각2,240~3,340원
    ※기간 : 9/12(목)~9/15(일) ※ 교차구매 가능
    
    2개 이상 구매시 한개당 50% 매일 상하 뼈로 가는 칼슘치즈/
    지방을 낮춘 고단백치즈(각 270g) 각4,990원
    
    2개 이상 구매시 한개당 30% 농심 포테토칩 4종
    (상품별 규격상이) 각1,771원
    ※ 오리지날/올디스타코맛/
    먹태청양마요맛/먹태고추장마요맛
    
    2개 이상 구매시 한개당 10%
    3개 이상 구매시 한개당 15% CJ 쁘띠첼 10종 각1,870원
    ( 각 210g) ※교차구매 가능

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    씨제이제일제당 전품목
    4만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 "ⓒ"을 확인하세요

    1분링 50개입으로 아낌없이 넉넉하게! 백설 멸치디포리 가득
    육수에는 1분링(200g) 8,980원

    그릴비엔나 구매시 안심비엔나 200g 증정!
    CJ 더건강한 그릴 비엔나(560g)/비비고 왕교자(350g×3) 8,980/9,980원

    사조대림 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉦ "을 확인하세요

    L.POINT 1980원 해표 식용유(1.7L) 6,120원

    사조대림 게맛살(500g) 3,780원

    동원에프앤비 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉢ "을 확인하세요

    1+1 동원 참치액 진/순 각7,980원
    (각 500g) ※교차구매 가능

    1+1 동원 리챔 클래식/더블라이트 각15,480원
    (각 200g×3) ※교차구매 가능

    동원 그릴리 델리햄(450g)/ 양반 통살 고기완자(850g) 6,480/9,480원

    샘표식품 전품목
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ⓢ "을 확인하세요

    샘표 진간장 금F-3 3입 기획(500ml×3입) 7,900원


    오뚜기 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉧ "을 확인하세요

    각 420원 할인 오뚜기 부침/튀김가루(각 1kg) 각2,980원

    오뚜기 갈비산적(400g×2입)/오뚜기 해물완자(390g×2입) 각8,980원

    오뚜기 열김치우동(2인) 5,980원

    롯데웰푸드 해당상품
    2만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉣ "을 확인하세요
    ※분유, 냉장 밀키트, 아이스크림 제외

    롯데 의성마늘햄(440g+100g증정)/쉐푸드 떡갈비(570g) 8,580/8,480원

    대상주식회사 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " D "을 확인하세요

    각1,000원 할인
    청정원 올리고당 2종(각 1.2kg) 각2,980원

    종가 아삭하고 개운한 포기김치(3kg) 24,900원
    
    동원 소와나무
    순수우유 기획
    (900ml×2입) 3,980원

    L.POINT 3000원 뉴케어 3종(각 200ml×16입) ※구수한맛/검은깨맛/인절미맛 각26,900~29,900원

    각3,000원 할인 센트룸 전품목(상품별 상이) 각23,000~24,000원

    살림안정 물가안정 인기 생필품

    롯데/신한/NH농협카드 60% 온더바디 데일리퍼퓸
    바디워시 3종(각 1,100ml) 각5,560원
    ※로즈/라벤더/머스크향
    ※점별 운영상품 상이

    2개 이상 구매시 한개당 50% 해피바스 스마일 바디워시/
    오리지널컬렉션 비누 각5,950원 
    (각 900g/90g×4) ※점별 운영상품 상이

    롯데/신한/NH농협카드 1+1 페브리즈 용기 6종 각6,900원
    (각 370ml) ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    1+1 다우니 생화향기 리필 5종 각23,300원
    (각 2.6L) ※교차구매 가능
    ※미스티크/시트러스버베나/코튼/스프링가든/프리지아페어

    바세린 컬러
    립밤 2종(각 4.2g) 각6,900원
    ※키씽레드/그레이프프룻

    2개 이상 구매시 1개당 50% 프리메이 핑크콜라겐/
    올리브시카카밍앰플 
    마스크팩 2종 각4,500원
    (각 5매입) ※점별 운영상품 상이

    롯데/신한/NH농협카드 2개이상 구매시 1개당 50% 프로쉬 전품목(상품별 상이) ※일부품목 제외 각3,450~7,750원
    ※페이지 하단 카드할인 세부내용 참고

    1+1 다우니 실내건조 세탁세제(3.2L) 36,900원

    메디안 잇몸과학 치약 2종(각 120g×3입) ※스트롱민트/클린민트 각5,950원

    7030원 할인 리엔 보윤 샴푸/컨디셔너 3종 (각 1,000ml) ※점별 운영상품 상이 각7,950원

    2개 이상 2500원 할인 닥터그루트 7주년 기획팩
    집중케어 샴푸 2종/두피영양토닉 각20,500원
    (상품별 상이) ※점별 운영상품 상이 ※샴푸 : 힘없는모발용/지성두피용

    2000원 할인 깨끗한나라 클린프리미엄 3겹 화장지 (30m×30롤) 14,900원

    1+1 려 우아채 새치 염색약 5종(각 48g) ※점별 운영상품 상이 ※교차구매 가능 각11,900원

    공구핫딜 케라시스 단백질
    염색약 기획(각 120g×2) 각8,900원
    ※내추럴블랙/내추럴브라운

    자민경 신상품 2종
    1개 이상 구매시
    장민호 포토카드
    1매 랜덤 증정
    ※한정수량으로 조기품절될 수 있습니다
    ※포토카드 소진 시 행사 조기종료
    ※구분자 "㉮"를 확인하세요

    자민경 핑크 달팽이크림/프로폴리스 크림&앰플(상품별 상이) 각19,900원

    우르오스
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉺"를 확인하세요
    ※결제시 계산대에서 수령

    우르오스(상품별 상이) 각10,560~23,760원

    하기스 기저귀
    8만원 이상 구매시
    10,000원
    롯데상품권 증정
    ※기간 : 9/5(목)~9/18(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 구매시 35% 하기스 맥스드라이/매직컴포트/네이처메이드 29종(상품별 상이) 각19,890~34,320원

    헨켈(퍼울/퍼실/프릴/브레프) 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "ⓗ"를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 구매시 50% 프릴 전품목(용기 700ml, 리필 1L) ※일부품목 제외 각4,750원

    유한킴벌리 행사상품
    3만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "●"를 확인하세요
    ※결제시 계산대에서 수령 ※해당상품에 한함

    크리넥스 안심클래식 키친타월/칼로리라이트 키친타월(상품별 상이) ※점별 운영상품 상이 6,790/9,100원

    2개 이상 구매시 50% 유한킴벌리 최대 65종(상품별 상이)※점별 입점상품 상이 ※행사 상품에 한함 각1,300~11,550원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
        50% 글라스락 센스 각2,950~9,950원
    시리즈(상품별 상이)
    ※행사상품에 한함 ※점별 재고 상이

    30% 코렐 식기류(상품별 상이) 각5,530~15,920원
    ※시나모롤/더블링그린티/누보스케치/스누피앤찰리/보라꽃 외
    ※점별 입점상품 상이 ※행사상품에 한함
 
    40% 덴비 엘레멘츠(상품별 상이) 각10,740~14,630원
    ※스톤화이트/핑크/펀그린
    ※점별 입점상품 상이 ※행사상품에 한함

    30% 썬터치 휴대용 가스렌지(상품별 상이) 각16,030~55,930원
    ※행사상품에 한함 ※점별 재고 상이 ※이미지 컷입니다

    1+1 기획팩 테이팩스 니트릴장갑(각 100매) 각8,900원
    ※화이트/블랙 ※행사상품에 한함 ※점별 재고 상이

    오늘좋은 데일리
    정전기 청소포 각4,900원
    (표준형 50매/대형 40매)

    롯데/신한/NH농협카드 10% 오늘좋은 데일리
    피치스킨 이불(S) 외 각13,500원
    ※S : 150×200cm
    ※점별 재고 상이 ※해당상품에 한함

    30% 룸바이홈 부드러운 침구(베개커버/싱글/퀸) 각7,630~41,930원

    롯데/신한/NH농협카드 20% 요리하다 보냉백 2,390원
    ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    테팔 브랜드 전품목
    5만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉫ "를 확인하세요
    ※'도와드리겠습니다'센터 증정

    40% 테팔 스타트이지 프라이팬 5종/
    퍼펙트쿡 프라이팬 5종/
    인텐시움 통 3중 냄비 3종 각24,480~43,080원

    50% 아이리스 시스템 수납함
    (3호)(40×50×23.5cm) 13,950원
    ※기간 : 9/1(일)~9/30(월)

    1+1 룸바이홈 프리미엄
    호텔 발매트 18,900원
    (82×50cm) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이

    2개 이상 구매시 1개당 30% 브랜드 양말(상품별 상이) ※점별 재고 상이 각6,930~7,630원

    불스원 전품목
    3만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정

    1+1 밴딩 기획팩 그라스 디퓨저(각 105ml) 각24,900원
    ※점별 재고 상이

    에탄올 그린워셔 기획 (1.5L×3) ※점별 재고 상이 7,590원

    ㆍTSA LOCK 장착!
    ㆍ확장지퍼로 수납력 확대!

    오늘좋은 기내용 여행가방 49,000원
 
    2개 이상 구매시 50% 오늘좋은 피치스킨 홈웨어(상품별 상이) 각4,950~7,950원

    토이저러스 인기완구 30% 
    ※행사기간 : 9/5(목)~9/25(수)
    ※입점점에 한함 ※점별 재고수량 상이
    ※매장에 더많은 행사상품이 준비되어 있습니다

    최대 30% 헬로카봇 80종 (상품별 상이) 각14,000~131,600원

    30000원 할인 + 롯데카드/신한카드 30000원 추가할인 스위치 OLED 마리오레드(전체 이용가) 355,000원

    20% 핑크퐁 아기상어 코딩컴퓨터 (3세 이상/38×8×27cm) 103,200원

    10000원 할인 코디열쇠로 말하는
    신데렐라 미미 옷장 39,900원
    (37개월 이상/61×14.5×38cm)
    ※입점점에 한함

    콜리올리 반려동물 식/용품 20%

    20% 콜리올리 도톰한
    3~4회용 패드 16,720/14,320/11,120원
    (M/L/XL) ※점별 재고 상이

    20% 하림 밥이보약, 더리얼
    강아지/고양이 식품 26종 각3,200~22,240원
    (상품별 상이) ※점별 재고 상이

    인기와인 L.POINT 3000원 할인 & 맥주 골라담기

    L.POINT 3000원 킬리카눈 슬라우치 쉬라즈/ 토레스 레세르바 델 솔(각 750ml) 9,900/8,900원

    롯데마트&슈퍼에서 단독으로 만나는 스페인식 레몬 맥주!
    4캔 구매시 클라라 레몬비어(500ml) 10,400원

    6캔 구매시 세계맥주 골라담기 12,000원
  
    요리하다 온가족 함께 즐기는 인기 먹거리 추천! 오늘좋은

    L.POINT 1000원 요리하다 흰 깨송편/쑥 깨송편(각 500g) 각5,990원
    
    L.POINT 1000원 요리하다×농가맛집
    마늘 돼지고기 김치찌개(760g)/
    고추장 애호박 찌개(580g) 각10,900원
    
    L.POINT 1000원 오늘좋은 우리쌀 미니약과(500g) 3,990원

    600원 할인 오늘좋은 참기름(450ml) 5,990원

    롯데마트 GO앱 9월 이벤트 안내
    ▶기간 : 9/1(일) ~ 9/30(월)

    이벤트 1 신규가입 하면 7만원 이상 구매시 5천원 할인쿠폰 증정!
    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 롯데마트GO앱 신규가입 고객
    ※가입일자 : 9/1(일) ~ 9/30(월)
    ▶쿠폰 사용기간 : 9/1(일) ~ 10/6(일)
    ▶쿠폰 사용장소 : 롯데마트 매장 및 MAXX 6개점

    이벤트 2 출석체크 하면 최대 L.POINT 200P/최대 5천원 할인쿠폰 증정!

    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 출석체크 참여 & 수신동의 고객
    ※마케팅 목적 정보이용 동의 및 광고성정보 (Push) 수신동의
    ▶당첨 안내 및 쿠폰 지급 : 10/7(월)
    ▶쿠폰 사용기간 : 10/7(월)~10/20(일)
    ▶쿠폰 사용장소 : 롯데마트 매장 및 MAXX 6개점

    GO앱 출석체크 하면 매일매일 L.POINT 200P 적립!

    Push 수신동의 고객 200명 추첨! 최대 5천원 할인쿠폰 증정!

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div> 
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 9. 12(목) ~ 2024. 9. 18(수) < 비신선식품 적용기간 : 2024. 9. 5(목) ~ 9. 18(수) >

    통큰 한가위 장보기 솔루션

    L.POINT 40% 1등급 한우 국거리/불고기
    (각 100g/냉장/국내산 한우고기)

    각 1000원 할인 백설 고기양념 4종(각 500g)
    ※소갈비/소불고기/돼지갈비/돼지불고기 양념 각3,480원

    L.POINT 최대1,200원 오뚜기 옛날 자른당면 3,980/6,480원
    (300g/500g)

    제수용 황태포/용대리 황태포 (60g 내외/75g 내외, 원산지 별도표기) 4,990/5,990원

    L.POINT 20% 간편수제 명태살/대구살 7,920/14,320원
    (각 500g/냉동/원산지 별도표기)

    L.POINT 20% 껍질벗긴 새우 15,920원
    (750g/냉동/원산지 별도표기)

    2팩 이상 구매시 각 1000원 할인 데친 고사리(300g/팩/국산)/깐도라지(250g/팩/국산) 각8,990원

    우리쌀 송편(100g/원산지 별도표기) ※기간 : 9/14(토) ~ 9/16(월) 1,091~1,900원

    제수용 배(3입/팩/국산) 17,990원

    제수용 사과(3입/팩/국산) 17,990원

    경산 햇대추(300g/팩/국산) 6,990원

    밤/깐밤(700g/150g, 봉/국산) 7,990/7,490원

    2개 이상 구매시 1개 당 500원 할인 CJ 비비고 도톰 동그랑땡/해물완자(425g×2/795g) 각9,480원

    백화수복 4,620원

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라!
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    L.POINT 50% 한우 양념 불고기(800g/냉장/원산지 별도표기) 18,900원

    L.POINT 10% + 롯데/신한/NH농협카드 10% 추가할인 제수용 문어(100g/냉장/국산) 4,852원
    ※페이지 하단 카드할인 세부내용 참고

    양파(2kg/망/국산) 3,990원
 
    GAP 하우스감귤 13,990원

    롯데/신한/NH농협카드 500원 할인 목우촌 주부9단
    살코기햄(1kg) 9,980원
    ※페이지 하단 카드할인 세부내용 참고

    1040원 할인 팔도 왕뚜껑(4개입) 1,980원

    2920원 할인 + 롯데/신한/NH농협카드 1000원 추가할인 크리스피 허니눈꽃 쌀과자(920g) 7,980원
    ※ 원주점, 의왕점, 서대전점 별도 행사 진행

    명절 전통음료 4종 2,980~3,900원

    6950원 할인 메디안 잇몸과학 치약
    2종(각 120g×3입) 각5,950원

    2개 이상 구매시 1개당 50% 할인 크리넥스
    울트라클린
    카카오(30m×30롤) 각17,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
    이번주 핫프라이스

    4인 가족 한 팩이면 충분!
    2근에 1만원대! ※1인분 300g 기준
    국내산 한돈 갈비
    (1.2kg/냉장/국내산)
    ※제주점 제외 ※조기 품절될 수 있습니다

    롯데/신한/NH농협카드 30% 15,890원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div>
  `,
  `
  <div>
    L.POINT 10000원 할인미국산/호주산
    LA갈비
    (각 1kg/냉동)
    ※미국산, 호주산

    미국산/호주산 찜갈비 (각 100g/냉장)
    L.POINT 30%할인

    L.POINT 40% 호주산 소 불고기/육전용
    (상품별 상이/냉장, 냉동/호주산)

    브랜드 돼지고기
    삼겹살/목심 각3,890원
    (각 100g/냉장/국내산 돼지고기)
    ※점별 입점 브랜드 상이 ※제주점 제외 ※입점점포 한정

    L.POINT 20000원 할인 통큰 양념 LA식 꽃갈비 59,900원
    (1.5kg/냉장/원산지 별도표기)

    L.POINT 5000원 할인 요리하다
    날개살로만
    훈제오리 6,900원
    (350g/냉장/원산지 별도표기)

    L.POINT 25% 완도 활 전복(왕/대) 4,350/2,475원
    (마리/냉장/국산)

    부세조기(마리/해동/중국산) 3,990원

    L.POINT 7000원 프리미엄
    파타고니아 생연어 22,900원
    (500g/냉장/칠레산)

    L.POINT 10% 광어연어우럭도미 39,960원
    모둠회(400g 내외/냉장)
    ※원산지 별도표기

    GAP 친환경 새송이(300g×2봉/국산)/
    GAP 친환경 표고버섯(300g/팩/국산)/
    친환경(生) 목이버섯(320g/팩/국산) 4,990/6,490/5,990원

    부침용 고구마(100g/국산) 590원

    L.POINT 7000원 GAP 완전미
    고시히카리 39,900원
    (10kg/국산)

    머루포도(3kg/박스/국산) 19,990원

    제수용 곶감 (8입/팩/국산) 10,990원

    하늘청 식혜/수정과 각5,990원
    (각 1개/원산지 별도표기)

    L.POINT 30% 갱엿 순살 닭강정(대) 11,893원
    (1팩/원산지 별도표기)

    해물 양장피(1팩/원산지 별도표기) 15,990원

    한돈 수제 떡갈비 6,990원

    모듬전&모듬나물(원산지 별도표기) 각3,900원
    ※일부 품목에 한함 ※반찬운영점 한함 ※연출된 이미지컷입니다
    ※기간 : 9/14(토)~9/16(월)

    하나사면 하나 더 1+1

    1+1 CJ 맥스봉 오리지널(525g) 8,480원

    1+1 CJ 육개장/들깨 칼국수 (각 2인) ※교차구매 가능 각8,480원

    1+1 사세 바삭 튀긴 통새우튀김 (300g) 9,900원

    1+1 CJ 고메 크리스피 핫도그/
    치즈핫도그(400g/340g) 각8,480원
    ※기간 : 9/12(목)~9/15(일) ※교차구매 가능

    1+1 동원 양반 진국 사골 곰탕 (500g) 1,980원

    1+1 매일유업 이유식/유아간식 30종 (상품별 규격상이) ※동일가격 교차구매 가능 각1,500~4,900원

    1+1 청정원 순창 찰골드 고추장 (1.4kg) 14,980원

    1+1 이보노이토 수제 소면(300g) 6,400원

    1+1 매일 상하 스트링치즈(18g×10입) 12,980원

    1+1 아내의 착즙주스 배도라지 외 4종 각29,900원
    (각 100ml×21입) ※ 교차구매 가능
    ※사과/감귤사과/당근사과/양배추사과

    1+1 칠성사이다/펩시/펩시제로/밀키스 각5,480원
    (각 190ml×6입) ※교차구매 가능

    1+1 굿바이 썸머 스포츠 음료 10종 각1,980~3,750원
    (상품별 규격상이) ※동일시리즈 상품간 교차구매 가능

    1+1 구카 누가 필링/솔티커피 누가 크래커 각9,980원
    (각 400g) ※교차구매 가능

    1+1 켈로그 시리얼바 전품목 각4,680/5,880원
    (상품별 규격상이) ※동일가격 교차구매 가능

    2개 이상 구매시 더싸게

    2개 이상 구매시 한개당 50% 한성 프로틴 크랩 각3,490원
    
    2개 이상 구매시 한개당 500원 CJ 비비고 남도 떡갈비/
    한입떡갈비기획/잔칫집 모둠잡채 각8,980원
    (상품별 규격상이) ※점별 운영상품 상이

    2개 이상 구매시 한개당 50% 롯데 쉐푸드
    미니돈까스(380g) 각3,490원

    2+1 동원 양반
    비빔드밥 6종 각4,980원
    (상품별 규격상이) ※교차구매 가능
    
    2개 이상 구매시 한개당 1500원 할인 폰타나 파스타소스 8종 각5,480~7,480원
    (각 430g) ※카르니아 베이컨&머쉬룸 크림 外
    
    2개 이상 구매시 한개당 50% 파스퇴르 위편한/속편한 요구르트/
    제로 플레인 요거트(상품별 규격상이) 각2,240~3,340원
    ※기간 : 9/12(목)~9/15(일) ※ 교차구매 가능
    
    2개 이상 구매시 한개당 50% 매일 상하 뼈로 가는 칼슘치즈/
    지방을 낮춘 고단백치즈(각 270g) 각4,990원
    
    2개 이상 구매시 한개당 30% 농심 포테토칩 4종
    (상품별 규격상이) 각1,771원
    ※ 오리지날/올디스타코맛/
    먹태청양마요맛/먹태고추장마요맛
    
    2개 이상 구매시 한개당 10%
    3개 이상 구매시 한개당 15% CJ 쁘띠첼 10종 각1,870원
    ( 각 210g) ※교차구매 가능

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    씨제이제일제당 전품목
    4만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 "ⓒ"을 확인하세요

    1분링 50개입으로 아낌없이 넉넉하게! 백설 멸치디포리 가득
    육수에는 1분링(200g) 8,980원

    그릴비엔나 구매시 안심비엔나 200g 증정!
    CJ 더건강한 그릴 비엔나(560g)/비비고 왕교자(350g×3) 8,980/9,980원

    사조대림 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉦ "을 확인하세요

    L.POINT 1980원 해표 식용유(1.7L) 6,120원

    사조대림 게맛살(500g) 3,780원

    동원에프앤비 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉢ "을 확인하세요

    1+1 동원 참치액 진/순 각7,980원
    (각 500g) ※교차구매 가능

    1+1 동원 리챔 클래식/더블라이트 각15,480원
    (각 200g×3) ※교차구매 가능

    동원 그릴리 델리햄(450g)/ 양반 통살 고기완자(850g) 6,480/9,480원

    샘표식품 전품목
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ⓢ "을 확인하세요

    샘표 진간장 금F-3 3입 기획(500ml×3입) 7,900원


    오뚜기 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉧ "을 확인하세요

    각 420원 할인 오뚜기 부침/튀김가루(각 1kg) 각2,980원

    오뚜기 갈비산적(400g×2입)/오뚜기 해물완자(390g×2입) 각8,980원

    오뚜기 열김치우동(2인) 5,980원

    롯데웰푸드 해당상품
    2만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉣ "을 확인하세요
    ※분유, 냉장 밀키트, 아이스크림 제외

    롯데 의성마늘햄(440g+100g증정)/쉐푸드 떡갈비(570g) 8,580/8,480원

    대상주식회사 전품목
    4만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※선물세트 제외 ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " D "을 확인하세요

    각1,000원 할인
    청정원 올리고당 2종(각 1.2kg) 각2,980원

    종가 아삭하고 개운한 포기김치(3kg) 24,900원
    
    동원 소와나무
    순수우유 기획
    (900ml×2입) 3,980원

    L.POINT 3000원 뉴케어 3종(각 200ml×16입) ※구수한맛/검은깨맛/인절미맛 각26,900~29,900원

    각3,000원 할인 센트룸 전품목(상품별 상이) 각23,000~24,000원

    살림안정 물가안정 인기 생필품

    롯데/신한/NH농협카드 60% 온더바디 데일리퍼퓸
    바디워시 3종(각 1,100ml) 각5,560원
    ※로즈/라벤더/머스크향
    ※점별 운영상품 상이

    2개 이상 구매시 한개당 50% 해피바스 스마일 바디워시/
    오리지널컬렉션 비누 각5,950원 
    (각 900g/90g×4) ※점별 운영상품 상이

    롯데/신한/NH농협카드 1+1 페브리즈 용기 6종 각6,900원
    (각 370ml) ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    1+1 다우니 생화향기 리필 5종 각23,300원
    (각 2.6L) ※교차구매 가능
    ※미스티크/시트러스버베나/코튼/스프링가든/프리지아페어

    바세린 컬러
    립밤 2종(각 4.2g) 각6,900원
    ※키씽레드/그레이프프룻

    2개 이상 구매시 1개당 50% 프리메이 핑크콜라겐/
    올리브시카카밍앰플 
    마스크팩 2종 각4,500원
    (각 5매입) ※점별 운영상품 상이

    롯데/신한/NH농협카드 2개이상 구매시 1개당 50% 프로쉬 전품목(상품별 상이) ※일부품목 제외 각3,450~7,750원
    ※페이지 하단 카드할인 세부내용 참고

    1+1 다우니 실내건조 세탁세제(3.2L) 36,900원

    메디안 잇몸과학 치약 2종(각 120g×3입) ※스트롱민트/클린민트 각5,950원

    7030원 할인 리엔 보윤 샴푸/컨디셔너 3종 (각 1,000ml) ※점별 운영상품 상이 각7,950원

    2개 이상 2500원 할인 닥터그루트 7주년 기획팩
    집중케어 샴푸 2종/두피영양토닉 각20,500원
    (상품별 상이) ※점별 운영상품 상이 ※샴푸 : 힘없는모발용/지성두피용

    2000원 할인 깨끗한나라 클린프리미엄 3겹 화장지 (30m×30롤) 14,900원

    1+1 려 우아채 새치 염색약 5종(각 48g) ※점별 운영상품 상이 ※교차구매 가능 각11,900원

    공구핫딜 케라시스 단백질
    염색약 기획(각 120g×2) 각8,900원
    ※내추럴블랙/내추럴브라운

    자민경 신상품 2종
    1개 이상 구매시
    장민호 포토카드
    1매 랜덤 증정
    ※한정수량으로 조기품절될 수 있습니다
    ※포토카드 소진 시 행사 조기종료
    ※구분자 "㉮"를 확인하세요

    자민경 핑크 달팽이크림/프로폴리스 크림&앰플(상품별 상이) 각19,900원

    우르오스
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉺"를 확인하세요
    ※결제시 계산대에서 수령

    우르오스(상품별 상이) 각10,560~23,760원

    하기스 기저귀
    8만원 이상 구매시
    10,000원
    롯데상품권 증정
    ※기간 : 9/5(목)~9/18(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 구매시 35% 하기스 맥스드라이/매직컴포트/네이처메이드 29종(상품별 상이) 각19,890~34,320원

    헨켈(퍼울/퍼실/프릴/브레프) 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "ⓗ"를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 구매시 50% 프릴 전품목(용기 700ml, 리필 1L) ※일부품목 제외 각4,750원

    유한킴벌리 행사상품
    3만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "●"를 확인하세요
    ※결제시 계산대에서 수령 ※해당상품에 한함

    크리넥스 안심클래식 키친타월/칼로리라이트 키친타월(상품별 상이) ※점별 운영상품 상이 6,790/9,100원

    2개 이상 구매시 50% 유한킴벌리 최대 65종(상품별 상이)※점별 입점상품 상이 ※행사 상품에 한함 각1,300~11,550원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    50% 글라스락 센스 각2,950~9,950원
    시리즈(상품별 상이)
    ※행사상품에 한함 ※점별 재고 상이

    30% 코렐 식기류(상품별 상이) 각5,530~15,920원
    ※시나모롤/더블링그린티/누보스케치/스누피앤찰리/보라꽃 외
    ※점별 입점상품 상이 ※행사상품에 한함
 
    40% 덴비 엘레멘츠(상품별 상이) 각10,740~14,630원
    ※스톤화이트/핑크/펀그린
    ※점별 입점상품 상이 ※행사상품에 한함

    30% 썬터치 휴대용 가스렌지(상품별 상이) 각16,030~55,930원
    ※행사상품에 한함 ※점별 재고 상이 ※이미지 컷입니다

    1+1 기획팩 테이팩스 니트릴장갑(각 100매) 각8,900원
    ※화이트/블랙 ※행사상품에 한함 ※점별 재고 상이

    오늘좋은 데일리
    정전기 청소포 각4,900원
    (표준형 50매/대형 40매)

    롯데/신한/NH농협카드 10% 오늘좋은 데일리
    피치스킨 이불(S) 외 각13,500원
    ※S : 150×200cm
    ※점별 재고 상이 ※해당상품에 한함

    30% 룸바이홈 부드러운 침구(베개커버/싱글/퀸) 각7,630~41,930원

    롯데/신한/NH농협카드 20% 요리하다 보냉백 2,390원
    ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    테팔 브랜드 전품목
    5만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉫ "를 확인하세요
    ※'도와드리겠습니다'센터 증정

    40% 테팔 스타트이지 프라이팬 5종/
    퍼펙트쿡 프라이팬 5종/
    인텐시움 통 3중 냄비 3종 각24,480~43,080원

    50% 아이리스 시스템 수납함
    (3호)(40×50×23.5cm) 13,950원
    ※기간 : 9/1(일)~9/30(월)

    1+1 룸바이홈 프리미엄
    호텔 발매트 18,900원
    (82×50cm) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이

    2개 이상 구매시 1개당 30% 브랜드 양말(상품별 상이) ※점별 재고 상이 각6,930~7,630원

    불스원 전품목
    3만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정

    1+1 밴딩 기획팩 그라스 디퓨저(각 105ml) 각24,900원
    ※점별 재고 상이

    에탄올 그린워셔 기획 (1.5L×3) ※점별 재고 상이 7,590원

    ㆍTSA LOCK 장착!
    ㆍ확장지퍼로 수납력 확대!

    오늘좋은 기내용 여행가방 49,000원
 
    2개 이상 구매시 50% 오늘좋은 피치스킨 홈웨어(상품별 상이) 각4,950~7,950원

    토이저러스 인기완구 30% 
    ※행사기간 : 9/5(목)~9/25(수)
    ※입점점에 한함 ※점별 재고수량 상이
    ※매장에 더많은 행사상품이 준비되어 있습니다

    최대 30% 헬로카봇 80종 (상품별 상이) 각14,000~131,600원

    30000원 할인 + 롯데카드/신한카드 30000원 추가할인 스위치 OLED 마리오레드(전체 이용가) 355,000원

    20% 핑크퐁 아기상어 코딩컴퓨터 (3세 이상/38×8×27cm) 103,200원

    10000원 할인 코디열쇠로 말하는
    신데렐라 미미 옷장 39,900원
    (37개월 이상/61×14.5×38cm)
    ※입점점에 한함

    콜리올리 반려동물 식/용품 20%

    20% 콜리올리 도톰한
    3~4회용 패드 16,720/14,320/11,120원
    (M/L/XL) ※점별 재고 상이

    20% 하림 밥이보약, 더리얼
    강아지/고양이 식품 26종 각3,200~22,240원
    (상품별 상이) ※점별 재고 상이

    "SPORT FOR THE MANY" 더 많은 사람들이 즐겁고 유익하게 누리는 스포츠

    1100원 할인 백패킹 공기주입식 베개 24,900원

    12100원 할인 캠핑 컴포트 리클라이너 의자 59,900원

    16000원 할인 To 성인 스쿠터 타운7XL 179,000원

    28000원 할인 캠핑 풀 조립 텐트 3인용 울프라 프레시 MH100 109,000원

    10% 할인 아동 스쿠터/
    아동 인라인 구매시
    추가 구매 상품

    10% 할인 텐트 구매시 추가 구매 상품
  
    요리하다 온가족 함께 즐기는 인기 먹거리 추천! 오늘좋은

    L.POINT 1000원 요리하다 흰 깨송편/쑥 깨송편(각 500g) 각5,990원
    
    L.POINT 1000원 요리하다×농가맛집
    마늘 돼지고기 김치찌개(760g)/
    고추장 애호박 찌개(580g) 각10,900원
    
    L.POINT 1000원 오늘좋은 우리쌀 미니약과(500g) 3,990원

    600원 할인 오늘좋은 참기름(450ml) 5,990원

    롯데마트 GO앱 9월 이벤트 안내
    ▶기간 : 9/1(일) ~ 9/30(월)

    이벤트 1 신규가입 하면 7만원 이상 구매시 5천원 할인쿠폰 증정!
    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 롯데마트GO앱 신규가입 고객
    ※가입일자 : 9/1(일) ~ 9/30(월)
    ▶쿠폰 사용기간 : 9/1(일) ~ 10/6(일)
    ▶쿠폰 사용장소 : 롯데마트 매장 및 MAXX 6개점

    이벤트 2 출석체크 하면 최대 L.POINT 200P/최대 5천원 할인쿠폰 증정!

    ▶기간 : 9/1(일) ~ 9/30(월)
    ▶대상 : 출석체크 참여 & 수신동의 고객
    ※마케팅 목적 정보이용 동의 및 광고성정보 (Push) 수신동의
    ▶당첨 안내 및 쿠폰 지급 : 10/7(월)
    ▶쿠폰 사용기간 : 10/7(월)~10/20(일)
    ▶쿠폰 사용장소 : 롯데마트 매장 및 MAXX 6개점

    GO앱 출석체크 하면 매일매일 L.POINT 200P 적립!

    Push 수신동의 고객 200명 추첨! 최대 5천원 할인쿠폰 증정!

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div> 
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202409_2";
const category = [];

const data = {
  L201: {
    title: "스마트전단지-전점201",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L202: {
    title: "스마트전단지-전점202",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L203: {
    title: "스마트전단지-제타플렉스 잠실점203",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L204: {
    title: "스마트전단지-제타플렉스 잠실점204",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L205: {
    title: "스마트전단지-제타플렉스 서울역점205",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L206: {
    title: "스마트전단지-제타플렉스 서울역점206",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
