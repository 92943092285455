import { LEAFLET_URL } from "../constants";

/* 601 생활공감 컨텐츠 */
const L601data = [
  "<h1>vol.09 롯데마트가 전하는 생활 속 공감 이야기 생활공감 행복의 온도</h1>",
  "<h1>일상의 행복을 찾아가는 여정 행복의 온도</h1>",
  "<h1>목차</h1>",
  "<h1>행복의 온도</h1>",
  "<h1>내입이 가장 행복한 온도를 찾아서</h1>",
  "<h1>뜨겁거나 혹은 차갑거나</h1>",
  "<h1>재료마다 요리마다 맛있는 온도는 따로있다</h1>",
  "<h1>재료마다 음식마다 사람들이 맛있다고 생각하는 온도는 따로 있다</h1>",
  "<h1>온도가 빚어내는 맛의 비밀</h1>",
  "<h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>",
  "<h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>",
  "<h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>",
  "<h1>맛있는온도 + 전골 95도, 온가족이 둘러 앉아 끓이는 보글보글 행복한 전골</h1>",
  "<h1>전골사진</h1>",
  "<h1>손이 가요 손이가, 보글보글 맛있는 꽃게탕</h1>",
  "<h1>기대 이상의 간편식이 에어프라이어의 맛있는 온도를 만났을때 쫄깃한 찹쌀 탕수육</h1>",
  "<h1>참쌀 모짜렐라 치즈볼</h1>",
  "<h1>칠리새우</h1>",
  "<h1>후라이드 치킨봉</h1>",
  "<h1>풍미가 좋은 프리미엄 오일로 요리를 더 맛있게</h1>",
  "<div>풍미가 좋은 프리미엄 오일로 요리를 더 맛있게</div>",
  "<div>트러플 오일로 만든 페퍼론치노 파스타</div>",
  "<div>올리브 오일로 만든 참외 샐러드</div>",
  "<div>산지에서 식탁으로 달고 시원한 제철 참외를</div>",
  "<div>대한민국 산지뚝심</div>",
  "<div>골이 깊고 색이 진할수록 싱싱하고 맛있는 참외랍니다.</div>",
  "<div>인터뷰</div>",
  "<div>삶의 온기를 마음의 온기를. 우리모두 해옥해지는 온도를 찾아서</div>",
  "<div>나누고 나누고</div>",
  "<div>사람들이 가장 기분좋게 느끼는 일상의 온도</div>",
  "<div>행복의 온도는?</div>",
  "<div>수의사 김명철이 말하는 반려동물과 함께 따뜻한 삶의 온기 느끼기</div>",
  "<div>김명철 수의사</div>",
  "<div>김명철 수의사 인터뷰</div>",
  "<div>김명철 수의사 인터뷰. 반려동물을 책임진다는 것은 아이를 낳고 성인이 될때까지 돌봐야 하는 것과 같아요.</div>",
  "<div>슬기로운 반려생활 반려동물의 온다 check, check!</div>",
  "<div>반려동물의 체온, 반려동물이 좋아하는 온도, 반려동물 체온계, 반려동물이 체온을 유지하는 방법</div>",
  "<div>내손으로 만드는 반려견묘 수제간식 초간단 요거트 아이스 쿠키</div>",
  "<div>멍냥이가 좋아하는 닭가슴살 소세지</div>",
  "<div>사랑하는 사람들과 함께 행복의 온도를 높이는 미니멀 캠핑</div>",
  "<div>미니멀 캠핑이란? 짐의 물리적 부피를 최대한 줄이고 가볍께 떠나는 캠핑</div>",
  "<div>캠린이를 위한 캠핑 장비를 보여줘!</div>",
  "<div>전문가의 플렉스를 보여줘!</div>",
  "<div>캠핑의 달인 유투버들에게서 배우는 갬핑의 온도 지키기</div>",
  "<div>피크니 캠프 </div>",
  "<div>캠핑장에서 색다른 요리를 당신의 캠핑을 요리하다.</div>",
  "<div>Inter갬핑장에서 맛보는 얼큰한 마라탕면view</div>",
  "<div>이것만은 포기못해, 숯불향 입은 떡갈비</div>",
  "<div>손쉽게 휘리릭 볶아먹는 치즈 스테이크 볶음밥</div>",
  "<div>우리 모두 행복하게 지구의 온도를 지켜요!</div>",
  "<div>지구의 온도가 1도씨 올라간다면</div>",
  "<div>친환경 시대를 선도해온 롯데마트의 발걸음</div>",
  "<div>eco, we go together, lotte mart</div>",
  "<div>집콕하는 당신만을 위한 자유공간</div>",
  "<div>온리프라이스 스파클링 워터 eco 2종 아트테라피를 완성하고 인스타그램 이벤트에 응모하세요.</div>",
  "<div>생활공감</div>",
  "<div>생활공감</div>",
  "<div>롯데푸드</div>",
];

/* 901 룸바이홈 컨텐츠 */
const L901data = [
  "<h1>기 능 주 의 디 자 인 룸바이홈</h1>",
  "<h1>Less, But Better</h1>",
  "<h1>Functionism [펑셔니즘]</h1>",
  "<h1>EARTHY, KINFOLK</h1>",
  "<h1>URBAN, COZY, PURE</h1>",
  "<h1>우드스틸 첼시</h1>",
  "<h1>우드스틸 첼시 선반, 내추럴 갤러리 액자, 우드스틸 첼시 바 테이블/스툴, 우드스틸 첼시 선반행거</h1>",
  "<h1>Earthy look [나무 흙 돌 바람]</h1>",
  "<h1>헤리티지 대용량 모던디퓨저, 샤인 차콜그레이 메탈 벽시계, 센티드가든 디퓨저, 헤리티지 대용량 모던디퓨저, 분갈이가 필요없는 도자기 화분, 플랜트 박스</h1>",
  "<h1>룸바이홈 쇼핑 캐리어. 트렌디 아이템으로 진화된 실용성 높은 카트 장바구니 라인.</h1>",
  "<h1>원터치 폴링웨건 그린, 우드테이블카트, 미니 폴딩박스, 롤링 캐리어, 접이식 핸드캐리어, 특대형 타포린백</h1>",
  "<h1>룸바이홈 홈패브릭. 복고를 즐기는 뉴트로 트렌드를 반영한 깅엄 민트+옐로우 체크 라인.</h1>",
  "<h1>우드스틸 소호 책상, 우드스틸 소호 책상세트, 우드스틸 소호 선반, 내추럴 갤러리 액자, 가구+수납 모듈, 라탄의 텍스쳐를 구현</h1>",
  "<h1>룸바이홈 캠프닉 봄 여름 나들이 필수품 룸바이홈 피크닉&캠핑 아이템.</h1>",
  "<h1>캠핑비치타월 스트라이프/에스닉, 스텐식기, 휴대가 용이한 이불</h1>",
  "<h1>룸바이홈 보온텀블러 오피스와 아웃도어, 수험생 죽통까지 갖춘 룸바이홈 핫앤쿨 라인.</h1>",
  "<h1>룸바이홈 오일로드팬. 바닥 면의 오일로드 패턴이 기름이 한쪽 방향으로 쏠리는 현상을 막아주어 적은 양의 오일로 조리가 가능한 건강팬.</h1>",
  "<h1>룸바이홈 스트롱골드팬:오랜시간 사용이 가능하고 세척이 편리한 주방에 엣지를 더해주는 기능팬.</h1>",
  "<h1>프리미엄 식기건조대 1단,프리미엄 식기건조대 2단 </h1>",
  "<h1>룸바이홈 유로그린 세라믹 인덕션 냄비, 룸바이홈 소창 행주</h1>",
  "<div>체크 주방장갑, 체크 앞치마, 우드 손잡이 도마, 우드 도마</div>",
  "<div>룸바이홈 프리미엄 크리스탈 와인잔. 독일 Stozel(社)의 명품 크리스탈 와인잔으로 볼 아랫부분이 넓어 와인의 향을 향상시키고 스웰링하기 편리한 디자인. 와인을 잘 음미할 수 있도록 네가지 쉐입으로 구성.</div>",
  "<div>와인 액세서리 용품 와인을 맛있게 즐길 수 있는 와인 액세서리 라인.</div>",
  "<div>룸바이홈 킨포크 식기 소박하지만 풍요로운 Kinfolk 라이프스타일을 반영한 라인.</div>",
  "<div>룸바이홈 가온 식기 단아하고 차분한 한 상 차림으로 마음의 여유를 가질수 있는 온기를 담은 라인.</div>",
  "<div>골드라인 식기 시리즈. 뉴본 도자기 소재와 조각패턴의 글라스에 골드림을 둘러 홈파티에 어울리는 라인.</div>",
  "<div>룸바이홈 데일리 식기. 깨끗한 화이트 도자기 소재로 어디에나 잘 어울리는 데일리 라인.</div>",
  "<div>룸바이홈 안티알러지 침구 집먼지 진드기 투과가 방지되는 고밀도의 원단을 사용한 기능성 이불로, 잔사가 적은 장섬유를 꼬아 제직해 먼지 날림이 적어 위생적인 이불.</div>",
  "<div>룸바이홈 안티더스트침구:부드러운 피치스킨 원단과 순면 100%의 원단에 안티더스트 가공을 더해 먼지가 쉽게 달라붙지 않아 쾌적하게 사용 가능한 침구.</div>",
  "<div>룸바이홈 안티더스트 호텔 침구:불순물을 제거해 더욱 부드러우며, 안티더스트 가공을 더해 먼지가 쉽게 달라붙지않는 침구.</div>",
  "<div>룸바이홈 피치스킨 침구:돌돌 말려 이동이 편리하고 양면이 다른 패턴으로 다양한 연출이 가능한 리버시블 침구 & 베개.</div>",
  "<div>룸바이홈 여름 침구 시리즈:접촉 냉감 소재 & 시어커서 소재로 흡한속건 기능이 뛰어나 여름에 사용하기 좋은 침구.</div>",
  "<div>룸바이홈 에어셀 시리즈:계란판 모양의 에어셀 토퍼가 체중을 분산해주고, 접이식 디자인으로 보관이 용이하여 침대 위에 사용해도 되고, 손님 접대용으로도 사용하기 좋은 토퍼.</div>",
  "<div>룸바이홈 스토리지:공간 효율성을 고려한 모던 스토리지 라인.</div>",
  "<div>스틸 틈새 선반, 스틸 사이드 선반, 트롤리 이동식 선반, 스틸 와이어 슬림선반</div>",
  "<div>룸바이홈 세탁용품:좁은 공간 활용이 좋은 빨래 건조대, 열 전도율이 높아 다림질이 빠른 다리미판.눈금이 있어 분무량 조절이 쉬운 분무기.</div>",
  "<div>욕실소품 블랙 시리즈</div>",
  "<div>ROOM X HOME 롯데마트 GO 앱 및 롯데마트몰 룸바이홈 특화관에서 구매 가능합니다.</div>",
];

/* 903, 904 전점 추석 사전예약 (~9/6) */
const L903data = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    통큰 한가위
    사전예약 기간 2024/8/1(목) ~ 2024/9/6(금)

  </div>
  `,
  `
  <div>
    미리 준비할수록 더 큰 혜택!
    ※사전예약 기간 : 2024. 8. 1(목) ~ 2024. 9. 6(금)

    롯데마트&MAXX카드, 롯데마트맥스 신한카드, 롯데카드, 비씨카드, KB국민카드, 신한카드, NH농협카드, 하나카드, 현대카드, 삼성카드, 우리카드, IBK 기업은헹, 전북은행 JB카드, 광주은행 KJ 카드
    최대 120만원
    상품권 증정 또는 즉시할인 ※해당 기간에 한함

    1차 8/1(목) ~ 8/23(금)
    30만원 이상 3만 6천원 ※하단 세부내용 참고
    50만원 이상 6만원
    100만원 ~ 1,000만원 미만 100만원 당 12만원
    1,000만원 이상 120만원

    2차 8/24(토) ~ 8/30(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 8만원
    1,000만원 이상 80만원

    3차 8/31(토) ~ 9/6(금)
    30만원 이상 1만 5천원
    50만원 이상 2만 5천원
    100만원 ~ 1,000만원 미만 100만원 당 5만원
    1,000만원 이상 80만원

    ※상품권 : 3만원 5천원 상품권 + 1천원 쿠폰할인(사전예약 1차 기간 내 30만원 이상에 한함)

    ※자세한 내용은 매장 내 고지물 및 롯데마트GO앱 참조
    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    롯데마트몰은 할인쿠폰으로 즉시 할인 (최대 120만원씩 5회 할인 가능)
    ※롯데마트몰은 행사카드 및 적용 대상 상품이 롯데마트 매장과 상이할 수 있습니다
    ※자세한 행사내용은 롯데마트몰에서 확인하세요

    최대30% 할인혜택
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT 회원 특별할인 ※해당상품에 한함
    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함
    3만원 이상 구매시 전국 무료배송 
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준
    ※롯데마트몰은 무료배송 기준 별도 운영

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 8. 1 기준이며, 일부 변경 될 수 있음

  </div>
  `,
  `
  <div>
    9의 자격, 오직 1%의 한우에게만

    마블나인 한우는 왜 특별한가요?

    1. 투쁠 한우 중에서도 가장 높은 9등급 한우만 취급
    2. 합리적인 가격을 위해 경매부터 정형까지 직접
    3. 축소된 롯데만의 물류단계로 더 빠르게 더 신선하게

    3구 세트 99,000원
    등안채 세트(등심/안심/채끝)
    차업치 세트(차돌박이/업진살/치마살)
    꽃늑토 세트(꽃/본갈비살/늑간(갈비)살/토시살)

    6구 세트 189,000원
    등안채+차업치 세트
    등안채+꽃늑토 세트

    롯데마트몰에서 편하게 구매하세요!
    판매 시작 기간 : 8.15(목) / 배송 시작 기간 : 8.20(화)
    카카오톡 선물하기에서도 롯데 프리미엄 푸드센터 or 마블나인을 검색해보세요
    
  </div>
  `,
  `
  <div>
    롯데마트만의 새로운 신선함, RE:FRESH를 만나보세요

    신선식품의 모든 과정을 다시 연구하고 새롭게 바꾸는 RE:FRESH 프로젝트를 통해 가장 신선한 경험을 전달합니다
    신선식품의 소싱 단계와 품질 기준을 강화하며, 매장 내 진열 및 신선도 관리 등을 철저하고 꼼꼼하게 재설계합니다. 
    신선식품 전체(축산/수산/과일/채소)에 적용되며, 온/오프라인에서 다양한 RE:FRESH 상품을 만나보실 수 있습니다

    바다째로 퍼와버린 롯데마트 집념전복
    하다하다 해수까지 그대로 운반하고 보관하다

    복불복 없는 완벽함
    흠집부터 당도까지 완벽 사전선별

    완도산 해수 그대로 담아 산지 직배송으로 싱싱한 신선함!
    ※ASC 인증 : 환경적, 사회적 책임을 다해 키운 양식 수산물에 부여되는 국제 인증
    7+1 300 세트 한정 L.POINT 20%
    ASC인증 자이언트 전복세트 223,200원
    ▶ ASC 인증 완도전복 2kg, 전복 : 국산 14~15마리/7미 사이즈/마리당 140g 내외

    특유의 단맛과 진한 향의 복숭아 선물세트! 유명산지 충주 하늘작 복숭아를 11브릭스, 300g 이상으로 엄선!
    충주 하늘작 복숭아 49,900원
    ▶ 복숭아 4kg 내외, 국산 ※택배 배송 불가

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    전문 MD가 직접 경매에 참가하여 엄선한 품질 좋은 한우 선물세트! 
    불고기/국거리에 적합한 최적의 부위와 두께를 엄선!
    10+1 L.POINT 50,000원 할인 
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g, 국거리/불고기 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    최적의 갈비 두께 12mm! 롯데 신선품질혁신센터 꽃갈비 상품화 공정 강화를 통한 업계 최상의 품질 제공 가능!
    ※상품권 증정 제외

    10+1 L.POINT 30,000원 할인 
    호주산 소 LA식 갈비세트 139,000원
    ▶ 호주산 냉동 꽃갈비 1.5kg×2
    ※10+1 구매시 개당 126,364원 ※전국 택배 운영

    제주 수산물 명인이 전통 채낚기 방식으로 어획하여 건강하게 은빛 비늘이 살아있는 은갈치!
    10+1 L.POINT 10,000원 할인 
    산지뚝심 제주 은갈치 세트 149,000원
    ▶ 4마리(1.8kg 내외), 냉동, 갈치 : 국산

    사과 연구소(Apple Research Institute)의 앞 철자 (ARI-아리)와 빼어날 '수(秀)'를 합쳐 이름을 딴 국내 개발 품종!(k품종)
    12브릭스 이상 비파괴 당도선별로 붉은색의 매끈한 표면과 아삭한 식감이 일품!
    영주 아리수 사과 79,900원
    ▶ 사과 12~14입(4.2kg 내외),국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    소중한 분들의 품격있는 식탁을 완성시키는 선물세트

    충주사과, 나주 하우스 배 99,900원
    ▶ 사과 6입(1.8kg 내외), 배 6입(3.9kg 내외), 국산

    나주 최종기 농부의 하우스배 79,900원
    ▶ 배 7~11입(6.5kg 내외), 국산

    자이언트 배 L.POINT 20,000원 할인 99,900원
    ▶ 배 9입(8.2kg 내외), 국산

    천안 지순태 농부의 GAP 배 L.POINT 10,000원 할인 89,900원
    ▶ 배 8-9입(6.7kg 내외), 국산

    충주 김택성 농부의 GAP 사과 99,900원
    ▶ 사과 11~12입(4.5kg 내외), 국산

    상주 왕곶감 L.POINT 20,000원 할인 119,900원
    ▶ 곶감 32입 (1.6kg 내외), 국산

    ※상품권 증정 제외
    롯데가 기른 L.POINT 30,000원 할인 
    Lgrow 와규 선물세트 119,000원
    ▶ 호주산 냉장 와규 윗등심 0.4kg×2,본갈비살 0.4kg, 앞치마살 0.4kg ※신선택배(전국 배송) 전용

    청정와규 세트 3호 L.POINT 30,000원 할인 109,000원
    ▶ 호주산 냉장 와규 부채살 0.4kg×2, 꾸리살 0.4kg, 삼각살 0.4kg ※전국 택배 운영

    10+1 L.POINT 10,000원 할인
    으뜸 대왕 갈치 세트 289,000원
    ▶ 4마리 3.2kg 내외, 국산

    7+1 L.POINT 50,000원 할인 
    대한민국 으뜸 굴비세트 499,000원
    ▶ 1.8kg/10미, 참조기/천일염 : 국산

    10+1 L.POINT 10,000원 할인 
    대한민국 명장 장석준 명란 1호 89,000원
    ▶ 명란 300g×2입, 명란 : 미국산

    10+1 L.POINT 10,000원 할인 
    국산 대하 세트 149,000원
    ▶ 국산 대하 18~24미 1.3kg

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    10+1 L.POINT 20,000원 할인 
    프리미엄 홈마카세 세트 99,000원
    ▶ 고등어초절임 190g, 단새우 80g, 우니 100g, 참다랑어 뱃살 100g, 참다랑어 속살 100g, 원산지 별도표기

    남해바다 정치망 멸치세트 L.POINT 25,000원 할인 74,900원
    ▶ 볶음용멸치 200g, 볶음조림용 멸치 180g, 조림용 멸치 150g, 국물용 멸치 180g, 멸치 : 국산

    CJ 제일명인 한우육포세트 L.POINT 20% 69,920원
    ▶ 한우육포 70g×6봉, 우육:국내산

    광천 곱창김 선물세트 L.POINT 10,000원 할인 78,000원
    ▶ 곱창김 캔김 30g×4캔, 조미 곱창전장김 20g×5봉, 무조미 곱창전장김18g×5봉, 원산지 별도표기

    대천 명품 캔김 세트(15캔) L.POINT 5,000원 할인 59,900원
    ▶ 곱창김 30g×15캔, 원산지 별도표기

    홍천 송선진 농부의 고랭지 더덕 세트 159,000원 
    ▶ 더덕 1.5kg(뿌리당 70g~90g), 국산

    팔각 어울림 버섯 L.POINT 20,000원 할인 119,000원
    ▶ 백화고100g, 흑화고100g×2, 국산

    유명산지 홍천 왕더덕 세트 L.POINT 10,000원 할인 169,000원
    ▶ 더덕 1.5kg(뿌리당 100g~130g), 국산

    6년근 프리미엄 인삼세트 L.POINT 10,000원 할인 149,000원
    ▶ 6년근 인삼 700g, 국산

    팔각 백화고 159,000원
    ▶ 백화고 100g×4, 국산

    7+1 창평한과 소쇄원 108,000원
    ▶ 유과, 강정, 다식(단호박/백년초/콩/대잎), 매작과(치자백년초/치자대잎), 약과, 정과(당절임키위/오렌지), 육포, 도라지
    ※사전예약 한정 운영

    7+1 창평한과 명옥원 135,000원
    ▶ 유과(찹쌀/백년초/단호박/대잎), 매작과(치자백년초/치자대잎), 정과(오렌지), 다식(단호박/백년초/대잎/콩), 조청산자 外
    ※사전예약 한정 운영

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    올해 추석에는 이색적인 특별함을 선물하세요!

    기순도 
    대한민국 전통식품 명인 제 35호 기순도, 370년 종가의 전통과 명인의 손맛이 선사하는 품격있는 선물세트

    기순도 미식예가 실속 2호 L.POINT 10% 61,200원
    ▶ 기순도 전통 간장 300ml, 맛장 300ml, 현미보리된장 200g, 현미고추장 200g

    기순도 미식예가 실속 3호 L.POINT 10% 62,100원
    ▶ 기순도 전통고추장 320g, 죽염 250g, 전통간장 300ml, 맛장 300ml

    기순도 미식예가 오복 2호 L.POINT 10% 71,100원
    ▶ 기순도 전통된장 320g, 전통고추장 320g, 쌀조청 200g, 죽염 160g, 맛장 300ml

    기순도 명인의 진장&김부각세트 2호 L.POINT 10% 89,100원
    ▶ 기순도 5년 숙성 명인 진장 120ml, 김부각 2봉, 전통고추장 180g 外

    청정원
    5+1 청정원 현미고추장 선물세트 79,000원
    ▶ 우리쌀 현미고추장 2kg(도자기)

    비온뒤 첫소금
    청정해역 신안에서 비가 온 뒤 가장 깨끗해진 토판 위에서 정성껏 수확하는 명품 소금 선물세트

    비온뒤 첫소금 어간장 세트 L.POINT 10% 19,800원
    ▶ 요리엔담다 어간장 250ml×2

    비온뒤 첫소금 프리미엄 6호 L.POINT 10% 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g

    비온뒤 첫소금 프리미엄 2호 L.POINT 10% 45,000원
    ▶ 3년숙성 천일염 135g, 프리미엄 구운소금 150g, 신안천일염 400g×2 外

    바로담아 
    엄선한 국산 참들깨를 사용, 전통방식으로 만들어 깨 본연의 고소하고 진한 풍미가 느껴지는 선물세트

    바로담아 국산 참들기름 1호 L.POINT 10% 53,900원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨 105g

    바로담아 국산 참들기름 3호 L.POINT 10% 59,850원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨 105g, 국산 깨소금 100g

    바로담아 국산 참들기름 2호 L.POINT 10% 66,900원
    ▶ 국산 참기름 180ml×2, 국산 들기름 180ml

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    올리타리아 
    신선한 원재료를 수확 후 24시간 안에 냉압착하여 전통적인 맛과 향이 살아있는 이탈리아 오일 브랜드
    
    올리타리아 스페셜 5호 L.POINT 10% 14,310원
    ▶ 해바라기유 500ml×2

    올리타리아 스페셜 33호 L.POINT 10% 36,810원
    ▶ 올리브유 500ml, 포도씨유 500ml, 해바라기유500ml

    올리타리아 스페셜 4호 L.POINT 10% 42,210원
    ▶ 올리브유 500ml×2

    올리타리아 스페셜 79호 L.POINT 10% 43,110원
    ▶ 올리브유 1L, 해바라기유 1L

    올리타리아 스페셜62호 L.POINT 10% 43,110원
    ▶ 올리브유 500ml, 포도씨유 500ml, 트러플오일 250ml

    신선한 올리브 열매를 수확한 즉시 선별, 압착한 황금(Oro) 올리브유
    올리타리아 스페셜 92호 L.POINT 10% 47,610원
    ▶ 오로 올리브오일 500ml×2

    올리타리아 스페셜 20호 L.POINT 10% 49,410원
    ▶ 올리브유 1L, 포도씨유1L

    주세페주스티
    17대째 내려오는 400년이 넘는 전통의 현존하는 가장 오래된 하이엔드 발사믹 브랜드
    주세페주스티 올리브유 6년숙성 발사믹식초 세트 L.POINT 20% 73,600원
    ▶ 엑스트라버진 올리브오일 250ml, 1메달 6년산 발사믹식초 250ml

    주세페주스티 올리브유 8년숙성 발사믹식초 세트 L.POINT 20% 86,400원
    ▶ 엑스트라버진 올리브오일 250ml, 2메달 8년산 발사믹식초 250ml

    주세페주스티 올리브유 12년숙성 발사믹식초 세트 L.POINT 20% 109,600원
    ▶ 엑스트라버진 올리브오일 250ml, 3메달 12년산 발사믹식초 250ml

    주세페주스티 6년숙성 발사믹&화이트발사믹 세트 L.POINT 20% 68,000원
    ▶ 1메달 6년산 발사믹식초 250ml, 화이트 발사믹식초 250ml

    그로브 
    100% 뉴질랜드의 신선한 아보카도로 만들어 건강함이 가득하고, 요리 본연의 맛을 살려주는 오일 브랜드

    그로브 아보카도오일 세트 1호 L.POINT 20% 29,200원
    ▶ 그로브 아보카도 오일 250ml×2

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    올해 추석에는 이색적인 특 별함을 선물하세요!
    샤인머스캣, 망고 L.POINT 10,000원 할인 69,900원
    ▶ 샤인머스캣 1송이(0.7kg 내외), 애플망고 2입(0.8kg 내외) 옐로우망고 2입(0.7kg 내외), 원산지 별도표기

    샤인머스캣, 사과 L.POINT 10,000원 할인 79,900원
    ▶ 샤인머스캣 3송이(2.1kg 내외), 사과 6입(2.0kg 내외), 국산

    샤인머스캣, 사과, 배, 황금향 89,900원
    ▶ 샤인머스캣 1송이(0.7 kg 내외), 사과 3입(0.9 kg 내외) 배 3입(1.8 kg 내외), 황금향 3입(0.8 kg 내외), 국산

    샤인머스캣, 메론 89,900원
    ▶ 샤인머스캣 2송이(2.0kg 내외), 머스크 메론 2입(3.2kg 이상), 국산
    
    샤인머스캣, 사과, 배 99,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외) 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산

    샤인머스캣 L.POINT 10,000원 할인 69,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    자이언트 용과, 아보카도, 망고 79,900원
    ▶ 용과 2입(1.0kg 내외), 아보카도 6입(1.8kg 내외), 옐로우망고 2입(1.0kg 내외), 원산지 별도표기

    킹 아보카도 망고 79,900원
    ▶ 아보카도 6입(1.8kg 내외), 옐로우망고 4입(1.8kg 내외), 원산지 별도표기

    3대 유명산지 곶감 139,900원
    ▶ 곶감 45입 (2.1kg 내외), 국산

    페퍼 우대 갈비 세트 1.8kg L.POINT 50,000원 할인 139,000원
    ▶ 미국산 초이스 등급 페퍼 우대갈비 0.9kg×2, 냉동 ※전국 택배 운영

    흑돼지 모둠구이 선물세트 2.0kg L.POINT 40,000원 할인 99,000원
    ▶ 냉장 흑돼지 삼겹살 1kg, 냉장 흑돼지 목심, 앞다리 각 500g
    ※산지택배(전국배송) 전용

    리얼스테이크 육포 THE GIFT L.POINT 40% 35,940원
    ▶ 외국산 우육 160g×3봉, 원산지 별도표기(우육 : 미국/호주/뉴질랜드)

    유기농 표고버섯 혼합 1호 L.POINT 15,000원 할인 49,900원
    ▶ 유기농 흑화고 100g, 유기농표고채 70g×2, 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    웰빙표고 실속세트 2호 L.POINT 10,000원 할인 44,900원
    ▶ 동고 70g, 표고채 60g, 표고사각 30g, 핑크솔트 140g, 흑후추 70g, 원산지 별도표기

    3+1 코주부 비프앤넛츠 프리미엄 선물세트 49,900원
    ▶ 120g×4봉, 우육 : 외국산(호주/뉴질랜드/미국), 아몬드/호두 : 미국산, 해바라기씨 : 불가리아산

    국내산 벌집꿀 L.POINT 10,000원 할인 79,900원
    ▶ 1.5kg/밀랍포함, 국산

    1+1 카무트 효소 4입 선물세트 69,800원
    ▶ 2g×14×4세트

    ※온라인몰 전점 운영(페이지 상단 QR코드 연결 시 온라인몰 구입 가능)
    ※오프라인 서울역점 운영
    경기떡집 선물세트 59,000원
    ▶ 20입(흰이티떡, 쑥이티떡, 약식, 완두시루떡, 고구마찰떡, 모듬영양떡), 원산지별도표기

    한만두 추석 1호 선물세트 29,900원
    ▶ 갈비만두 300g, 짬뽕만두 330g, 고기 왕교자 330g, 김치 왕교자 330g, 군만두 280g, 식혜 400g

    쿠캣 시그니처 냉동떡 선물세트 46,000원
    ▶ 딸기 쏙 우유 찹쌀떡 540g×2팩, 티라미수 쏙 찹쌀떡 540g, 초코 쏙 녹차 찹쌀떡 540g

    시루조아 쌀카스테라 호박인절미 선물세트 30,900원
    ▶ 쌀 카스텔라 단호박 인절미 50g×24봉

    시루조아 오색 찹쌀 송편 선물세트 24,900원
    ▶ 찹쌀 8봉, 단호박 4봉, 쑥 4봉, 홍국 4봉, 흑미 4봉, 50g×24봉

    테일러 아라비안 디저트 L.POINT 10,000원 할인 24,900원
    ▶ 점보 데이츠 10입 , 원산지 별도표기

    아임비타 멀티비타민 세트 33,700원
    ▶ 이뮨플러스 14병, 멀티비타민 60정

    애경 산양유 바디케어 기프트 L.POINT 5,000원 할인 11,900원
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 90g×2입

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    MD'S PICK 전문가가 추천하는 선물세트

    GAP 사과, 배(情) L.POINT 10,000원 할인 49,900원
    ▶ 사과 5입(1.4kg 내외), 배 4입(2.2kg 내외), 국산

    천안 배 L.POINT 10,000원 할인 천안 배 39,900원
    ▶ 배 5-6입(5.0kg 내외), 국산
    
    충주 프레샤인 사과 L.POINT 10,000원 할인 39,900원
    ▶ 사과 17~20입(5.0kg 내외), 국산

    보은사과 L.POINT 10,000원 할인 49,900원
    ▶ 사과 14-16입(4.5kg 내외), 국산

    상주 둥시 곶감(福) L.POINT 10,000원 할인 49,900원
    ▶ 곶감 24입(0.8kg 내외), 국산

    ※상품권 증정 제외
    10+1 L.POINT 30,000원 할인 
    미국산 소 LA식 갈비세트 117,323원
    ▶ 미국산 냉동 꽃갈비 1.5kg×2
    ※전국 택배 운영

    ※상품권 증정 제외
    10+1 L.POINT 20,000원 할인 
    미국산 소 프라임 LA식 갈비세트 108,182원
    ▶ 미국산 냉동 프라임등급 꽃갈비 1.2kg×2
    ※1,100세트 한정 ※전국 택배 운영

    10+1 L.POINT 30,000원 할인 
    한우 정육세트 2호 99,000원
    ▶ 1등급 한우 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※마트/슈퍼 공통 전국 택배 운영

    10+1 L.POINT 30,000원 할인 
    한우 실속 정육세트 99,000원
    ▶ 1등급 한우 국거리, 불고기, 산적 각 600g, 양념소스, 냉동, 국내산 한우고기 ※마트/슈퍼 공통 전국 택배 운영

    10+1 L.POINT 30,000원 할인 
    한우 알꼬리 선물세트 99,000원
    ▶ 한우 알꼬리 2kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    뉴질랜드산 소 LA식 갈비세트 99,000원
    ▶ 뉴질랜드산 냉동 꽃갈비 1.5kg×2
    ※500세트 한정 ※전국 택배 운영

    10+1 L.POINT 20,000원 할인 
    무항생제 한우 사골 세트 59,000원
    ▶ 무항생제 한우 사골 5kg,국내산(한우고기)
    ※전국 택배 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    캐나다 인증 돼지고기 선물세트 L.POINT 10,000원 할인 49,000원
    ▶냉장 캐나다 돼지고기 삼겹살/목심 각 1kg
    ※600세트 한정 ※센터택배(전국 배송) 전용 

    코주부 육포세트 2호 L.POINT 7,000원 할인 32,900원
    ▶ 외국산 우육 40g×7봉

    자연향 버섯 혼합세트 1호 L.POINT 20,000원 할인 49,900원
    ▶ 동고 70g, 표고채 55g×2, 건목이 40g, 흰목이 30g 표고칩 30g, 표고버섯가루 60g, 국산

    6년근 뿌리 많은 인삼 세트 L.POINT 10,000원 할인 39,900원
    ▶ 난발용 인삼 500g, 국산

    자연향 버섯 혼합세트 2호 L.POINT 20,000원 할인 39,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산
    
    실속형 금산 인삼 더덕 혼합세트 L.POINT 10,000원 할인 49,900원
    ▶ 인삼(5-7뿌리) 300g, 더덕(9-12뿌리) 400g, 국산

    자연향 담은 백화고 혼합세트 L.POINT 20,000원 할인 54,900원
    ▶ 사각칩 40g×2, 상황채 40g, 백화고 90g, 동고 70g×2, 국산

    자연향담은 동고혼합세트 L.POINT 10,000원 할인 29,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    MD'S PICK 전문가가 추천하는 선물세트

    10+1  L.POINT 10,000원 할인
    제주 갈치 옥돔 혼합세트 109,000원
    ▶ 갈치 2마리(600g 내외), 옥돔 2마리(400g 내외), 냉동, 갈치/옥돔/천일염 : 국산

    10+1  L.POINT 10,000원 할인
    실속 제주 은갈치 세트 109,000원
    ▶ 4마리(330g 내외), 냉동, 갈치 : 국산

    7+1 자연담은 칠산소금 굴비 실속세트 89,900원
    ▶ 10마리(0.9kg 내외), 냉동, 참조기/천일염 : 국산

    10+1  L.POINT 10,000원 할인
    제주 옥돔세트 109,000원 
    ▶ 2마리(800g 내외), 냉동, 옥돔/천일염 : 국산

    10+1 프리미엄 명란, 젓갈 세트 99,000원
    ▶ 명란 100g×6입, 새우젓 2EA, 오징어젓 2EA, 각 180g, 명란 : 러시아산/새우, 오징어 : 국산

    대천 곱창김 혼합1호 카드할인 30% 32,830원
    ▶ 곱창 전장김 25g×8봉, 곱창김 캔김 30g×3캔, 원산지 별도표기

    양반 들기름김세트 9,900원
    ▶ 들기름김 4g×18봉, 원산지 별도표기

    비비고 토종김 5호 9,900원
    ▶ 참기름김 4g×18봉, 원산지 별도표기

    성경 보성녹차 정성L호 L.POINT 4,000원 할인 11,900원
    ▶ 녹차 재래 식탁김 4g×24봉, 원산지 별도표기

    10+1 광천 김수미 재래김 선물세트 10,900원
    ▶ 재래김 4g×20봉, 원산지 별도표기

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외    

  </div>
  `,
  `
  <div>
    매일견과 80봉 L.POINT 20,000원 할인 29,900원
    ▶ 매일견과 18g×20봉×4케이스, 구운아몬드(미국) 28%, 호두(미국) 24%, 구운캐슈너트(베트남) 17%, 건포도(칠레) 31%

    The 고소한 마카다미아 매일견과 50봉 L.POINT 10,000원 할인 29,900원
    ▶ 매일견과 20g×50봉, 호두 30%, 구운아몬드 30%, 건포도(칠레) 25%, 마카다미아(호주) 15%

    시그니처 매일견과 세트 50봉 L.POINT 10,000원 할인 25,900원
    ▶ 매일견과 20g×50봉,구운아몬드(미국) 20%, 호두(미국) 25%, 구운캐슈넛(베트남) 20%, 구운땅콩(미국) 25%, 건크랜베리(미국) 10%

    1+1 넛츠박스 매일견과 세트(20봉) 19,900원
    ▶ 매일견과 20g×20봉, 호두 5g(미국), 구운아몬드 5g(미국), 구운땅콩 5g(미국), 건포도 5g(칠레)

    1+1 약사신협 프리미엄 생녹용진액 39,000 원
    ▶ 70ml×30포

    1+1 농협 한삼인 홍삼침향환골드 100 110,000 원
    ▶3.75g×100입

    L.POINT 50%
    CMG제약 본래원 흑염소진액 23,900원
    ▶ 50ml×30포

    1+1 엘지 보타닉 퍼퓸 컬렉션 30,900원
    ▶ 엘라스틴 보타닉 퍼퓸 샴푸/컨디셔너 480ml, 벨먼 보타닉 퍼퓸 바디워시 480ml 外

    1+1 엘지 정성담은 프리미엄 1호 53,900원
    ▶ 엘라스틴 데미지케어 10X샴푸 200ml×2, 오가니스트 바디워시 라벤더향 190ml×2 外

    ※상품권 및 즉시할인 제외
    1+1 아내의 착즙주스 배도라지즙/사과즙/당근사과즙/감귤사과즙 착즙하는남자 양배추 사과즙 각29,900원
    ▶ 각 100ml×21입 ※교차구매 가능

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 홈스토랑 선물세트

    몽탄 우대갈비 선물세트 139,000원
    ▶ 몽탄 우대갈비 1.2kg (3대), 무생채

    천하일미 한우 불고기 선물세트 69,900원
    ▶ 천하일미 한우 1+ 불고기 180g×10팩

    사미헌 LA갈비&야채불고기 선물세트 57,500원
    ▶ LA갈비 500g×2팩, 야채불고기 400g

    사미헌 프리미엄 갈비탕 세트 74,900원
    ▶ 명품갈비탕, 능이갈비탕, 전복매생이갈비탕, 갈비탕 각 1팩(1kg), 만능육수1팩(800g

    사미헌 갈비탕 &소불고기 선물세트 49,000원
    ▶ 사미헌 갈비탕 1kg×2팩, 야채불고기 1kg×2팩

    설성목장 명품 이동갈비 세트 129,000원
    ▶ 소 이동 본갈비 500g×2팩, 소 이동 한입 LA갈비 500g×2팩

    설성목장 이동본갈비 세트 64,900원
    ▶ 소 이동 본갈비 500g×2팩

    설성목장 함박스테이크 선물세트 59,800원
    ▶ 한우 함박스테이크 100g×8팩

    설성목장 한우 사골곰탕 만두 선물세트 69,600원
    ▶ 한우 사골곰탕1kg×4팩, 한우 왕만두 420g×2팩, 한우 김치왕만두 420g×2팩

    설성목장 한우 양념갈비 선물세트 199,000원
    ▶ 암소 한우 양념갈비 400g×5팩

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    경복궁 명절 한상 선물세트 79,000원
    ▶ 칼집 LA갈비 600g, 영양 갈비탕 850g, 양념불고기 600g, 한우 사골곰탕 600g

    경복궁 한우 불고기, 갈비 혼합선물세트 69,000원
    ▶ 한우불고기 600g, 칼집 LA갈비 600g

    경복궁 따뜻한 국물 보양 선물세트 카드할인 6,000원 할인 39,000원
    ▶ 한우 사골곰탕 600g×2팩, 양곰탕 600g, 꼬리곰탕 600g

    경복궁 양념불고기 선물세트 49,000원
    ▶ 양념불고기 600g×2팩

    경복궁 한우 차림세트 카드할인 5,000원 할인 44,000원
    ▶ 한우 사골곰탕 600g×2팩, 한우사골 고기곰탕 600g, 한우 떡갈비 200g

    더본코리아 본가 선물세트 카드할인 30% 105,000원
    ▶ 본가 소갈비찜 500g×4팩, 본가 소불고기 250g×6팩, 본가 너비아니 150g×6팩

    더본코리아 빽쿡 선물세트 카드할인 30% 69,300원
    ▶ 본가 소갈비찜 500g×2팩, 백종원 인생 삼계탕 1.2kg×2팩, 본가 너비아니 150g×5팩

    하이포크 동물복지 불고기 세트 카드할인 20,000원 할인 45,000원
    ▶ 동물복지 담백한돼지불고기 500g×3팩, 동물복지 매콤한돼지불고기 500g×3팩

    VIPS 홈레스토랑 선물세트 1호 72,400원
    ▶ 오리지널 비비큐 폭립, 척아이롤 스테이크, 명란 까르보나라 원팬 파스타, 뇨끼 감바스, 미트 라자냐

    VIPS 홈레스토랑 선물세트 2호 89,900원
    ▶ 오리지널 비비큐 폭립, 슈바인학센, 단호박 찹스스테이크, 척아이롤 스테이크, 쉬림프 로제 원팬 파스타, 미트 라자냐

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    소중한 분들께 건강을 선물 하세요!

    정관장 활삼골드 카드할인 20% 31,200원
    ▶ 50ml×30포

    4+1 정관장 홍삼원기 37,000원
    ▶ 50ml×30포

    해당카드 3+1 정관장 홍삼진본 49,000원
    ▶ 40ml×20포

    해당카드 3+1 정관장 홍삼진고 이뮨스틱 46,000원
    ▶ 30포

    1+1 엘지 리튠 홍삼진 제로 골드 39,900원
    ▶ 50ml×24포

    1+1 엘지 리튠 흑마늘진액 골드 49,900원
    ▶ 50ml×24포

    1+1 씨제이 한뿌리 홍삼대보 44,800원
    ▶ 40ml×24입

    1+1 씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    종근당건강 콘드로이친 세트 27,900원
    ▶ 850mg×30정×3입

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외 ※카드할인가는

  </div>
  `,
  `
  <div>
    1+1 한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포

    1+1 한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포

    3+1 풍기인삼 황풍정 산삼배양근 보 39,000원
    ▶ 75ml×16입

    풍기인삼 에너지허브 이뮨 더블업 세트 39,900원
    ▶ 5병×4세트

    레모나 에스산 선물세트 카드할인 16,000원 할인 33,900원
    ▶ 90포×3세트

    뉴트리원 올인원 멀티비타 이뮨플러스 카드할인 6,000원 할인 43,900원
    ▶ 7병×4세트

    엘지 피지오겔 DMT 크림 기획세트 L.POINT 30% 할인 35,630원
    ▶ 피지오겔 DMT 크림 150ml, 피지오겔 DMT 에센스인토너 50ml 外

    엘지 시그니처 컬렉션 그린 L.POINT 30% 할인 39,830원
    ▶ 닥터그루트 집중케어 지성용 샴푸 330ml, 피지오겔 DMT 크림 75ml 外

    엘지 프로폴리테라 에센셜 세트 L.POINT 30% 할인 35,630원
    ▶ 엘라스틴 프로폴리테라 샴푸 500ml, 엘라스틴 프로폴리테라 손상용 샴푸 500ml 外

    아모레퍼시픽 섬김 5호 카드할인 25% 43,425원
    ▶ 해피바스 오리지널컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml×2 外

    엘지 시그니처 컬렉션 퍼플 L.POINT 30% 할인 49,630원
    ▶ 닥터그루트 집중케어 샴푸 330ml, 피지오겔 DMT 클렌징 젤 150ml 外

    1+1 엘지 생활의 감동 모던 프리미엄 61,900원
    ▶ 오가니스트 모던 샴푸/컨디셔너 400ml, 온더바디 모던 바디워시 400g 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 신선 식품 선물세트

    10+1 L.POINT 50,000원 할인
    한우 홈파티팩 선물세트 199,000원
    ▶ 1등급 한우 알등심/새우살 각 300g×2, 냉장, 국내산 한우고기 ※산지택배(전국배송) 가능

    10+1 L.POINT 50,000원 할인
    미경산 한우 암소 선물세트 (1+등급) 289,000원
    ▶ 1+등급 한우 등심/채끝 각 300g×3, 냉장, 국내산 한우고기 ※산지택배(전국배송) 가능

    10+1 L.POINT 50,000원 할인
    암소 에이징 한우 선물세트 179,000원
    ▶ 2등급 한우 등심, 샤또 브리앙, 채끝 각 200g×2, 냉장, 국내산 한우고기 ※산지택배(전국배송) 가능

    마리네이드 한우 채끝 스테이크 세트 L.POINT 50,000원 할인 169,000원
    ▶ 1등급 한우 채끝 스테이크 0.5kg, 찹스테이크 0.3kg, 냉장, 국내산 한우고기
    ※산지택배(전국배송) 전용 ※상품권 증정 제외

    10+1 L.POINT 50,000원 할인
    1+등급 한우 갈비세트 199,000원
    ▶ 1+등급 한우 찜갈비 700g×4, 냉동, 양념소스, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    1등급 한우 갈비세트 149,000원
    ▶ 1등급 한우 찜갈비 700g×3, 냉동, 양념소스 국내산 한우고기 ※전국 택배 운영

    9의 자격, 오직 1%의 한우에게만 
    10+1 L.POINT 50,000원 할인
    마블나인 구이혼합세트(1++(9)등급) 699,000원
    ▶ 1++(9)등급 한우 등심 500g×2, 채끝/안심 각 400g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 등심 혼합세트 1호(1++(9)등급) 499,000원
    ▶ 1++(9)등급 한우 등심 500g×2, 채끝 500g×2, 국거리 500g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 구이세트 2호(1++(9)등급) 439,000원
    ▶ 1++(9)등급 한우 등심 500g×2, 채끝/부채살 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 구이세트 3호(1++(9)등급) 349,000원
    ▶ 1++(9)등급 한우 등심, 채끝, 설깃, 찹스테이크 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    1++(9)등급 그 이상의 품격, Marble9으로 집에서 즐기는 한우 오마카세
    10+1 L.POINT 50,000원 할인
    마블나인 홈마카세 선물세트(1++(9)등급) 149,000원
    ▶ 1++(9)등급 한우 안심, 채끝, 부채살, 치마살 각 150g, 냉장, 국내산 한우고기

    10+1 L.POINT 50,000원 할인
    마블나인 한우 갈비세트(1++(9)등급) 299,000원
    ▶ 1++(9) 등급 한우 찜갈비 700g×5, 냉동, 국내산 한우고기 ※전국 택배 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    10+1 L.POINT 50,000원 할인
    한우 등심채끝 혼합세트 249,000원
    ▶ 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 등심정육세트 1호 219,000원
    ▶ 1등급 한우 등심, 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 한끼 구이세트 199,000원
    ▶ 1등급 한우 등심 200g×2, 안심/채끝/치마살/부채살 각 200g, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 등심세트 2호 239,000원
    ▶ 1등급 한우 등심 500g×4, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 등심세트 1호 339,000원
    ▶ 1등급 한우 등심 500g×6, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 등심안심 혼합 세트 229,000원
    ▶ 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 구이 스페셜 세트 239,000원
    ▶ 1등급 한우 등심 500g, 채끝/안심/부채살 각 400g, 냉장, 국내산 한우고기 ※전국 택배 운영

    한우 양념 불고기 모둠 세트 L.POINT 60,000원 할인 119,000원
    ▶ 한우 양념 불고기/언양식 불고기 각 1.2kg, 냉동
    ※센터택배(전국배송) 전용

    10+1 L.POINT 50,000원 할인
    한우 LA식 갈비세트 249,000원
    ▶ 1등급 한우 LA식 갈비 1.5kgX2 냉동, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    무항생제 등심정육세트 5호(1+등급) 209,000원
    ▶ 무항생제 1+등급 한우등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    무항생제 한우 등심채끝 혼합 세트 269,000원
    ▶ 무항생제 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    호반 칡소 선물세트(2등급 이상) 239,000원
    ▶ 한우등심 500gX2, 채끝/안심 각 400g, 냉장, 국내산 한우고기 ※전국택배 30세트 한정

    호주산 양념 LA식 꽃갈비 세트 L.POINT 70,000원 할인 119,000원
    ▶ 호주산 소고기 양념 LA식 꽃갈비 1.2kgX2, 냉동
    ※전국택배 운영 ※상품권 증정 제외

    7+1 자연담은 칠산소금 참굴비세트 특호 199,000원
    ▶ 1.2kg/10미, 냉동, 참조기/천일염 : 국산

    7+1 자연담은 칠산소금 굴비 세트 2호 119,000원
    ▶ 10마리(1.0kg 내외), 냉동, 참조기/천일염 : 국산

    7+1 자연담은 칠산소금 굴비 세트 3호 159,000원
    ▶ 10마리(1.1kg 내외), 냉동, 참조기/천일염 : 국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 신선 식품 선물세트

    신화, 창조 배 79,900원
    ▶ 배 12입 (7.5kg 내외),국산

    문경 사과 L.POINT 10,000원 할인 59,900원
    ▶ 사과 12-14입 (4.5kg 내외), 국산

    충주 사과 L.POINT 10,000원 할인 59,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    프라임 사과, 배 L.POINT 10,000원 할인 59,900원
    ▶ 사과 4입(1.2kg 내외), 배 6입(3.6kg 내외), 국산

    충주사과, 천안 배 L.POINT 10,000원 할인 69,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외),국산

    나주 배 L.POINT 10,000원 할인 59,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    밀양 얼음골 사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12-14입 (4.2kg 내외), 국산

    정성담은 사과, 배 L.POINT 10,000원 할인 79,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외),국산

    정성담은 신고배 L.POINT 10,000원 할인 59,900원
    ▶ 배 9~11입(6.5kg 내외),국산

    정성담은 사과 L.POINT 10,000원 할인 59,900원
    ▶ 사과 12~14입(4.2kg 내외),국산
    
    GAP 사과, 배 89,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외),국산

    샤인머스캣 L.POINT 10,000원 할인 69,900원
    ▶ 샤인머스캣 4kg 내외, 국산

    당도선별 메론 L.POINT 10,000원 할인 69,900원
    ▶ 머스크 메론 4입(6.4kg 이상),국산

    망고 혼합 L.POINT 10,000원 할인 79,900원
    ▶ 옐로우 망고 3입(1.0kg 내외), 애플망고 6입(2.4kg내외), 원산지 별도표기

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    크림치즈와 호두 품은 곶감 L.POINT 10,000원 할인 79,900원
    ▶ 호두치즈곶감375g×2팩, 곶감12입(0.4kg 내외), 국산

    상주 둥시 곶감(情) L.POINT 10,000원 할인 69,900원
    ▶ 곶감 30입 (1.2kg 내외),국산

    냉장 시즈닝 스테이크 모둠 세트 1.8kg L.POINT 60,000원 할인 99,000원
    ▶ 시즈닝 척아이롤/부채살/살치살 각 0.6kg
    ※산지택배(전국배송) 가능 ※상품권 증정 제외

    HBAF 한가위 견과세트 L.POINT 10,000원 할인 39,900원
    ▶ 꿀홍삼 땅콩 130g, 마누카 허니마카다미아 130g, 호지차 아몬드 130g, 구운 아몬드 195g 外, 원산지 별도표기

    하이그레이드 6종 견과 L.POINT 20,000원 할인 69,900원
    ▶ 구운 아몬드 220g, 피칸 170g, 브라질너트 240g, 마카다미아 210g 外, 원산지 별도표기

    BEST 견과 10종 세트 L.POINT 10,000원 할인 49,900원
    ▶ 구운 아몬드 170g, 호두 110g, 구운 캐슈넛 150g, 피칸 120g 外, 원산지 별도표기

    견과&건과일 6종 세트 L.POINT 10,000원 할인 29,900원
    ▶ 구운 아몬드 190g, 호두 120g, 건포도 200g, 건자두 190g 外, 원산지 별도표기

    특선 견과 선물세트 6종 L.POINT 10,000원 할인 39,900원
    ▶ 구운 아몬드 160g, 호두 110g, 마카다미아 150g, 브라질넛 160g, 원산지 별도표기

    명작 견과&건과일 10종 세트 L.POINT 10,000원 할인 39,900원
    ▶ 구운 아몬드 170g, 호두 110g, 구운 피스타치오 120g, 대추야자 160g 外, 원산지 별도표기

    L.POINT 15,000원 할인 카드 할인 10,000원 할인
    흑화고 혼합세트 49,900원
    ▶ 흑화고 80g, 표고채 50g×2, 목이버섯 40g 外, 국내산
    
    자연담은 건강버섯 세트 L.POINT 20,000원 할인 59,900원
    ▶ 은이버섯 25g, 상황슬라이스 25g, 녹각영지 20g 外, 원산지별도표기

    건강버섯 특선세트 L.POINT 10,000원 할인 99,000원
    ▶ 국내산 상황버섯 60g, 국내산 영지 180g, 차가버섯 200g 外, 원산지 별도표기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    세련된 감각의 테이블을 완성! 치즈/커피&차 선물세트

    치즈 
    경성치즈 전용 그레이터와 갈아먹는 치즈로 구성된 프리미엄 치즈세트

    치즈 그레이터 세트 카드할인 10% 59,400원
    ▶ 보스카 이지 그레이터, 파르네제 그라나 파다노 150g, 파르네제 파르미지아노 레지아노 150g, 마블스 멜론엔망고 크림치즈 100g, 아페리프레 코트 다쥐르 100g, 브리에뜨 크리미 앤 마일드 125g, 베르기어 미니믹스 120g, 에스푸나 세라노 하몽 50g, 연성 치즈 나이프
    ※상품권 및 즉시할인 제외
    
    와인 페어링 치즈 세트 카드할인 10% 44,100원
    ▶ 아페리프레 코트 다쥐르 100g, 엠보그 치즈 트라이앵글 140g, 레노스트라네 그린 올리브 절임 200g, 쁘띠 브리 125g, 고다 포션 인 네트 100g, 르 갈 크림치즈 플레인 150g, 에스푸나 살치촌 엑스트라 100g, 에스푸나 초리조 엑스트라 100
    ※상품권 및 즉시할인 제외

    치즈&샤퀴테리 세트 카드할인 10% 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g, 에스푸나 세라노 하몽 50g, 포스 칼라마타 피티드 올리브 마리네이티드 60g, 마블스 과일치즈 멜론&망고 100g, 에스뿌나 살치촌 슬라이스 50g, 미니 스포르나티니 토마토엔 오레가노 100g, 아그네시 스파게티
    ※상품권 및 즉시할인 제외

    임실치즈 옥정호 선물세트 54,000원
    ▶ 임실 크림치즈 200g, 벨라유 포션치즈 120g×4 (플레인,단호박,아몬드,양파), 임실 모짜렐라 슬라이스 치즈 100g×3, 임실스트링치즈20g×5

    임실치즈 선물세트 1호 64,000원
    ▶ 임실생치즈 500g, 임실구워먹는치즈 125g×2, 임실 모짜렐라 슬라이스 치즈 100g×3,벨라유 포션치즈 120g×4(플레인,단호박,아몬드,양파)

    임실치즈세트 특호 95,000원
    ▶ 임실 크림치즈 200g, 벨라유 포션치즈 120g×4(플레인, 단호박,아몬드,양파), 벨라유 모짜렐라 슬라이스 치즈 100g×4, 임실구워먹는치즈 250g×2, 임실 스트링치즈100g×2

    치즈 플레이트 세트 카드할인 10% 49,500원
    ▶ 보스카 치즈 보드S, 체리페퍼 위드 망고 크림치즈 280g, 엠보그 치즈 트라이앵글 140g, 파르네제 그라나 파다노 150g, 마블스 멜론엔망고 크림치즈 100g, 쁘띠 까망베르 125g, 그린 올리브 절임 200g, 연성 치즈나이프

    스타벅스

    스타벅스 원두믹스 머그 선물세트 카드할인 20% 28,080원
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml

    스타벅스 드립백 선물세트 25,000원
    ▶ 하우스블렌드 10T×2입, 파이크플레이스 5T, 블랙머그 370ml

    스타벅스 카페모먼트 선물세트 32,000원
    ▶ 브라이트 1병, 스무스 1병, 글라스머그 370ml

    콜드브루&티백

    보르딘 콜드브루 앰플 24종 세트 34,900원
    ※상품권 및 즉시할인 제외
    ※사전예약 전용 상품
    ▶ 액상 콜드브루 커피 25ml×24병 (엔투비블렌드×2병, 탄자니아 AA×2병 外)

    보르딘 블렌딩 앰플 14종 세트 24,900원
    ※상품권 및 즉시할인 제외
    ※사전예약 전용 상품
    ▶ 액상 블렌딩 콜드브루 23ml×14병(로스팅밤×2병, 크래쉬와일드×2병 外)

    보르딘 커피 선물세트 S-1 19,900원
    ※상품권 및 즉시할인 제외
    ※사전예약 전용 상품
    ▶ 액상 스틱커피 30ml×12입, 티백커피 10g×4입, 콜드브루 160ml×2병

    보르딘 커피티백 세트 18입 34,900원
    ※상품권 및 즉시할인 제외
    ※사전예약 전용 상품
    ▶ 케냐 AA티백 커피/에티오피아 예가체프 티백 커피/브라질내추럴 티백커피, 각 10g×6봉

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    네스카페 돌체구스토 지니오S쉐어모카 스타벅스 캡슐&머그기획 L.POINT 60,000원 할인 129,000원
    ▶ 지니오S 쉐어 모카 머신, 하우스블렌드캡슐 12입, 카라멜마키아토캡슐12입, 스타벅스 블랙머그컵 2개, 마커펜 1개

    일리 원두 믹스 스틱 선물세트 카드할인 10% 23,310원
    ▶ 일리 클래식 로스트 미니 30T, 일리 디카페인 미니 30T, 일리 볼드로스트 미니 10T, 라떼 글라스 1개

    10+1 커피빈 실속 선물세트 13,700원
    ▶ 커피빈 캡틴 아메리카노 미니 10T×3개, 콜롬비아 아메리카노 미니 10T×3개

    쌍계명차
    쌍계명차 티클립스 그란데 세트 카드할인 20% 14,240원
    ▶ 머스캣홍차/피치우롱/애플캐모마일/얼그레이/레몬진저/루이보 스 바닐라 각1 0T

    1+1 쌍계명차 티포레스트 더즌 세트 31,980원
    ▶ 유기농 녹차/도라지차/우엉차/헛개나무차/국화차/옥수수수염차 각 5T 外

    1+1 쌍계명차 티포레스트 컬렉션 24,900원
    ▶ 더덕차/구수한메밀차/결명자차/블랙보리차/유기농녹차/옥수수수염차/홍도라지차/수국차 각 5T

    3+1 쌍계명차 쌍화차 세트 카드할인 20% 32,000원
    ▶ 쌍화차 18g×30T

    10+1 녹차원 차다움세트 9,900원
    ▶ 꿀유자차 350g, 생강대추배차 7T, 호두아몬드율무차 7T

    10+1 녹차원 포시즌 베스트 세트 9,900원
    ▶ 쌍화차/생강차/캐모마일/페퍼민트/히비스커스/보이차 각 5T

    1+1 녹차원 허브차 세트 24,900원
    ▶ 맛있는녹차 작설/캐모마일/루이보스/페퍼민트/ 얼그레이/쟈스민/스위트 후르츠/히비스커스 각 5 T

    초록원 액상차 3입세트 L.POINT 20% 16,720원
    ▶ 유자차 580g, 생강차 560g, 제주한라봉차 560g
    ※상품권 및 즉시할인 제외

    햄스테드 유기농티 선물세트 카드할인 20% 35,120원
    ※선물용 쇼핑백과 함께 택배로 배송됩니다
    ▶ 유기농 얼그레이/유기농 잉글리시브렉퍼스트/유기농 페퍼민트&스피어민트/오가닉 카모마일 각 7T 外

    녹차원 우전세작 선물세트 카드할인 10% 66,510원
    ▶ 우전 40g, 작설세작 50g

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    온가족 담소시간! 전통과자 /꿀&잼 선물세트

    호정가
    5+1 유영군 명인한과 1호 34,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎), 다식(단호박/백년초/대잎/콩), 강정(쌀/백년초/치자/대잎/쌀아몬드/흑미), 약과(미니)

    10+1 유영군 명인한과 2호 59,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎), 오렌지 정과, 다식(단호박/백년초/대잎/콩) 外
    
    1+1 창평한과 면앙정 69,900원
    ▶ 유과(찹쌀/백년초/단호박), 다식(단호박/백년초/대잎/콩) 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미), 약과

    7+1 창평한과 송강정 70,000원
    ※사전예약 한정 운영
    ▶ 유과(찹쌀/단호박/백년초/대잎), 매작과, 약과, 강정(쌀/백년초/치자/대잎/참깨/들깨/땅콩), 오렌지정과 外

    창평한과 죽녹원 89,900원
    ▶ 당귀, 매작과(치자백년초/치자대잎), 오렌지정과, 다식, 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기 外) 外

    창평한과 식영정 49,900원
    ▶ 유과(찹쌀/단호박/백년초/대잎), 치자백년초매작과, 미니약과, 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미쌀/보리) 外

    화과방
    화과방 더담아 생과자 1호 L.POINT 10% 34,650원
    ▶ 밤, 자두, 살구, 병아리콩 4가지 앙금에 원물이 통째로 들어가 있는 프리미엄 생과자 세트
    ※ 선물용 쇼핑백과 함께 택배로 배송됩니다 ※상품권 및 즉시할인 제외

    화과방 홍삼양갱 2호 L.POINT 10% 29,250원
    ▶ 홍삼의 맛과 향이 그대로 담긴 프리미엄 양갱 세트
    ※선물용 쇼핑백과 함께 택배로 배송됩니다
    ※상품권 및 즉시할인 제외

    화과방 지현 양갱 3호 L.POINT 10% 32,310원
    ▶ 흑임자, 흑당팥, 군고구마, 복분자, 쑥 5가지 맛으로 구성된 프리미엄 양갱 세트
    ※선물용 쇼핑백과 함께 택배로 배송됩니다
    ※상품권 및 즉시할인 제외

    호정가 찹쌀약과 세트 1kg 12,900원
    ▶ 달콤하고 쫀득한 약과를 풍성하게 담아, 간식이나 선물용으로 제격인 호정가 대표 인기상품
    ※ 상품권 및 즉시할인 제외

    돌리 국산 찹쌀유과세트 1.3kg 19,980원
    ▶ 생강 엑기스가 들어가 깔끔하고 건강한 맛을 더한 전통 한과(2팩 분리포장)

    돌리 제주 감귤과즐 선물세트 19,980원
    ▶ 제주 감귤이 들어가 새콤달콤한 전통과자(22g×30봉)

    청우 옛날애 종합전병세트 800g L.POINT 2,000원 할인 9,980원
    ▶ 김말이 전병, 생강맛 전병, 오란다, 땅콩맛 전병, 부채형 김전병
    ※상품권 및 즉시할인 제외
    
    한입전병 선물세트 13,980원
    ▶ 코인크기의 과자가 한 입에 쏙 들어가 부서질 걱정없이 깔끔하게 먹을 수 있는 전병세트

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    꽃샘 아카시아 야생화꿀 세트 카드할인 20% 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g

    프리미엄 고가구 아카시아꿀 세트 L.POINT 25% 74,250원
    ▶ 아카시아 꿀 1.2kg

    꽃샘 밤꿀세트 카드할인 20% 54,400원
    ▶ 밤꿀 1.2kg
 
    한국양봉 제주 아카시아꿀 선물세트 L.POINT 20% 23,920원
    ▶ 아카시아꿀 12g×30입
    ※상품권 및 즉시할인 제외

    골든 허니콤 벌집꿀 L.POINT 35% 64,350원
    ▶ 2kg(밀랍포함), 국산
    ※상품권 및 즉시할인 제외

    비담 허니스틱 선물세트 L.POINT 20% 48,000원
    ▶ 아카시아꿀 10g×20입, 야생화꿀 10g×40입

    ※상품권 및 즉시할인 제외
    한국양봉 강녕 자연꿀 3종 세트 L.POINT 25% 26,250원
    ▶ 아카시아꿀/때죽나무꿀/피나무꿀 각 12g×10T

    콤비타 UMF 5+ 마누카꿀&클로바꿀 세트 L.POINT 40% 51,000원
    ▶ UMF 5+ 마누카꿀 250g,클로바꿀 250g
    ※선물용 쇼핑백과 함께 택배로 배송됩니다
    ※상품권 및 즉시할인 제외

    가보 프리미엄 벌꿀 5호세트 L.POINT 20% 47,920원
    ▶ 잡화꿀 600g, 아카시아꿀 600g

    랑네제 아카시아 허니콤 L.POINT 20,000원 할인 70,000원
    ▶ 아카시아 허니콤 500g×2입
    ※선물용 쇼핑백과 함께 택배로 배송됩니다

    샹달프 쨈 5종 선물세트 카드할인 10% 26,082원
    ▶ 블루베리, 딸기, 라즈베리, 무화과, 애플시나몬, 각 170g×5

    슈퍼너츠 피넛버터 2종 선물세트 32,200원
    ▶ 슈퍼너츠 피넛버터 스무스/크런치 각 460g×2
    ※상품권 및 즉시할인 제외

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    식탁을 더욱 풍성하게! 어묵&수제햄&바베큐 선물세트

    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다
    고래사어묵 선물세트 3호 40,000원
    ▶ 고래바 65g×2, 통새우말이 50g, 치즈빵빵 80g,떡말이 90g, 소시지말이 90g 外

    고래사어묵 선물세트 5호 60,000원
    ▶ 고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g, 떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外

    고래사어묵 선물세트 7호 80,000원
    ▶ 용궁어묵 180g, 전복어묵 120g, 통새우말이 90g×2, 치즈빵빵 80g×2, 떡말이 90g, 새우봉어묵 150g 外

    한성 특3호세트 카드할인 30% 63,000원
    ▶ 골드 명란젓갈 300g, 창란젓갈 300g,오징어젓갈 300g ※상품권 및 즉시할인 제외

    상하농원 수제 햄&치즈 세트 카드할인 20% 79,200원
    ▶ 클래식비엔나 120g×2, 화이트 비엔나 120g, 롤비엔나 120g×2, 치즈프랑크 180g×2 外

    상하농원 수제 햄&치즈 고급세트 카드할인 20% 96,000원
    ▶ 클래식비엔나 120g, 화이트 비엔나 120g, 스모크 프랑크 180g, 스모크라운드햄 160g 外

    상하농원 햄공방 실속세트 카드할인 20% 79,200원
    ▶ 클래식비엔나 120g×2, 화이트비엔나 120g, 스모크프랑크 180g, 치즈프랑크 180g 外

    ※상품권 및 즉시할인 제외
    오뗄 수제햄 특선 1호 64,900원
    ▶ 바베큐 안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 부어스트에센 105g×2 外

    ※상품권 및 즉시할인 제외
    오뗄 수제햄 실속 1호 39,900원
    ▶ 바베큐안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 모타델라 300g, 부어스트에센 105g

    존쿡 프리미엄 수제 델리미트 세트 카드할인 10% 90,000원
    ▶ 훈제 삼겹바베큐 250g, 목살바베큐 200g, 텐더로인 350g, 비어슁켄 300g 外
    
    존쿡델리미트 바베큐 세트 카드할인 10% 71,100원
    ▶ 슈바인학센 650g, 바베큐백립 450g, 델리부어스트 240g, 데브리치너 240g 外
    
    존쿡델리미트 베스트 세트 카드할인 10% 49,500원
    ▶ 바베큐립 460g, 킬바사 270g, 킬바사치즈 270g, 비프핫도그 300g 外

    존쿡델리미트 브런치 세트 카드할인 10% 35,100원
    ▶ 브렉퍼스트 소시지 210g, 킬바사 270g, 단호박 까망베르스프 160g×3 外

    존쿡델리미트 샤퀴테리 세트 카드할인 10% 49,500원
    ▶ 하몽세라노 120g, 까챠토레 150g, 코파 50g, 이탈리안살라미 50g, 카바노치 50g 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트

    ※ 하기 이미지는 연출컷으로 실제 상품과 상이할 수 있습니다 ※온라인몰 상품 주문 시, 매장에서 픽업 수령 가능합니다

    조니워커 블루 우마미 L.POINT 52,000원 할인 465,800원
    ▶ 조니워커 블루 우마미 1,000ml, 수첩, 캘린더, 바매트 2입

    조니워커 그린 L.POINT 20,000원 할인 79,800원
    ▶ 조니워커 그린 700ml, 온더락 글라스 1입, 글라스 캡 1입

    2묶음 세트 
    아영 스페인세트 33,900원
    ▶ 도메인 올리비에 브누아 750ml×2병

    글렌 그란트 12년 L.POINT 40,000원 할인 회원가 69,900원
    ▶ 글렌그란트 12년 700ml, 노징글라스 2입

    레뱅 이탈리아 비고르 세트 L.POINT 30,000원 할인 36,900원
    ▶ 우마니론끼 비고르 레드 750ml, 화이트 750ml

    화요 3종 선물세트 58,000원
    ▶ 화요 17%/25%/53% 각 200ml

    2묶음 세트
    금양 프랑스 와인 2호세트 59,900원
    ▶ 샤토 도트푀유 750ml, 샤토 다르티그 750ml

    로얄 살루트 폴로 마이애미 L.POINT 35,000원 할인 315,000원
    ▶ 로얄 살루트 폴로 마이애미 700ml

    발렌타인 10년 L.POINT 10,000원 할인 39,900원
    ▶ 발렌타인 10년 700ml, 온더락글라스 2입

    일품진로 1924 헤리티지 100주년 에디션 99,000원
    ▶ 100주년에디션 750ml 샷잔 2입

    해미과실주 선물 1호 32,000원
    ▶ 딸기주375ml, 복숭아주375ml

    글렌엘긴 12년 L.POINT 24,100원 할인 65,800원
    ▶ 글렌엘긴 12년 700ml

    오반/달위니 디스틸러스 에디션 182,800/169,800원
    ▶ 오반 디스틸러스 에디션 700ml/달위니 디스틸러스 에디션 700ml

    포르투 발도우로 토니포트 세트 24,900원
    ▶ 포르투 발도우로 토니포트 1병, 포트 와인잔 1입

    와일드터키 켄터키 스피릿 L.POINT 28,100원 할인 89,900원
    ▶ 와일드터키 켄터키 스피릿 750ml

    사락 선물세트 36,800원
    ▶ 사락 375ml×2병, 하이볼잔 1입

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트

    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준
    대천 곱창도시락김 세트 L.POINT 4,000원 할인 21,900원
    ▶ 곱창 식탁김 5g×54봉, 원산지 별도표기

    동원 양반김 M20호 L.POINT 30% 25,970원
    ▶ 들기름 전장김 20g×6봉 , 참치액(진) 900g, 원산지 별도표기
    
    10+1 동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉, 들기름 식탁김 4g×12봉, 원산지 별도표기ㄴ

    비비고 토종김 1호 16,900원
    ▶ 담백 구운김 전장(10g)×3봉 , 비비고 참기름김 도시락김 (4g)×6봉×2ea, 원산지 별도표기

    동원 양반김 M30호 L.POINT 30% 29,960원
    ▶ 더바삭한 전장김 25g×6봉, 살코기 참치 90g×6캔, 요리유 480ml, 원산지 별도표기

    비비고 토종김 4호 L.POINT 30% 25,130원
    ▶ 비비고 참기름김 20g×4봉×2ea , 카놀라유 900ml, 원산지 별도표기

    비비고 토종김 복합 2호 L.POINT 30% 29,330원
    ▶ 직화 참기름김 전장 20g×6입, 스팸 120g×4입, 카놀라유 500ml, 원산지 별도표기

    비비고 토종김 S-1호 L.POINT 30% 29,120원
    ▶ 담백 구운김 전장(10g)×4봉×2ea , 죽염 돌김 캔(50g) ×2ea, 원산지 별도표기

    비비고 직화구이김 혼합 1호 L.POINT 30% 29,960원
    ▶ 직화 참기름김 전장(22g)×3봉 ,직화 참기름김 캔 25g×2ea ,직화 참기름김 도시락 4.5g×8ea, 원산지 별도표기

    대천 명품 캔김 세트(6캔) L.POINT 2,000원 할인 29,900원
    ▶ 곱창김 30g×6캔, 원산지 별도표기

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    종근당건강 생유산균 락토핏 골드 더블세트 L.POINT 3,000원 할인 20,800원
    ▶ 50포×2입

    1+1 약사신협 6년근 고려홍삼액 진 39,000원
    ▶ 70ml×30포
    
    1+1 약사신협 내몸애생발란스 산삼배양근진액 39,000원
    ▶ 70ml×30포

    4+1 바이래오 이경제 더힘찬 면역스틱 29,900원
    ▶ 10g×30포

    1+1 엘지 리튠 구증구포 흑삼진 골드 49,900원
    ▶ 50ml×24포

    남아공 스타루비 자몽 L.POINT 10,000원 할인 29,900원
    ▶ 자몽 12입(4.5kg 내외), 남아공산

    듀얼 카스텔라 선물세트 18,900원
    ▶ 벌꿀 카스텔라(1입), 녹차 카스텔라(1입) , 원산지 별도표기
    ※ 온라인몰 전점 운영 , 오프라인 베이커리팩토리/쁘띠르뽀미에/풍미소 입점점 운영

    제스프리 그린키위 L.POINT 5,000원 할인 24,900원
    ▶ 그린키위 25입(2.4kg 이상), 뉴질랜드산

    호주산 오렌지 L.POINT 10,000원 할인 29,900원
    ▶ 오렌지 12입(3.8kg 내외), 호주산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트

    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준
    동원
    10+1 동원 포도씨유 혼합 23호 15,900원
    ▶ 카놀라유 480ml×2, 포도씨유 480ml

    동원 양반김 M70호 카드할인 30% 19,950원
    ▶ 더바삭한 전장김 25g×3봉, 살코기참치 135g×3, 건강요리유 480ml
    
    동원 프리미엄 1호 카드할인 30% 19,950원
    ▶ 살코기참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g 外

    동원 프리미엄 55호 카드할인 30% 19,950원
    ▶ 살코기참치 90g×2, 카놀라유 480ml, 참기름 160ml, 구운소금 210g 外

    동원 스페셜 1호 카드할인 30% 25,970원
    ▶ 살코기참치 135g×2, 리챔 120g×4, 건강요리유 480ml×2

    동원 스페셜 5호 카드할인 30% 29,890원
    ▶ 살코기참치 90g×6, 리챔 120g×3, 카놀라유 480ml×2

    동원 프리미엄 62호 카드할인 30% 29,960원
    ▶ 살코기참치 90g×5, 리챔 120g×2, 참치액 진 240g, 참기름 75ml 外

    동원 스페셜 49호 카드할인 30% 29,960원
    ▶ 살코기참치 90g×4, 리챔 더블라이트 120g×4, 참기름 330ml

    청정원

    10+1 청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml x 2, 요리유 500ml

    청정원 스페셜S호 카드할인 30% 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g x 2, 진간장골드 500ml, 요리올리고당 700g, 사과식초 500ml

    청정원 L1호 카드할인 30% 22,260원
    ▶ 카놀라유 500ml, 옥수수유 500ml, 잇츠팜 115g x 2 外

    청정원 L2호 카드할인 30% 24,360원
    ▶ 카놀라유 500ml, 잇츠팜 115g x 4, 옥수수유 500ml, 올리고당 700g

    청정원 L6호 카드할인 30% 27,860원
    ▶ 카놀라유 500ml x 2, 우리팜델리 115g x 4, 올리고당 700g, 어간장 410ml

    청정원 L7호 카드할인 30% 29,960원
    ▶ 카놀라유 500ml, 잇츠팜 115g x 5, 요리올리고당 700g 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인

    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    씨제이 제일제당
    씨제이 백설 프리미엄 5호 12,900원
    ▶ 바삭한요리유 500ml x 2, 해바라기씨유 500ml

    10+1 씨제이 백설 프리미엄 14호 15,900원
    ▶ 카놀라유 500ml×2, 해바라기씨유 500ml

    5+1 씨제이 백설 프리미엄 23호 18,900원
    ▶ 카놀라유 500ml×2, 요리유 500ml×2

    씨제이 특별한선택 I호 19,900원
    ▶ 요리올리고당 700g, 사과식초 500ml, 스팸 120g x 2, 맛술 500ml 外

    씨제이 특별한선택 K-4호 카드할인 30% 21,630원
    ▶ 카놀라유 500ml x 2, 올리고당 500g, 스팸 120g x 4

    5+1 씨제이 고소함 가득 참기름 A호 26,900원
    ▶ 고소함가득참기름 330ml x 2, 천일염 170g x 2

    씨제이 특별한선택 L-3호 카드할인 30% 28,630원
    ▶ 카놀라유 500ml x 2, 요리올리고당 700g, 스팸 120g x 6

    씨제이 특별한선택 K-2호 카드할인 30% 29,330원
    ▶ 카놀라유 500ml, 요리올리고당 700g, 스팸 200g x 2 外

    씨제이 특별한선택 L-1호 카드할인 30% 29,330원
    ▶ 카놀라유 500ml x 2, 요리올리고당 700g, 스팸 200g, 육수에는1분링 멸치디포리 60g 外

    씨제이 특별한선택 E-1호 카드할인 30% 29,330원
    ▶ 카놀라유 500ml, 스팸 200g x 3, 요리올리고당 700g, 육수에는1분링 멸치디포리 80g, 사골 80g

    1+1 씨제이 백설 육수에는 1분링 세트 29,900원
    ▶ 육수에는1분링 멸치디포리 80g x 2, 사골 80g

    사조
    사조 고급유 3호 11,900원
    ▶ 카놀라유 500ml x 3

    사조 안심특선 92호 카드할인 30% 19,950원
    ▶ 안심팜 115g x 2, 참기름 55ml, 바삭요리유 500ml, 구운소금 200g, 맛술 500ml 外

    사조 안심특선 95호 카드할인 30% 25,900원
    ▶ 카놀라유 500ml, 바삭요리유 500ml, 살코기참치 85g x 4, 안심팜 115g x 2 外

    사조 안심특선 94호 카드할인 30% 26,530원
    ▶ 카놀라유 500ml, 안심팜 115g x 2, 진간장 500ml, 맛술 500ml, 참기름 55ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트
    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준

    동서 & 담터
    5+1 카누 아메리카노 8호 25,930원
    ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개, 카누미니 라이트 10T× 2개, 투게더머그

    5+1 카누 블렌드 7호 21,900원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누 말차 라떼 8T, 카누 바닐라 라떼 8T, 카누 미니 마일드 10T×2개

    담터 다정 종합세트 카드할인 30% 19,320원
    ▶ 호두아몬드율무차 18g×15T,단호박마차 17g×15T, 쌍화차플러스 15g×15T 外

    담터 다담 종합세트 카드할인 30% 24,850원
    ▶ 호두아몬드율무차 18g×15T, 캐모마일 1g×15T 단호박마차 17g×15T, 쌍화차플러스 15g×15T 外

    1+1 담터 허브티컬렉션 31,980원
    ▶ 마테차, 누룽지둥굴레차, 우엉차, 국화차, 도라지차, 쟈스민차, 옥수수수염차, 루이보스, 페퍼민트 外 각 5T
  
    담터 정담라이트 카드할인 10% 20,880원
    ▶ 호두아몬드율무차 18g×50T, 쌍화차라이트 15g×15T, 생강차라이트 15g×15T

    일상용품 세트
    엘지 프로폴리스 기프트 프리미엄 9,990원
    ▶ 페리오 프로폴리스 치약 90g×4, 온더바디 프로폴리스 비누 80g×2 外

    10+1 애경 리미티드 셀렉션 11호 11,900원
    ▶ 케라시스 데미지 클리닉 샴푸 400ml, 샤워메이트 유자 바디워시 400ml 外

    10+1 엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 10X 볼륨 샴푸/컨디셔너 200ml, 오가니스트 체리블라썸 바디워시 200g 外

    아모레퍼시픽 섬김 1호 카드할인 20% 19,920원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml 外

    아모레퍼시픽 해피바스 플라워테라피 바디 기프트 카드할인 60% 19,960원
    ▶ 해피바스 플라워 바디워시 라벤더 760g×2, 해피바스 플라워 바디워시 프리지아 760g×2 外

    엘지 생활의 품격 3호 카드할인 30% 20,930원
    ▶ 닥터그루트 샴푸 180ml, 오가니스트 수분 샴푸 200ml, 오가니스트 바디워시 라벤더향 190ml×2 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인

    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음
    
    아모레퍼시픽 행복듬뿍 1호 카드할인 50% 24,950원
    ▶ 해피바스 촉촉한 바디워시 400g, 려 함빛 샴푸 400ml, 려 흑운 샴푸 400ml×2 外

    엘지 생활의 품격 5호 카드할인 30% 26,530원
    ▶ 닥터그루트 집중케어 샴푸/컨디셔너 180ml, 리엔 자윤 중건성용 샴푸 200ml×2 外

    1+1 애경 케라시스 퍼퓸 셀렉션 26호 26,900원
    ▶ 케라시스 러블리 퍼퓸 샴푸 400ml×2, 케라시스 러블리 퍼퓸 린스 400ml 外

    엘지 수분광채 벨먼 스파세트 L.POINT 30% 27,230원
    ▶ 벨먼 스파 수분광채 바디워시 로즈 400g, 벨먼 스파 수분광채 바디워시 자스민 400g 外

    엘지 히말라야 핑크솔트 에센셜세트 카드할인 30% 27,230원
    ▶ 히말라야 핑크솔트 샴푸 200ml×2, 히말라야 핑크솔트 바디워시 민트향 190ml 外

    아모레퍼시픽 섬김3호 카드할인 50% 29,900원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml 外

    양말 선물세트
    1+1 유렉시아 명화 양말 세트 각 6,900/8,900원
    ▶ 2매/3매

    1+1 헤드/휠라 양말 세트 각 7,900원
    ▶ 2매

    5+1 카드할인 30%
    피에르가르뎅 다이아 양말 세트 각 6,230원
    ▶ 3매

    5+1 카드할인 30%
    휠라 캐주얼 자수 양말 세트 각 6,930원
    ▶ 3매

    5+1 카드할인 30%
    네파 아웃도어 양말 세트 각 7,630원
    ▶ 3매

    5+1 카드할인 30%
    컬럼비아 중목 양말 세트 각 7,630원
    ▶ 3매

    5+1 카드할인 30%
    네파 남녀 스포츠 양말 세트 10,430원
    ▶ 6매

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트
    나주 청미래 배 L.POINT 10,000원 할인 39,900원
    ▶ 배 7~9입(5.0kg 내외), 국산

    제스프리 골드키위 L.POINT 10,000원 할인 39,900원
    ▶골드키위 25입(2.5kg 이상), 뉴질랜드산

    제주 황금향 49,900원
    ▶황금향 8~12입(3.0kg 내외), 국산

    허니듀메론 L.POINT 10,000원 할인 49,900원
    ▶허니듀메론(7.2kg 내외), 미국산

    홍대감 채끝살 육포세트 L.POINT 6,000원 할인 43,900원
    ▶ 외국산 우육 60g×5봉, 원산지 별도표기(우육 : 미국/호주/뉴질랜드)

    궁 쇠고기육포 선물세트 L.POINT 12,000원 할인 47,900원
    ▶ 외국산 우육 70g×6봉, 원산지 별도표기(우육 : 미국/호주/뉴질랜드)

    청정곳간 유명산지 멸치새우 선물세트 L.POINT 15,000원 할인 41,900원
    ▶ 국물용 170g, 볶음용 140g, 볶음조림용 170g, 두절꽃새우 80g/멸치 : 국산

    유명산지 통영여수 멸치세트 L.POINT 16,000원 할인 43,900원
    ▶ 볶음용멸치 240g, 볶음조림용 멸치 220g 조림용 멸치 210g, 국물용 멸치 210g/멸치 : 국산

    청정곳간 유명산지 통영멸치 세트 L.POINT 15,000원 할인 41,900원
    ▶ 어린이멸치 230g, 볶음용멸치 230g, 조림용 멸치 210g, 국물용 멸치 210g/멸치 : 국산

    6년근 실속 금산 인삼 세트 L.POINT 20,000원 할인 29,900원
    ▶6년근 인삼 난밭 350g, 국산

    유명산지 멸치&견과 혼합세트 L.POINT 15,000원 할인 가 41,900원
    ▶ 볶음용 220g, 볶음조림용 100g, 조림용 100g 호두 130g, 아몬드 170g/멸치 : 국산/호두, 아몬드 : 미국

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    1+1 씨제이 한뿌리 흑삼아르기닌 흑마늘 SET 69,800원
    ▶ 100ml×6병, 50ml×12입

    1+1 농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    동원 양반돌김 L.POINT 30% 39,970원
    ▶ 돌김캔 30g×8캔, 원산지 별도표기

    동원 감태김 M11호 L.POINT 30% 34,230원
    ▶ 감태 전장김 20g×3봉, 감태김 30g×2캔 , 초사리 식탁김 4g×8봉, 원산지 별도표기

    동원 양반김 M6호 L.POINT 20% 32,960원
    ▶ 더바삭한 전장김 25g×6봉, 돌김 30g×2캔, 원산지 별도표기

    동원 양반김 M40호 L.POINT 30% 44,940원
    ▶ 들기름 전장김 20g×6봉, 돌김 30g×2캔, 살코기 참치 135g×8캔, 원산지 별도표기

    비비고 초사리 곱창돌김 1호 L.POINT 30% 39,830원
    ▶ 초사리 곱창돌김 전장 20g × 3봉 , 초사리 곱창돌김 캔 25g × 2캔 外, 원산지 별도표기

    비비고 감태김 혼합 1호 L.POINT 30% 34,230원
    ▶ 감태김 전장 20g × 3봉 外, 원산지 별도표기

    비비고 감태김·초사리 곱창돌김 L.POINT 30% 49,980원
    ▶ 초사리전장 20gx6봉, 감태캔 25gx4캔 外, 원산지 별도표기

    성경 매생이김 감태김 세트 L.POINT 8,000원 할인 33,900원
    ▶ 매생이김 전장 20g×4봉 外, 원산지 별도표기

    해우촌 진도 곱창김 세트 L.POINT 7,000원 할인 37,900원
    ▶ 구운곱창김 12g×8봉 外, 원산지 별도표기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트

    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준
    동원
    동원 프리미엄 5호 카드할인 30% 30,940원
    ▶ 카놀라유 480ml×2 , 동원맛참 고소 90g×5, 리챔 120g×2 外
    
    2+1 동원 참치액 1호 32,900원
    ▶ 동원 참치액 진 500g×2, 참치액 순 500g×2

    동원 튜나리챔 30호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×12, 리챔 더블라이트 120g×3

    동원 프리미엄 60호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×9, 동원 참치액 진 240g, 건강요리유 480ml×2 外

    동원 프리미엄 45호 카드할인 30% 34,930원
    ▶ 카놀라유 480ml, 살코기참치 90g×5, 올리고당 700g 外

    2+1 동원 프리미엄 K호 38,900원
    ▶ 카놀라유 480ml, 살코기참치 90g×3, 리챔 120g, 올리고당 700g 外

    동원참치 M14호 L.POINT 20% 38,960원
    ▶ 살코기참치 90g×4, 135g×10

    동원 리챔 G9호 카드할인 30% 38,990원
    ▶ 리챔 200g×9

    동원 튜나리챔 64호 카드할인 30% 39,920원
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    동원 프리미엄 20호 L.POINT 30% 39,970원
    ▶ 살코기참치 90g×12, 리챔 120g×4, 건강요리유 480ml×2 外

    동원 스페셜 38호 카드할인 30% 39,970원
    ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4, 건강요리유 480ml×2 外

    동원 튜나리챔 100호 카드할인 30% 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    씨제이 제일제당
    씨제이 스팸복합 1호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6

    씨제이 스팸복합 5호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 120g×9

    씨제이 특별한선택 스페셜 A호 카드할인 30% 34,930원
    ▶ 카놀라유 500ml, 스팸 200g×4, 올리고당 500g 外

    3+1 씨제이 백설 고소함가득 참기름 2호 35,900원
    ▶ 고소함가득 참기름 450ml, 참기름(병) 320ml×2

    씨제이 특별한선택 스페셜 S호 카드할인 30% 38,430원
    ▶ 카놀라유 500ml×2, 스팸 200g×5, 고소함가득참기름 330ml 外

    씨제이 스팸복합 S호 카드할인 30% 39,830원
    ▶ 카놀라유 500ml×2, 스팸 200g×5, 스팸 340g×2 外

    씨제이 특별한선택 L-2호 카드할인 30% 40,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 700g 外

    씨제이 특별한선택 N호 카드할인 30% 41,230원
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7

    씨제이 스팸 8K호 카드할인 20% 41,520원
    ▶ 스팸 200g×6, 스팸 라이트 200g×3

    2+1 씨제이 특별한선택 K호 43,900원
    ▶ 카놀라유 500ml, 요리올리고당 700g×2, 스팸 120g×4 外

    씨제이 특별한선택 JH호 카드할인 30% 44,730원
    ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 500g 外

    씨제이 특별한선택 L-5호 카드할인 30% 49,630원
    ▶ 카놀라유 500ml×2, 스팸 200g x 8 外

    씨제이 스팸복합 K호 L.POINT 30% 49,910원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×6

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트

    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준
    청정원
    10+1 청정원 맛선생 한알육수 세트 40,000원
    ▶ 국물내기 한알 멸치디포리 100g, 야채 100g, 사골 87.5g

    5+1 청정원 맛선생 국물 티백 세트 37,000원
    ▶ 맛선생 국물내기티백 멸치 10g×6, 해물 9g×6, 야채 6g×6

    10+1 카드할인 30%
    청정원 스페셜 S2호 36,260원
    ▶ 카놀라유 500ml×2, 잇츠팜 115g, 맛술 생강&매실 410ml 外

    10+1 카드할인 30%
    청정원 L8호 37,660원
    ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g×6 外

    청정원 L9호 카드할인 30% 39,760원
    ▶ 카놀라유 500ml×2, 잇츠팜 190g×2 外

    청정원 L10호 카드할인 30% 43,960원
    ▶ 카놀라유 500ml, 우리팜델리 115g×2 外

    청정원 L11호 카드할인 30% 46,760원
    ▶ 카놀라유 500ml×2, 우리팜델리 115g×8 外

    청정원 L12호 카드할인 30% 49,560원
    ▶ 순살참치 135g×5, 카놀라유 500ml×2 外

    사조

    사조 The Wave 참치 세트 카드할인 30% 33,530원
    ▶ 살코기참치 85g×15

    사조 안심특선 99호 카드할인 30% 34,300원
    ▶ 살코기참치 135g×6, 살코기참치 85g×6, 안심팜 115g×4

    사조 안심특선 98호 카드할인 30% 34,720원
    ▶ 카놀라유 500ml, 바삭요리유 500ml, 살코기참치 85g×5, 안심팜 115g×2, 참치액 500g 外

    녹차원

    1+1 녹차원 액상차 세트 32,900원
    ▶ 꿀유자차 350 g, 홍삼담은레몬차 350g , 꿀자몽차 350g, 홍삼 담은 석류차 350g , 통곡물율무차 5T 통곡물마차 5T

    1+1 녹차원 홍삼차세트 39,900원
    ▶ 홍삼진골드 6병 , 홍삼배도라지 70 ml×4포, 녹용진 70 ml×4포, 쌍화차 10포, 생강차 10포

    1+1 녹차원 홍삼 배도라지 세트 37,900원
    ▶ 홍삼배도라지 70 ml×24입

    1+1 녹차원 녹용진 세트 37,900원
    ▶ 녹용진 70 ml×24입

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    엘지 프로폴리스 프리미엄 L.POINT 50% 30,950원
    ▶ 엘라스틴 프로폴리스 샴푸/컨디셔너 385ml, 벨먼 프로폴리스 바디워시 385ml 外

    엘지 생활의 품격 7호 카드할인 30% 31,430원
    ▶ 닥터그루트 집중케어 샴푸/컨디셔너 180ml, 오가니스트 바디워시 라벤더향 190ml×2 外

    5+1 카드할인 30%
    엘지 AI 스페셜에디션 프리미엄 32,130원
    ▶ 오가니스트 스페셜에디션 샴푸 380ml×2, 온더바디 스페셜에디션 바디워시 380ml 外

    엘지 히말라야 핑크솔트 로즈에디션 카드할인 30% 32,830원
    ▶ 히말라야 핑크솔트 로즈 샴푸 380ml×2, 히말라야 핑크솔트 로즈 바디워시 380ml×2 外

    1+1 엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2, 온더바디 보타닉 레몬바디워시 500g 外

    1+1 애경 내추럴밀크 33호 33,900원
    ▶ 케라시스 오트밀크 샴푸 400ml, 샤워메이트 산양유 바디워시 400m×2 外

    아모레퍼시픽 섬김 4호 카드할인 50% 33,900원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml×2 外

    엘지 정성담은 프리미엄 3호 L.POINT 40% 34,740원
    ▶ 엘라스틴 프로폴리테라 샴푸 200ml×2, 오가니스트 수분 샴푸/컨디셔너 각 200ml 外

    1+1 애경 프로폴리스 36호 36,900원 
    ▶ 케라시스 로얄 프로폴리스 샴푸 400ml×2, 샤워메이트 프로폴리스 바디워시 400ml 外

    2+1 엘지 정성담은 36호 36,900원
    ▶ 닥터그루트 밀도케어 샴푸 190ml, 오가니스트 바디워시 190ml×2 外

    엘지 히말라야 핑크솔트 프리미엄 카드할인 30% 37,730원
    ▶ 히말라야 핑크솔트 샴푸 자몽향 500ml, 히말라야 핑크솔트 클렌징폼 200ml 外

    엘지 정성담은 모던 클래식 카드할인 30% 40,530원
    ▶ 오가니스트 모던 샴푸 400ml×2, 온더바디 모던 바디워시 385ml 外

    엘지 닥터그루트 에센셜 카드할인 30% 39,900원 
    ▶ 닥터그루트 힘없는 모발용 샴푸 300ml×2, 닥터그루트 힘없는 모발용 샴푸 180ml 外

    1+1 엘지 모던에디션 라이프 41,900원 
    ▶ 오가니스트 모던 샴푸 400ml, 온더바디 모던 바디워시 385ml 外

    엘지 정성담은 63호 L.POINT 30% 44,730원 
    ▶ 리엔 로열젤리 샴푸 400ml, 온더바디 보타닉 레몬바디워시 500g 外

    1+1 엘지 프로폴리스 라이프 45,900원 
    ▶ 엘라스틴 프로폴리스 샴푸/컨디셔너 385ml, 벨먼 프로폴리스 바디워시 200ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    5~6만원대 선물세트
    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준

    장수 사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    태국산 망고 L.POINT 10,000원 할인 69,900원
    ▶ 옐로우망고 9입 (3.2kg 내외), 태국산
    
    GAP 청도 반건시 L.POINT 10,000원 할인 59,900원
    ▶ 청도 반건시 30입(1.5kg 내외), 국산

    7+1 자연담은 칠산소금 참굴비세트 1호 60,000원
    ▶ 0.8kg, 10미, 참조기/천일염, 국산

    유명산지 금산 인삼 세트 카드할인 10,000원 할인 69,900원
    ▶ 4년근 인삼 500g(뿌리당 50g 이상), 국내산

    유명산지 홍천더덕 세트 카드할인 10,000원 할인 69,900원 
    ▶ 더덕 1.2kg(뿌리당 40~50g 이상), 국내산

    CJ 제일명인 정통 육포 세트 L.POINT 20% 회원가 55,120원 
    ▶ 외국산 우육 70g×6입, 원산지 별도표기(우육 : 호주/뉴질랜드/미국)

    3+1 코주부 프리미엄 육포세트 4호 59,900원
    ▶ 외국산 우육 50g×8입 원산지 별도표기(우육 : 호주/뉴질랜드/미국)

    3+1 동원 쇠고기 육포 선물세트 65,900원
    ▶ 외국산 우육 60g×6입, 원산지 별도표기(우육 : 호주/뉴질랜드/미국)

    3+1 우리소 육포세트 69,900원
    ▶ 국내산 우육 55g×7입, 원산지 별도표기(우육 : 국내산)

    정관장 홍삼원기 카드할인 20% 54,400원
    ▶ 50ml×60포

    정관장 홍삼진활력 카드할인 25% 54,750원
    ▶ 50ml×60포
    
    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    동원 스페셜 102호 카드할인 30% 52,990원
    ▶ 살코기참치 135g×9, 리챔 더블라이트 120g×8, 건강요리유 480ml×2

    동원 튜나리챔 102호 카드할인 30% 54,950원 
    ▶ 살코기참치 135g×9, 리챔 200g×8
    
    동원 프리미엄 86호 카드할인 30% 54,950원
    ▶ 살코기참치 90g×7, 동원 참치액진 500g, 동원 참치액순 500g, 리챔 120g×2 外

    동원 튜나리챔 11호 L.POINT 25% 59,850원 
    ▶ 살코기참치 135g×12, 고추참치 90g×8, 리챔 더블라이트 200g×4

    1+1 동원 리챔 더블라이트 M9호 59,900원
    ▶ 리챔 더블라이트 120g×3, 리챔 더블라이트 200g×6

    동원 스페셜 25호 카드할인 30% 59,990원
    ▶ 살코기참치 90g×6, 리챔 120g×13, 카놀라유 480ml×2
    
    동원 튜나리챔 10호 카드할인 20% 69,920원
    ▶ 살코기참치 135g×18, 리챔 200g×2, 리챔340g

    씨제이 스팸복합 100호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 340g×2, 스팸 120g×2

    씨제이 특별한선택 스페셜 L-6호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2, 고소함가득 참기름 330ml, 구운소금 180g×2, 스팸라이트 200g×6 外
    
    씨제이 최고의선택 레드라벨 카드할인 30% 67,830원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×5 外

    1+1 씨제이 Save Earth Choice LM호 69,900원
    ▶ 안달루시안 올리브유 500ml, 퓨어 올리브유 500ml, 해바라기씨유 500ml

    씨제이 스팸 6호 69,900원 
    ▶ 스팸 200g×12

    청정원 L13호 카드할인 30% 54,460원 
    ▶ 카놀라유 500ml×2, 잇츠팜 115g×7, 정통사과식초 470ml 外

    청정원 L14호 카드할인 30% 59,990원
    ▶ 카놀라유 500ml, 어간장 410ml, 잇츠팜 115g×6, 우리팜델리 115g×4 外

    엘지 히말라야 핑크솔트 클래식 카드할인 30% 50,330원 
    ▶ 히말라야 핑크솔트 샴푸 500ml, 히말라야핑크솔트 바디워시 480ml 外

    엘지 닥터그루트 밀도케어 세트 카드할인 30% 58,030원
    ▶ 닥터그루트 밀도케어 샴푸 자스민 400ml, 닥터그루트 밀도케어 샴푸 바질향 400ml 外

    엘지 시그니처 컬렉션 카드할인 30% 59,430원
    ▶ 닥터그루트 밀도케어 샴푸 바질향 400ml, 피지오겔 레드수딩 AI 크림 100ml 外

    10+1 엘지 명작 프리미엄 59호 59,900원
    ▶ 테크 세탁세제 975ml, 샤프란 핑크 1L, 히말라야 핑크솔트 샴푸 380ml 外

    엘지 닥터그루트 프리미엄 세트 카드할인 30% 65,100원 
    ▶ 닥터그루트 힘없는 모발용 샴푸 400ml, 닥터그루트 바이옴 캡슐 샴푸 400ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    7~9만원대 선물세트
    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준

    프라임 배 L.POINT 10,000원 할인 79,900원
    ▶ 배 9입(6.8kg 내외),국산

    프라임 사과 sL.POINT 10,000원 할인 79,900원
    ▶ 사과 12입(4.5kg 내외), 국산

    산청곶감(情) L.POINT 10,000원 할인 79,900원
    ▶ 곶감 32입(1.1kg 내외), 국산

    10+1 L.POINT 30,000원 할인
    호주산 소 냉동 찜갈비 세트 99,000원
    ▶ 호주산 냉동 찜갈비 0.8kg×3, 양념소스
    ※700세트 한정 ※산지택배(전국배송) 가능
    ※ 10+1 구매시 개당 90,000원

    7+1 마른 부세 굴비 세트 89,900원
    ▶ 1.0kg, 5미, 부세굴비: 중국산/천일염 :국산

    상황 영지버섯 L.POINT 10,000원 할인 89,900원
    ▶ 상황버섯 130g, 영지버섯 300g, 국내산

    금산 인삼, 홍천 더덕 세트 99,900원
    ▶ 인삼 400g, 더덕 600g, 국내산

    건강 버섯 특선세트 L.POINT 10,000원 할인 99,900원
    ▶ 국내산 상황버섯 60g, 국내산 영지버섯 180g, 차가버섯200g 外, 원산지 별도표기

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    행사카드 구매 금액대별 최대 120 만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※ 1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    청정와규 세트 1호 L.POINT 40,000원 할인 99,000원
    ▶ 호주산 냉장 와규 윗등심 0.5kg×2, 양지 국거리 0.5kg, 정육 불고기 0.5kg
    ※산지택배(전국배송) 가능
    ※상품권 증정 제외
    
    청정와규 세트 2호 L.POINT 20,000원 할인 79,000원
    ▶ 호주산 냉장 와규 양지 국거리 0.5kg×2 , 정육 불고기 0.5kg×2
    ※산지택배(전국배송) 가능
    ※상품권 증정 제외

    프리미엄 무염 견과 9종 세트 L.POINT 20,000원 할인 79,900원
    ▶ 호두 160g, 구운아몬드 210g, 구운캐슈넛 180g, 브라질너트 250g 外, 원산지 별도표기

    세계 견과/건과일 BEST 10종 세트 L.POINT 20,000원 할인 79,900원
    ▶ 구운아몬드 110g, 구운 캐슈너트 100g, 호두 75g, 브라질너트 75g, 피칸 80g 外, 원산지 별도표기

    CMG제약 침향진환 L.POINT 50% 72,000원
    ▶ 3.75g×60환

    전복장(800g), 간장새우장(600g), 양념게장(500g), 간장게장(500g), 밥도둑 반찬 4종 실속 종합세트
    장 종합 선물세트 79,000원
    ▶ 원산지 별도표기
    ※온라인몰 전점 운영(페이지 상단 QR코드 연결 시 온라인몰 구입 가능), ※오프라인 잠실점 운영

    씨제이 스팸 3K호 79,900원 
    ▶ 스팸 200g×6, 스팸 300g×6

    동원 튜나리챔 6호 카드할인 20% 99,920원
    ▶ 포도씨유참치 150g×12, 살코기참치 135g×12,리챔 200g×8

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    10만원대 이상 선물세트
    ※ 카드/엘포인트 할인, N+1 등 프로모션 적용 후 개당 가격 기준

    자이언트 사과, 배 109,900원
    ▶ 사과 5입(2.1kg 내외), 배 4입(3.6kg 내외)

    나주하우스배, 충주사과 199,900원
    ▶ 사과 8입(2.9kg 내외), 배 6입(4.9kg 내외),국산

    나주 최종기 농부의 자이언트 하우스배 L.POINT 20,000원 할인 109,900원
    ▶ 배 9입 (8.0kg 내외), 국산

    자이언트 사과 109,900원
    ▶ 사과 12입(5.3kg 내외), 국산

    10+1 L.POINT 30,000원 할인
    미국산 소 냉동 혼합 갈비 세트 119,000원
    ▶ 미국산 냉동 꽃갈비/찜갈비 각 1.5kg ※900세트 한정
    ※10+1 구매시 개당 108,182원 ※전국 택배 운영
    ※상품권 증정 제외

    7+1 참굴비 세트 3호 139,000원
    ▶ 1.8KG/20미

    10+1 L.POINT 30,000원 할인
    대한민국 으뜸 제주 옥돔 세트 179,000원
    ▶ 3마리(1.5kg 내외), 냉동, 옥돔/천일염 : 국산

    6년근 프리미엄 인삼세트 L.POINT 10,000원 할인 149,000원
    ▶ 6년근 인삼 700g, 국내산

    팔각 백화고 159,000원
    ▶ 백화고 100g×4입, 국내산

    풍기인삼 황풍정 홍삼정과 107,000원 
    ▶ 700g

    10+1 씨제이 스팸 블랙라벨 109,900원
    ▶ 안달루시안 올리브유 500ml×2, 스팸 340g×9

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    통큰 한가위 명절에 가장 받고 싶은 최고의 선물 롯데상품권

    구입 장소 전국 롯데마트 상품권 데스크
    상품권 종류 지류상품권 - 5천원/1만원/3만원/5만원/10만원/30만원/50만원
    ※3만원권은 점별 보유 수량 상이

    결제 방법 현금(자기앞수표 포함), 법인카드, 체크카드 
    ※체크카드/자기앞수표는 100만원 한도

    사용 장소
    쇼핑 
    롯데마트(전점 및 문화센터, 토이저러스) 롯데슈퍼, 롯데백화점, 롯데면세점, 롯데하이마트 등

    호텔
    롯데호텔, 시그니엘(서울, 부산), 롯데시티호텔, 그랜드워커힐, 서울프라자, 경주힐튼호텔, 메종글래드제주

    외식
    T.G.I FRIDAY’S, 아웃백 스테이크하우스, 빕스 크리스피 크림도넛, 매드포갈릭, 딘타이펑 등

    레저
    롯데월드, 롯데JTB, 롯데리조트(부여, 속초, 제주), 서울랜드 파라다이스 스파 도고, 한솔오크밸리, 한화리조트 등

    골프
    롯데스카이힐 C.C(부여, 제주), 프라자 C.C(용인, 설악, 제주) 베어즈베스트 청라 골프클럽 등

    문화/예술
    롯데시네마, 롯데문화센터 롯데콘서트홀, 롯데뮤지엄, 영풍문고
    ※사용처별 일부매장 제외 ※사용처는 변경될 수 있습니다
    
  </div>
  `,
  `
  <div>
    추석 선물세트도 역시 롯데마트몰에서!
    클릭 한번으로 두 손은 가볍게! 혜택은 무겁게 가득 받아가세요!

    최대 50% 해당상품 카드할인

    주문 1건당 최대 120만원 즉시할인

    한 번의 결제로 여러 곳에 배송

    다양한 선물세트로 폭넓은 선택

  </div>
  `,
];

/* 793, 794 맥스 추석 사전예약 (~9/6) */
const L793data = [
  `<h1>LOTTE Mart MAXX</h1>
  <div>
    통큰 한가위 MAXX
    사전예약 기간 2024/8/1(목) ~ 2024/9/6(금)

  </div>
  `,
  `
  <div>
    미리 준비할수록 더 큰 혜택! ※사전예약 기간 : 2024. 8. 1(목) ~ 2024. 9. 6(금)

    롯데마트&MAXX카드, 롯데마트맥스 신한카드, 롯데카드, 비씨카드, KB국민카드, 신한카드, NH농협카드, 하나카드, 현대카드, 삼성카드, 우리카드, IBK 기업은헹, 전북은행 JB카드, 광주은행 KJ 카드
    최대 120만원
    상품권 증정 또는 즉시할인 ※해당 기간에 한함

    1차 8/1(목) ~ 8/23(금)
    30만원 이상 3만 6천원 ※하단 세부내용 참고
    50만원 이상 6만원
    100만원 ~ 1,000만원 미만 100만원 당 12만원
    1,000만원 이상 120만원

    2차 8/24(토) ~ 8/30(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 8만원
    1,000만원 이상 80만원

    3차 8/31(토) ~ 9/6(금)
    30만원 이상 1만 5천원
    50만원 이상 2만 5천원
    100만원 ~ 1,000만원 미만 100만원 당 5만원
    1,000만원 이상 80만원

    ※상품권 : 3만원 5천원 상품권 + 1천원 쿠폰할인(사전예약 1차 기간 내 30만원 이상에 한함)

    ※자세한 내용은 매장 내 고지물 및 롯데마트GO앱 참조
    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    최대30% 할인혜택
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT 회원 특별할인 ※해당상품에 한함
    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함
    3만원 이상 구매시 전국 무료배송 
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준
    ※롯데마트몰은 무료배송 기준 별도 운영

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 8. 1 기준이며, 일부 변경 될 수 있음

  </div>
  `,
  `
  <div>
    받으시는 분의 소중한 품격을 더한 프리미엄 선물세트

    최고의 미식 경험 
    아이슬란드 지중해 바다에서 건져올린 진귀한 가시발새우 풍미가 섬세하고 부드러운 식감과 단맛이 일품인 미식 신세계!

    사전예약 상품권 증정 카드할인 20%
    랑구스틴세트 170,000원
    ▶ 1kg/18마리/냉동/아이슬란드

    1++(9)등급, 1+등급으로 즐기는 고품격 한우 갈비세트 

    L.POINT 50,000원 할인 
    Marble9 한우 갈비세트 268,000원
    ▶ 1++(9) 등급 한우 찜갈비 700g×5, 냉동, 국내산 한우고기

    L.POINT 50,000원 할인 
    1+등급 한우 갈비세트 198,000원
    ▶ 1+등급 한우 찜갈비 700g×4, 냉동, 양념소스, 국내산 한우고기

    해풍맞고 자라 속심이 적고 아삭한 울릉도 왕더덕

    사전예약 상품권 증정
    울릉도 왕더덕 카드할인 20,000원 18,000원
    ▶ 더덕 1.5kg

    L.POINT 10,000원 할인
    청정환경에서 키운 우리한우로 만들어 진한 육향과 부드러운 식감이 일품
    사전예약 상품권 증정
    지리산 순한한우 육포세트 69,800원
    ▶ 한우 60g×5봉, 원산지 별도표기

    L.POINT 30,000원 할인
    청정지역 남해안산 멸치로, 일일이 수작업으로 담아내 선명한 은빛과 두툼한 몸통이 특징인 최고급 멸치
    사전예약 상품권 증정
    팔각 명품 귀족 멸치세트 139,800원
    ▶ 멸치720g, 국산

    프리미엄 햄/요리

    사전예약 상품권 증정 
    존쿡 델리미트 샤퀴테리 세트 카드할인 10% 49,500원
    ▶ 하몽 세라노 그란 레세르바, 까챠토레, 코파, 이탈리안살라미, 카바노치 外

    사전예약 상품권 증정 
    존쿡 델리미트 바베큐 세트 카드할인 10% 71,100원
    ▶ 슈바인학센, 바베큐백립, 델리부어스트, 데브리치너, 보헤미안 화이트와인소시지 外

    사전예약 상품권 증정 
    상하농원 수제 햄&치즈 세트 카드할인 20% 79,200원
    ▶ 클래식비엔나 화이트비엔나, 롤비엔나, 치즈프랑크, 그릴드치즈, 스틱치즈

    사전예약 상품권 증정 
    상하농원 햄공방 실속세트 카드할인 20% 79,200원
    ▶ 클래식비엔나, 화이트비엔나, 스모크프랑크, 치즈프랑크 外

    경복궁 한우 차림 세트 카드할인 10% 44,100원
    ▶ 한우 사골곰탕 600g×2팩, 한우사골, 고기곰탕 600g, 한우 떡갈비 200g

    오뗄 수제햄 특선 1호 64,900원
    ▶ 바베큐안심햄, 화이트본레스햄, 차슈, 모타델라, 부어스트에센 바비큐 슁켄, 비어슁켄, 쿡살라미 外

    경복궁 명절 한상 선물세트 79,000원
    ▶ 칼집LA갈비600g, 영양갈비탕850g, 양념불고기600g, 한우사골곰탕600g

    더본코리아 본가 선물세트 카드할인 30% 105,000원
    ▶ 본가 소갈비찜 500g×4, 본가 소불고기 250g×6, 본가 너비아니 150g×6

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    탐스럽게 영근 알찬 과실만 모은 과일 선물세트

    · 높은 일교차와 비옥한 토질로 모양과 빛깔이 뛰어난 문경사과
    · 일조량이 풍부하고 일교차가 큰 유명산지에서 재배한 고품질 신고배

    사전예약 상품권 증정
    프리미엄 문경사과 신고배 세트 카드할인 20% 47,840~55,840원
    ▶ 사과 6입, 배 6입(5.5kg 내외), 국산

    사전예약 상품권 증정
    황금당도 샤인머스캣 세트 49,800원
    ▶ 샤인머스캣 3~4입(3kg), 국산

    사전예약 상품권 증정
    유명산지 신고배 세트 49,800~59,800원
    ▶ 배 10~11입(6.5kg 내외), 국산

    사전예약 상품권 증정
    유명산지 문경사과 세트 44,800~54,800원
    ▶ 사과 15~16입(4.5kg 내외), 국산

    사전예약 상품권 증정
    큰사과큰배 혼합세트 카드할인 20% 63,840~71,840원
    ▶ 사과 6입, 배 6입(6.4kg 내외), 국산

    사전예약 상품권 증정
    샤인머스캣, 사과, 배 세트 49,800~54,800원
    ▶ 사과 4입, 배 3입, 샤인머스캣 1입(3.6kg 내외), 국산

    사전예약 상품권 증정
    상주 왕 곶감 99,800원
    ▶ 왕곶감 32입(1.6kg 내외), 국산

    사전예약 상품권 증정
    제스프리 골드키위 세트 29,800원
    ▶ 골드키위 18~20입(2.7kg 내외), 뉴질랜드산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다

  </div>
  `,
  `
  <div>
    햇살과 바람이 키운 건식품 선물세트

    제주 추자도 거친 파도 속에서 자생하는 자연산 돌미역을 해녀가 직접 채취한 귀한 미역과 통영산 멸치로 구성
    사전예약 상품권 증정 
    추자도미역 통영멸치세트 카드할인 15,000원 49,800원
    ▶ 추자도미역 80g, 볶음용멸치 270g, 볶음조림용멸치 240g, 국물용멸치 240g, 각 국산

    사전예약 상품권 증정 
    남해바다 정치망 멸치세트 L.POINT 25,000원 할인 74,800원
    ▶ 볶음용멸치 200g, 볶음조림용멸치 180g, 조림용멸치 150g, 국물용멸치 180g, 원산지 별도표기

    사전예약 상품권 증정
    대천 캔김 세트 L.POINT 10,000원 59,800원
    ▶ 대천 캔김 세트 15캔, 원산지 별도표기

    사전예약 상품권 증정
    동원 쇠고기 육포세트 L.POINT 17,000원 할인 48,800원
    ▶ 60g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    코주부 육포세트 2호 카드할인 10,000원 44,800원
    ▶60g×8봉, 원산지 별도표기

    사전예약 상품권 증정
    국내산 소고기 육포세트 L.POINT 10,000원 할인 39,800원
    ▶ 국내산 육우 50g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    코주부 육포세트 1호 카드할인 7,000원 36,800원
    ▶ 60g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    프리미엄 지도표 성경 김세트 카드할인 10,000원 29,980원
    ▶ 재래김/녹차김 각 30g×4봉 外, 원산지 별도표기

    사전예약 상품권 증정
    동원 양반김 M30호 L.POINT 10,000원 할인 29,880원
    ▶ 더바삭한김전장김 20g×6봉, 살코기참치 90g×6 外, 원산지 별도표기

    사전예약 상품권 증정
    대천 곱창김 세트 카드할인 8,000원 24,980원
    ▶ 곱창김(캔) 30g×4, 곱창김(전장) 25g×5봉, 김 : 국산, 원산지 별도표기

    사전예약 상품권 증정
    성경 보성녹차 정성L호 9,980원
    ▶ 녹차 식탁김 4g×3봉×8EA, 원산지 별도표기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    바다에서 나고 자란 신선함을 선물하세요 수산 선물세트

    유명산지 완도에서 직접 양식하여 좋은 품질만을 엄선하여 선별한!
    ASC 인증이란?
    안전안 환경과 품질 관련 까다로운 인증절차를 거쳐 믿을 수 있는 국제 수산물 인증제도

    사전예약 상품권 증정
    ASC인증 완도 活 전복 세트 74,900원
    ▶ 2.0 kg 내외(특대, 마리당 90g 내외, 22마리), 국산

    사전예약 상품권 증정
    킹블랙타이거 새우세트 137,500원
    ▶ 1 kg 내외(왕특대, 5마리/냉동), 기니아산

    사전예약 상품권 증정
    칠산소금 참굴비 세트 2호 카드할인 20% 110,000원
    ▶ 1.0kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산
    
    사전예약 상품권 증정
    영광 법성포 참굴비 세트 1호 카드할인 20% 69,520원
    ▶ 1.4kg 내외(20마리), 냉동 참조기 : 국산, 천일염 : 국산

    사전예약 상품권 증정
    칠산소금 참굴비 세트 1호 76,880원
    ▶ 0.8kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산

    사전예약 상품권 증정
    칠산소금 굴비 특호 187,500원
    ▶ 1.2kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산

    사전예약 상품권 증정
    제주 갈치/옥돔 혼합 세트 99,000원
    ▶ 1.16kg 내외(갈치 400g×2, 옥돔 180g×2), 냉동, 국산

    사전예약 상품권 증정
    제주 프리미엄 갈치 세트 카드할인 20% 159,200원
    ▶ 2.2kg(550g×4팩), 냉동, 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다.

  </div>
  `,
  `
  <div>
    귀한 분께 언제나 축산 선물세트

    사전예약 상품권 증정
    호주산 LA식 꽃갈비 세트 카드할인 30,000원 148,000원
    ▶ 호주산 LA식 꽃갈비(냉동)2kg×2

    사전예약 상품권 증정
    미국산 LA식 꽃갈비 세트 카드할인 30,000원 148,000원
    ▶ 미국산 LA식 꽃갈비(냉동)2kg×2

    사전예약 상품권 증정
    호주산 혼합갈비 세트 카드할인 30,000원 128,000원
    ▶ 호주산 LA식 꽃갈비(냉동)2kg, 호주산 찜갈비(냉동)2kg

    사전예약 상품권 증정
    한우 갈비 세트 L.POINT 30,000원 할인 138,000원
    ▶ 한우 갈비(냉동) 700g×3, 양념소스, 국내산 한우고기

    한우 갈비 정육 세트 L.POINT 20,000원 할인 108,000원
    ▶ 한우갈비(냉동) 700g, 한우 국거리(냉동) 700g, 한우 불고기(냉동) 700g, 양념소스, 국내산 한우고기

    사전예약 상품권 증정
    무항생제 한우 꼬리반골 세트 L.POINT 15,000원 할인 54,800원
    ▶ 무항생제 한우 꼬리반골(냉동) 5kg, 국내산 한우고기

    사전예약 상품권 증정
    한우 등심실속 세트 L.POINT 30,000원 할인 158,000원
    ▶ 2등급 한우 등심 500g×4, 냉장, 국내산 한우고기 ※200세트한정

    사전예약 상품권 증정
    한우 등심 세트 2호 L.POINT 40,000원 할인 208,000원
    ▶ 1등급 한우 등심 500g×4, 냉장, 국내산 한우고기

    사전예약 상품권 증정
    한우 등심채끝 혼합세트 L.POINT 40,000원 할인 218,000원
    ▶ 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기

    사전예약 상품권 증정
    한우 등심 정육 세트 2호 L.POINT 30,000원 할인 148,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기

    사전예약 상품권 증정
    한우 등심정육세트 1호 L.POINT 40,000원 할인 188,000원
    ▶ 1등급 한우 등심, 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    땅의 기운을 오롯이 담은 더덕ㆍ인삼ㆍ버섯&건강한 견과 선물세트

    사전예약 상품권 증정
    6년 동안 정성스레 재배한 인삼을 엄선 선별한 귀한삼 
    6년근 프리미엄 인삼 카드할인 20,000원 108,000원
    ▶ 6년근 인삼 750g, 국산

    사전예약 상품권 증정
    해풍맞고 자란 울릉도 특산품 속심이 적고 아린맛이 덜한 고유의 특별함
    울릉도 섬더덕 79,800원
    ▶ 더덕 800g, 국산

    사전예약 상품권 증정
    정성담은 표고버섯 견과 혼합 카드할인 15,000원 63,800원
    ▶ 흑화고 80g×2, 표고채 60g×2, 구운캐슈넛 150g 外, 원산지 별도표기

    사전예약 상품권 증정
    건강한 다과한상 39,800원
    ▶ 도라지정과 25g×3, 건대추채 50g, 건도라지 35g 外, 원산지 별도표기

    사전예약 상품권 증정
    자연담은 건강버섯 어울림 카드할인 15,000원 59,800원
    ▶ 상황버섯 50g, 영지버섯 130g, 녹각 45g 外, 원산지 별도표기

    사전예약 상품권 증정
    자연담은 표고버섯 어울림 카드할인 13,000원 49,800원
    ▶ 표고채 60g, 흑화고 80g×2, 목이버섯 40g 外, 원산지 별도표기

    사전예약 상품권 증정
    국산 참나무 원목에서 자연 재배하여 키운 버섯 단단한 육질과 고유의 뛰어난 식감이 일품
    팔각 명품 백화고 139,000원
    ▶ 백화고 400g, 국산

    사전예약 상품권 증정
    지리산 벌집꿀 카드할인 20,000원 89,800원
    ▶ 벌집꿀 3kg(밀랍, 나무틀 포함), 국산

    사전예약 상품권 증정
    빅너츠 앤베리 카드할인 10,000원 49,800원
    ▶ 구운아몬드 310g, 구운캐슈넛강정 250g 外, 원산지 별도표기

    사전예약 상품권 증정
    하루한줌 프리미엄 80봉 견과 카드할인 10,000원 29,800원
    ▶ 하루한줌 4종 구성, 원산지 별도표기 (프리미엄, 믹스넛, 넛츠앤베리, 오리지널 각 20입)

    사전예약 상품권 증정
    정성 담은 웰빙견과 10종 카드할인 10,000원 39,800원
    ▶ 볶음 아몬드 190g, 볶음 캐슈넛 160g, 호두 120g 外, 원산지 별도표기

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다

  </div>
  `,
  `
  <div>
    즐거운 추석, 이색 선물세트로 특별함을 선물하세요

    어묵&냉동요리
    삼진어묵 프리미엄 세트 50,000원
    ▶ 당면도톰네모, 야채도톰네모, 부추잡채도톰네모, 핫땡초말이 外

    고래사어묵 선물세트 5호 60,000원
    ▶ 고래바×2, 통새우말이×2, 치즈빵빵, 떡말이, 새우봉어묵, 크랩모아 外

    고래사어묵 선물세트 7호 80,000원
    ▶ 용궁어묵, 전복어묵, 통새우말이,떡말이, 치즈빵빵×2, 새우봉어묵, 크랩모아 外

    삼진어묵 이금복 명품세트 80,000원
    ▶ 명품 소라어묵, 명품 새우어묵, 명품 오징어어묵, 명품바(소시지치즈, 새우치즈) 外

    한성젓갈 특3호 세트 카드할인 30% 63,000원 
    ▶ 창란젓갈 300g, 명란젓갈 300g, 오징어젓갈 300g

    설성목장 이동본갈비 세트 85,000원
    ▶ 소 이동 본갈비 500g×2팩

    씨제이 푸드빌 VIPS 홈레스토랑 선물세트 1호 72,400원
    ▶ 오리지널 바비큐 폭립, 척아이롤 스테이크, 명란까르보나라 원팬 파스타, 뇨끼감바스, 미트라자냐

    설성목장 명품 한우 양념갈비 세트 199,000원
    ▶ 암소한우 양념갈비 400g×5팩

    치즈&디저트
    사전예약 상품권 증정
    10+1 호정 유영군 명인한과 2호 59,900원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎), 약과(미니), 다식(단호박, 백년초, 대잎, 콩), 정과(오렌지) 外

    사전예약 상품권 증정
    5+1 호정 유영군 명인한과 1호 34,900원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎), 약과(미니) 강정(쌀, 백년초, 치자, 대잎, 쌀아몬드, 흑미) 外

    시루조아 쌀카스테라 호박인절미 선물세트 30,900원
    ▶ 쌀 카스텔라 단호박 인절미 50g×24봉

    쿠캣 시그니처 냉동떡 선물세트 46,000원
    ▶ 딸기쏙우유 찹쌀떡 540g×2팩, 티라미수쏙 찹쌀떡 540g, 초코녹차 찹쌀떡 540g

    치즈 그레이터선물세트 카드할인 10% 59,400원
    ▶ 보스카 이지 그레이터, 파르네제 그라나 파다노 150g, 에스푸나 세라노 하몽 50g 外

    치즈&샤퀴테리 세트 카드할인 10% 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 에스푸나 세라노 하몽 50g 外

    임실치즈 선물세트 1호 64,000원
    ▶ 임실 생치즈 500g, 임실 구워먹는치즈 125g×2, 임실 모짜렐라 슬라이스 치즈 100g×3 外

    신라명과 셀레브르쿠키 중 22,900원
    ▶ 쵸코칩라운드쿠키120g, 빠똥자망드 치즈쿠키90g 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    즐거운 추석, 이색 선물세트로 특별함을 선물하세요

    커피&차/꿀

    사전예약 상품권 증정
    5+1 카누 아메리카노 8호 25,480원
    ▶ 카누미니 마일드 10T×6ea, 카누미니 다크 9g×10T×2ea 外

    스타벅스 프리미엄 스틱커피 선물세트 카드할인 20% 28,080원
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml

    콤비타 멀티플로랄 마누카 허니 MGO 50 L.POINT 25% 59,175원
    ▶ 마누카 허니 500g×2입

    사전예약 상품권 증정
    꽃샘 천연꿀세트(아카시아/야생화) 카드할인 20% 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g

    담터 다정 종합세트 카드할인 30% 19,320원
    ▶ 단호박마차 17g×15입, 쌍화차플러스 15g×15입 外

    사전예약 상품권 증정
    1+1 녹차원 건강담은 홍삼차 세트 39,900원
    ▶ 홍삼진골드 100ml×6병, 홍삼배도라지 70ml×4포 外

    다정헌 유기농 허브차 베스트 선물세트 L.POINT 20% 19,840원
    ▶ 유기농 캐모마일 1.5g×10T, 유기농 페퍼민트 1.5g×10T 外

    1+1 침향녹용보 / 경북햇살농원 핸드캐리 3종 각 29,800원
    ※교차구매 가능
    ▶ 자임 침향녹용보 100ml×30입, 아내의 당근사과즙/사과즙/배도라지즙 각 1000ml×21입

    유지/식초

    사전예약 상품권 증정
    바로담아 국산 참들기름 2호 L.POINT 10% 66,900원
    ▶ 국산 참기름 180ml×2입, 국산 들기름 180ml
    
    사전예약 상품권 증정
    바로담아 국산 참들기름 1호 L.POINT 10% 53,900원
    ▶ 국산 참기름 180ml, 국산들기름 180ml, 국산 볶음참깨 105g

    셀렉티아 트러플세트 52,000원
    ▶ 셀렉티아 화이트트러플 올리브오일 100ml,셀렉티아 트러플 소스 75g 外

    골드라벨 식초세트 46,400원
    ▶ 모데나 발사믹식초 골드라벨 250ml, 모데나 화이트포도식초 골드라벨 250ml

    사전예약 상품권 증정
    주세페주스티 올리브유 12년숙성 발사믹식초 세트 L.POINT 20% 109,600원
    ▶ EV 올리브오일 250ml, 3메달 12년산 발사믹식초 250ml

    5+1 델파파 유기농2호 선물세트 51,000원
    ▶ 델파파 유기농, 엑스트라버진 500ml×2병

    5+1 델파파 혼합 2호 선물세트 38,250원
    ▶ 델파파 엑스트라버진 500ml,델파파 포도씨유 500ml

    풍국면 장수면 세트 29,000원
    ▶ 풍국면 다복면 500g, 쑥면 300g, 단호박면 300g, 흥국 쌀면 300g 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다

  </div>
  `,
  `
  <div>
    알찬 구성의 가성비가 돋보이는 통조림ㆍ조미료ㆍ유지류 선물세트

    장류/간편식/유지 
    사전예약 상품권 증정
    비온뒤첫소금 프리미엄 6호 L.POINT 10% 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g

    사전예약 상품권 증정
    비온뒤첫소금 어간장 세트 L.POINT 10% 19,800원
    ▶ 요리엔담다 어간장 250ml×2

    사전예약 상품권 증정
    그로브 아보카도오일 세트 1호 L.POINT 20% 29,200원
    ▶ 그로브 아보카도 오일 250ml x 2

    오성 국내산 누룽지세트 L.POINT 10% 20,610원
    ▶ 쌀 누룽지 150g×5입, 현미 누룽지 150g×5입

    씨제이
    사전예약 상품권 증정
    씨제이 스팸복합 K호 L.POINT 30% 49,910원
    ▶ 카놀라유 500×2입, 스팸 200×8입, 120×6입

    사전예약 상품권 증정
    씨제이 스팸복합 100호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2입, 스팸클래식 200g×8입, 스팸클래식 340g×2입, 스팸클래식 120g×2입

    사전예약 상품권 증정
    씨제이 특별한 선택 스페셜 L-6호 카드할인 30% 51,730원
    ▶ 스팸 라이트 200g×6입, 스팸 클래식 200g×3입, 카놀라유 500ml×2입, 구운소금 180g×2입 外

    사전예약 상품권 증정
    씨제이 특별한 선택 L-5호 카드할인 30% 49,630원
    ▶ 카놀라유 500ml×2입, 해바라기씨유 500ml, 스팸클래식 200g×8입, 스팸 클래식 120g×4입

    사전예약 상품권 증정
    씨제이 스팸 8K호 카드할인 20% 41,520원
    ▶ 스팸 클래식 200g×6입, 스팸 라이트 200g×3입

    사전예약 상품권 증정
    씨제이 특별한 선택 N호 카드할인 30% 41,230원
    ▶ 스팸 클래식 200g×7입, 카놀라유 500ml×2입, 고소함 가득 참기름 330ml

    사전예약 상품권 증정
    씨제이 특별한 선택 L-2호 카드할인 30% 40,530원
    ▶ 스팸 클래식 200g×6입, 스팸 클래식 120g×4입 카놀라유 500ml×2입, 요리올리고당 700g

    사전예약 상품권 증정
    씨제이 스팸복합 S호 카드할인 30% 39,830원
    ▶ 스팸 클래식 200g×5입, 스팸 클래식 340g×2입, 카놀라유 500ml×2입 外

    사전예약 상품권 증정
    씨제이 스팸복합 1호 카드할인 30% 33,530원
    ▶ 스팸 클래식 200g×6입, 카놀라유 500ml×2입

    사전예약 상품권 증정
    씨제이 스팸복합 5호 카드할인 30% 33,530원
    ▶ 스팸클래식 120g×9입, 카놀라유 500ml×2입

    사전예약 상품권 증정
    씨제이 특별한 선택 K-2호 카드할인 30% 29,330원
    ▶ 카놀라유 500ml, 요리 올리고당 700g, 스팸 클래식 200g×2입, 스팸 라이트 200g×2입 外

    사전예약 상품권 증정
    씨제이 특별한 선택 K-4호 카드할인 30% 21,630원
    ▶ 스팸 클래식 120g×4입,카놀라유 500ml×2입, 올리고당 500g

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    알찬 구성의 가성비가 돋보이는 통조림ㆍ조미료ㆍ유지류 선물세트

    동원
    사전예약 상품권 증정
    1+1 동원 리챔 더블라이트 M9호 59,900원
    ▶ 리챔더블라이트 120g×3입,리챔더블라이트 200g×6입

    사전예약 상품권 증정
    동원 튜나리챔 11호 L.POINT 25% 59,850원
    ▶ 살코기참치 135g×12입, 고추참치 90g×8입, 리챔 더블라이트 200g×4입

    사전예약 상품권 증정
    동원 튜나리챔 102호 카드할인 30% 54,950원
    ▶ 살코기 참치 135g×9입,리챔 200g×8입

    사전예약 상품권 증정
    동원 스페셜 102호 카드할인 30% 52,990원
    ▶ 살코기 참치 135g×9입, 리챔 더블라이트 120g×8입, 건강요리유 480ml×2입

    사전예약 상품권 증정
    10+1 동원 튜나런천미트 67호 카드할인 20% 50,960원
    ▶ 살코기 135g×12입, 런천미트 200g×4입

    사전예약 상품권 증정
    1+1 동원 튜나리챔 8호 49,900원
    ▶ 살코기 90g×4입, 리챔오리지널 120g×6입

    사전예약 상품권 증정
    동원 튜나리챔 100호 카드할인 30% 49,910원
    ▶ 살코기 참치 135g×12입, 리챔 200g×4입

    사전예약 상품권 증정
    1+1 동원참치 M8호 49,900원
    ▶ 살코기 90g×2입 살코기 135g×6입

    사전예약 상품권 증정
    동원 스페셜 38호 카드할인 30% 39,970원
    ▶ 살코기 참치 135g×6입, 리챔 더블라이트 200g×4입, 리챔 120g×4입, 건강 요리유 480ml×2입

    사전예약 상품권 증정
    동원 튜나리챔 64호 카드할인 20% 39,920원
    ▶ 살코기 참치 135g×6입, 고추참치 90g×6입, 리챔 120g×4입

    사전예약 상품권 증정
    동원참치 M14호 L.POINT 20% 38,960원
    ▶ 살코기참치 90g×4입, 살코기참치 135g×10입

    사전예약 상품권 증정
    동원 프리미엄 60호 카드할인 30% 34,930원
    ▶ 살코기 참치 90g×9입, 참치액 240g, 참기름 75ml×2입 外

    사전예약 상품권 증정
    동원 튜나리챔 30호 카드할인 30% 34,930원
    ▶ 살코기 참치 90g×12입, 리챔 더블라이트 120g×3입

    사전예약 상품권 증정
    동원 프리미엄 62호 카드할인 30% 29,960원
    ▶ 살코기 참치 90g×5입, 리챔 120g×2입, 참치액 240g, 참기름 75ml 外

    사전예약 상품권 증정
    동원 스페셜 49호 카드할인 30% 29,960원
    ▶ 살코기 참치 90g×4입, 리챔 더블라이트 120g×4입, 참기름 330ml

    사전예약 상품권 증정
    동원 스페셜 5호 카드할인 30% 29,890원
    ▶ 살코기 참치 90g×6입, 리챔 120g×3입, 카놀라유 480ml×2입

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다

  </div>
  `,
  `
  <div>
    사조대림
    사전예약 상품권 증정
    동원 프리미엄 55호 카드할인 30% 19,950원
    ▶ 살코기 참치 90g×2입, 카놀라유 480ml, 참기름 160ml, 구운소금 210g, 요리 올리고당 700g

    사전예약 상품권 증정
    사조 안심특선 98호 카드할인 30% 34,720원
    ▶ 카놀라유 500ml, 바삭요리유 500ml, 참치액 500g, 사과식초 500ml 外

    사전예약 상품권 증정
    사조 안심특선 99호 카드할인 30% 34,300원
    ▶ 살코기참치 135g×6입, 살코기참치 85g×6입, 안심팜 115g×4입

    사전예약 상품권 증정
    사조 The Wave 참치 세트 카드할인 30% 33,530원
    ▶ 살코기참치 85g×15입

    사전예약 상품권 증정
    사조 안심특선 94호 카드할인 30% 26,530원
    ▶ 카놀라유 500ml, 사과식초 500ml, 안심팜 115g×2입, 진간장 500ml 外

    사전예약 상품권 증정
    사조 안심특선 95호 카드할인 30% 25,900원
    ▶ 카놀라유 500ml, 바삭한요리유 500ml, 살코기참치 85g×4입, 안심팜 115g×2입 外

    사전예약 상품권 증정
    사조 안심특선 92호 카드할인 30% 19,950원
    ▶ 사과식초 500ml, 안심팜 115g×2입, 바삭요리유 500ml, 구운소금 200g 外

    사전예약 상품권 증정
    사조 고급유 3호 11,900원
    ▶ 카놀라유 500ml×3입

    청정원
    사전예약 상품권 증정
    청정원 L14호 카드할인 30% 59,990원
    ▶ 카놀라유 500ml, 정통현미식초 470ml, 맛선생참치액 250g 外

    사전예약 상품권 증정
    청정원 L9호 카드할인 30% 39,760원
    ▶ 카놀라유 500ml×2입, 잇츠팜115g×2입, 190g×2입 外

    사전예약 상품권 증정
    청정원 8호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2입, 정통현미식초 470ml, 양조간장200ml, 천일염가는소금 190g 外

    사전예약 상품권 증정
    청정원9호 카드할인 30% 32,130원
    ▶ 카놀라유 500ml×2입, 요리올리고당 700g, 정통사과식초 470ml 外

    사전예약 상품권 증정
    청정원 스페셜 7호 카드할인 20% 30,320원
    ▶ 카놀라유 500ml×2입, 생강매실맛술 410ml, 요리올리고당 700g 外

    사전예약 상품권 증정
    청정원 L7호 카드할인 30% 카드할인 30% 
    ▶ 카놀라유 500ml, 잇츠팜 115g×5입, 고소한 건강생각 참기름 160ml 外

    사전예약 상품권 증정
    청정원 스페셜S호 카드할인 30% 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2입, 진간장골드 500ml, 요리올리고당 700g 外

    사전예약 상품권 증정 
    10+1 청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml×2입, 요리유 500ml

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    건강가득, 받는 분의 감동이 더 커지는 건강식품 선물세트

    건강식품
    해당카드 3+1
    정관장 홍삼진고 이뮨스틱 46,000원
    ▶ 10g×30포

    해당카드 4+1
    정관장 홍삼원기 37,000원
    ▶ 50ml×30포

    카드할인 20%
    정관장 홍삼원기 54,400원
    ▶ 50ml×60포

    해당카드 3+1
    정관장 홍삼진본 49,000원
    ▶ 40ml×20포

    1+1 씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    1+1 씨제이 한뿌리 홍삼대보 44,800원
    ▶40ml×24입

    1+1 한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포

    1+1 한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포
    
    L.POINT 50%
    CMG제약 본래원 흑염소진액 23,900원

    3+1
    풍기인삼 황풍정 산삼배양근보 39,000원
    ▶75ml×16입

    종근당건강 아임비타 멀티비타민 세트 33,700원
    ▶이문플러스 2입, 멀티비타민 1입

    L.POINT 3,000원 할인
    종근당건강 생유산균 락토핏 골드 더블세트 20,800원
    ▶50포×2입

    뉴트리원 올인원 멀티비타 이뮨플러스 L.POINT 6,000원 할인 43,900원
    ▶ 7EA×4입

    뉴트리원 리포좀 비타민C 골드 세트 L.POINT 5,000원 할인 32,800원
    ▶ 1,100mg×72정

    뉴트리원 루테인지아잔틴164 플러스 SET L.POINT 6,000원 할인 28,800원
    ▶ 500mg×50캡슐×2EA

    뉴트리원 프로&프리바이오틱스 선물세트 L.POINT 6,000원 할인 33,800원
    ▶ 5g×30포×3개입

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다

  </div>
  `,
  `
  <div>
    소중한 분께 보내는 향과 멋 주류ㆍ일상용품 선물세트

    와인/양주 ※제외점 : MAXX상무점, MAXX창원중앙점
    발렌타인 10년 L.POINT 13,100원 할인 56,800원
    ▶ 발렌타인 10년 1,000ml, 온더락 글라스 2pcs

    조니워커 블루 298,000원
    ▶ 조니워커 블루 750ml

    2묶음 세트
    롯데칠성 프랑스와인 2종 세트 59,900원
    ▶ 파라번트 750ml, 웰던클래식 750ml, 3종 선물케이스

    일품진로 1924 헤리티지 100주년 에디션 98,000원
    ▶ 일품진로 1924 헤리티지 100주년 750ml(30도), 샷잔 2EA

    일상용품
    사전예약 상품권 증정
    엘지 프로폴리스 기프트세트 프리미엄 9,990원
    ▶ 페리오 프로폴리스향 90g×4, 페리오 프로폴리스 칫솔×2 온더바디 프로폴리스 비누 80×2

    사전예약 상품권 증정
    10+1 엘지 생활의 품격 1호 13,900원
    ▶ ES볼륨 탄력케어 10X 샴푸 200ml, ES볼륨 탄력케어 10X 컨디 200ml, 페리오 초극세모 슬림터치흑진주 핑크×2 外

    사전예약 상품권 증정
    1+1 엘지 보타닉 퍼퓸컬렉션 30,900원
    ▶ 보타닉 퍼퓸컬렉션 샴푸/컨디셔너/바디워시 각 480ml, 보타닉 퍼퓸컬렉션 비누 80×2
     
    사전예약 상품권 증정
    1+1 엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ ES 데일리 리페어링 샴푸 400×2, ES 데일리 리페어링 컨디 400, 온)보타닉 레몬바디워시 500g 外

    사전예약 상품권 증정
    1+1 엘지 모던에디션 라이프 41,900원
    ▶ ES 오가 체리 수분 샴푸 400, 온)더내추럴 바디워시 레몬향 385, 外

    사전예약 상품권 증정
    엘지 생활의 품격 3호 카드할인 30% 20,930원
    ▶ 닥터그루트 힘없는샴푸180ml, 페리오캐비티토탈 레드퍼플 90g×6 ES 오가 체리 수분 샴푸/컨디셔너 각 200, 外

    사전예약 상품권 증정
    엘지 히말라야 핑크솔트 에센셜 카드할인 30% 27,230원
    ▶ ES 오가 히핑 스케일링샴푸 200(비건)×2, 히말라야핑크솔트 치약 아이스100g×2 外

    10+1 애경 선물세트 리미티드 셀렉션 11호 11,900원
    ▶ 케라시스 데미지 클리닉 단백질 샴푸 200ml, 400ml, 샤워메이트 내추럴 유자 바디워시 200ml 外

    제이엠솔루션 꿀광 마스크팩 세트 30매 카드할인 10% 17,982원
    ▶ 꿀광 로얄 프로폴리스 마스크 플러스 30매

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    명절에 가장 받고 싶은 최고의 선물 롯데상품권

    구입 장소 롯데마트 MAXX 매장 상품권 데스크
    상품권 종류 지류상품권 - 5천원/1만원/3만원/5만원/10만원/30만원/50만원
    ※3만원권은 점별 보유 수량 상이

    결제 방법 현금(자기앞수표 포함), 법인카드, 체크카드
    ※체크카드/자기앞수표는 100만원 한도

    사용 장소
    쇼핑
    롯데마트 MAXX, 롯데마트(전점 및 문화센터, 토이저러스, 롯데하이마트)
    롯데슈퍼, 롯데백화점, 롯데면세점, 롯데하이마트 등
    
    호텔
    롯데호텔, 시그니엘(서울, 부산), 롯데시티호텔, 그랜드워커힐,
    서울프라자, 경주힐튼호텔, 메종글래드제주

    외식
    T.G.I FRIDAY’S, 아웃백 스테이크하우스, 빕스, 크리스피 크림도넛, 매드포갈릭, 딘타이펑 등

    레저
    롯데월드, 롯데JTB, 롯데리조트(부여, 속초, 제주), 서울랜드, 파라다이스 스파 도고, 한솔오크밸리, 한화리조트 등

    골프
    롯데스카이힐 C.C(부여, 제주), 프라자 C.C(용인, 설악, 제주),베어즈베스트 청라 골프클럽 등

    문화/예술
    롯데시네마, 롯데문화센터, 롯데콘서트홀, 롯데뮤지엄, 영풍문고
    ※사용처별 일부매장 제외 ※사용처는 변경될 수 있습니다

  </div>
  `,
];

/* 909, 910 슈퍼 추석 사전예약 (~9/6) */
const L909data = [
  `
  <h1>LOTTE Super</h1>
  <div>
    통큰 한가위

    사전예약 기간 2024/8/1(목) ~ 2024/9/6(금)
    롯데슈퍼

  </div>
  `,
  `
  <div>
    미리 준비할수록 더 큰 혜택!
    ※사전예약 기간 : 2024. 8. 1(목) ~ 2024. 9. 6(금)

    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    최대 120만원 상품권 증정 또는 즉시할인 ※해당 기간에 한함

    1차 8/1(목) ~ 8/23(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 10만원
    1,000만원 이상 120만원

    2차 8/24(토) ~ 8/30(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 8만원
    1,000만원 이상 80만원

    3차 8/31(토) ~ 9/6(금)
    30만원 이상 1만 5천원
    50만원 이상 2만 5천원
    100만원 ~ 1,000만원 미만 100만원 당 5만원
    1,000만원 이상 80만원

    ※자세한 내용은 매장 내 고지물 및 롯데마트GO앱 참조

    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    최대 30% 할인혜택
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT회원 특별할인
    ※해당상품에 한함
    
    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 8. 1 기준이며, 일부 변경 될 수 있음

  </div>
  `,
  `
  <div>
    9의 자격, 오직 1%의 한우에게만

    마블나인 한우는 왜 특별한가요?

    1. 투쁠 한우 중에서도 가장 높은 9등급 한우만 운영
    2. 합리적인 가격을 위해 경매부터 정형까지 직접
    3. 축소된 롯데만의 물류단계로 더 빠르게 더 신선하게

    3구 세트 99,000원
    등안채 세트(등심/안심/채끝)
    차업치 세트(차돌박이/업진살/치마살)
    꽃늑토 세트(꽃/본갈비살/늑간(갈비)살/토시살)

    6구 세트 189,000원
    등안채+차업치 세트
    등안채+꽃늑토 세트

    롯데마트몰에서 편하게 구매하세요!
    판매 시작 기간 : 8.15(목) / 배송 시작 기간 : 8.20(화)
    카카오톡 선물하기에서도 마블나인을 검색하세요!
    ※'23년 한우 전체 등급 낙찰 두수 53.1만두 중 롯데마트 한우 MD 직접 경매 1++(9)등급 Marble9 두수 801두

  </div>
  `,
  `
  <div>
    최상급 프리미엄 한우
    마블나인, 예술을 선물하세요. 최상급 프리미엄 한우 대한민국 한우 구이, 정육 세트

    9의 자격, 오직 1%의 한우에게만
    MARBLE 9(마블나인)은 1++한우(7~9등급)중에서도 근내지방도 19% 이상만을 엄선한 최상급(9등급)만을 선보입니다.
    ※23년 한우 전체등급 낙찰 두수 53.1만두 중 롯데마트 한우 MD 직접 경매 1++(9)등급 Marble9 두수 801두

    10+1 L.POINT 50,000원 할인
    마블나인 프리미엄 혼합세트(1++9등급) 699,000원
    ▶ 등심 500g×2, 안심, 채끝 각 400g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 구이세트 3호(1++9등급) 349,000원
    ▶ 등심, 채끝, 설깃 스테이크, 찹스테이크 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 등심 혼합 세트 1호(1++9등급) 499,000원
    ▶ 등심, 국거리, 채끝 각 500g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 구이세트 2호(1++9등급) 439,000원
    ▶ 등심 500g×2, 채끝, 부채 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    마블나인 한우 갈비세트 (냉동)(1++9등급) 299,000원
    ▶ 마블나인 한우 냉동 찜갈비 700g×5, 냉동, 국내산 한우고기
    ※전국 택배 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    10+1 L.POINT 30,000원 할인
    한우 정육세트 1호 169,000원
    ▶ 한우 국거리, 불고기 각 500g×3, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 30,000원 할인
    한우정육세트2호 99,000원
    ▶ 한우국거리/불고기 각 500g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    무항생제 한우 정육세트 3호(1+등급) 149,000원
    ▶ 무항생제 한우 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우 등심 안심 혼합세트 229,000원
    ▶ 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 구이 스페셜 세트 239,000원
    ▶ 1등급 한우 등심 500g, 채끝, 안심, 부채살 각 400g, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우등심세트 1호 339,000원
    ▶ 한우등심팩 500g×6, 냉장, 국내산 한우고기 
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우등심세트2호 239,000원
    ▶ 한우등심팩 500g×4, 냉장, 국내산 한우고기
    ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우등심채끝혼합세트 249,000원
    ▶ 한우등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    무항생제 한우 등심 채끝 혼합(1+등급) 269,000원
    ▶ 무항생제 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우등심정육세트1호 219,000원
    ▶ 한우등심/국거리/불고기 각 500g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    무항생제 한우 등심 정육세트 5호(1+등급) 209,000원
    ▶ 무항생제 한우 등심 500g×6, 냉장, 국내산 한우고기
    ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    무항생제 한우 등심세트 1호(1+등급) 319,000원
    ▶ 무항생제 한우 등심 500g×6, 냉장, 국내산 한우고기 ※전국 택배 운영

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    롯데슈퍼의 새로운 신선함, RE:FRESH를 만나보세요

    신선식품의 모든 과정을 다시 연구하고 새롭게 바꾸는 RE:FRESH 프로젝트를 통해 가장 신선한 경험을 전달합니다

    신선식품의 소싱 단계와 품질 기준을 강화하며, 매장 내 진열 및 신선도 관리 등을 철저하고 꼼꼼하게 재설계합니다.
    신선식품 전체(축산/수산/과일/채소)에 적용되며, 온/오프라인에서 다양한 RE:FRESH 상품을 만나보실 수 있습니다.

    바다째로 퍼와버린 롯데마트 집념전복
    하다하다 해수까지 그대로 운반하고 보관하다

    복불복 없는 완벽함 흠집부터 당도까지 완벽 사전선별

    완도산 해수 그대로 담아 산지 직배송으로 싱싱한 신선함!
    ※ASC 인증 : 환경적, 사회적 책임을 다해 키운 양식 수산물에 부여되는 국제 인증

    7+1 300 세트 한정 L.POINT 20%
    ASC인증 자이언트 전복세트 223,200원
    ▶ ASC 인증 완도전복 2kg, 전복 : 국산 
    14~15마리/7미 사이즈/마리당 140g 내외

    특유의 단맛과 진한 향의 복숭아 선물세트!
    유명산지 충주 하늘작 복숭아를 11브릭스, 300g 이상으로 엄선!
    충주 하늘작 복숭아 49,900원
    ▶ 복숭아 4kg 내외, 국산 ※택배 배송 불가

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    전문 MD가 직접 경매에 참가하여 엄선한 품질 좋은 한우 선물세트! 
    불고기/국거리에 적합한 최적의 부위와 두께를 엄선!
    10+1 L.POINT 50,000원 할인
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g, 국거리/불고기 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    최적의 갈비 두께 12mm! 롯데 신선품질혁신센터 꽃갈비 상품화 공정 강화를 통한 업계 최상의 품질 제공 가능!
    10+1 L.POINT 30,000원 할인
    호주산 소 LA식 갈비세트 139,000원
    ▶ 호주산 냉동 꽃갈비 1.5kg×2
    ※10+1 구매시 개당 126,364원 ※전국 택배 운영

    제주 수산물 명인이 전통 채낚기 방식으로 어획하여 건강하게 은빛 비늘이 살아있는 은갈치!
    10+1 L.POINT 10,000원 할인
    실속 갈치세트 109,000원
    ▶ 갈치 330g 내외, 4마리 내외, 냉동, 국산

    사과 연구소(Apple Research Institute)의 앞 철자 (ARI-아리)와 빼어날 '수(秀)'를 합쳐 이름을 딴 국내 개발 품종!(k품종)
    12브릭스 이상 비파괴 당도선별로 붉은색의 매끈한 표면과 아삭한 식감이 일품!
    영주 아리수 사과 79,900원
    ▶ 사과 12~14입(4.2kg 내외),국산

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 과일 선물세트

    나주 최종기 농부의 하우스배 79,900원
    ▶ 배 7~11입(6.5kg 내외), 국산

    나주 최종기 농부의 자이언트 하우스배 L.POINT 20,000원 할인 109,900원
    ▶ 배 9입 (8.0kg 내외)국산
    
    충주사과, 나주 하우스 배 99,900원
    ▶ 사과 6입(1.8kg 내외), 배 6입(3.9kg 내외), 국산
    
    천안배 L.POINT 10,000원 할인 39,900원
    ▶ 배 5-6입(5.0kg 내외), 국산

    GAP 사과, 배(情) L.POINT 10,000원 할인 49,900원
    ▶ 사과 5입(1.4kg 내외), 배 4입(2.2kg 내외), 국산
    
    샤인머스캣 L.POINT 10,000원 할인 69,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    샤인머스캣, 사과, 배, 황금향 89,900원
    ▶ 샤인머스캣 1송이 (0.7 kg 내외),사과 3입 (0.9 kg 내외), 배 3입 (1.8 kg 내외),황금향 3입 (0.8 kg 내외), 국산
    
    샤인머스캣, 사과 L.POINT 10,000원 할인 79,900원
    ▶ 샤인머스캣 3송이(2.1kg내외), 사과 6입(2.0kg 내외), 국산
    
    샤인머스캣, 메론 89,900원
    ▶ 샤인머스캣 2송이(2.0kg 내외), 머스크 메론 2입(3.2kg 이상), 국산
    
    샤인머스캣, 사과, 배 99,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외), 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산

    충주 프레샤인 사과 L.POINT 10,000원 할인 39,900원
    ▶ 사과 17~20입(5.0kg 내외), 국산
    
    보은사과 L.POINT 10,000원 할인 49,900원
    ▶ 사과 14~16입(4.5kg 내외), 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    신화, 창조 배 79,900원
    ▶ 배 12입(7.5kg 내외), 국산

    문경사과 L.POINT 10,000원 할인 59,900원
    ▶ 사과 12~14입(4.5kg 내외), 국산

    프라임 사과, 배 L.POINT 10,000원 할인 59,900원
    ▶ 사과 4입(1.2kg 내외), 배 6입(3.6kg 내외), 국산

    충주사과, 천안배 L.POINT 10,000원 할인 69,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입 (3.6kg 내외), 국산
    
    당도선별 메론 L.POINT 10,000원 할인 69,900원
    ▶ 머스크 메론 4입(6.4kg 이상), 국산
    
    망고 혼합 L.POINT 10,000원 할인 79,900원
    ▶ 옐로우 망고 3입(1.0kg 내외), 애플망고 6입(2.4kg내외), 원산지 별도표기

    나주 하우스배, 충주사과 199,900원
    ▶ 사과 8입(2.9kg 내외), 배 6입 (4.9kg 내외), 국산

    밀양얼음골사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12-14입(4.2kg 내외), 국산
    
    충주 사과 L.POINT 10,000원 할인 59,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    나주 배 L.POINT 10,000원 할인 59,900원
    ▶ 배 9~11입(6.5kg 내외), 국산
    
    상주 둥시 곶감(情) L.POINT 10,000원 할인 69,900원
    ▶ 곶감 30입(1.2kg 내외), 국산

    크림치즈와 호두 품은 곶감 L.POINT 10,000원 할인 79,900원
    ▶ 호두치즈곶감375g×2팩, 곶감12입(0.4kg 내외), 국산

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다 9

  </div>
  `,
  `
  <div>
    충주 김택성 농부의 GAP 사과 99,900원
    ▶ 사과 11~12입(4.5kg 내외), 국산

    천안 지순태 농부의 GAP 배 L.POINT 10,000원 할인 89,900원
    ▶ 배 8-9입(6.7kg 내외), 국산

    정성담은 신고배 L.POINT 10,000원 할인 59,900원
    ▶ 배 9~11입(6.5kg 내외), 국산
    
    정성담은 사과, 배 L.POINT 10,000원 할인 79,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외),국산
    
    정성담은 사과 L.POINT 10,000원 할인 59,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    제스프리 골드키위 L.POINT 10,000원 할인 39,900원
    ▶ 골드키위 25입 (2.5kg 이상), 뉴질랜드산
    
    허니듀메론 L.POINT 10,000원 할인 49,900원
    ▶ 허니듀 메론 4입 (7.2kg 내외), 미국산

    3대 유명산지 곶감 139,900원
    ▶ 곶감 45입 (2.1kg 내외), 국산

    상주 둥시 곶감(福) L.POINT 10,000원 할인 49,900원
    ▶ 곶감 24입(0.8kg 내외), 국산
    
    태국산 망고 L.POINT 10,000원 할인 69,900원
    ▶ 옐로우망고 9입 (3.2kg 내외), 태국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    1+1 넛츠박스 매일견과 20봉 19,900원
    ▶ 매일견과 20g×20봉, 호두 5g(미국), 구운아몬드 5g(미국), 구운땅콩 5g(미국), 건포도 5g(칠레), 원산지 별도표기

    매일견과 하루한봉 세트 (80봉) L.POINT 20,000원 할인 29,900원
    ▶ 매일견과 18g×20봉×4케이스, 구운아몬드(미국) 28%, 호두(미국) 24% 外, 원산지 별도표기
    
    더 고소한 마카다미아 매일견과 50봉 L.POINT 10,000원 할인 29,900원
    ▶ 매일견과 20g×50봉, 호두 30%, 구운아몬드 30%, 건포도(칠레) 25%, 마카다미아(호주) 15%, 원산지 별도표기

    시그니쳐 매일견과 세트 50봉 L.POINT 12,000원 할인 27,900원
    ▶ 매일견과 20g×50봉, 구운아몬드(미국) 20%, 구운캐슈넛(베트남) 20% 外, 원산지 별도표기

    견과 & 건과일 6종 세트 L.POINT 5,000원 할인 34,900원
    ▶ 구운아몬드 190g(미국), 호두 120g(미국), 볶음캐슈넛 170g (베트남), 건포도 200g(미국) 外, 원산지 별도표기

    특선 견과 선물세트 6종 L.POINT 10,000원 할인 39,900원
    ▶ 구운아몬드 160g(미국), 호두 110g(미국), 볶음캐슈넛 150g(베트남) 外, 원산지 별도표기

    명작 견과 & 건과일 10종 세트 L.POINT 10,000원 할인 39,900원
    ▶ 구운아몬드 170g(미국), 호두 110g(미국), 구운캐슈넛 150g (베트남), 구운피스타치오 120g(미국) 外, 원산지 별도표기
    
    바프 한가위 견과세트 ㄴL.POINT 10,000원 할인 44,900원
    ▶ 꿀홍삼 땅콩 130g, 마누카 허니마카다미아 130g, 호지차 아몬드 130g, 구운 아몬드 195g 外, 원산지 별도표기

    BEST 견과 10종 세트 L.POINT 10,000원 할인 49,900원
    ▶ 구운아몬드 170g(미국), 호두 110g(미국), 구운캐슈넛 150g (베트남), 피칸 120g(미국) 外, 원산지 별도표기

    High Grade 견과 6종 세트 L.POINT 10,000원 할인 79,900원
    ▶ 구운 아몬드 220g, 호두 160g, 구운캐슈너트 210g, 피칸 170g, 브라질너트 240g, 마카다미아 210g, 원산지 별도표기

    세계 견과/건과일 BEST 10종 세트 L.POINT 20,000원 할인 89,900원
    ▶ 구운아몬드 110g, 구운 캐슈너트 100g, 호두 75g, 브라질너트 75g, 피칸 80g, 믹스넛 80g 外, 원산지 별도표기
    
    프리미엄 무염 견과 9종 세트 L.POINT 20,000원 할인 79,900원
    ▶ 호두 160g(미국), 구운아몬드 210g(미국), 구운캐슈넛 180g (베트남), 브라질너트 250g(페루) 外, 원산지 별도표기

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    인기만점 육즙 가득한 소고기&돼지고기 선물세트

    미국산 소 냉동 혼합 갈비 세트(3kg) L.POINT 30,000원 할인 119,000원
    ▶ 미국산 냉동 꽃갈비, 찜갈비 각 1.5kg
    ※전국 택배 운영
    
    미국산 소 냉동 LA식 갈비 세트(3kg) L.POINT 30,000원 할인 129,000원
    ▶ 미국산 냉동 꽃갈비 구이용1.5kg×2
    ※전국 택배 운영
    
    제주 흑돈 구이세트 1kg L.POINT 10,000원 할인 59,900원
    ▶ 국내산 돼지고기 흑돼지 오겹살/목심 각 500g, 냉장
    ※산지택배(전국택배) 전용
    
    제주 흑돈 모둠세트 2kg L.POINT 40,000원 할인 99,000원
    ▶ 국내산 돼지고기 흑돼지 오겹살 500g×2, 흑돼지 목심/앞다리살 각 500g, 냉장 ※산지택배(전국택배) 전용
    
    캐나다 인증 돼지고기 선물세트 2kg L.POINT 10,000원 할인 49,000원
    ▶ 캐나다산 돼지고기 삼겹살/목심 각 1kg, 냉장
    ※전국 택배 운영 ※택배 전용
    
    호주산 냉동 찜갈비 세트(2.4kg) L.POINT 30,000원 할인 99,000원
    ▶ 호주산 냉동 찜갈비 800g×3, 양념소스
    ※전국 택배 운영
    
    한우냉동실속정육세트 L.POINT 30,000원 할인 99,000원
    ▶ 한우냉동국거리 600g, 불고기 600g, 산적 600g, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영

    ※상품권 증정 제외
    시즈닝스테이크세트(1.8kg)(냉장) L.POINT 50,000원 할인 109,000원
    ▶ 미국산 시즈닝 채끝 스테이크 200g×3, 미국산 시즈닝 부채살 스테이크 200g×3 外
    ※산지택배(전국배송) 전용

    ※상품권 증정 제외
    L.POINT 50,000원 할인
    양념한우불고기 모둠세트(2.4kg)/(냉동) 129,000원
    ▶ 양념 한우불고기 1.2kg, 한우 언양식 불고기 1.2kg
    ※센터택배(전국배송) 전용

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    호주산 청정와규 선물세트 1호 L.POINT 30,000원 할인 109,000원 
    ▶ 윗등심 500g×2, 양지 국거리, 정육 불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    호주산 청정와규 선물세트 2호 L.POINT 20,000원 할인 79,000원
    ▶ 양지 국거리 500g×2, 정육 불고기 500g×2
    ※전국 택배 운영

    한우갈비세트2호 L.POINT 30,000원 할인 99,000원
    ▶ 한우냉동찜갈비 600g×2, 양념소스, 냉동, 국내산 한우고기
    ※전국 택배 운영

    한우냉동갈비세트 L.POINT 50,000원 할인 149,000원
    ▶ 한우냉동찜갈비700g×3, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영

    양념 소 LA식 갈비 세트 (2.4kg) (냉동) L.POINT 60,000원 할인 129,000원
    ▶ 호주산 양념 소 LA식 갈비 2.4kg
    ※센터택배(전국배송) 전용

    무항생제 꼬리 반골세트 L.POINT 20,000원 할인 59,000원
    ▶ 5kg, 냉동, 국내산 한우고기
    ※전국 택배 운영
    
    무항생제 한우 사골 세트 L.POINT 20,000원 할인 59,000원
    ▶ 무항생제 한우 사골 5kg, 냉동, 국내산 한우고기
    ※전국 택배 운영
    
    한우 알꼬리 선물세트 L.POINT 30,000원 할인 99,000원
    ▶ 한우 알꼬리 2kg, 냉동, 국내산 한우고기
    ※전국 택배 운영
    
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    추석 인기 선물
    생선/멸치/수산물 세트 프리미엄 김 세트

    10+1 L.POINT 20,000원 할인
    프리미엄 홈마카세 세트 99,000원
    ▶ 고등어초절임 190g, 단새우 80g, 우니 100g, 참다랑어 뱃살 100g, 참다랑어 속살 100g, 원산지 별도표기

    청정곳간 유명산지 멸치·새우 혼합세트 L.POINT 15,000원 할인 41,900원
    ▶ 국물용 멸치 170g, 볶음용 멸치 140g, 볶음조림용 멸치 170g, 두절 꽃새우 80g, 국산

    7+1 자연담은 칠산소금 참굴비 실속/특호 89,900/199,000원
    ▶ 실속 : 10마리 (0.9kg 내외), 냉동, 참조기/천일염 : 국산
    특호 : 10마리 (1.2kg 내외), 냉동, 참조기/천일염 : 국산

    10+1 L.POINT 10,000원 할인
    제주 은갈치/옥돔 혼합 세트 109,000원
    ▶ 갈치 2마리 600g 내외, 옥돔 2마리 400g 내외, 냉동, 국산

    10+1 L.POINT 30,000원 할인 
    으뜸 옥돔세트 179,000원
    ▶ 3마리 1.5kg 내외/ 냉동, 옥돔 : 국산

    7+1 마른부세굴비세트 89,900원
    ▶ 1.0kg (5마리), 중국산

    10+1 L.POINT 10,000원 할인 
    국산 대하 세트 149,000원
    ▶ 국산 대하 18~24미 1.3kg, 냉동

    청정곳간 유명산지 통영 멸치세트 L.POINT 15,000원 할인 41,900원
    ▶ 어린이 멸치 230g, 볶음용 멸치 230g, 조림용 멸치 210g, 국물용 멸치 210g, 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    성경 보성녹차 정성L호 L.POINT 4,000원 할인 11,900원
    ▶ 녹차 재래 식탁김 4g×24봉
    
    코주부 육포세트 2호 L.POINT 4,000원 할인 32,900원
    ▶ 외국산 우육 40g×7봉, 원산지 별도표기(우육 : 호주/뉴질랜드/미국)

    비비고 토종김 5호 9,900원
    ▶ 비비고 참기름김 도시락 4g×6번들×3EA, 원산지 별도표기

    양반 들기름김세트 9,900원 
    ▶ 들기름 김 4g×18봉, 원산지 별도표기
    
    7+1 자연담은 칠산소금 참굴비 1호 60,000원
    ▶ 10마리 (0.8kg 내외), 냉동, 참조기/천일염 : 국산

    대천 곱창김 혼합세트 1호 L.POINT 7,000원 할인 39,900원
    ▶ 곱창 전장김 25g×8봉, 곱창김 캔 30g×3EA,원산지 별도표기

    동원 감태김 M11호 L.POINT 30% 34,230원
    ▶ 감태 전장김 20g×3봉 , 감태김 캔 30g×2ea , 초사리 식탁김 4g×8봉, 원산지 별도표기
    
    동원 양반김 M6호 L.POINT 20% 32,960원
    ▶ 더바삭한 전장김 25g×6봉 , 돌김 캔 30g×2ea

    CJ 비비고 감태김·초사리 곱창돌김 L.POINT 30% 49,980원
    ▶ 초사리 전장김 20g×6봉 , 감태김 캔 25g×4ea , 감태김 도시락 4g×4봉

    CJ 비비고 감태김 혼합1호 L.POINT 30% 34,230원
    ▶ 감태김 전장 20g×3봉 , 감태김 캔 25g×2ea , 감태김 도시락 4g×8봉
    
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    버섯&인삼&홍삼 선물세트 L.POINT 10,000원 할인 29,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    자연향 버섯 혼합세트 2호 L.POINT 20,000원 할인 39,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산
    
    6년근 실속 금산 인삼세트 L.POINT 20,000원 할인 29,900원
    ▶ 6년근 인삼 난발 350g, 국산

    웰빙표고 실속세트 2호 L.POINT 10,000원 할인 44,900원
    ▶ 동고 70g, 표고채 60g, 표고사각 30g 핑크솔트 140g, 흑후추 70g, 원산지 별도표기

    유명산지 금산 인삼 세트 카드할인 10,000원 할인 69,900원
    ▶4년근 인삼 500g, 국내산
    
    유명산지 홍천 더덕 세트 카드할인 10,000원 할인 69,900원
    ▶더덕 1.2kg(뿌리당 40~50g), 국산

    국내산 벌집꿀 L.POINT 10,000원 할인 79,900원
    ▶ 1.5kg, 밀랍포함, 국산
    
    유기농 표고버섯 혼합 1호 L.POINT 15,000원 할인 49,900원
    ▶ 유기농 흑화고 100g, 유기농 표고채 70g×2, 국산

    팔각 어울림 버섯 L.POINT 20,000원 할인 119,000원
    ▶ 백화고100g, 흑화고100g×2, 국산

    홍천 송선진 농부의 고랭지 더덕 세트 159,000원
    ▶ 더덕 1.5kg(뿌리당 70~90g), 국산

    상황 영지버섯 L.POINT 10,000원 할인 89,900원
    ▶ 상황버섯 130g, 영지버섯 300g, 국산

    홍천 왕더덕 세트 L.POINT 10,000원 할인 169,000원
    ▶ 더덕 1.5kg(뿌리당 100~130g), 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    고려인삼 녹용침향액 39,000원
    ▶ 75ml×10병

    1+1 농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병
    
    종근당건강 활력녹용보 L.POINT 50% 21,900원
    ▶ 50ml×30포

    1+1 농협 한삼인 홍삼본 골드 39,800원 
    ▶ 50ml×30포

    1+1 농협 한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포
    
    3+1 풍기인삼 황풍정 산삼배양근 보 39,000원 
    ▶ 75ml×16입

    1+1 씨제이 한뿌리 홍삼대보 44,800원 
    ▶ 40ml×24입
    
    1+1 씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    2+1 풍기인삼 자연의 기운을 품은 심 1호 54,000원
    ▶ 산삼배양근 8병, 산삼배양근 단 8입, 절편 3입
    
    풍기인삼 황풍정 홍삼정과 107,000원
    ▶ 700g

    정관장 홍삼원기 카드할인 4+1 37,000원
    ▶ 50ml×30포
    
    2+1 풍기인삼 자연의 기운을 품은 심 2호 49,000원
    ▶ 산삼배양근 보 8입, 봉밀홍삼절편 5입
    
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    인기 브랜드 햄/오일/통조림 선물세트

    씨제이 제일제당
    카드할인 30%
    씨제이 특별한선택 L-3호 28,630원 
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6

    5+1 씨제이 백설 프리미엄 23호 18,900원
    ▶ 카놀라유 500ml×2, 요리유 500ml×2

    씨제이 특별한선택 K-4호 카드할인 30% 21,630원
    ▶ 카놀라유 500ml×2, 올리고당 500g, 스팸 120g×
    
    씨제이 특별한선택 N호 카드할인 30% 41,230원
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7
    
    씨제이 스팸복합 1호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6

    씨제이 스팸복합 5호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 120g×9
    
    씨제이 특별한선택 스페셜 A호 카드할인 30% 34,930원
    ▶ 카놀라유 500ml, 스팸 200g×4, 올리고당 500g 外

    씨제이 스팸복합 S호 카드할인 30%  39,830원
    ▶ 카놀라유 500ml×2, 스팸 200g×5, 스팸 340g×2 外
    
    씨제이 특별한선택 L-2호 카드할인 30% 40,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 700g 外

    씨제이 스팸 8K호 카드할인 30% 41,520원
    ▶ 스팸 200g×6, 스팸 라이트 200g×3
    
    2+1 씨제이 특별한선택 K호 43,900원
    ▶ 카놀라유 500ml, 요리올리고당 700g×2, 스팸 120g×4 外
    
    씨제이 스팸복합 100호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 340g×2, 스팸 120g×2

    씨제이 특별한선택 스페셜 L-6호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2, 고소함가득 참기름 330ml, 구운소금 180g×2, 스팸라이트 200g×6 外

    씨제이 최고의선택 레드라벨 카드할인 30% 67,830원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×5 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    동원 튜나리챔 100호 카드할인 30% 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    동원 프리미엄 1호 카드할인 30% 19,950원
    ▶ 살코기참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g 外
    
    동원 스페셜 1호 카드할인 30% 25,970원
    ▶ 살코기참치 135g×2, 리챔 120g×4, 건강요리유 480ml×2
    
    동원 스페셜 5호 카드할인 30% 29,890원
    ▶ 살코기참치 90g×6, 리챔 120g×3, 카놀라유 480ml×2
    
    동원 프리미엄 62호 카드할인 30% 29,960원
    ▶ 살코기참치 90g×5, 리챔 120g×2, 참치액 진 240g, 참기름 75ml 外
    
    2+1 동원 참치액 1호 32,900원
    ▶ 동원 참치액 진 500g×2, 참치액 순 500g×2

    동원 튜나리챔 30호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×12, 리챔 더블라이트 120g×3

    동원 프리미엄 60호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×9, 동원 참치액 진 240g, 건강요리유 480ml×2 外

    동원 참치V20호 카드할인 20% 36,960원
    ▶ 살코기150g×8 + 고추참치100g×4

    2+1 동원 프리미엄K호 38,900원
    ▶ 동원 살코기 90g×3, 리챔 120g, 구운소금 210g, 히말라야소금100g 外

    동원 튜나리챔 64호 카드할인 20% 39,920원 
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4
    
    동원 스페셜 38호 카드할인 30% 39,970원
    ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4 外
    
    동원 튜나리챔25호 카드할인 30% 44,940원
    ▶ 살코기 135g×13, 리챔오리지널120g×3

    10+1 카드할인 20% 
    동원 튜나런천미트67호 50,960원
    ▶ 살코기 135g×12, 런천 200g×4

    동원 튜나리챔 102호 카드할인 30% 54,950원
    ▶ 살코기참치 135g×9, 리챔 200g×8
    
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    인기 브랜드 햄/오일 선물세트
    청정원 
    10+1 청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml×2개, 요리유 500ml

    청정원 스페셜 S호 카드할인 30% 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 두번달여더진한 진간장골드 500ml 外
    
    청정원 L1호 카드할인 30% 22,260원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 맛술 생강&매실 410ml, 요리올리고당 700g 外

    청정원 L2호 카드할인 30% 24,360원
    ▶ 카놀라유 500ml, 잇츠팜 115g×4개, 올리고당 700g, 옥수수유 500ml

    청정원 L7호 카드할인 30% 29,960원 
    ▶ 카놀라유 500ml, 잇츠팜115g×5개, 고소한 건강생각 참기름 160ml 外
    
    10+1 카드할인 30%
    청정원 L8호 37,660원
    ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g×6개, 정통 사과식초 470ml 外

    1+1 청정원 L3호 49,800원 
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 올리고당 700g 外
    
    1+1 청정원 L5호 49,800원
    ▶ 카놀라유 500ml, 요리올리고당 700g, 잇츠팜 115g×4개, 두번달여 더 진한 진간장 골드 200ml 外
    
    청정원 L13호 카드할인 30% 54,460원
    ▶ 카놀라유 500ml×2개, 정통사과식초 470ml, 천일염가는소금 190g 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    이색적인 상품 소금/오일/과일즙/홍삼진액 선물세트

    비온뒤 첫소금 
    청정해역 신안에서 비가 온 뒤 가장 깨끗해진 토판 위에서 정성껏 수확하는 명품 소금 선물세트

    비온뒤 첫소금 프리미엄 2호 L.POINT 10% 45,000원
    ▶ 3년숙성 천일염 135g, 프리미엄 구운소금 150g 外

    비온뒤첫소금 프리미엄 6호 L.POINT 10% 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g

    그로브 
    100% 뉴질랜드의 신선한 아보카도로 만들어 건강함이 가득하고, 요리 본연의 맛을 살려주는 오일 브랜드

    그로브 아보카도 오일 1호 L.POINT 20% 29,200원
    ▶ 그로브 아보카도 오일 엑스트라 버진 250ml×2병

    올리타리아 
    신선한 원재료를 수확 후 24시간 안에 냉압착하여 전통적인 맛과 향이 살아있는 이탈리아 오일 브랜드

    올리타리아 스페셜 5호 L.POINT 10% 14,310원
    ▶ 해바라기유 500ml×2
    
    올리타리아 스페셜 33호 L.POINT 10% 36,810원
    ▶ 올리브유 500ml, 포도씨유 500ml, 해바라기유500ml
    
    올리타리아 스페셜 4호 L.POINT 10% 42,210원
    ▶ 올리브유 500ml×2

    바로담아
    엄선한 국산 참들깨를 사용, 전통방식으로 만들어 깨 본연의 고소하고 진한 풍미가 느껴지는 선물세트

    바로담아 국산 참들기름선물세트 L.POINT 10% 53,900원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨105g

    1+1 고려인삼 6년근 홍삼진액골드 89,000원 
    ▶ 70ml×60포
    
    1+1 고려인삼 녹용홍삼진액 69,000원 
    ▶ 70ml×30포

    1+1 고려인삼 흑염소진액골드 69,000원
    ▶ 70ml×30포
    
    1+1 고려인삼 흑마늘 홍삼진액 69,000원
    ▶ 70ml×30포
    
    1+1 아내의 착즙주스 배도라지즙/사과즙/당근사과즙/감귤사과즙 착즙하는남자 양배추 사과즙 각29,900원
    ▶ 각 100ml×21입 ※교차구매 가능

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트

    레뱅 이탈리아비고르세트 L.POINT 33,000원 할인 36,900원
    ▶ 우마니론끼 비고르 레드 750ml, 우마니론끼 비고르 화이트 750ml

    금양 1865 헤리티지골프백세트 55,000원 
    ▶ 1865 헤리티지 블렌드 750ml, 미니 골프백 가방, 와인오프너

    롯데 미국브레드앤버터2종세트 67,600원
    ▶ 브레드앤버터 까베르네소비뇽 750ml, 브레드앤버터 샤르도네 750m

    CSR 미국텍스트북세트 119,000원
    ▶ 텍스트북 까베르네소비뇽 750ml, 텍스트북 멜롯 750ml

    발렌타인 10년 L.POINT 10,000원 할인 39,900원
    ▶ 발렌타인10년 700ml, 온더락 글라스 2입

    와일드터키 8년 L.POINT 11,000원 할인 54,900원
    ▶ 와일드터키8년700ml, 온더락글라스, 하이볼글라스

    조니워커그린 L.POINT 20,000원 할인 79,800원
    ▶ 조니워커 그린 750ml, 온더락 글라스, 글라스 캡

    조니워커 블루 L.POINT 32,200원 할인 295,800원
    ▶ 조니워커 블루 750ml

    로얄살루트21년500ml L.POINT 40,800원 할인 169,000원
    ▶ 로얄살루트 500ml

    백운명주 36호 39,500원
    ▶ 500ml×3(산삼,복분자,송이), 잔 4입

    더한주류 3호세트 28,000원
    ▶ 375ml×3(서울의밤,서울의밤명랑스컬,매실원주) 전용잔 2입

    화요 3종선물세트 2호 58,000원 
    ▶ 200ml×3 (화요 17도, 화요 25도, 화요 53도)

    일품진로 1924 헤리티지100주년 에디션 99,900원
    ▶ 일품진로 1924 100주년 에디션750ml, 전용잔 2입

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
  
  </div>
  `,
  `
  <div>
    식탁을 더욱 풍성하게! 어묵&수제햄&바베큐 선물세트

    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다
    치즈 그레이터 세트 카드할인 10% 59,400원 
    ▶ 보스카 이지 그레이터, 파르네제 그라나 파다노 150g, 파르네제 파르미지아노 레지아노 150g 外

    치즈&샤퀴테리 세트 카드할인 10% 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g, 에스푸나 세라노 하몽 50g 外
    
    ※상품권 및 즉시할인 제외
    몽탄 우대갈비 선물 세트 139,000원
    ▶ 몽탄 우대갈비 1.2kg (3대), 무생채

    존쿡델리미트 베스트 세트 카드할인 10% 49,500원
    ▶ 바베큐립 460g, 킬바사 270g, 킬바사치즈 270g, 비프핫도그 300g, 컨츄리베이컨 120g 外

    존쿡델리미트 샤퀴테리 세트 카드할인 10% 49,500원
    ▶ 하몽세라노 120g, 까챠토레 150g, 코파 50g, 이탈리안살라미 50g, 카바노치스낵 50g 外
    
    존쿡델리미트 브런치 세트 카드할인 10% 35,100원
    ▶ 브렉퍼스트 소시지 210g, 킬바사 270g, 단호박 까망베르스프 160g×3, 마일드베이컨 120g 外

    프리미엄 어묵
    고래사어묵 선물세트 3호 40,000원
    ▶ 고래바 65g×2, 통새우말이 90g, 치즈빵빵 80g, 떡말이 90g, 소시지말이 90g 外

    고래사어묵 선물세트 5호 60,000원
    ▶ 고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g, 떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外

    고래사어묵 선물세트 7호 80,000원 ▶ 용궁어묵 180g, 전복어묵 120g, 통새우말이 90g×2, 치즈빵빵 80g×2, 떡말이 90g 外

    프리미엄 수제햄
    ※상품권 및 즉시할인 제외
    오뗄 수제햄 실속 1호 39,900원
    ▶ 바베큐 안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 모타델라 300g, 부어스트에센 105g

    프리미엄 햄&치즈
    상하농원 수제 햄&치즈 세트 카드할인 20% 79,200원
    ▶ 클래식비엔나 120g×2, 화이트 비엔나 120g, 롤비엔나 120g×2, 치즈프랑크 180g×2 外

    홈스토랑
    VIPS 홈레스토랑 선물세트 1호 72,400원
    ▶ 오리지널 바비큐 폭립, 척아이롤 스테이크, 명란 까르보나라 원팬 파스타, 뇨끼 감바스, 미트 라자냐

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ※상품권 및 즉시할인 제외
    동서 맥심 20호 세트 27,450원
    ▶ 맥심 모카 골드 마일드 믹스 12g×200입

    5+1 카누 아메리카노 8호 25,930원
    ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개, 카누미니 라이트 10T× 2개, 투게더머그 ※사전예약 한정 운영

    5+1 카누 블렌드 7호 21,900원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누 말차 라떼 8T, 카누 바닐라 라떼 8T 外 ※사전예약 한정 운영

    스타벅스 원두믹스 머그 선물세트 카드할인 20% 28,080원
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml
    ※상품권 및 즉시할인 제외
    
    1+1 녹차원 향기담은 허브차 세트 24,900원
    ▶ 쟈스민 허브차 5입, 맛있는녹차작설 5입, 얼그레이 5입 外
    ※사전예약 한정 운영

    10+1 녹차원 차다움세트 9,900원 
    ▶ 꿀유자차 350g, 생강대추배차 7T, 호두아몬드율무차 7T
    ※사전예약 한정 운영

    창평한과 송강정 70,000원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎 外), 매작과(치자백년초 外) 外 ※사전예약 한정 운영

    창평한과 소쇄원 108,000원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎) 外
    ※사전예약 한정 운영

    담터 다정 종합세트 카드할인 30% 19,320원
    ▶ 호두아몬드율무차 18g×15T,단호박마차 17g×15T, 쌍화차플러스 15g×15T 外

    꽃샘 아카시아 야생화꿀 세트 카드할인 20% 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g
    ※사전예약 한정 운영
    
    꽃샘 밤꿀세트 카드할인 20% 54,400원
    ▶ 밤꿀 1.2kg
    ※사전예약 한정 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    바디케어 선물세트

    애경 산양유 바디케어 기프트 L.POINT 5,000원 할인 11,900원
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 90g×2입

    10+1 LG 정성담은13호 13,900원
    ▶ 엘라스틴 데미지윤기케어10X샴푸 200ml×2, 엘라스틴 데미지윤기케어10X컨디셔너 200ml 外
    
    1+1 LG 보타닉퍼퓸 컬렉션스페셜 30,900원 
    ▶ 보타닉 퍼퓸컬렉션 샴푸 480ml×2, 보타닉 퍼퓸컬렉션 컨디셔너 480ml 外

    LG 히말라야핑크솔트38호 27,230원 
    ▶ 오가니스트 히말라야핑크솔트 샴푸 200ml×3, 히말라야 핑크솔트 바이옴치약 100g×4 外

    LG 정성담은두배세트 13,930원 
    ▶ 오가니스트 체리 수분 샴푸 200ml, 컨디셔너 200ml, 오가니스트 히말라야 핑크솔트 샴푸 200ml 外

    1+1 LG 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2, 엘라스틴 데일리 리페어링 컨디셔너 400ml 外

    LG 정성담은 43호 30,730원
    ▶ 닥터그루트 샴푸 180ml, 닥터그루트 컨디셔너 180ml, 오가니스트 히말라야핑크솔트 샴푸 200ml×2 外

    LG AI스페셜에디션 프리미엄세트 32,130원 
    ▶ 오가니스트 체리 샴푸 380ml×2, 오가니스트 체리 컨디셔너 380ml×2 外

    2+1 LG 정성담은 36호 36,900원
    ▶ 리엔 로열젤리 샴푸 200ml, 컨디셔너 200ml, 죽염 청신원 치약 90g×6 外

    1+1 LG 모던에디션 라이프 41,900원
    ▶ 온더바디 레몬 버베나 바디워시 400ml, 오가니스트 체리 수분 샴푸 200ml 外
    
    1+1 애경 케라시스 퍼퓸셀렉션 26호 26,900원
    ▶ 케라시스 러블리 데이지 퍼퓸 샴푸 400ml×2, 린스 400ml, 샤워메이트 실키 모이스춰 비누 80g×3

    LG 정성담은 29호 20,930원 
    ▶ 닥터그루트 샴푸 180ml, 오가니스트 체리 수분 샴푸 200ml, 컨디셔너 200ml 外

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트

    10+1 광천 김수미 재래김 선물세트 10,900원 
    ▶ 재래김 4g×20봉

    LG 프로폴리스 기프트세트 프리미엄 9,990원
    ▶ 페리오 프로폴리스향 치약 90g×4, 온더바디 프로폴리스 비누 80g×2, 페리오 프로폴리스 칫솔×2

    CJ 비비고 토종김 복합2호 L.POINT 30% 29,330원
    ▶ 직화 참기름김 전장 20g×6봉, 스팸 120g×4입, 카놀라유 500ml
    
    비비고 토종김 S-1호 L.POINT 30% 29,120원
    ▶ 담백 구운김 전장 10g×4봉×2ea, 죽염돌김 캔 50g×2ea

    10+1 동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉, 들기름 식탁김 4g×12봉
    
    대천 곱창 도시락김 세트 L.POINT 4,000원 할인 21,900원
    ▶ 곱창 도시락김 5g×54봉

    동원 양반김 M20호 L.POINT 30% 25,970원
    ▶ 들기름 전장김 20g×6봉, 참치액(진) 900g

    동원 양반김 M30호 L.POINT 30% 29,960원
    ▶ 더바삭한 전장김 25g×6봉, 살코기 참치 90g×6캔 , 건강요리유 480ml

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    최대 120만원 상품권 증정

    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    CJ 비비고 직화구이김 혼합1호 L.POINT 30% 29,960원
    ▶ 직화 참기름김 전장 22g×3봉, 직화 참기름김 캔 25g×2ea , 직화 참기름김 도시락 4.5g×8ea

    CJ 비비고 토종김 1호 16,900원
    ▶ 담백 구운김전장 10g×3봉, 비비고 참기름김 도시락 4g×6봉×2ea

    6년근 뿌리 많은 인삼세트 L.POINT 10,000원 할인 39,900원
    ▶ 6년근 인삼 난발 500g/국산

    제스프리 그린키위 L.POINT 5,000원 할인 24,900원
    ▶ 그린키위 25입 (2.4kg 이상), 뉴질랜드산
    
    남아공 스타루비 자몽 L.POINT 10,000원 할인 29,900원
    ▶ 자몽 12입(4.5kg 내외), 남아공산
    
    호주산 오렌지 L.POINT 10,000원 할인 29,900원
    ▶ 오렌지 12입 (3.8kg 내외), 호주산

    CJ 비비고 토종김 4호 L.POINT 30% 25,130원
    ▶ 비비고 참기름김 20g×8봉 , 카놀라유 900ml

    아모레 섬김 1호 L.POINT 20% 19,920원
    ▶ 해피바스 오리지널컬렉션 굿나잇 라벤더 기프트에디션 바디워시 490g 外

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트

    1+1 약사신협 6년근 고려홍삼액 진 39,000원
    ▶ 70ml×30포
    
    1+1 약사신협 내몸애생발란스 산삼배양근진액 39,000원 
    ▶ 70ml×30포

    해당카드 3+1 정관장 홍삼진고 이뮨스틱 46,000원
    ▶ 30포
    
    뉴트리원 올인원 멀티비타 이뮨플러스 카드할인 6,000원 할인 43,900원
    ▶ 7병×4세트
    
    농협헬스케어 프리미엄 녹용진 27,000원
    ▶ 50ml×30포

    홍대감 채끝살 육포세트 L.POINT 6,000원 할인 43,900원
    ▶ 외국산 육포 60g×5봉 원산지 별도표기(우육 : 호주/뉴질랜드/미국)
    
    궁 쇠고기 육포세트 L.POINT 12,000원 할인 47,900원
    ▶ 외국산 육포 70g×6봉 원산지 별도표기(우육 : 호주/뉴질랜드/미국
    
    실속형 금산 인삼 더덕 혼합세트 L.POINT 10,000원 할인 49,900원 
    ▶인삼 300g, 더덕 400g / 국산

    자연향 버섯 혼합세트 1호 L.POINT 20,000원 할인 49,900원
    ▶ 동고 70g, 흰목이 30g, 표고채 55g×2 外, 국내산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    최대 120만원 상품권 증정

    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    동원 양반 돌김세트 L.POINT 30% 39,970원
    ▶ 돌김 캔 30g×8입

    동원 양반김 M40호 L.POINT 30% 44,940원
    ▶ 들기름 전장김 20g×6봉 , 돌김 캔 30g×2ea , 살코기 참치 135g×8캔
    
    CJ 비비고 초사리 곱창돌김1호 L.POINT 30% 39,830원
    ▶ 초사리 곱창돌김 전장 20g×3봉 , 초사리 곱창돌김 캔 25g×2ea , 초사리 곱창돌김 도시락 5g×8봉

    리얼스테이크 육포세트 L.POINT 40% 35,940원 
    ▶ 외국산 육포 160g×3봉 원산지 별도표기(우육 : 호주/뉴질랜드/미국)
    
    나주 청미래 배 L.POINT 10,000원 할인 39,900원
    ▶ 배 7~9입(5.0kg 내외), 국산
    
    제주 황금향 49,900원
    ▶ 황금향 8~12입 (3.0kg 내외), 국산

    1+1 녹차원 건강 담은 액상차 세트 32,900원
    ▶ 꿀유자차 350g, 홍삼담은석류차 350g, 꿀자몽차 350g, 홍삼담은레몬차 350g, 통곡물율무차 18g×5입, 통곡물마차 18g×5입 ※사전예약 한정 운영

    1+1 녹차원 홍삼차 세트 39,900원
    ▶ 홍삼진골드 100ml×6입, 홍삼배도라지 70ml×4입, 녹용진 70ml×4입, 쌍화차 15g×10입, 생강차 15g×10입 ※사전예약 한정 운영

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    5~10만원대 선물세트

    장수 사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    자이언트 사과, 배 109,900원
    ▶ 사과 5입(2.1kg 내외), 배 4입(3.6kg 내외), 국산

    자이언트 사과 109,900원
    ▶ 사과 12입(5.3kg 내외), 국산

    자이언트 배 L.POINT 20,000원 할인 99,900원
    ▶ 배 9입(8.2kg 내외),국산

    프라임 배 L.POINT 10,000원 할인 79,900원
    ▶ 배 9입(6.8kg 내외),국산
    
    프라임 사과 L.POINT 10,000원 할인 79,900원
    ▶ 사과 12입(4.5kg 내외), 국산

    GAP 청도 반건시 L.POINT 10,000원 할인 59,900원
    ▶ 청도 반건시 30입 (1.5kg 내외), 국산

    산청 곶감 (情) L.POINT 10,000원 할인 79,900원
    ▶ 곶감 32입 (1.1kg 내외),국산

    상주 왕곶감 L.POINT 20,000원 할인 119,900원
    ▶ 곶감 32입 (1.6kg 내외), 국산

    3+1 코주부 우리소 육포세트 69,900원 
    ▶ 한우 육포 55g×7봉, 우육 : 국내산

    CJ 제일명인 정통 육포세트 L.POINT 20% 55,120원
    ▶ 외국산 육포 70g×6봉 우육 : 호주/뉴질랜드/미국

    샤인머스캣 L.POINT 10,000원 할인 69,900원
    ▶ 샤인머스캣 4kg 내외, 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    최대 120만원 상품권 증정

    ※최대 120만원은 2024년 추석 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 2' 4. 8. 1 기준이며, 일부 변경 될 수 있음

    3+1 코주부 육포세트 4호 59,900원
    ▶ 외국산 육포 50g×8봉 우육 : 호주/뉴질랜드/미국

    3+1 동원 쇠고기 육포세트 65,900원
    ▶ 외국산 육포 60g×6봉 우육 : 호주/뉴질랜드/미국

    건강버섯 특선세트 L.POINT 10,000원 할인 99,000원 
    ▶ 상황버섯60g 外 원산지:별도표기

    금산 인삼 홍천더덕 세트 99,900원 
    ▶ 인삼 400g, 더덕 600g

    6년근 프리미엄 인삼세트 L.POINT 10,000원 할인 149,000원
    ▶ 6년근 인삼 700g, 국산

    자연향 담은 백화고 혼합세트 L.POINT 20,000원 할인 54,900원
    ▶ 백화고 90g, 동고 70g×2, 사각칩 40g×2 外, 국산

    자연담은 건강버섯 세트 L.POINT 20,000원 할인 59,900원
    ▶ 은이버섯 25g 外, 국산

    CJ 제일명인 한우 육포 세트 L.POINT 20% 69,920원
    ▶ 한우 육포 70g×6봉, 원산지 별도표기(우육 : 국내산

    L.POINT 15,000원 할인 카드할인 10,000원 할인
    흑화고 혼합세트 49,900원
    ▶ 냉동 흑화고 80g, 표고채 50g,표고사각 50g, 外 원산지:별도표기

    10+1 L.POINT 10,000원 할인
    대한민국 명장 장석준 명란 세트 89,000원 
    ▶명란 600g(300g×2), 원산지 별도표기, 냉동

    대천 명품 캔김세트 15캔 L.POINT 5,000원 할인 59,900원
    ▶ 곱창김 캔 30g×15

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    롯데슈퍼
  </div>
  `,
];

/* 920 슈퍼 백일산점 (~9/6) */
const L920data = [
  `
  <h1>LOTTE Super</h1>
  <div>
    통큰 한가위

    사전예약 기간 2024/8/1(목) ~ 2024/9/6(금)

    롯데백화점 일산점 식품관
    FOOD MARKET

  </div>
  `,
  `
  <div>
    통큰 한가위
    미리 준비할수록 더 큰 혜택!
    ※사전예약 기간 : 2024. 8. 1(목) ~ 2024. 9. 6(금)

    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    최대 120만원 상품권 증정 또는 즉시할인 ※해당 기간에 한함

    1차 8/1(목) ~ 8/23(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 10만원
    1,000만원 이상 120만원

    2차 8/24(토) ~ 8/30(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 8만원
    1,000만원 이상 80만원

    3차 8/31(토) ~ 9/6(금)
    30만원 이상 1만 5천원
    50만원 이상 2만 5천원
    100만원 ~ 1,000만원 미만 100만원 당 5만원
    1,000만원 이상 80만원

    ※자세한 내용은 매장 내 고지물 참조
    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    최대 30% 할인혜택
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT회원 특별할인
    ※해당상품에 한함
    
    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 8. 1 기준이며, 일부 변경 될 수 있음

  </div>
  `,
  `
  <div>
    통큰 한가위 프리미엄 선물세트

    장수 사과 L.POINT 10,000원 할인 79,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    자이언트 사과, 배 129,900원
    ▶ 사과 5입(2.1kg 내외), 배 4입(3.6kg 내외), 국산

    자이언트 사과 129,900원
    ▶ 사과 12입(5.3kg 내외), 국산

    자이언트 배 L.POINT 20,000원 할인 129,900원
    ▶ 배 9입(8.2kg 내외),국산

    프라임 배 L.POINT 10,000원 할인 89,900원
    ▶ 배 9입(6.8kg 내외),국산

    프라임 사과 L.POINT 10,000원 할인 89,900원
    ▶ 사과 12입(4.5kg 내외), 국산

    샤인머스캣 L.POINT 10,000원 할인 79,900원
    ▶ 샤인머스캣 4kg 내외, 국산

    견과 3종 GIFT(진) 250,000원
    ▶ 산청곶감 14개(45~49g), 백잣 350g, 호두 370g, 국산

    견과종합 GIFT 220,000원
    ▶ 백잣 250g, 호두 230g, 감말랭이 300g, 곶감호두말이 300g, 국산

    견과 3종 GIFT(향) 210,000원
    ▶ 산청곶감 6개(45~49g), 백잣 350g, 호두 370g, 국산

    정과원육포 GIFT (향) 130,000원
    ▶ 호주산 쇠고기 육포 700g, 원산지 별도포기

    정과원육포 GIFT (수) 90,000원
    ▶ 호주산 쇠고기 육포 400g, 원산지 별도포기

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    최상급 프리미엄 한우 
    마블나인, 예술을 선물하세요

    9의 자격, 오직 1%의 한우에게만
    MARBLE 9(마블나인)은 1++한우(7~9등급) 중에서도 근내지방도 19% 이상만을 엄선한 최상급(9등급)만을 선보입니다.

    마블나인 프리미엄 혼합세트(1++9등급) L.POINT 50,000원 할인 749,000원
    ▶ 등심 500g×2, 안심, 채끝 각 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    마블나인 구이세트 3호(1++9등급) L.POINT 50,000원 할인 399,000원
    ▶ 등심, 채끝, 설깃 스테이크, 찹스테이크 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    마블나인 등심 혼합 세트 1호(1++9등급) L.POINT 50,000원 할인 549,000원
    ▶ 등심, 국거리, 채끝 각 500g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    마블나인 구이세트 2호(1++9등급) L.POINT 50,000원 할인 489,000원
    ▶ 등심 500g×2, 채끝, 부채 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    마블나인 한우 갈비세트 (냉동)(1++9등급) L.POINT 50,000원 할인 349,000원
    ▶ 마블나인 한우 냉동 찜갈비 700g×5, 냉동, 국내산 한우고기
    ※전국 택배 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    대한민국 한우 구이, 정육 세트

    마블나인 한우세트 (6구, 1++9등급) 1,000,000원
    ▶ 마블나인 한우 1++(9)등급 등심, 채끝 각 1kg, 안심, 특수부위 각 500g

    마블나인 한우 종합세트 (6구, 1++9등급) 600,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1.5kg, 국거리 1kg, 불고기 500g

    마블나인 한우구이세트 (6구, 1++9등급) 750,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1.5kg, 채끝 1.5kg

    마블나인 한우구이세트 (4구, 1++9등급) 450,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1kg, 채끝 1kg

    마블나인 한우모듬세트(4구, 1++9등급) 350,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1kg, 국거리 500g, 불고기 500g

    마블나인 한우실속세트 (4구, 1++9등급) 300,000원
    ▶ 마블나인 한우 1++(9)등급 국거리, 불고기 각 1kg

    마블나인 한우알뜰세트 1호(3구, 1++9등급) 350,000원
    ▶ 마블나인 한우 1++(9)등급 등심 500g, 채끝 500g, 안심 500g

    마블나인 한우알뜰세트 2호(3구, 1++9등급) 250,000원
    ▶ 마블나인 한우 1++(9)등급 등심 500g, 국거리 500g, 불고기 500g

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 과일 선물세트

    나주 최종기 농부의 하우스배 89,900원
    ▶ 배 7~11입(6.5kg 내외), 국산

    나주 최종기 농부의 자이언트 하우스배 L.POINT 20,000원 할인 129,900원
    ▶ 배 9입 (8.0kg 내외)국산

    충주사과, 나주 하우스 배 111,900원
    ▶ 사과 6입(1.8kg 내외), 배 6입(3.9kg 내외), 국산

    GAP 사과, 배(情) L.POINT 10,000원 할인 59,900원 
    ▶ 사과 5입(1.4kg 내외), 배 4입(2.2kg 내외), 국산

    샤인머스캣 L.POINT 10,000원 할인 79,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    샤인머스캣, 사과, 배, 황금향 99,900원
    ▶ 샤인머스캣 1송이 (0.7 kg 내외),사과 3입 (0.9 kg 내외), 배 3입 (1.8 kg 내외),황금향 3입 (0.8 kg 내외), 국산

    샤인머스캣, 사과 L.POINT 10,000원 할인 89,900원 
    ▶ 샤인머스캣 3송이(2.1kg내외), 사과 6입(2.0kg 내외), 국산

    샤인머스캣, 메론 99,900원
    ▶ 샤인머스캣 2송이(2.0kg 내외), 머스크 메론 2입(3.2kg 이상), 국산

    샤인머스캣, 사과, 배 129,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외), 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    신화, 창조 배 89,900원 
    ▶ 배 12입(7.5kg 내외), 국산

    문경사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12~14입(4.5kg 내외), 국산

    프라임 사과, 배 L.POINT 10,000원 할인 69,900원
    ▶ 사과 4입(1.2kg 내외), 배 6입(3.6kg 내외), 국산

    충주사과, 천안배 L.POINT 10,000원 할인 79,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입 (3.6kg 내외), 국산

    당도선별 메론 L.POINT 10,000원 할인 79,900원
    ▶ 머스크 메론 4입(6.4kg 이상), 국산

    망고 혼합 L.POINT 10,000원 할인 89,900원
    ▶ 옐로우 망고 3입(1.0kg 내외), 애플망고 6입(2.4kg내외), 원산지 별도표기

    나주 하우스배, 충주사과 219,900원
    ▶ 사과 8입(2.9kg 내외), 배 6입 (4.9kg 내외), 국산

    밀양얼음골사과 L.POINT 10,000원 할인 79,900원
    ▶ 사과 12-14입(4.2kg 내외), 국산

    영주 아리수 사과 89,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    나주 배 L.POINT 10,000원 할인 69,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    정성담은 사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    정성담은 신고배 L.POINT 10,000원 할인 69,900원
    ▶ 배 9~11입(4.2kg 내외), 국산

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 과일 선물세트

    충주 김택성 농부의 GAP 사과 109,900원
    ▶ 사과 11~12입(4.5kg 내외), 국산

    천안 지순태 농부의 GAP 배 L.POINT 10,000원 할인 99,900원
    ▶ 배 8-9입(6.7kg 내외), 국산

    제스프리 골드키위 L.POINT 10,000원 할인 69,900원
    ▶ 골드키위 25입(2.5kg 이상), 뉴질랜드산

    정성담은 사과, 배 L.POINT 10,000원 할인 89,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외),국산

    대봉곶감 GIFT(향) 140,000원
    ▶ 곶감 18개, 국산

    상주곶감 GIFT(향) 120,000원
    ▶ 상주곶감 24개(42~49g), 국산

    허니듀메론 L.POINT 10,000원 할인 59,900원
    ▶ 허니듀 메론 4입 (7.2kg 내외), 미국산

    산지곶감혼합 GIFT 230,000원
    ▶ 대봉곶감 8개, 상주곶감 16개, 산청곶감 16개, 국산

    곶감ㆍ반건시 혼합 GIFT 160,000원
    ▶ 상주곶감 12개, 산청곶감 12개, 청도 반건시 8개, 국산

    태국산 망고 L.POINT 10,000원 할인 79,900원
    ▶ 옐로우망고 9입 (3.2kg 내외), 태국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    견과류 선물세트

    잣, 호두명품 GIFT 380,000원
    ▶ 백잣 500g, 황잣 500g, 호두 500g, 국산

    잣, 호두 GIFT(진) 230,000원 
    ▶ 백잣 350g, 황잣 350g, 호두 460g, 국산

    잣, 호두 GIFT(향) 160,000원 
    ▶ 백잣 200g, 황잣 200g, 호두 230g, 국산

    잣, 호두 GIFT(수) 100,000원
    ▶ 백잣 200g, 호두 200g, 국산

    백화고 3종 GIFT 250,000원
    ▶ 백잣 350g, 백화고 180g, 호두 370g, 국산

    삼색 GIFT 140,000원
    ▶ 백잣 200g, 호두 230g, 대추채 130g, 국산

    표고채 견과 GIFT 100,000원
    ▶ 표고채 70g×2, 대추채 60g, 백잣 100g, 원산지 별도표기

    잣 GIFT(진) 160,000원
    ▶ 백잣 350g, 황잣 350g, 원산지 별도표기

    프리미엄 견과 6종 GIFT 120,000원
    ▶ 백잣 100g, 호두 100g, 대추채 60g, 표고채 30g, 볶은서리태콩 120g, 볶은땅콩 130g, 원산지 별도표기

    견과다복 GIFT 130,000원
    ▶ 백잣 220g, 구운아몬드 300g, 호두 220g, 구운캐슈너트 300g, 원산지 별도표기

    견과 6종 GIFT 100,000원
    ▶ 구운아몬드 200g, 호두 130g, 마카다미아 160g, 브라질너트 180g, 구운캐슈너트 180g, 피칸 160g, 원산지 별도표기

    견과 5종 GIFT 80,000원
    ▶ 구운아몬드 290g, 구운캐슈너트 130g, 호두 100g, 피스타치오 110g, 브라질너트 120g, 원산지 별도표기

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    인기만점 육즙 가득한 소고기&돼지고기 선물세트

    미국산 소 냉동 혼합 갈비 세트(3kg) L.POINT 30,000원 할인 169,000원
    ▶ 미국산 냉동 꽃갈비, 찜갈비 각 1.5kg
    ※전국 택배 운영

    한우 냉동 갈비세트(1+등급) L.POINT 50,000원 할인 249,000원
    ▶ 1+등급 한우 냉동 찜갈비 700g×4, 양념소스, 국내산 한우고기

    제주 돼지 혼합세트 L.POINT 14,900원 할인 45,000원
    ▶ 삼겹살 500g, 뒷다리 불고기 500g×2

    명품 제주돼지 구이세트(4구) 149,000원
    ▶ 삼겹살 500g×2, 목심 500g×2

    한우등심+제주 돼지 삼겹살 4구 299,000원
    ▶ 한우 1등급 이상 한우등심 500g×2, 제주 돼지 삼겹살 500g×2

    호주산 냉장 찜갈비 세트(4구) 160,000원
    ▶ 호주산 찜갈비 700g×4

    호주산 냉장 찜갈비 세트(6구) 300,000원
    ▶ 호주산 찜갈비 700g×6

    명품한우 냉장 찜갈비 세트(4구) 500,000원
    ▶ 한우 1등급 이상 한우갈비 700g×4

    명품한우 냉장 찜갈비 세트(6구) 750,000원
    ▶ 한우 1등급 이상 한우갈비 700g×6

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    L'grow 와규 냉장세트 (6구, MBS8+) 350,000원
    ▶ 와규 등심 2kg, 국거리 500g, 불고기 500g

    L'grow 와규 혼합세트 (4구, MBS8+) 250,000원
    ▶ 와규 등심 1kg, 국거리 500g, 불고기 500g

    한우갈비세트2호 L.POINT 30,000원 할인 149,000원
    ▶ 한우냉동찜갈비 600g×2, 양념소스, 냉동, 국내산 한우고기
    ※전국 택배 운영

    한우냉동갈비세트 L.POINT 50,000원 할인 199,000원
    ▶ 한우냉동찜갈비700g×3, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영

    미국산 소 냉동 LA식 갈비세트 3kg L.POINT 30,000원 할인 199,000원
    ▶ 미국산 냉동 꽃갈비(구이용) 3kg

    무항생제 꼬리 반골세트 L.POINT 20,000원 할인 99,000원
    ▶ 5kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    무항생제 한우 사골 세트 L.POINT 20,000원 할인 99,000원
    ▶ 무항생제 한우 사골 5kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    한우 알꼬리 선물세트 L.POINT 30,000원 할인 149,000원
    ▶ 한우 알꼬리 2kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    제주 옥돔 은갈치세트1kg L.POINT 30,000원 할인 150,000원
    ▶ 옥돔160g×3미, ▶갈치300g×2미, 옥돔/갈치 : 국산

    活 전복세트 L.POINT 15,000원 할인 65,000원
    ▶ 1마리 50g 내외, 20마리, 전복 : 국산
    ※스트로폼 및 바닷물포장

    새천년 법성포 굴비세트(산) L.POINT 50,000원 할인 200,000원
    ▶ 1.1kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 20,000원

    새천년 법성포굴비세트(일) L.POINT 50,000원 할인 300,000원
    ▶ 1.3kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 30,000원

    새천년 법성포 굴비세트(월) L.POINT 60,000원 할인 240,000원
    ▶ 1.2kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 24,000원

    새천년 법성포 굴비세트(리) L.POINT 90,000원 할인 360,000원
    ▶ 1.4kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 36,000원

    바다원 고급 멸치세트 1호 L.POINT 20% 64,000원
    ▶ 볶음용멸치/볶음조림용멸치/조림용멸치/ 국물용멸치 각230g

    바다원 사각 특선 멸치세트 1호 L.POINT 20% 96,000원
    ▶ 볶음용멸치110g×2, 볶음조림용멸치110g×2, 국물용멸치100g×2, 건새우70g×2

    자연향 담은 백화고 혼합세트 L.POINT 20,000원 할인 74,900원
    ▶ 백화고 90g, 동고 70g×2, 사각칩 40g×2 外, 국산

    자연담은 건강버섯 세트 L.POINT 20,000원 할인 79,900원
    ▶ 은이버섯 25g 外, 국산

    대한민국 명장 장석준 명란 세트 10+1 L.POINT 10,000원 할인 89,000원
    ▶명란 600g(300g×2), 원산지 별도표기, 냉동

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    추석 인기 선물 프리미엄 김&육포 세트

    대천 명품캔김세트(15캔) L.POINT 5,000원 할인 84,900원
    ▶ 곱창김 30g×15캔, 원산지 별도표기

    대천 명품캔김세트(6캔) L.POINT 2,000원 할인 53,900원
    ▶ 곱창김 30g×6캔, 원산지 별도표기

    CJ비비고감태김혼합1호 L.POINT 30% 48,930원
    ▶ 감태김 전장 20g×3봉 , 감태김 캔 25g×2캔 , 감태김 도시락 4g×8봉, 원산지 별도표기

    CJ 비비고 초사리곱창돌김 1호 L.POINT 30% 53,830원
    ▶ 초사리 곱창돌김 전장 20g×3봉, 초사리 곱창돌김 캔 25g×2캔, 초사리 곱창돌김 도시락 5g×8봉, 원산지 별도표기

    궁 쇠고기육포선물세트 L.POINT 12,000원 할인 67,900원
    ▶ 육포(홍두깨살) 70g×6봉, 외국산(미국/호주/뉴질랜드)

    한우육포 GIFT 240,000원
    ▶ 한우 육포 700g, 원산지 별도표기

    코주부 비프&너츠프리미엄선물세트 L.POINT 15,000원 할인 79,900원
    ▶ 견과혼합육포120g×4봉
    
    CJ 제일명인정통육포세트 L.POINT 30% 71,120원
    ▶ 육포(홍두깨살) 70g×6봉, 외국산(미국/호주/뉴질랜드)

    CJ 제일명인 한우육포세트 99,000원 ▶ 한우육포70g×6봉

    쇠고기육포 GIFT 180,000원
    ▶ 국내산 쇠고기 육포 700g, 원산지 별도표기

    정과원육포 GIFT (진) 170,000원
    ▶ 호주산 쇠고기 육포 1kg

    금산 인삼 홍천더덕 세트 119,900원
    ▶ 인삼 400g, 더덕 600g

    6년근 프리미엄 인삼세트 L.POINT 10,000원 할인 159,000원
    ▶ 6년근 인삼 700g, 국산

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    자연향담은 동고혼합세트 L.POINT 10,000원 할인 39,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    자연향 버섯 혼합세트 2호 L.POINT 20,000원 할인 59,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산

    6년근 실속 금산 인삼세트 L.POINT 20,000원 할인 49,900원
    ▶ 6년근 인삼 난발 350g, 국산

    웰빙표고 실속세트 2호 L.POINT 10,000원 할인 54,900원
    ▶ 동고 70g, 표고채 60g, 표고사각 30g 핑크솔트 140g, 흑후추 70g, 원산지 별도표기

    유명산지 금산 인삼 세트 카드할인 10,000원 할인 79,900원
    ▶4년근 인삼 500g, 국내산

    유명산지 홍천 더덕 세트 카드할인 10,000원 할인 79,900원
    ▶더덕 1.2kg(뿌리당 40~50g),

    국내산 벌집꿀 L.POINT 10,000원 할인 89,900원
    ▶ 1.5kg, 밀랍포함, 국산

    유기농 표고버섯 혼합 1호 L.POINT 15,000원 할인 59,900원
    ▶ 유기농 흑화고 100g, 유기농 표고채 70g×2, 국산

    팔각 어울림 버섯 L.POINT 20,000원 할인 139,000원
    ▶ 백화고100g, 흑화고100g×2, 국산

    홍천 송선진 농부의 고랭지 더덕 세트 179,000원
    ▶ 더덕 1.5kg(뿌리당 70~90g), 국산

    상황 영지버섯 L.POINT 10,000원 할인 99,900원
    ▶ 상황버섯 130g, 영지버섯 300g, 국산

    홍천 왕더덕 세트 L.POINT 10,000원 할인 179,000원
    ▶ 더덕 1.5kg(뿌리당 100~130g), 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    고려인삼 녹용침향액 39,000원
    ▶ 75ml×10병

    1+1 농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    종근당건강 활력녹용보 L.POINT 50% 21,900원
    ▶ 50ml×30포

    1+1 농협 한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포

    1+1 농협 한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포 

    3+1 풍기인삼 황풍정 산삼배양근 보 39,000원
    ▶ 75ml×16입

    1+1 씨제이 한뿌리 홍삼대보 44,800원 
    ▶ 40ml×24입

    1+1 씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    2+1 풍기인삼 자연의 기운을 품은 심 1호 54,000원
    ▶ 산삼배양근 8병, 산삼배양근 단 8입, 절편 3입

    풍기인삼 황풍정 홍삼정과 107,000원
    ▶ 700g

    카드할인 4+1
    정관장 홍삼원기 37,000원 ▶ 50ml×30포

    2+1 풍기인삼 자연의 기운을 품은 심 2호 49,000원
    ▶ 산삼배양근 보 8입, 봉밀홍삼절편 5입

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    인기 브랜드 햄/오일/통조림 선물세트

    씨제이 제일제당

    씨제이 특별한선택 L-3호 카드할인 30% 28,630원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6

    5+1
    씨제이 백설 프리미엄 23호 18,900원
    ▶ 카놀라유 500ml×2, 요리유 500ml×2

    씨제이 특별한선택 K-4호 카드할인 30% 21,630원
    ▶ 카놀라유 500ml×2, 올리고당 500g, 스팸 120g×4

    씨제이 특별한선택 N호 카드할인 30% 41,230원
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7

    씨제이 스팸복합 1호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6

    씨제이 스팸복합 5호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 120g×9

    씨제이 특별한선택 스페셜 A호 카드할인 30% 34,930원 
    ▶ 카놀라유 500ml, 스팸 200g×4, 올리고당 500g 外

    씨제이 스팸복합 S호 카드할인 30% 39,830원 
    ▶ 카놀라유 500ml×2, 스팸 200g×5, 스팸 340g×2 外

    씨제이 특별한선택 L-2호 카드할인 30% 40,530원 
    ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 700g 外

    씨제이 스팸 8K호 카드할인 30% 41,520원
    ▶ 스팸 200g×6, 스팸 라이트 200g×3

    2+1
    씨제이 특별한선택 K호 43,900원
    ▶ 카놀라유 500ml, 요리올리고당 700g×2, 스팸 120g×4 外

    씨제이 스팸복합 100호 카드할인 30% 51,730원 
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 340g×2, 스팸 120g×2

    씨제이 특별한선택 스페셜 L-6호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2, 고소함가득 참기름 330ml, 구운소금 180g×2, 스팸라이트 200g×6 外

    씨제이 최고의선택 레드라벨 카드할인 30% 67,830원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×5 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    동원

    동원 튜나리챔 100호 카드할인 30% 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    동원 프리미엄 1호 카드할인 30% 19,950원
    ▶ 살코기참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g 外

    동원 스페셜 1호 카드할인 30% 25,970원
    ▶ 살코기참치 135g×2, 리챔 120g×4, 건강요리유 480ml×2

    동원 스페셜 5호 카드할인 30% 29,890원
    ▶ 살코기참치 90g×6, 리챔 120g×3, 카놀라유 480ml×2

    동원 프리미엄 62호 카드할인 30% 29,960원
    ▶ 살코기참치 90g×5, 리챔 120g×2, 참치액 진 240g, 참기름 75ml 外

    2+1
    동원 참치액 1호 32,900원
    ▶ 동원 참치액 진 500g×2, 참치액 순 500g×2

    동원 튜나리챔 30호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×12, 리챔 더블라이트 120g×3

    동원 프리미엄 60호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×9, 동원 참치액 진 240g, 건강요리유 480ml×2 外

    동원 참치V20호 카드할인 20% 36,960원
    ▶ 살코기150g×8 + 고추참치100g×4

    2+1
    동원 프리미엄K호 38,900원
    ▶ 동원 살코기 90g×3, 리챔 120g, 구운소금 210g, 히말라야소금100g 外

    동원 튜나리챔 64호 카드할인 20% 39,920원
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    동원 스페셜 38호 카드할인 30% 39,970원 
    ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4 外

    동원 튜나리챔25호 카드할인 30% 44,940원
    ▶ 살코기 135g×13, 리챔오리지널120g×3

    10+1 카드할인 20%
    동원 튜나런천미트67호 50,960원
    ▶ 살코기 135g×12, 런천 200g×4

    동원 튜나리챔 102호 카드할인 30% 54,950원
    ▶ 살코기참치 135g×9, 리챔 200g×8

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    통큰 한가위 인기 브랜드 햄/오일 선물세트

    청정원 
    10+1 청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml×2개, 요리유 500ml

    청정원 스페셜 S호 카드할인 30% 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 두번달여더진한 진간장골드 500ml 外

    청정원 L1호 카드할인 30% 22,260원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 맛술 생강&매실 410ml, 요리올리고당 700g 外

    청정원 L2호 카드할인 30% 24,360원
    ▶ 카놀라유 500ml, 잇츠팜 115g×4개, 올리고당 700g, 옥수수유 500ml

    청정원 L7호 카드할인 30% 29,960원
    ▶ 카놀라유 500ml, 잇츠팜115g×5개, 고소한 건강생각 참기름 160ml

    청정원 L8호 10+1 카드할인 30% 37,660원
    ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g×6개, 정통 사과식초 470ml 外

    1+1 청정원 L3호 49,800원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 올리고당 700g 外

    1+1 청정원 L5호 49,800원
    ▶ 카놀라유 500ml, 요리올리고당 700g, 잇츠팜 115g×4개, 두번달여 더 진한 진간장 골드 200ml

    청정원 L13호 카드할인 30% 54,460원
    ▶ 카놀라유 500ml×2개, 정통사과식초 470ml, 천일염가는소금 190g 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    이색적인 상품 소금/오일 선물세트

    비온뒤 첫소금
    청정해역 신안에서 비가 온 뒤 가장 깨끗해진 토판 위에서 정성껏 수확하는 명품 소금 선물세트

    비온뒤 첫소금 프리미엄 2호 L.POINT 10% 45,000원
    ▶ 3년숙성 천일염 135g, 프리미엄 구운소금 150g 外

    비온뒤첫소금 프리미엄 6호 L.POINT 10% 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g

    올리타리아
    신선한 원재료를 수확 후 24시간 안에 냉압착하여 전통적인 맛과 향이 살아있는 이탈리아 오일 브랜드

    올리타리아 스페셜 5호 L.POINT 10% 14,310원
    ▶ 해바라기유 500ml×2

    올리타리아 스페셜 20호 L.POINT 10% 36,810원
    ▶ 올리브유 500ml, 포도씨유 500ml, 해바라기유500ml

    올리타리아 스페셜 4호 L.POINT 10% 42,210원
    ▶ 올리브유 500ml×2

    그로브 
    100% 뉴질랜드의 신선한 아보카도로 만들어 건강함이 가득하고, 요리 본연의 맛을 살려주는 오일 브랜드

    그로브 아보카도 오일 1호 L.POINT 20% 29,200원
    ▶ 그로브 아보카도 오일 엑스트라 버진 250ml×2병

    바로담아
    엄선한 국산 참들깨를 사용, 전통방식으로 만들어 깨 본연의 고소하고 진한 풍미가 느껴지는 선물세트

    바로담아 국산 참들기름선물세트 L.POINT 10% 53,900원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨105g

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트

    조니워커 블루 L.POINT 32,200원 할인 295,800원
    ▶ 조니워커 블루 750ml

    로얄살루트21년500ml L.POINT 40,800원 할인 169,000원
    ▶ 로얄살루트 500ml

    발베니더블우드 12년 L.POINT 14,800원 할인 110,000원
    ▶ 발베니더블우드12년 700ml

    조니워커그린 L.POINT 20,000원 할인 79,800원
    ▶ 조니워커 그린 750ml, 온더락 글라스, 글라스 캡

    맥캘란더블캐스크 12년 L.POINT 12,000원 할인 110,000원
    ▶ 맥캘란더블캐스크12년 700ml

    발렌타인 21년 L.POINT 64,100원 할인 225,900원
    ▶ 발렌타인 21년 700ml

    샤또글로리아18Y 100,000원
    ▶ 샤또글로리아18Y 750ml

    샤또깡뜨메를르17Y 75,000원
    ▶ 샤또깡뜨메를르17Y 750ml

    샤또라퐁로쉐17Y 99,000원
    ▶ 샤또라퐁로쉐17Y 750ml

    샤또디켐Y19 830,000원
    ▶ 샤또디켐Y19 750ml

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    식탁을 더욱 풍성하게! 어묵&수제햄&바베큐 선물세트

    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다

    치즈 그레이터 세트 카드할인 10% 59,400원
    ▶ 보스카 이지 그레이터, 파르네제 그라나 파다노 150g, 파르네제 파르미지아노 레지아노 150g 外

    치즈&샤퀴테리 세트 카드할인 10% 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g, 에스푸나 세라노 하몽 50g 外
    
    ※상품권 및 즉시할인 제외
    몽탄 우대갈비 선물 세트 139,000원
    ▶ 몽탄 우대갈비 1.2kg (3대), 무생채

    존쿡델리미트 베스트 세트 카드할인 10% 49,500원
    ▶ 바베큐립 460g, 킬바사 270g, 킬바사치즈 270g, 비프핫도그 300g, 컨츄리베이컨 120g 外

    존쿡델리미트 샤퀴테리 세트 카드할인 10% 49,500원
    ▶ 하몽세라노 120g, 까챠토레 150g, 코파 50g, 이탈리안살라미 50g, 카바노치스낵 50g 外

    존쿡델리미트 브런치 세트 카드할인 10% 35,100원 
    ▶ 브렉퍼스트 소시지 210g, 킬바사 270g, 단호박 까망베르스프 160g×3, 마일드베이컨 120g 外

    프리미엄 어묵
    고래사어묵 선물세트 3호 40,000원
    ▶ 고래바 65g×2, 통새우말이 90g, 치즈빵빵 80g, 떡말이 90g, 소시지말이 90g 外

    고래사어묵 선물세트 5호 60,000원
    ▶ 고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g, 떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外

    고래사어묵 선물세트 7호 80,000원
    ▶ 용궁어묵 180g, 전복어묵 120g, 통새우말이 90g×2, 치즈빵빵 80g×2, 떡말이 90g 外

    프리미엄 수제햄
    ※상품권 및 즉시할인 제외
    오뗄 수제햄 실속 1호 39,900원
    ▶ 바베큐 안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 모타델라 300g, 부어스트에센 105g

    프리미엄 햄&치즈
    상하농원 수제 햄&치즈 세트 카드할인 20% 79,200원
    ▶ 클래식비엔나 120g×2, 화이트 비엔나 120g, 롤비엔나 120g×2, 치즈프랑크 180g×2

    홈스토랑
    ※상품권 및 즉시할인 제외
    VIPS 홈레스토랑 선물세트 1호 72,400원
    ▶ 오리지널 바비큐 폭립, 척아이롤 스테이크, 명란 까르보나라 원팬 파스타, 뇨끼 감바스, 미트 라자냐

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    온가족 담소시간! 전통과자&커피&차

    ※상품권 및 즉시할인 제외
    동서 맥심 20호 세트 27,450원
    ▶ 맥심 모카 골드 마일드 믹스 12g×200입

    5+1 카누 아메리카노 8호 25,930원
    ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개, 카누미니 라이트 10T× 2개, 투게더머그 ※사전예약 한정 운영

    5+1 카누 블렌드 7호 21,900원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누 말차 라떼 8T, 카누 바닐라 라떼 8T 外 ※사전예약 한정 운영

    스타벅스 원두믹스 머그 선물세트 카드할인 20% 28,080원 
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml

    1+1
    녹차원 향기담은 허브차 세트 24,900원
    ▶ 쟈스민 허브차 5입, 맛있는녹차작설 5입, 얼그레이 5입 外
    ※사전예약 한정 운영

    10+1
    녹차원 차다움세트 9,900원
    ▶ 꿀유자차 350g, 생강대추배차 7T, 호두아몬드율무차 7T
    ※사전예약 한정 운영

    창평한과 송강정 70,000원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎 外), 매작과(치자백년초 外) 外 ※사전예약 한정 운영

    창평한과 소쇄원 108,000원 
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎) 外
    ※사전예약 한정 운영

    담터 다정 종합세트 카드할인 30% 19,320원
    ▶ 호두아몬드율무차 18g×15T,단호박마차 17g×15T, 쌍화차플러스 15g×15T 外

    꽃샘 아카시아 야생화꿀 세트 카드할인 20% 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g
    ※사전예약 한정 운영

    꽃샘 밤꿀세트 카드할인 20% 54,400원
    ▶ 밤꿀 1.2kg
    ※사전예약 한정 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    바디케어 선물세트

    애경 산양유 바디케어 기프트 L.POINT 5,000원 할인 11,900원
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 90g×2입

    10+1
    LG 정성담은13호 13,900원
    ▶ 엘라스틴 데미지윤기케어10X샴푸 200ml×2, 엘라스틴 데미지윤기케어10X컨디셔너 200ml 外

    1+1
    LG 보타닉퍼퓸 컬렉션스페셜 30,900원
    ▶ 보타닉 퍼퓸컬렉션 샴푸 480ml×2, 보타닉 퍼퓸컬렉션 컨디셔너 480ml 外

    LG 히말라야핑크솔트38호 27,230원
    ▶ 오가니스트 히말라야핑크솔트 샴푸 200ml×3, 히말라야 핑크솔트 바이옴치약 100g×4 外

    LG 정성담은두배세트 13,930원
    ▶ 오가니스트 체리 수분 샴푸 200ml, 컨디셔너 200ml, 오가니스트 히말라야 핑크솔트 샴푸 200ml 外

    1+1
    LG 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2, 엘라스틴 데일리 리페어링 컨디셔너 400ml 外

    LG 정성담은 43호 30,730원 
    ▶ 닥터그루트 샴푸 180ml, 닥터그루트 컨디셔너 180ml, 오가니스트 히말라야핑크솔트 샴푸 200ml×2 外

    LG AI스페셜에디션 프리미엄세트 32,130원 
    ▶ 오가니스트 체리 샴푸 380ml×2, 오가니스트 체리 컨디셔너 380ml×2 外

    2+1
    LG 정성담은 36호 36,900원
    ▶ 리엔 로열젤리 샴푸 200ml, 컨디셔너 200ml, 죽염 청신원 치약 90g×6 外

    1+1
    LG 모던에디션 라이프 41,900원
    ▶ 온더바디 레몬 버베나 바디워시 400ml, 오가니스트 체리 수분 샴푸 200ml 外

    1+1 
    애경 케라시스 퍼퓸셀렉션 26호 26,900원
    ▶ 케라시스 러블리 데이지 퍼퓸 샴푸 400ml×2, 린스 400ml, 샤워메이트 실키 모이스춰 비누 80g×3

    LG 정성담은 29호 20,930원 
    ▶ 닥터그루트 샴푸 180ml,오가니스트 체리 수분 샴푸 200ml, 컨디셔너 200ml 外

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    롯데백화점 일산점 식품관
    FOOD MARKET

  </div>
  `,
];

/* 905,906 전점 추석 본세트 (~9/17) */
const L905data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·적용기간 : 2024. 9. 7(토) ~ 2024. 9. 17(화)

    생활비 다이어트 롯데마트&MAXX 카드
    롯데마트맥스 신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 우리카드 IBK기업은행 전북은행 JB카드 광주은행 KJ카드

    통큰 한가위
    최대 50만원
    상품권 증정 또는 즉시할인

    최대 30% 할인 혜택
    ▶기간 : 9/7(토) ~ 9/17(화) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    L.POINT 회원 특별할인
    ※해당상품에 한함

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외(일부 도서 산간 지역 제외)
    ※결제금액 기준

    L.POINT 50,000원 할인
    마블나인 구이혼합세트(1++(9)등급) 699,000원
    ▶ 1 ++(9)등급 한우 등심 500g×2, 채끝/안심 각 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 50,000원 할인
    한우 홈파티팩 선물세트 199,000원
    ▶ 1등급 한우 새우살 300g×2, 알등심 300g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 50,000원 할인
    1등급 한우 갈비세트 149,000원
    ▶ 1등급 한우 찜갈비 700g×3, 냉동, 국내산 한우고기, 양념소스 ※전국 택배 운영

    L.POINT 50,000원 할인
    한우 등심채끝 혼합세트 249,000원
    ▶ 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 50,000원 할인
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 30,000원 할인
    한우 정육세트 2호 99,000원
    ▶ 1등급 한우 냉장 국거리/불고기 각 500g×2, 국내산 한우고기 ※전국 택배 운영

    L.POINT 60,000원 할인
    냉장 시즈닝 스테이크 모둠 세트 1.8kg 99,000원
    ▶ 미국산 소고기 시즈닝 척아이롤/부채살/살치살 각 0.6kg
    ※전국 택배 운영 ※상품권 증정 제외

    L.POINT 60,000원 할인
    한우 양념 불고기 모둠 세트(2.4kg) 119,000원
    ▶ 양념 한우 불고기 1.2kg, 한우 언양식 불고기 1.2kg, 냉동
    ※센터택배(전국배송) 가능 ※상품권 증정 제외

    L.POINT 70,000원 할인
    양념 LA식 꽃갈비 세트 119,000원
    ▶ 미국산 초이스 냉동 꽃갈비 1.2kg×2
    ※상품권 증정 제외

    L.POINT 30,000원 할인
    호주산 소 LA식 갈비세트 139,000원
    ▶ 호주산 냉동 꽃갈비 1.5kg×2
    ※상품권 증정 제외

    L.POINT 30,000원 할인
    미국산 소 혼합갈비 세트 119,000원
    ▶ 미국산 냉동 꽃갈비, 짬길비 각 1.5kg
    ※상품권 증정 제외

    L.POINT 10,000원 할인
    롯데호텔 미국산 초이스 LA갈비 세트 129,000원
    ▶ 미국산 초이스 냉동 꽃갈비 1kg×2, 롯데호텔 LA갈비 특제소스 200ml×4 ※상품권 증정 제외

    9의 자격, 오직 1% 한우에게만.
    ※ '23년 한우 전체 등급 낙찰 두수 53.1만두 중 롯데마트 한우 MD 직접 경매 1++(9)등급 Marble9 두수 801두

    마블나인 선물세트 롯데마트몰에서 편하게 구매하세요!
    판매 시작 기간 : 8.15(목) / 배송 시작 기간 : 8.20(화)
    카카오톡 선물하기에서도 마블나인을 검색해보세요!

    9등급 프리미엄 한우 세트 3입 각99,000원
    ※세부 규격 롯데마트몰 참고

    9등급 프리미엄 한우 세트 6입 각189,000원
    ※세부 규격 롯데마트몰 참고

    카드할인 10,000원 할인
    충주 사과, 천안 배 69,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외), 국산

    카드할인 10,000원 할인
    정성담은 사과, 배 79,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외), 국산

    L.POINT 10,000원 할인
    천안 배 39,900원
    ▶ 배 5~6입(5kg 내외), 국산

    카드할인 10,000원 할인
    정성담은 신고배 59,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    카드할인 10,000원 할인
    충주 프레샤인 사과 39,900원
    ▶ 사과 17~20입(5kg 내외), 국산

    카드할인 10,000원 할인
    정성담은 사과 59,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    L.POINT 10,000원 할인
    샤인머스캣 69,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    L.POINT 10,000원 할인
    당도선별 메론 69,900원
    ▶ 머스크 메론 4입(6.4kg 이상), 국산

    L.POINT 10,000원 할인
    제스프리 골드키위 39,900원
    ▶ 골드키위 25입(2.5kg 이상), 뉴질랜드산

    L.POINT 10,000원 할인
    상주 곶감(福) 49,900원
    ▶ 곶감 24입(35g±4g×24입), 국산

    7+1
    자연담은 칠산소금 참굴비세트 2호 130,000원
    ▶ 마리 당 100g 내외/10마리, 원산지 별도표

    7+1
    참굴비 세트 2호 89,900원
    ▶ 마리당 80g 내외/20마리, 원산지 별도표기

    비비고 토종김 5호 9,900원
    ▶ 참기름 도시락김 4g×18봉, 원산지 별도표기

    L.POINT 4,000원 할인
    성경 보성녹차 정성L호 11,900원
    ▶ 녹차 재래식탁김 4g×24봉, 원산지 별도표기

    10+1
    광천 김수미 재래김 세트 10,900원
    ▶ 재래김 4g×20봉, 원산지 별도표기

    10+1
    동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉, 들기름 식탁김 4g×12봉, 원산지 별도표기

    3+1
    코주부 비프앤넛츠 프리미엄 선물세트 49,900원
    ▶ 120g×4봉, 우육 : 외국산(호주/뉴질랜드/미국), 아몬드/호두 : 미국산, 해바라기씨 : 불가리아산

    L.POINT 12,000원 할인
    궁 쇠고기육포 선물세트 47,900원
    ▶ 미국산 우육 70g×6봉, 원산지 별도표기

    L.POINT 15,000원 할인
    유명산지 멸치&견과 혼합세트 41,900원
    ▶ 볶음용 220g, 볶음조림용 100g, 조림용100g, 호두 130g, 아몬드 170g, 원산지 별도표기

    L.POINT 10,000원 할인
    명작 견과&건과일 10종 세트 39,900원
    ▶ 구운아몬드 170g, 구운땅콩 150g, 건포도 160g, 호두 110g,구운피스타치오 120g, 건크랜베리 160g, 대추야자 160g, 씨를 뺀 건자두 160g, 건무화과 150g, 구운캐슈넛 150g, 원산지 별도표기

    1+1
    넛츠박스 매일견과 세트 20봉 19,900원
    ▶ 매일견과 20g×20봉, 원산지 별도표기

    L.POINT 20,000원 할인
    매일견과 하루한봉 80봉 29,900원
    ▶ 매일견과 18g×20봉×4 케이스, 원산지 별도표기

    카드할인 10,000원 할인
    유명산지 금산 인삼 세트 69,900원
    ▶ 4년근 인삼 500g(뿌리당 50g 이상), 국산

    카드할인 10,000원 할인 L.POINT 10,000원 할인
    흑화고 혼합세트 54,900원
    ▶ 흑화고 80g, 표고채 50g×2, 목이버섯 40g,표고버섯가루 90g, 표고사각 50g, 국산

    카드할인 10,000원 할인
    유명산지 홍천 더덕 세트 69,900원
    ▶ 더덕 1.1kg(뿌리당 40~50g), 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 1호 49,900원
    ▶ 동고 70g, 표고채 55g×2, 건목이 40g, 흰목이30g, 표고칩 30g, 표고버섯가루 60g, 원산지 별도표기

    금산 인삼, 홍천 더덕 세트 99,900원
    ▶ 인삼 400g, 더덕 600g, 국산

    L.POINT 10,000원 할인
    BEST 견과 10종 세트 49,900원
    ▶ 구운캐슈넛 150g, 마카다미아 150g, 구운피스타치오 120g, 해바라기씨 170g, 호두 110g, 호박씨 180g, 구운땅콩 150g, 브라질넛츠 150g, 구운아몬드 170g, 피칸 120g, 원산지 별도표기

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스신한/롯데/BC/KB국민/신한/NH농협/하나/우리/IBK기업/전북은행/광주은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    카드할인 20%
    정관장 홍삼원기(氣) 29,600원
    ▶ 50ml×30포

    카드할인 20%
    정관장 홍삼원기(氣) 54,400원
    ▶ 50ml×60포

    카드할인 20%
    정관장 활삼골드 31,200원
    ▶ 50ml×30포

    L.POINT 3,000원 할인
    종근당건강 생유산균 락토핏 골드 더블세트 20,800원
    ▶ 50포×2입

    L.POINT 6,000원 할인
    뉴트리원 올인원 멀티비타 이뮨플러스 43,900원
    ▶ 7병×4세트

    풍기인삼 에너지허브 이뮨 더블업 세트 39,900원
    ▶ 5병×4세트

    1+1
    한삼인 홍삼본 골드 39,800원
    ▶50ml×30포

    1+1
    한삼인 홍삼진 굿데이스틱 59,600원 
    ▶10ml×60포

    1+1
    농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    1+1
    약사신협 6년근 고려홍삼액 진 39,000원
    ▶ 70ml×30포

    1+1
    약사신협 내몸애생발란스 산삼배양근진액 39,000원
    ▶ 70ml×30포

    1+1
    씨제이 한뿌리 홍삼대보 44,800원
    ▶ 40ml×24입

    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24입

    1+1
    씨제이 한뿌리 흑삼아르기닌 흑마늘 SET 69,800원
    ▶ 100ml×6병, 50ml×12입

    1+1
    엘지 리튠 홍삼진 골드 제로 39,900원
    ▶ 50ml×24포

    3+1
    풍기인삼 황풍정 산삼배양근 보 39,000원
    ▶ 75ml×16입

    1+1
    농협 한삼인 홍삼침향환 골드 100 110,000원
    ▶ 3.75g×100개

    1+1
    동원 튜나리챔 8호/동원참치 M8호 각49,900원 ※교차구매 가능
    ▶ 튜나리챔 8호 : 살코기참치 90g×4, 리챔 120g×6, 동원참치 M8호 : 살코기참치 90g×2, 살코기참치 135g×6

    L.POINT 30%
    씨제이 스팸복합 K호 49,910원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×6

    카드할인 30%
    씨제이 특별한선택 L-2호 40,530원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 200g×6, 스팸 120g×4

    1+1
    청정원 L3호/청정원 L5호
    각49,800원 ※교차구매 가능
    ▶ L3호 : 카놀라유 500ml, 잇츠팜 115g×2, 올리고당 700g, 사과식초 500ml, 진간장골드 500ml, 소갈비양념 280g×2
    L5호 : 카놀라유 500ml, 요리올리고당 700g, 잇츠팜 115g×4, 진간장골드 200ml, 씨간장 양조간장 200ml

    카드할인 30%
    동원 스페셜 38호 39,970원
    ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4, 리챔 120g×4, 건강요리유 480ml×2

    L.POINT 30%
    동원 프리미엄 20호 39,970원
    ▶ 살코기참치 90g×12, 리챔 120g×4, 건강요리유 480ml×2 , 동원 참치액 진 240g×2

    1+1
    씨제이 스팸 12K호/씨제이 스팸 닭가슴살 12호 각59,800원 ※교차구매 가능
    ▶ 스팸 12K호 : 스팸 200g×8, 스팸 닭가슴살 12호 : 스팸닭가슴살 200g×8

    동서 맥심 커피세트 20호 23,800원
    ▶ 맥심 모카골드 믹스 12g×200입

    카드할인 20%
    스타벅스 원두믹스 머그 선물세트 28,080원
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml

    5+1
    카누 블렌드 7호 21,900원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누말차라떼 8T, 카누 바닐라라떼 8T, 카누 미니 마일드 10T×2개

    10+1
    유영군 명인한과 2호 59,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎), 정과(오렌지), 강정(쌀/백년초/치자/대잎/흑미/보리/참깨/들깨), 다식(단호박/백년초/대잎/콩), 약과(미니), 매작과(치자백년초)

    1+1
    창평한과 면앙정 69,900원
    ▶ 유과(찹쌀/백년초/단호박), 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미), 다식(단호박/백년초/대잎/콩), 약과(미니)

    L.POINT 43,000원 할인
    발렌타인 21년 224,800원
    ▶ 700ml

    L.POINT 40,000원 할인
    글렌그란트 12년 69,900원
    ▶ 700m

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    칠성 칠레 비냐마올로 파고 2종 세트 29,900원
    ▶ 750ml×2

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    레뱅 스페인 1호 29,900원
    ▶ 750ml×2

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    금양 프랑스 와인 1호 53,900원
    ▶ 750ml×2

    L.POINT 20,000원 할인
    조니워커 그린 79,800원
    ▶ 700ml

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    금양 투조 XO 99,000원
    ▶ 700ml

    ※점포별 입점 상품 상이
    ※조기소진 가능
    금양 1865 헤리티지 세트 (미니 골프백) 55,000원
    ▶ 750ml

    ※점포별 입점 상품 상이
    ※조기소진 가능
    L.POINT 33,000원 할인
    레뱅 이탈리아 우마니론끼 비고르 세트 36,900원
    ▶ 750ml×2

    ※점포별 입점 상품 상이
    ※조기소진 가능
    화요 선물세트 2호 58,000원
    ▶ 200ml×3

    10+1
    엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 볼륨 샴푸/컨디셔너 각 200ml, 오가니스트 바디워시 190ml, 온더바디 비누 80g×2, 페리오 치약 90g×2, 페리오칫솔 2개

    카드할인 30%
    엘지 생활의 품격 3호 20,930원
    ▶ 닥터그루트 샴푸 180ml, 오가니스트 샴푸/컨디셔너 각 200ml,오가니스트 바디워시 190ml×2, 온더바디 비누 80g×2, 페리오치약 90g×6

    1+1
    엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 리페어링 샴푸 400ml×2, 컨디셔너 400ml, 온더바디 바디워시 500g, 온더바디 비누 80g×2

    카드할인 30%
    엘지 히말라야 핑크솔트 에센셜 세트 27,230원
    ▶ 히말라야 핑크솔트 샴푸 200ml×2, 바디워시 190ml, 비누 90g×2, 히말라야 핑크솔트 치약 민트향 100g×2, 바이옴치약 100g×4

    카드할인 30%
    엘지 히말라야 핑크솔트 클래식 세트 50,330원
    ▶ 히말라야 핑크솔트 샴푸 500ml, 샴푸 자몽 500ml, 바디워시 480ml,샴푸 380ml/200ml 각 1개, 폼클렌징 200ml, 펌핑치약 285g,바이옴치약 100g×5, 비누 90g×2

    1+1 헤드 단목 양말세트 7,900원
    ▶ 각 2매

    5+1 카드할인 30%
    휠라 스포츠 양말세트 6,930원
    ▶ 각 3매

    5+1 카드할인 30%
    컬럼비아 중목 양말 세트 각7,630원
    ▶ 각 3매

    5+1 카드할인 30%
    네파 남녀 스포츠 양말 세트 10,430원
    ▶ 6매

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰과 전단지 상의 가격 및 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 809,810 슈퍼 추석 본세트 (~9/17) */
const L809data = [
  `<h1>LOTTE SUPER</h1>
  <div>
    ·롯데슈퍼 추석 선물세트 적용기간 : 2024. 9. 7(토) ~ 2024. 9. 18(수)

    롯데카드 비씨카드 삼성카드 신한카드 KB국민카드

    통큰 한가위
    최대 50만원
    상품권 증정 또는 즉시할인

    최대 30% 할인 혜택
    ▶기간 : 9/7(토) ~ 9/18(수) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    L.POINT 회원 특별할인
    ※해당상품에 한함

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외(일부 도서 산간 지역 제외)
    ※결제금액 기준

    9의 자격, 오직 1% 한우에게만.
    한우 최고 9등급 한우로 맛, 신선도, 가격 모든 면에서 격이 다른 마블나인 선물세트를 선보입니다
    ※ '23년 한우 전체 등급 낙찰 두수 53.1만두 중 롯데마트
    한우 MD 직접 경매 1++(9)등급 Marble9 두수 801두

    9/7(토) ~ 9/11(수) 기간한정! ※ 자세한 사항은 매장 안내데스크로 문의 바랍니다

    3입 세트 99,000원 등안채 세트 1++(9)등급
    등심, 안심, 채끝 각 200g/냉장/국내산 한우고기
    고급 휴대용 패키지

    6입 세트 189,000원 
    1++(9)등급 등심, 안심, 채끝 각 200g
    차돌박이, 업진살, 치마살 각 150g 냉장/국내산 한우고기

    L.POINT 30,000원 할인
    한우정육세트2호 99,000원
    ▶ 한우국거리/불고기 각 500g×2, 냉장, 국내산 한우고기

    L.POINT 30,000원 할인
    한우 정육세트 1호 169,000원
    ▶ 한우 국거리, 불고기 각 500g×3, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심세트2호 239,000원
    ▶ 한우등심팩 500g×4, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심채끝혼합세트 249,000원
    ▶ 한우등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심정육세트1호 219,000원
    ▶ 한우등심/국거리/불고기 각 500g×2, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 등심 안심 혼합세트 229,000원
    ▶ 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 구이 스페셜 세트 239,000원
    ▶ 1등급 한우 등심 500g, 채끝, 안심, 부채살 각 400g, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심세트 1호 339,000원
    ▶ 한우등심팩 500g×6, 냉장, 국내산 한우고기

    L.POINT 30,000원 할인
    한우냉동실속정육세트 99,000원
    ▶ 한우냉동국거리 600g, 불고기 600g, 산적 600g, 양념소스, 냉동, 국내산 한우고기

    L.POINT 50,000원 할인
    한우냉동갈비세트 149,000원
    ▶ 한우냉동찜갈비700g×3, 양념소스, 냉동, 국내산 한우고기

    L.POINT 30,000원 할인
    호주산 냉동 찜갈비 세트(2.4kg) 99,000원
    ▶ 호주산 냉동 찜갈비 800g×3, 양념소스

    L.POINT 50,000원 할인
    양념한우불고기 모둠세트(2.4kg)/(냉동) 129,000원
    ▶ 양념 한우불고기 1.2kg, 한우 언양식 불고기 1.2kg ※상품권 증정 제외

    L.POINT 30,000원 할인
    미국산 소 냉동 LA식 갈비 세트(3kg) 129,000원
    ▶ 미국산 냉동 꽃갈비 구이용1.5kg×2

    L.POINT 5,000원 할인
    충주 사과 64,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    L.POINT 15,000원 할인
    정성담은 신고배 64,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    L.POINT 10,000원 할인
    충주사과, 천안배 69,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입 (3.6kg 내외), 국산

    L.POINT 10,000원 할인
    샤인머스캣 69,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    L.POINT 10,000원 할인
    제스프리 골드키위 39,900원
    ▶ 골드키위 25입 (2.5kg 이상), 뉴질랜드산

    충주 프레샤인 사과 49,900원
    ▶ 사과 17~20입(5.0kg 내외), 국산

    천안배 49,900원
    ▶ 배 5-6입(5.0kg 내외), 국산

    샤인머스캣, 사과, 배 99,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외), 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산

    제주 황금향 49,900원
    ▶ 황금향 8~12입 (3.0kg 내외), 국산

    L.POINT 10,000원 할인
    상주 둥시 곶감(福) 49,900원
    ▶ 곶감 24입(0.8kg 내외), 국산

    1+1
    넛츠박스 매일견과 20봉 19,900원
    ▶ 매일견과 20g×20봉, 호두 5g(미국), 구운아몬드 5g(미국), 구운땅콩 5g(미국), 건포도 5g(칠레), 원산지 별도표기

    L.POINT 12,000원 할인
    시그니쳐 매일견과 세트 50봉 27,900원
    ▶ 매일견과 20g× 50봉, 구운아몬드 20%, 호두25%, 구운땅콩25%, 구운캐슈너트 20%, 원산지 별도표기

    L.POINT 20,000원 할인
    매일견과 하루한봉 세트 (80봉) 29,900원
    ▶ 매일견과 (18g×20개입)×4입, 건포도 31%, 구운아몬드 28%, 호두 24%, 구운캐슈너트 17%, 원산지 별도표기

    L.POINT 10,000원 할인
    더 고소한 마카다미아 매일견과 50봉 29,900원
    ▶ 매일견과 20g×50봉, 호두 30%, 구운아몬드 30%, 건포도(칠레) 25%, 마카다미아(호주) 15%, 원산지 별도표기

    L.POINT 10,000원 할인
    명작 견과 & 건과일 10종 세트 39,900원
    ▶ 견과류 5종 + 건과일 5종 총 1490g, 원재료 정보 별도표기

    L.POINT 10,000원 할인
    자연향담은 동고혼합세트 29,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    L.POINT 20,000원 할인
    6년근 실속 금산 인삼세트 29,900원
    ▶ 6년근 인삼 난발 350g, 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 2호 39,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 1호 49,900원
    ▶ 동고 70g, 표고채 55g×2, 건목이 40g, 흰목이 30g, 표고칩 30g, 표고버섯분말 60g, 국내산

    L.POINT 20,000원 할인
    자연향 담은 백화고 혼합세트 54,900원
    ▶ 백화고 90g, 동고 70g×2, 사각칩 40g×2, 상황채 40g, 영지채 50g,국내산

    ※ 발행일(2024년 9월 7일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외 ※카드할인가는 롯데/BC/삼성/신한/KB국민/카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    10+1
    대한민국 명장 장석준 명란세트 99,000원
    ▶ 명란 600g(300g×2), 냉동, 원산지 별도표기

    7+1
    자연담은 칠산소금 참굴비 1호/2호 69,900/130,000원
    ▶ 1 호 : 10마리 (0.8kg 내외), 냉동, 참조기/천일염 : 국산
    2호 : 10마리 (1.0kg 내외), 냉동, 참조기/천일염 : 국산

    10+1
    광천 김수미 재래김 선물세트 10,900원
    ▶ 재래김 4g×20봉

    CJ 비비고 토종김 1호 16,900원
    ▶ 담백 구운김전장 10g×3봉, 비비고 참기름 도시락김 4g×6봉×2ea

    L.POINT 30%
    동원 양반김 M20호 25,970원
    ▶ 들기름 전장김 20g×6봉, 참치액(진) 900g

    비비고 토종김 5호 9,900원
    ▶ 비비고 참기름 도시락김 4g×6번들×3EA

    양반 들기름김세트 9,900원
    ▶ 들기름 김 4g×18봉

    L.POINT 4,000원 할인
    성경 보성녹차 정성L호 11,900원
    ▶ 녹차 재래 식탁김 4g×24봉

    10+1
    동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉, 들기름 식탁김 4g×12봉

    L.POINT 7,000원 할인
    코주부 육포세트 2호 32,900원
    ▶ 외국산 우육 40g×7봉, 원산지 별도표기(우육 : 호주/뉴질랜드/미국)

    카드할인 20%
    정관장 홍삼원기 21,900원
    ▶ 50ml×30포

    고려인삼 녹용침향액 39,000원
    ▶ 75ml×10병

    L.POINT 10,000원 할인
    6년근 뿌리 많은 인삼세트 39,900원
    ▶ 6년근 인삼 난발 500g/국산

    L.POINT 10,000원 할인
    실속형 금산 인삼 더덕 혼합세트 49,900원
    ▶인삼 300g, 더덕 400g / 국산

    1+1
    아내의 착즙주스 배도라지즙/사과즙/당근사과즙/감귤사과즙 착즙하는남자 양배추 사과즙 각29,900원
    ▶ 각 100ml×21입 ※교차구매 가능

    1+1
    농협 한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포

    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    2+1
    풍기인삼 자연의 기운을 품은 심 2호 49,000원
    ▶ 산삼배양근 보 8입, 봉밀홍삼절편 5입

    딱, 필요한 상품으로만, 가성비 선물세트 추천
    카드할인 30%
    씨제이 특별한선택 L-3호 28,630원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6

    카드할인 30%
    씨제이 스팸복합 1호 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6ㄴ

    카드할인 30%
    씨제이 특별한선택 L-2호 40,530원
    ▶ 카놀라유 500ml×2, 스팸 200g x 6, 스팸 120g×4, 요리올리고당 700g

    카드할인 30%
    씨제이 특별한선택 N호 41,230원 
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7

    카드할인 20%
    씨제이 스팸 8K호 41,520원
    ▶ 스팸 200g×6, 스팸라이트 200g×3

    카드할인 30%
    동원 프리미엄 1호 19,950원
    ▶ 살코기 참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g, 건강요리유 480ml, 카놀라유 480ml

    2+1
    동원 프리미엄K호 38,900원
    ▶ 살코기 90g×3, 리챔오리지널 120g, 구운소금 210g, 핑크솔트 100g, 올리고당 700ml, 카놀라유 480ml

    1등 참치와 짜지않은 리챔을 가득 담은 인기세트 추천
    카드할인 30%
    동원 튜나리챔 100호 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    카드할인 30%
    동원 프리미엄 62호 29,960원
    ▶ 살코기 90g×5, 리챔 120g×2, 참치액 240g, 참기름 75ml, 카놀라유 480ml×2

    카드할인 20%
    동원 튜나리챔 64호 39,920원 
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    카드할인 30%
    청정원 스페셜 S호 19,950원
    ▶ 카놀라유 500ml, 양조간장 500ml, 프레스햄 115g×2, 발효식초 500ml, 올리고당 700gㄴ

    카드할인 30%
    청정원 L1호 22,260원
    ▶ 옥수수기름 500ml, 카놀라유 500ml, 프레스햄 115g, 올리고당 700g, 생강매실맛술 410ml

    1+1
    청정원 L3호 49,800원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2, 올리고당 700g,사과식초 500ml, 진간장골드 500ml, 소갈비양념 280g×2

    1+1
    청정원 L5호 49,800원
    ▶ 카놀라유 500ml, 올리고당700g, 잇츠팜115g×4, 진간장골드 200ml, 양조간장200ml

    L.POINT 30,000원 할인
    레뱅 이탈리아비고르세트 36,900원
    ▶ 우마니론끼 비고르 레드 750ml, 우마니론끼 비고르 화이트 750ml

    롯데 미국브레드 앤버터2종세트 67,600원
    ▶ 브레드앤버터 까베르네소비뇽 750ml, 브레드앤버터 샤르도네 750ml

    L.POINT 16,200원 할인
    조니워커 블랙 45,800원
    ▶ 조니워커 블랙 700ml, 하이볼글라스, 미니레몬즙 또는 종이코스터 ※점별 구성 상품이 상이할 수 있습니다

    L.POINT 11,000원 할인
    와일드터키 8년 54,900원
    ▶ 와일드터키8년700ml, 온더락글라스, 하이볼글라스

    L.POINT 20,000원 할인
    조니워커그린 79,800원
    ▶ 조니워커 그린 750ml,온더락 글라스, 글라스 캡

    L.POINT 5,000원 할인
    애경 산양유 바디케어 기프트 11,900원
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 90g×2입

    10+1
    LG 정성담은13호 13,900원
    ▶ 샴푸 200ml×2, 컨디셔너 200ml, 바디워시(라벤더향) 190ml, 페리오 치약 90g×2, 페리오 칫솔×2(낱개)

    LG 히말라야핑크솔트38호 27,230원
    ▶ 샴푸 200ml×3, 핑크솔트 치약 100g×2, 바이옴 치약 100g×4, 핑크솔트 바디워시 190ml

    1+1 LG 샴푸&바디세트 프리미엄 33,900원
    ▶ 퍼퓸 샴푸 400ml×2, 퍼퓸 컨디셔너 400ml, 레몬 바디워시 500g, 보타닉 비누 80g×2
    
    1+1 LG 모던에디션 라이프 41,900원
    ▶ 바디워시 385ml, 체리블라썸 샴푸 400ml, 체리블라썸 샴푸 200ml, 컨디셔너 200ml, 페리오캐비티 모던 90g×2

    ※ 발행일(2024년 9월 7일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외 ※카드할인가는 롯데/BC/삼성/신한/KB국민/카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
];

/* 791,792 맥스 추석 본세트 (~9/17) */
const L791data = [
  `<h1>LOTTE Mart maxx</h1>
  <div>
    ·적용기간 : 2024. 9. 7(토) ~ 2024. 9. 17(화)
    ·롯데마트맥스 : 목포, 상무, 송천, 창원중앙, 금천, 영등포점

    생활비 다이어트 롯데마트&MAXX 카드
    롯데마트맥스 신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 우리카드 IBK기업은행 전북은행 JB카드 광주은행 KJ카드

    통큰 한가위
    최대 50만원
    상품권 증정 또는 즉시할인

    최대 30% 할인 혜택
    ▶기간 : 9/7(토) ~ 9/17(화) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    L.POINT 회원 특별할인
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외(일부 도서 산간 지역 제외)
    ※결제금액 기준

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    유명산지 문경 사과세트 49,800원
    ▶ 사과 15~16입/4.5kg 내외, 국산

    유명산지 신고배 세트 49,800원
    ▶ 배 10~11입/6.5kg 내외, 국산

    카드할인 20%
    프리미엄 문경사과/신고배세트 51,840원
    ▶ 사과 6입, 배 6입/5.5kg 내외, 국산

    카드할인 20%
    큰사과 큰배 혼합세트 63,480원
    ▶ 사과 6입, 배 6입/6.4kg 내외, 국산

    샤인머스캣 사과 배 세트 49,800원
    ▶ 사과 4입, 배 3입, 샤인머스캣 1입/3.6kg 내외, 국산

    제스프리 골드키위 세트 29,800원
    ▶ 골드키위 18~20입(2.7kg 내외), 뉴질랜드산

    왕 곶감 세트 99,800원
    ▶ 왕곶감 32입(1.6kg 내외), 국산

    카드할인 13,000원 할인
    자연담은 표고버섯 어울림 49,800원
    ▶ 표고채 60g, 흑화고 80g×2, 목이버섯 40g, 표고버섯가루 90g×2, 국산

    카드할인 15,000원 할인
    정성담은 표고버섯 견과 혼합 63,800원
    ▶ 흑화고 80g×2, 표고채 60g×2 구운캐슈넛 150g, 피칸 120g 外,원산지 별도표기

    카드할인 20,000원 할인
    울릉도 왕더덕 118,000원
    ▶ 더덕 1.5kg, 국산

    카드할인 30,000원 할인
    호주산 소LA식 꽃갈비세트 148,000원
    ▶ 호주산 LA식 꽃갈비(냉동)2kg×2

    카드할인 30,000원 할인
    미국산 소 LA식꽃갈비 세트 148,000원
    ▶ 미국산 LA식 꽃갈비(냉동)2kg×2

    L.POINT 30,000원 할인 상품권 증정
    한우 등심 정육 세트 148,000원 
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기

    L.POINT 40,000원 할인 상품권 증정
    한우 등심 세트 2호 208,000원
    ▶ 1등급 한우 등심 500g×4, 냉장, 국내산 한우고기

    L.POINT 30,000원 할인 상품권 증정
    한우 갈비 세트 138,000원 
    ▶ 한우 갈비(냉동) 700g×3, 양념 소스, 국내산 한우고기

    ASC인증 완도활전복세트 74,900원 
    ▶ 2kg 내외(특대/11미)

    킹블랙타이거 새우세트 137,500원
    ▶ 1kg 내외(왕특대, 5마리/냉동), 기니아산

    카드할인 20%
    제주 프리미엄 갈치 세트 159,200원
    ▶ 2.2kg(550g×4팩), 냉동, 국산
    
    카드할인 20%
    칠산소금 참굴비 세트 2호 110,000원 
    ▶ 1.0kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산

    카드할인 20%
    영광 법성포 참굴비 세트 1호 69,520원 
    ▶ 1.4kg 내외(20마리), 냉동 참조기 : 국산, 천일염 : 국산

    제주 추자도 거친 파도 속에서 자생하는 자연산 돌미역을 해녀가 직접 채취한 귀한 미역과 통영산 멸치로 구성
    카드할인 15,000원 할인
    추자도미역 통영멸치세트 49,800원
    ▶ 추자도미역 80g, 볶음용멸치 270g, 볶음조림용멸치 240g, 국물용멸치 240g, 국산

    카드할인 8,000원 할인
    대천 곱창김 세트 24,980원
    ▶ 곱창김(캔) 30g×4, 곱창김(전장) 25g×5봉, 김 : 국산

    카드할인 7,000원 할인
    코주부 육포세트 1호 36,800원
    ▶ 60g×6봉, 원산지 별도표기

    카드할인 10,000원 할인
    정성 담은 웰빙견과 10종 39,800원
    ▶ 볶음 아몬드 190g, 볶음 캐슈넛 160g, 호두 120g, 피스타치오 150g 外, 원산지 별도표기

    카드할인 10,000원 할인
    하루한줌 프리미엄 80봉 견과 29,800원
    ▶ 하루한줌 4종 구성(프리미엄, 믹스넛, 넛츠앤베리, 오리지널 각 20입), 원산지 별도표기

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데맥스송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스신한/롯데/BC/KB국민/신한/NH농협/하나/우리/IBK기업/전북은행/광주은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    카드할인 25%
    정관장 홍삼진본 36,750원
    ▶ 40ml×20포

    1+1 상품권 증정
    한삼인 홍삼진 굿데이스틱 59,600원
    ▶10ml×60포

    1+1 상품권 증정
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    L.POINT 6,000원 할인 상품권 증정
    뉴트리원 올인원 멀티비타 이뮨플러스 선물세트 43,900원
    ▶ 21.5g×7병×4입

    L.POINT 16,000원 할인 상품권 증정
    경남제약 레모나 에스산 선물세트 33,900원
    ▶ 1.5g×90포×3입

    카드할인 30% 상품권 증정
    동원 스페셜 38호 39,970원
    ▶ 살코기 참치 135g×6입, 리챔 더블라이트 200g×4입, 리챔 120g×4입, 건강요리유 480ml×2입

    카드할인 30% 상품권 증정
    동원 튜나리챔 30호 34,930원 
    ▶ 살코기 참치 90g×12입, 리챔 더블라이트 120g×3입

    1+1 상품권 증정
    씨제이 스팸 12K호 59,800원 
    ▶ 스팸 200g×8입

    카드할인 30% 상품권 증정
    씨제이 스팸복합 S호 39,830원
    ▶ 스팸 클래식 200g×5입, 스팸 클래식 340g×2입, 스팸클래식 120g×2입, 카놀라유 500ml×2입

    카드할인 30% 상품권 증정
    청정원 L9호 39,760원
    ▶ 카놀라유 500ml×2입, 잇츠팜 190g×2입, 잇츠팜 115g×2입, 우리팜델리 330g×2입, 요리올리고당 700g

    카드할인 30% 상품권 증정
    사조 안심특선 94호 26,530원
    ▶ 카놀라유 500ml, 사과식초 500ml, 안심팜 115g×2입, 구운소금 200g, 진간장 500ml, 맛술 500ml, 참기름 55ml, 올리고당 700g

    L.POINT 10%
    오성푸드 국내산 누룽지세트 20,610원
    ▶ 쌀 누룽지 150g×5입, 현미 누룽지 150g×5입

    해당상품 2,500원 할인 5+1 상품권 증정
    동서 카누 아메리카노 8호 22,980원
    ▶ 카누미니 마일드 10T×6입, 카누미니 다크 9g×10T×2입, 카누미니 라이트 9g×10T×2입, 트윈머그

    카드할인 30% 상품권 증정
    담터 다정 종합세트 19,320원
    ▶ 호두아몬드율무차 18g×15입, 단호박마차 17g×15입, 생강차플러스 15g×15입, 쌍화차플러스 15g×15입, 대추차플러스 15g×15입

    1+1
    침향녹용보/아내의 배도라지즙, 당근사과즙, 사과즙 각29,800원
    ▶100ml×30입/각 100ml×21입
    ※교차구매 가능

    2묶음 세트
    칠성 칠레 비냐마올로 파고 2종 세트 29,900원
    ▶ 750ml×2

    2묶음 세트
    레뱅 스페인 1호 29,900원
    ▶ 750ml×2

    L.POINT 74,000원 할인
    로얄살루트 21년 227,800원
    ▶ 700ml

    L.POINT 33,000원 할인
    글렌그란트 10년 54,800원
    ▶ 700ml,하이볼 잔 1개, 노징글라스 1개

    L.POINT 8,200원 할인
    화요 선물세트 2호 49,800원
    ▶ 200ml×3 ※17도, 25도, 53도

    엘지 프로폴리스 기프트세트 프리미엄 9,900원
    ▶ 페리오 프로폴리스향 90g×4, 페리오 프로폴리스 칫솔×2, 온더바디 프로폴리스 비누 80g×2

    10+1
    엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 볼륨 샴푸/컨디셔너 200ml 각 1개, 온더바디 체리블라썸 바디워시 190ml, 페리오 캐비티 B 90g×2,온더바디 보타닉 비누 A 80g×2, 페리오 초극세모 슬림터치 칫솔 낱개×2

    1+1 엘지 보타닉 퍼퓸컬렉션 30,900원
    ▶ 보타닉 퍼퓸컬렉션 샴푸/컨디셔너/바디워시 각 480ml, 보타닉 퍼퓸컬렉션 비누 80g×2

    1+1 엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 리페어링 샴푸 400ml×2, 컨디셔너 400ml, 온더바디 레몬 바디워시 500g, 온더바디 보타닉 비누 80g×2

    1+1 엘지 모던에디션 라이프 41,900원
    ▶ 오가니스트 체리블라썸 샴푸 모던에디션 400ml, 200ml 각 1개, 컨디셔너 200ml, 페리오캐비티 모던에디션 90g×2, 온더바디 더 내추럴레몬 바디워시 모던에디션 385ml

    카드할인 30%
    엘지 생활의 품격 3호 20,930원
    ▶ 닥터그루트 힘없는 모발용 샴푸 180ml, 온더바디 보타닉 비누 B 80g×2, 오가니스트 체리블라썸 샴푸/컨디셔너 200ml 각 1개, 온더바디 바디워시 라벤더향 190ml×2, 페리오캐비티 B 90g×6

    카드할인 30%
    엘지 히말라야 핑크솔트 에센셜 27,230원
    ▶ 오가니스트 핑크솔트 샴푸 200ml×2, 바디워시 190ml, 죽염 히말라야 핑크솔트 치약 아이스카밍민트 100g×2, 바이옴치약 100g×4, 온더바디 벨먼 핑크솔트 비누 90g×2

    카드할인 10%
    제이엠솔루션 꿀광 마스크팩 세트 30매 17,982원
    ▶ 꿀광 로얄 프로폴리스 마스크 플러스 30매

    1+1 
    유렉시아 명화양말 선물세트 전품목
    ※재고 소진시 행사 종료 ※동일상품에 한함

    PAT 남성 립중목, 여성 다이아 중목 양말세트/PAT 남성 다이아중목, 여성 다선컬러 중목 양말세트
    5,980/7,980원 ▶ 각 2족/각 3족

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데맥스송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰과 전단지 상의 가격 및 혜택은 상이할 수 있습니다
    
  </div>
  `,
];

// 111, 112 원주점(~9/11)
const L111data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 9. 5(목) ~ 2024. 9. 18(수) < 신선식품 적용기간 : 2024. 9. 5(목) ~ 9. 11(수) >
    ·원주점

    롯데마트 원주점 새단장 OPEN

    단독 일별 특가

    9/5 목
    캐나다 삼겹살/목심(각 100g/냉장/캐나다산 돼지고기) 각990원
    ※삼겹살 200kg/목심 100kg 한정
    ※조기 품절될 수 있습니다 ※1인 2팩 구매제한

    보은 햇사과(4~7입/봉/국산) 9,990원

    GAP 충주 하늘작 복숭아 (4~8입/박스/국산) ※100박스 한정 12,990원

    9/6 금
    스윗볼 스테비아 대추방울토마토(500g/팩/원산지 별도표기) 4,990원

    9/7 토
    수산대전 20%
    손질 오징어(마리/해동/원양산) 3,992원
    ※산지 시황에 따라 냉동으로 대체될 수 있습니다
    ※수산대전은 L.POINT 회원 기간 중 최대 2만원 할인

    9/8 일
    팽이버섯(360g/봉/국산) 790원

    9/9 월
    DHA 진미오징어(250g) 5,990원

    9/10 화
    L.POINT 10%
    동서 맥심기획 모카골드(12g×60포×4개) 25,630원

    9/11 수
    L.POINT 30%
    농심 올리브 짜파게티(5개입) 3,416원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다 
    이번주 핫프라이스 HOT
    송이송이 과즙 한가득! 달콤함 한가득!
    국산 혼합 포도 (2송이/팩/국산) 12,990원
    L.POINT 3,000원 롯데/신한/NH농협카드 2,000원 추가할인
    ※기간 : 9/5(목) ~ 9/8(일)
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    신규 오픈
    전품목 1+1
    ※9/5(목) ~ 9/15(일), 11일간
    ※탑텐 키즈 매장은 위치 이동
    탑텐밸런스 단독! 5만원 이상 구매시 선착순 700명 양말 증정!

    CURLYSUE edit
    24F/W 10/20/30만원 이상 구매시 1/3/5만원 금액대별 할인!
    9/5(목) 단 하루
    선착순 50명 최대 10만원 랜덤 금액권 증정!
    10만원 이상 구매시 양말 3종 세트 선착순 증정!
    ※기간 : 9/5(목) ~ 9/22(일)

    아가방
    20만원 이상 구매 고객 한정 출산준비물 20% 할인!
    모든 구매 고객 아가방 포인트 3배 적립
    10/20/30만원 이상 구매시 1/2/3만원 할인권 증정!
    ※기간 : 9/5(목) ~ 9/18(수)

    토니모리
    행사상품 최대 50% ※일부품목 제외
    구매 금액대별 증정!
    3만원 이상 구매시 원더토너/에멀전/수분크림샘플 증정
    5만원 이상 구매시 골드폼/퍼스트에센스 중 택1    

    롯데마트 원주점 새단장 OPEN 이벤트
    EVENT 1
    단 4일간! 7만원 이상 구매시 오늘좋은 장바구니(특대형) 선착순 증정!
    ※기간 : 9/5(목) ~ 9/8(일)
    ※일 450명, 총 1,800명 한정
    ※영수증 지참 후 ‘도와드리겠습니다’ 센터에서 수령
    ※당일 영수증에 한함 ※1인 1일 1개 한정

    EVENT 2
    AK PLAZA 앱 다운받고
    롯데마트 5만원 이상 구매시 3천원 할인쿠폰 증정!

    AK PLAZA 신규&리뉴얼 OPEN 매장
    ※ AK플라자 원주점에서 더 많은 브랜드를 만나보세요

    롯데마트 원주점 7만원 이상 구매시 5,000원 할인쿠폰!
    쿠폰 사용 기간 : 9/5(목) ~ 9/18(수)

    ※L.POINT 회원에 한함 ※기간 내 1인 1회 한정
    ※롯데마트 원주점에서만 사용 가능
    ※타 할인권 및 적립권 중복적용 불가
    ※담배, 주류 구매금액 합산 불가
    ※ 결제취소 및 결제수단 변경시 할인권 및 이에 상응하는
    금액으로 환불해 드리지 않으며, 결제취소 및 재결제시
    할인권 사용기간 이후에는 적용이 불가합니다
    ※할인권 분실시 재발행 불가
    ※본 쿠폰을 절취선에 따라 잘라주세요

    ※정상가란? 2024년 8월 15일(목) ~ 9월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외

  </div>
  `,
  `
  <div>
    원주점 단독 상품을 만나보세요!

    소프라이즈 대한민국 한우세일
    농림축산식품부/전국 한우협회/한우 자조금 관리위원회 공동기획

    한우 등심(1등급)(100g/냉장/국내산 한우고기) 5,990원

    한우 국거리/불고기(1등급) (각 100g/냉장/국내산 한우고기) 각2,590원

    1+1
    닭볶음탕용 (800g/국내산) 8,990원

    L.POINT 40%
    자이언트 전복 (3마리/냉장/국산) 9,990원

    L.POINT 10,000원
    항공직송 동원 생연어 (320g/냉장/노르웨이산) 11,900원

    수산대전 20% 롯데/신한/NH농협카드 10% 추가할인
    서해안 햇꽃게(100g/냉장/국산) 792원
    ※산지 시황에 따라 조기 품절될 수 있습니다
    ※수산대전은 L.POINT 회원 기간 중 최대 2만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    풀무원 콩나물 전품목 5종(상품별 상이) 50%
    ※기간 : 9/5(목)~9/8(일)

    직소싱 노르웨이 고등어 필렛 L.POINT 50% 8,950원
    (700g/냉동/원산지 별도표기)

    대천 재래/파래 식탁김(각 5g×20봉/원산지 별도표기) 각4,990원

    행복생생란(대란/30입/국산) 30입 4,990원
    ※기간 : 9/5(목)~9/8(일)

    대왕님표 여주쌀 진상미(10kg/국산) 10kg 29,900원

    GAP 친환경 느타리 버섯 (500g/팩/국산) 1,990원

    10개 구매시
    제스프리 골드 키위 (개/뉴질랜드산) 9,900원
    ※1개 구매시 1,390원

    스위트마운틴 바나나(송이/원산지 별도표기) 2,990원

    비파괴 당도선별 하우스 샤인머스캣(1송이/국산) 6,990원
    
    日 100마리 한정
    큰치킨 (1마리/국내산 계육) 8,990원

    국내산 통족발 (1팩/1.3kg 내외) 19,800원

    日 100마리 한정
    우유식빵 1,500원 할인 2,000원
    (1팩/원산지 별도표기) ※라비베이커리 매장 해당
    ※기간 : 9/5(목)~9/11(수)

    하나사면 하나 더 1+1
    1+1 종가 맛김치(900g) 15,900원

    1+1 대상 호밍스 우거지 순살 감자탕(700g) 12,000원
    
    1+1 동원 통그릴 후랑크(250g×2) 9,980원

    1+1 CJ 미정당 국물떡볶이(401g) 4,980원

    1+1 동원 딤섬 3종 11,980/9,980원
    ※ 새우하가우 420g/부추창펀 550g, 샤오롱바오 510g
    ※동일 가격 상품간 교차구매 가능

    1+1 풀무원 생면식감 직화짜장(4개입) 5,450원

    1+1 폰타나 파스타소스 전품목 13종(상품별 규격상이) 각6,480~8,980원
    ※동일 가격 상품간 교차구매 가능

    1+1 오뚜기 양조식초(1.8L) 2,600원

    1+1 해찬들 고추장/된장/쌈장 18종(상품별 규격상이) 각2,580~35,500원
    ※동일 상품에 한함

    1+1 켈로그 프링글스 오리지날/사워크림어니언(각 110g) 각3,330원
    ※교차구매 가능

    1+1 임페리얼 데니쉬 버터쿠키(500g) 10,980원

    1+1 크리스피 허니눈꽃 쌀과자(920g) 11,900원

    1+1 청우 찰떡쿠키 오리지널 (258g) 5,600원

    1+1 포스트 오곡 코코볼(570g) 6,480원

    인기 주류 L.POINT 최대 7,100원 할인

    L.POINT 7,100원
    산토리 가쿠빈(700ml) 34,900원

    L.POINT 각 4,000원
    디아블로 데블스 카나발레드/스위트 12,900원

    생활 필수품
    2개 이상 50%
    온더바디 더내추럴 바디워시 3종(각 900g) 2개 이상 구매시 1개당 각5,450원
    ※레몬/라벤더/올리브향
    ※1개 구매시 각 10,900원 ※교차구매 가능

    네오플랜 퓨어락 원형/직사각/정사각(상품별 상이) 50% 각2,200~6,200원

    오늘좋은 박스형 종이컵 각1,000원 할인 13,900/11,900원
    ※ 눈금형/실속형

    오늘좋은 아로마 세면타월(각 4P) 30% 각13,930원
    ※ 블라썸/아쿠아/유칼립투스/라벤더

    아이리스 시스템 수납함(중형 2호) 50% 9,950원

    토이저러스 인기완구 30%
    ※행사상품에 한함 ※점별 재고수량 상이
    ※매장에 더많은 행사상품이 준비되어 있습니다
    
    영실업 또봇 Z/Y/X 30% 각31,430원

    미미월드 똘똘이 시럽쭉쭉 아이스크림가게 30% 30,700원

    colioli 반려동물 식/용품 최대 30%
    콜리올리 도톰한 3~4회용 패드(M/L/XL) 각30% 14,630/12,530/9,730원

    이나바 챠오츄르 씨푸드 버라이어티(40P) 6,600원 할인 19,900원

    ※정상가란? 2024년 8월 15일(목) ~ 9월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

// 221, 222 원주점(~9/18)
const L221data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 9. 12(목) ~ 2024. 9. 18(수) < 비신선식품 적용기간 : 2024. 9. 5(목) ~ 9. 18(수) > 
    롯데마트 원주점 새단장 OPEN 단독 일별 특가

    9/12목 L.POINT 30% 껍질벗긴새우 13,930원
    (750g/냉동)
    ※원산지 별도표기

    9/13금 상생 양배추 1,990원

    9/14토 1+1 참프레 훈제오리 14,990원
    슬라이스
    (500g/원산지 별도표기)

    9/15일 풀무원 특등급
    국산콩 두부 2입 기획 3,990원
    (300g×2/국산)

    9/16월 L.POINT 3000원 옥수수를 먹여키운
    만능대패 삼겹살 9,900원
    (800g/냉동/미국산 돼지고기)

    9/18수 실속 파프리카 990원
 
    L.POINT 40% 1등급 한우
    국거리/불고기
    (각 100g/냉장/국내산 한우고기)

    제수용 사과/배 각17,990원

    생대추(300g/팩/국산) 6,990원

    밤/깐밤 (700g/150g, 봉/국산) 7,990/7,490원

    L.POINT 20%  간편수제 명태살/대구살 7,920/14,320원
    (각 500g/냉동/원산지 별도표기)

    제수용 황태포/용대리 황태포 4,990/5,990원
    (60g 내외/75g 내외, 원산지 별도표기)

    GAP 팽이버섯 990원
    (3입/봉/국산)
    ※일 250봉 한정

    2팩 이상 구매시 1000원 할인 데친 고사리(300g/팩/국산)/
    깐도라지(250g/팩/국산) 각8,990원

    우리쌀 송편(100g/원산지 별도표기) 1,500원

    한가위 전/나물 (팩/원산지 별도표기) 2,500~13,000원

    L.POINT 1200원 오뚜기 옛날 자른당면(300g/500g) 3,980/6,480원

    2개 이상 구매시 1개당 500원 할인 CJ 비비고 도톰 동그랑땡/ 해물완자(425g×2/795g) 각9,480원

    1000원 할인 백설 고기양념 4종(각 500g)※소갈비/소불고기/돼지갈비/돼지불고기 양념 각3,480원

    백화수복(700ml) 4,620원

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라 
    롯데마트&롯데슈퍼가 물가안정에 앞정섭니다.

    L.POINT 50% 한우 양념 불고기(800g/냉장/원산지 별도표기) 18,900원

    L.POINT 20% 데친 문어(100g/냉장/모리타니아산) 3,192원

    신한/롯데/NH농협카드 500원 할인 목우촌 주부9단 9,980원
    살코기햄(1kg)
    ※페이지 하단 카드할인 세부내용 참고

    1040원 할인 팔도 왕뚜껑(4개입) 1,980원

    양파(2kg/망/국산) 3,990원

    GAP 하우스감귤(1.4kg/팩/국산) 13,990원

    1+1 크리스피 허니눈꽃 쌀과자(920g) 11,900원

    명절 전통음료 4종 (팔도 비락 식혜 1.5L 外 3종) 2,980~3,900원

    6950원 할인 메디안 잇몸과학 치약 2종(각 120g×3입) 각5,950원

    2개 이상 구매시 50% 할인 크리넥스
    울트라클린
    카카오(30m×30롤) 각17,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
    이번주 핫프라이스

    4인 가족 한 팩이면 충분!
    2근에 1만원대! ※1인분 300g 기준
    국내산 한돈 갈비
    (1.2kg/냉장/국내산)
    ※제주점 제외 ※조기 품절될 수 있습니다

    롯데/신한/NH농협카드 30% 15,890원


    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외
      </div>
  `,
  `
  <div>
    원주점 단독 상품을 만나보세요!

    L.POINT 16000원 미국산 LA갈비(1kg/냉동/미국산) 33,900원

    L.POINT 50% 호주청정우 앞다리살 불고기용(100g/냉장/호주산) 1,690원

    국내산 한돈 삼겹살/목심 1,590/1,690원
    (각 100g/냉장/국내산)
    ※조기품절될 수 있습니다

    L.POINT 50% 수원식 양념 돼지왕갈비 구이 11,995원
    (800g/원산지 별도표기) ※일 100팩 한정

    L.POINT 10000원 할인 프리미엄 파타고니아 생연어 19,900원
    (500g/냉장/칠레산)

    L.POINT 50% 노르웨이 고등어 필렛 8,950원
    (700g/냉동/원산지 별도표기)

    L.POINT 35% 완도 활전복(대) 8,580원
    (4마리/냉장/국산)

    L.POINT 10% 국산 고등어자반(특대) 4,491원
    (1손 2마리/냉장/고등어, 천일염 : 국산)

    대천/재래 파래 식탁김 (각 5g×20봉/원산지 별도표기) 각4,990원

    정화 백진미 오징어 2봉 기획(500g/원산지 별도표기) 9,900원

    동물복지인증 유정란(대란/25구/국산) 7,990원

    갱엿 순살 닭강정(중) (1팩/원산지 별도표기) 5,990원

    하나사면 하나 더 1+1

    1+1 종가 맛김치(900g) 15,900원

    1+1 대상 호밍스 우거지 순살 감자탕(700g) 12,000원
 
    1+1 동원 통그릴 후랑크(250g×2) 9,980원

    1+1 CJ 미정당 국물떡볶이(401g) 4,980원

    1+1 동원 딤섬 3종 11,980/9,980원
    ※ 새우하가우 420g/
    부추창펀 550g, 샤오롱바오 510g

    1+1 풀무원 생면식감 직화짜장(4개입) 5,450원

    1+1 폰타나 파스타소스 각6,480~8,980원
    전품목 13종(상품별 규격상이)

    1+1 오뚜기 양조식초(1.8L) 2,600원

    1+1 해찬들 고추장/된장/쌈장 18종(상품별 규격상이) ※동일 상품에 한함 각2,580~35,500원

    1+1 켈로그 프링글스
    오리지날/사워크림어니언 각3,330원
    (각 110g) ※교차구매 가능

    1+1 임페리얼 데니쉬 버터쿠키(500g) 10,980원

    1+1 크리스피 허니눈꽃 쌀과자(920g) 11,900원

    1+1 청우 찰떡쿠키 오리지널 (258g) 5,600원

    1+1 포스트 오곡 코코볼(570g) 6,480원

    동원 비피더스 명장 기획 (125ml×12입)
    
    L.POINT 6000원  종근당건강 락토핏 9,900원
    생유산균 골드(2g×50포)

    살림안정 물가안정 인기 생필품

    2개 이상 구매시 1개당 50% 할인 온더바디
    더내추럴 각5,450원
    바디워시 3종(각 900g)
    ※레몬/라벤더/올리브향

    50% 네오플랜 퓨어락 원형/
    직사각/정사각(상품별 상이) 각2,200~6,200원

    1000원 할인 오늘좋은 박스형 종이컵 13,900/11,900원
    ※ 눈금형/실속형

    3000원 할인 세타필 웰시 글로우 19,900원
    수분크림 기획(48g)

    4000원 할인 메디힐 하이드라 수딩 마스크/
    바이탈 퍼밍 마스크(각 20매) 각9,900원

    30% 오늘좋은
    아로마 세면타월(각 4P) 각13,930원
    ※ 블라썸/아쿠아/유칼립투스/라벤더

    50% 아이리스 시스템 수납함 9,950원
    ( 중형 2호)

    인기 주류 L.POINT 7100원 할인

    L.POINT 7100원 산토리 가쿠빈
    (700ml) 34,900원

    L.POINT 4000원 디아블로 데블스
    카나발레드/스위트 12,900원
    (각 750ml)

    토이저러스 인기완구 30%

    30% 영실업 또봇 X/Y/Z 각31,430원

    30% 미미월드 똘똘이
    시럽쭉쭉 아이스크림가게 30,700원

    콜리올리 반려동물 식/용품 30%

    30% 콜리올리 도톰한 3~4회용 패드(M/L/XL) 14,630/12,530/9,730원

    6600원 할인 이나바 챠오츄르 씨푸드 버라이어티(40P) 19,900원

    ※정상가란? 2024년 8월 22일(목) ~ 9월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div> 
  `,
];
// 331, 332 원주점(~9/25)
const L331data = [
  `<h1>LOTTE Mart</h1>
  <div>
   ·전단적용기간 : 2024. 9. 19(목) ~ 2024. 9. 30(월) < 신선식품 적용기간 : 2024. 9. 19(목) ~ 9. 25(수) > ·원주점

   원주점 새단장 OPEN 단독 일별특가

   9/19목 갱엿 순살 닭강정(대) 9,990원

   9/20금 L.POINT 35% 완도 전복(대) 8,580원
 
   9/21토 L.POINT 3000원 수제 모둠 소시지 9,900원

   9/22일 큰 치킨 8,990원

   9/23월 L.POINT 50% 팔도 비빔면 1,850원

   9/24화 꽃보다 오징어 소프트 11,990원

   
  2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
  이번주 핫프라이스

  요리 해방 치트키!
  쉽게 즐기는 간편 요리 '특가'

  냉동 밀키트/냉동 국탕 90종
  (상품별 상이)
  롯데/신한/NH농협카드 2개 이상 구매시 50% 각2,975~9,490원
  ※1개 구매시 각 5,950~18,980원 ※교차구매 가능 ※기간 : 9/19(목) ~ 9/25(수) ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
  
  롯데마트 매장에서 SNOWPLAN 멤버 전용 스노우 특가 상품을 찾아보세요!
  SNOW PLAN 가을 페스타 9/19(목) ~ 9/30(월)

  롯데마트 GO 앱을 다운받고 가입하셨다면, 스노우플랜 멤버입니다!
  스노우특가란?
  스노우플랜 멤버를 위한 특별할인가로,
  결제시 롯데마트 GO App의
  바코드를 꼭 스캔해주세요
  ※핸드폰번호로 적립시, 스노우특가 적용 불가

  9/19 목 9/20 금 9/21 토 SNOWPLAN 50% 캐나다산 삼겹살/목심 각990원
  (각 100g/냉장/캐나다산 돼지고기)
  ※조기 품절될 수 있습니다 ※1인 2팩 구매제한

  40% + SNOWPLAN 3000원 추가할인 청정원 고추장 2종 각14,760원
  (각 2kg, 찰고추장/태양초 고추장)

  SNOWPLAN 30% 동원 간편한끼 훈제연어 6,930원
  (150g/냉장/연어:칠레산)

  SNOWPLAN 500원 할인 햇 밤 고구마 6,490원
  (1.5kg/박스/국산)

  SNOWPLAN 1000원 할인 스테비아 대추방울 토마토/
  대추방울스윗마토 각4,990원
  (각 500g/팩/원산지 별도표기)

  SNOWPLAN 500원 할인 동원 통그릴비엔나(300g×2) 7,480원

  1200원 할인 + SNOWPLAN 500원 추가할인 동원 소화가 잘되는 우유(2.3L) 6,180원

  SNOWPLAN 5% + 롯데/신한/NH농협카드 10% 추가할인
  동서 카누 미니 3종 각27,189원
  (각 0.9g×150입, 마일드/다크/라이트)
  ※실제 할인적용 순서는 결제 수단에 따라 상이할 수 있습니다
  ※행사카드로 미결제시 최종 할인가는 상이할 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  매일 선착순 1천명 한정! 9/19(목)~9/30(월)
  12일간, 매일매일!
  10,000원
  할인쿠폰 증정

  매일 9/19(목)~9/30(월) ※19,26일 제외
  5,000원
  할인쿠폰 증정 5,000
  ※5만원 이상 구매시 사용 가능

  목요일 9/19(목), 9/26(목) 2일만!
  10만원 이상 구매시
  ※10만원 이상 구매시 사용 가능
  10,000원
  할인쿠폰 증정

  L.POINT 20% + SNOWPLAN 4020원 추가할인

  SNOWPLAN 10%

  SNOWPLAN 3000원 할인

  20% + SNOWPLAN 2000원 할인

  2개 이상 구매시 50% + SNOWPLAN 10% 추가할인

  미션 물가를 잡아라 롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

  1+1 요리하다 날개살로만 훈제오리(350g/냉장/국내산) 13,900원

  물가안정 제주갈치 2,900원
  (마리/해동/국산)
  ※기간 : 9/19(목)~9/22(일)

  가지(3입/봉/국산) 2,790원

  제주 황토밭 하우스 감귤 7,990원
  
  1+1 오뚜기 후랑크 핫도그/삼립 그릭슈바인 핫도그 8,980/6,980원

  1+1 씨제이 햇반 곤약밥 4종(각 150g×3입) ※교차구매 가능 각8,980원

  1+1 상온/냉장 커피음료 71종 각1,400~3,980원
  
  롯데/신한/NH농협카드 2000원 나무야 깨끗한
  무향 3겹 화장지(27m×30롤) 9,900원
  ※페이지 하단 카드할인 세부내용 참고

  히말라야 핑크솔트 센서티브 칫솔(4입) 6,990원

  롯데/신한/NH농협카드 1+1 퍼실 기능성 용기/리필 30,900/20,900원
  (상품별 상이, 실내건조/하이진젤/그린파워)
  ※용기/리필 각 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외

  </div>
  `,
  `
  <div>
  원주점 단독 상품을 만나보세요!
  1+1 마늘치킨 훈제 슬라이스 8,990원

  L.POINT 20% + 롯데/신한/NH농협카드 10% 추가할인 서해안 햇꽃게 972원
  (100g/냉장/국산)  
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 35% 완도 전복(대) 9,100원

  L.POINT 40% 내가 만드는 연어(횟감용) 3,480원

  L.POINT 5000원 순살 그릴 고등어 9,900원

  대천 김자반 3,490원

  바다원 마른 몸통 오징어(240g/원산지 별도표기) 9,900원

  L.POINT 50%  직소싱 새우살 3종(대/중/소) 각9,900원

  고산지 감숙왕 바나나 2,990원

  하나사면 하나더 1+1

  1+1 종가 포기김치(900g) 15,900원

  1+1 풀무원 즉석 해물 칼국수(2인) 7,480원

  1+1 CJ 삼호 정통 어묵탕(336g) 4,870원

  1+1 풀무원 생 물만두 9,450원

  1+1 마니커 치킨너겟골드(1kg) 12,980원

  1+1 풀무원 자연건면 로스팅 짬뽕 홍게(4개입) 5,450원

  1+1 풀무원 크로엣지
  페퍼로니(350g)/
  스위트콘&치즈(372g) 각9,980원

  1+1 켈로그 현미 그래놀라 샤르르 큐브(500g) 9,680원

  1+1 남양 프렌치카페믹스/
  아라비카 골드라벨 누보 9,980/11,200원
  (10.9g×50입/10.7g×50입)

  4+1 칠성사이다 外 균일가 캔 14종 각900원

  L.POINT 5100원 할인 고려은단 비타민C 1000 (1,80mg×120정) 9,900원

  L.POINT 6000원 할인 종근당건강 멀티비타민 이뮨플러스(23g×7입) 10,900원

  2개 이상 구매시 더 싸게!

  2개 이상 구매시 1개당 50% CJ 고메 바삭 통등심돈카츠(450g) 각4,990원

  2개 이상 구매시 1개당 50% 동원 인포켓 치즈(200g) 각7,000원
  
  2개 이상 구매시 1개당 50% 빙그레 요플레 10종 각1,890원

  인기 와인 37000원 L.POINT 할인 

  L.POINT 14100원 할인 모엣 상동 임페리얼(750ml) 74,900원

  L.POINT 2000원 할인 디아블로 데블스 카나발레드/스위트/카베네소비뇽/소비뇽(각 750ml) 각14,900원

  L.POINT 37000원 할인 글렌피딕 12년/15년(각 700ml) 79,900/109,900원


  살림안정 물가안정
  인기 생필품

  2개이상 구매시 1개당 50% 수퍼테크 리필 각5,450원

  2개이상 구매시 1개당 50% 순샘 레몬(1.2L) 각1,950원

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  </div> 
  `,
];

/* 999 테스트 전단 */
const L999data = [
  `
  <h1>·전단적용기간 : 2022. 5. 26(목) ~ 소진시까지 ·해당점 : 구미, 대덕, 부평, 사상, 송파, 월드컵, 의왕, 춘천점</h1>
  <div>
    최대 70% 가격인하 라스트 찬스

    조리도구

    주방잡화

    테이블웨어

    인테리어

    라이프스타일
  </div>
  `,
  ` 
  <div>
    일상용품

    HOME

    언더웨어

    양말

    패션잡화
  </div>
  `,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getL601data = () => {
  const dataArray = [];
  L601data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: "생활공감",
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL901data = () => {
  const dataArray = [];
  L901data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: "룸바이홈",
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL903data = () => {
  const dataArray = [];
  L903data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 추석 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL793data = () => {
  const dataArray = [];
  L793data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 맥스 추석 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL909data = () => {
  const dataArray = [];
  L909data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `super_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 추석 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL920data = () => {
  const dataArray = [];
  L920data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `ilsan_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼(백)일산점 추석 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL905data = () => {
  const dataArray = [];
  L905data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 전점 본세트 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL809data = () => {
  const dataArray = [];
  L809data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `super_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 본세트 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL791data = () => {
  const dataArray = [];
  L791data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트맥스 본세트 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL111data = () => {
  const dataArray = [];
  L111data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `wonju_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 원주점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL221data = () => {
  const dataArray = [];
  L221data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `wonju_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 원주점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};
const getL331data = () => {
  const dataArray = [];
  L331data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `wonju_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 원주점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

// test
const getL999data = () => {
  const dataArray = [];
  L999data.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 테스트 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L601: {
    title: "스마트전단지-매거진601",
    category: [],
    type: "",
    isFooter: false,
    imgPath: "images/fixed/601",
    contents: getL601data(),
  },
  L901: {
    title: "스마트전단지-룸바이홈901",
    category: [],
    type: "",
    isFooter: false,
    imgPath: "images/fixed/901",
    contents: getL901data(),
  },
  L903: {
    title: "전점 추석 사전예약903",
    category: [],
    type: "HYPER",
    isFooter: false,
    imgPath: "images/202408_1/09",
    contents: getL903data(),
  },
  L904: {
    title: "전점 추석 사전예약904",
    category: [],
    type: "HYPER",
    isFooter: false,
    imgPath: "images/202408_1/09",
    contents: getL903data(),
  },
  L793: {
    title: "맥스 추석 사전예약793",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202408_1/09",
    contents: getL793data(),
  },
  L794: {
    title: "맥스 추석 사전예약794",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202408_1/09",
    contents: getL793data(),
  },
  L909: {
    title: "슈퍼 추석 사전예약909",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202408_1/09",
    contents: getL909data(),
  },
  L910: {
    title: "슈퍼 추석 사전예약910",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202408_1/09",
    contents: getL909data(),
  },
  L920: {
    title: "슈퍼(백)일산점 추석 사전예약920",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202408_1/09",
    contents: getL920data(),
  },
  L905: {
    title: "전점 본세트 905",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL905data(""),
  },
  L906: {
    title: "전점 본세트 906",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL905data(""),
  },
  L809: {
    title: "롯데슈퍼 본세트 809",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL809data(""),
  },
  L810: {
    title: "롯데슈퍼 본세트 810",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL809data(""),
  },
  L791: {
    title: "롯데마트맥스 본세트 791",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL791data(""),
  },
  L792: {
    title: "롯데마트맥스 본세트 792",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL791data(""),
  },
  L111: {
    title: "원주점 모바일전단 111",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_1/01",
    contents: getL111data(""),
  },
  L112: {
    title: "원주점 모바일전단 112",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_1/01",
    contents: getL111data(""),
  },
  L221: {
    title: "원주점 모바일전단 221",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_2/01",
    contents: getL221data(""),
  },
  L222: {
    title: "원주점 모바일전단 222",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_2/01",
    contents: getL221data(""),
  },
  L331: {
    title: "원주점 모바일전단 331",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_3/01",
    contents: getL331data(""),
  },
  L332: {
    title: "원주점 모바일전단 332",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_3/01",
    contents: getL331data(""),
  },
  L999: {
    title: "스마트전단지-테스트999",
    category: [
      "오늘소식",
      "매일신선",
      "하루식사",
      "담소시간",
      "건강생활",
      "홈&패션",
    ], //전시 카테고리 6개 (주마다 현업에서 확정 필요)
    isFooter: true, //롯데마트몰 바로가기 사용 유무 (공통:HYPER,ZETTA 만 사용. 변경 시 현업에서 확정 필요)
    isCoupon: true, //쿠폰 유무 (주마다 현업에서 확정 필요)
    type: "HYPER", //점포타입 'HYPER' | 'ZETTA' | 'VIC' | 'MAXX' | 'SUPER' | ''(룸바이홈, 생활공감)
    imgPath: "images/fixed/01/",
    contents: getL999data(),
  },
};

export default data;
