/* 309,310 롯데슈퍼(~9/25) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
  ·전단적용기간 : 2024. 9. 19(목) ~ 2024. 9. 25(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
  풍성한 가을 밥상 알뜰 대전 ※점별 취급상품 상이 롯데슈퍼

  롯데/신한/삼성카드 1000원 할인 제주 황토밭 하우스 감귤 8,990원
  (1kg/팩/국산)
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  스위트마운틴
  바나나 2,990원
  (1.1kg 내외/송이/에콰도르산) 

  5+1 팔도 공구핫딜 왕뚜껑 5+1기획(110g×6개입) 5,550원

  1520원 할인 하림 더미식 장인라면 각6,280원
  (116g×4/112g×4) ※얼큰한맛/담백한맛

  동원 순수우유기획 3,980원

  롯데/신한/삼성카드 20% 국내산 한돈
  앞다리/뒷다리
  (각 100g/냉장/국내산)
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  롯데/신한/삼성카드 30% 1등급 한우 구이류
  (각 100g/냉장/국내산 한우고기)
  ※점별 취급상품 상이
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  1+1 다논 그릭요거트(각 90g×4) 각5,290원
  ※플레인/블루베리/달지않은
  ※교차구매 가능

  1+1 농심 사천짜파게티/
  먹태청양마요 만능소스 각5,200원
  (280g/250g) ※교차구매 가능

  미션 물가를 잡아라 롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
  ※점별 취급상품 상이

  1+1 요리하다 날개살로만 훈제오리 13,900원

  롯데/신한/삼성카드 1000원 할인 손질 갈치(3마리/팩/냉장/국산) 9,990원
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  l.point 1000원 할인 망고향 스테비아 토마토(500g/팩/국내산) 6,990원

  가지(3입/봉/국산) 2,990원

  2개 구매시 CJ 소바바치킨 소이허니순살/
  고메너겟(240g/450g) ※교차구매 가능 10,000원

  1+1 햇반 현미귀리/귀리흑미
  곤약밥(각 150g×3입) ※교차구매 가능 각9,480원

  롯데 빈츠(204g) 4,480원

  1+1 롯데 칸타타 콘트라베이스 500ml
  외 커피음료 23종(상품별 용량상이) 각2,100~4,400원

  고단백 혼합곡
  (4kg/원산지 별도표기) 8,990원
  ※3,000봉 한정물량

  롯데카드/삼성카드 2000원 할인 퍼실 기능성 세제 3종 각15,450원
  (상품별 상이)
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함

  2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
  이번주 핫프라이스

  요리 해방 치트키!
  쉽게 즐기는 간편 요리 '특가'
  찌개/전골 밀키트 4종
  행사카드 결제시 50%

  L.POINT 30% + 롯데/신한/삼성카드 20% CJ 쿡킷 진한육수 곱창전골/
  스팸 모둠사리 스팸부대전골(상품별 규격상이) 각7,990원

  L.POINT 25% + 롯데/신한/삼성카드 25% 요리하다 송탄식/의정부식
  부대찌개(상품별 규격상이) 각3,990원

  ※기간 : 9/20(금)~9/25(수), 6일간 ※조기품절될 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※신한BC카드 제외

  롯데마트 go 앱 설치하고 9월 이벤트 참여하세요!
  신규가입 하면 3만원 이상 구매시 2천원 할인쿠폰 증정!
  ▶기간 : 9/1(일) ~ 9/30(월) ▶쿠폰 사용기간 : 9/1(일) ~ 10/6(일)
  ▶대상 : 롯데마트GO앱 신규가입 고객 ▶쿠폰 사용장소 : 롯데슈퍼 직영매장

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  추희자두 7,990원
  ( 1kg/팩/국산)

  온가족 인삼 17,900원

  햇 밤 고구마 6,990원

  청양고추 2,990원

  호주산 청정우
  (각 100g/냉장/호주산)
  ※점포별 취급상품 상이
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외
  롯데/신한/삼성카드 30%

  1+1 요리하다 제주 흑돼지
  간장/고추장 불고기 각12,900원
  (600g/원산지 별도표기)

  롯데/신한/삼성카드 3000원 옥수수를 먹여키운
  만능 대패삼겹살(800g/냉동/미국산) 11,900원
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  2개 이상 구매시 20% 한끼/한끼 시즈닝 스테이크 전품목
  (150g~200g/냉장/원산지 별도표기) ※교차구매 가능

  L.POINT 2000원 닭볶음탕용 5,990원
  (800g/냉장/국내산)

  L.POINT 6000원 양념 민물장어(데리야끼맛) 13,900원
  (200g/냉장/원산지 별도표기)

  1+1 하림 치킨스테이크 2종 6,990/9,990원
  (상품별 상이/냉장/원산지 별도표기)

  L.POINT 4000원 간편한끼 훈제연어 6,900원
  (150g/냉장/원산지 별도표기)

  2개 이상 구매시 1000원 할인 마쌤 10종 각5,990원
  (상품별 규격상이/원산지 별도표기)

  롯데/신한/삼성카드 1000원 할인 손질오징어 9,990원
  (3마리/해동/원양산)
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  롯데/신한/삼성카드 2000원 할인 블랙타이거 새우 8,990원
  (300g/해동/원산지 별도표기)
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  하나사면 하나 더 1+1

  1+1 오뚜기 불고기피자(396g)/콤비네이션 피자(415g) ※교차구매 가능 각9,990원

  1+1 동원 개성교자만두 6,990원

  1+1 하림 팝콘치킨/
  통가슴살 치킨너겟 10,990원
  (650g/500g) ※교차구매 가능

  1+1 오뚜기 떡볶이/라볶이 3종 각4,990원
  (상품별 용량상이) ※교차구매 가능
 
  1+1 동원 양반 수라
  도가니 설렁탕 8,980원
  (460g)

  1+1 CJ 백설 멸치 디포리/사골 가득 각7,980원
  육수에는 1분링(각 80g) ※교차구매 가능

  1+1 청정원 쌈장/
  고기전용 쌈장 5,490/7,480원
  (500g/450g) ※교차구매 불가

  1+1 폰타나 베네치아 갈릭 봉골레파스타/
  볼로냐 베이컨 볼로네제소스(각 430g) 각11,960원 ※교차구매 가능

  1+1 빙그레 요플레 5종(상품별 용량상 이) 각3,780원
  ※클래식, 플레인, 제주감귤 외 2종 ※교차구매 가능

  1+1 정식품 뼈에 좋은
  칼슘두유(콩깨쌀) 20,000원
  (190ml×16)

  1+1 롯데 칠성사이다/제로 
  롯데 펩시콜라/제로(1.25L) ※교차구매 가능 각3,580원

  2개이상 구매시 50% 아우라 생화캡슐
  피오니로즈리필(1.6L) 각3,900원
  
  2개이상 구매시 50% 피죤 용기 각3,450원
  (각 3L, 핑크/옐로)
  
  1+1 클링스 스케일케어(각 100g×3입) 각11,900원
  ※쿨링민트/스트롱민트 ※교차구매 가능
  
  2개이상 구매시 50% 한성 프로틴크랩 각3,495원
  
  2개이상 구매시 50% 수퍼테크 액체세제 리필 각5,950원

  2개 이상 구매시 4000원  종근당 프리바이오틱스(5g×30포)/
  락토핏 생유산균골드(2g×50포) 10,900/11,900원

  롯데/신한/삼성카드 1000원 할인
  나무야 도톰한 3겹화장지(27m×30롤) 9,900원
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함

  공구핫딜
  케라시스 염색약 기획 각8,900원
  (각 120g×2) ※내추럴블랙/내추럴브라운

  비어페스티벌 맥주최저가 도전!
  4캔구매시 하이네켄실버/타이거/
  코젤다크/필스너우르켈 8,000원
  (각 500ml/캔) ※교차구매 가능

  4캔구매시 산미구엘페일필젠(320ml/병)/
  기린당류제로, 칭따오, 아사히 9,000원
  (각 500ml/캔) ※교차구매 가능

  맛있는 간편식

  THE 큰치킨 9,900원

  연어새우 한판초밥 9,990원

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
];

/* 209 210 롯데슈퍼(~9/18) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
  ·전단적용기간 : 2024. 9. 12(목) ~ 2024. 9. 18(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능

  롯데/신한/삼성카드 40% 한우 국거리/불고기
  (각 100g/냉장/국내산 한우고기)
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  롯데/신한/삼성카드 30% 수입 냉동 LA 갈비/
  호주산 찜용 갈비
  (각 100g, 냉동/냉장, 호주산/미국산)
  ※조기품절될 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  롯데/신한/삼성카드 30% 호주산 곡물 소고기 전품목
  (각 100g/냉장/호주산)
  ※조기품절될 수 있습니다 ※점포별 취급 상이
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  CJ 백설 소갈비/
  소불고기/돼지갈비/
  돼지불고기 양념(각 500g) 각3,480원

  오뚜기 옛날 자른당면(500g/300g) 6,480/3,980원

  대림 게맛살(150g)/ 동원 싱싱맛살(500g) 1,190/3,690원

  요리하다 흰깨/쑥깨송편 각6,990원

  크라운 찹쌀 선과/설병 각4,180원
  
  백화수복 골드 5,200/10,900원

  2개 구매시 씨제이 비비고 도톰동그랑땡(340g)/
  한입떡갈비(320g)/도톰해물완자(340g) 10000원

  곶감(8입/팩/국산) 10,990원

  제수용 사과/제수용 배 각17,990원

  L.POINT 50% 양념 한우 불고기
  (800g/원산지 별도표기) 19,900원

  L.POINT 10% + 롯데/신한/삼성카드 10%  할인 서해안 햇꽃게 992원
  (100g/냉장/국산)
  ※조기품절 될 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  2팩 이상 구매시 1팩당 1000원 데친 고사리/깐도라지 각8,990원
  (300g/250g, 팩, 국산)

  GAP 하우스감귤(1.4kg/팩/국산) 13,990원

  L.POINT 7000원 코주부 징기스칸 육포 각7,900원
  (각 130g/원산지 별도표기)
  ※순한맛/매운맛

  2100원 + 롯데/신한/삼성카드 500원 추가할인 목우촌 주부9단 살코기햄(1kg) 10,490원
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  1040원 할인 팔도 왕뚜껑(4개입) 1,980원

  4500원 할인 미에로화이바 세트(210ml×10입) 10,500원

  1210원 할인 연세 요거트(각 300ml) 각990원

  2개 이상 구매시 50% 할인 크리넥스 수프림소프트
  3겹 화장지(27m×30롤) 각19,700원

      2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
    이번주 핫프라이스

    4인 가족 한 팩이면 충분!
    2근에 1만원대! ※1인분 300g 기준
    국내산 한돈 갈비
    (1.2kg/냉장/국내산)
    ※제주점 제외 ※조기 품절될 수 있습니다

  L.POINT 15% + 롯데/신한/삼성카드 15% 추가할인 17,850원

  ※전단행사는 해당일 영업시간부터 적용됩니다 ※식품구매시 ‘유통기한’을 꼭 확인하시기 바랍니다
  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율,가공지역, 품질보증기간 및 취급시 주의사항은 제품 및 라벨등에 표시되어있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다
  </div> 
  `,
  `
  <div>
  L.POINT 10000원 양념 소 LA 갈비 32,900원

  밤 7,990원

  깐밤 7,490원

  생대추 6,990원

  L.POINT 20% 국내산 무항생제 삼겹살, 목심/
  국내산 대패 삼겹살
  (각 100g/500g, 냉장/냉동, 국내산)

  GAP 친환경 새송이버섯 2,990원

  양파 4,990원

  온가족 인삼 17,900원

  1+1 동물복지 닭갈비 2종 각12,990원
  (매콤/데리야키, 상품별 규격상이)

  L.POINT 5000원 요리하다
  날개살로만 훈제오리 7,900원
  (350g/냉장/원산지 별도표기)

  CJ 행복한콩 큰두부(1kg/원산지 별도표기) 3,290원

  롯데/신한/삼성카드 1000원 할인 손질 오징어(3마리/팩/냉장/원양산) 9,990원
  ※해당 단일카드로 전액 결제시에 한함
  ※체크카드/앱카드 포함 ※신한BC카드 제외

  2개 이상 1000원 할인 동원 양반 들기름/참기름/참숯구이김(각 4g×16봉/원산지 별도표기) 각5,490원

  제수용 황태포(80g/1미/원산지 별도표기) 6,990원

  L.POINT 7000원 프리미엄 파타고니아 생연어 22,900원

  순살 명태전감/대구전감 4,900/8,900원

  하나사면 하나더 1+1

  2개 이상 50% 할인 오뚜기 크런치 치킨너겟(480g) 각4,995원
  
  1+1 해태 고향만두 5,990원
  
  1+1 롯데 켄터키 핫도그(350g) 8,990원
  
  2개 이상 50% 할인 한성 프로틴 크랩(140g×2) 각3,495원

  1+1 동원 양반 나주식 곰탕 2,980원

  1+1 동원 리챔/리챔더블라이트 각14,980원

  1+1 청정원
  맛선생 참치액/
  꽃게참치액 12,980/13,480원

  1+1 CJ 백설 포도씨유/카놀라유 16,000/8,980원

  1+1 매일 바이오(각 85g×4) ※딸기/백도 ※교차구매 가능 각3,990원

  1+1 아내의 배도라지즙 外 6종 (상품별 상이) 각29,900원

  1+1 아우라 생화캡슐 피오니로즈리필(1.6L) 7,800원

  1+1 프릴 4종(상품별 상이) ※퓨어레몬/와일드베리 ※교차구매 가능 각9,500원

  1+1 2080 어드밴스 치약 각8,900원
  (각 140g×3입)
  ※구취케어/충치케어 ※교차구매 가능

  L.POINT 2650원 할인 맥심 20호 24,800원
  (모카골드 마일드 커피믹스 12g×200입)
  ※기간 : 8/27(화)~9/16(월)

  맛있는 간편식

  모둠전 3,490원

  온가족 모둠초밥 16,900원

  경양식 함박세트 7,990원

  언양식 불고기한판 5,990원
 
  신상품

  오뚜기 카레치킨 6,990원

  온가족 함께 즐기는 인기 먹거리 추천!

  오늘좋은 요리용 맛살(165g) 1,790원
 
  오늘좋은 김밥햄(100g) 1,290원
   
  오늘좋은 김밥단무지(400g) 2,000원

  ※식품구매시 ‘유통기한’을 꼭 확인하시기 바랍니다 ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율,가공지역, 품질보증기간 및 취급시 주의사항은 제품 및 라벨등에 표시되어있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L309: {
    title: "스마트전단지-슈퍼309",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202409_3/02",
    contents: getSuperData(),
  },
  L310: {
    title: "스마트전단지-슈퍼310",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202409_3/02",
    contents: getSuperData(),
  },
  L209: {
    title: "스마트전단지-슈퍼209",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202409_2/02",
    contents: getSuperNewData(),
  },
  L210: {
    title: "스마트전단지-슈퍼210",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202409_2/02",
    contents: getSuperNewData(),
  },
};

export default data;
