import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
  롯데마트 매장에서 SNOWPLAN 멤버 전용 스노우 특가 상품을 찾아보세요!
  SNOW PLAN 가을 페스타 9/19(목) ~ 9/30(월)

  롯데마트 GO 앱을 다운받고 가입하셨다면, 스노우플랜 멤버입니다!
  스노우특가란?
  스노우플랜 멤버를 위한 특별할인가로,
  결제시 롯데마트 GO App의
  바코드를 꼭 스캔해주세요
  ※핸드폰번호로 적립시, 스노우특가 적용 불가

   매일 선착순 1천명 한정! 9/19(목)~9/30(월)
  12일간, 매일매일!
  10,000원
  할인쿠폰 증정

  매일 9/19(목)~9/30(월) ※19,26일 제외
  5,000원
  할인쿠폰 증정 5,000
  ※5만원 이상 구매시 사용 가능

  9/19 목 9/20 금 9/21 토 SNOWPLAN 50% 캐나다산 삼겹살/목심 각990원
  (각 100g/냉장/캐나다산 돼지고기)
  ※조기 품절될 수 있습니다 ※1인 2팩 구매제한

  40% + SNOWPLAN 3000원 추가할인 청정원 고추장 2종 각14,760원
  (각 2kg, 찰고추장/태양초 고추장)

  SNOWPLAN 30% 동원 간편한끼 훈제연어 6,930원
  (150g/냉장/연어:칠레산)

  SNOWPLAN 500원 할인 햇 밤 고구마 6,490원
  (1.5kg/박스/국산)

  SNOWPLAN 1000원 할인 스테비아 대추방울 토마토/
  대추방울스윗마토 각4,990원
  (각 500g/팩/원산지 별도표기)

  SNOWPLAN 500원 할인 동원 통그릴비엔나(300g×2) 7,480원

  1200원 할인 + SNOWPLAN 500원 추가할인 동원 소화가 잘되는 우유(2.3L) 6,180원

  SNOWPLAN 5% + 롯데/신한/NH농협카드 10% 추가할인
  동서 카누 미니 3종 각27,189원
  (각 0.9g×150입, 마일드/다크/라이트)
  ※실제 할인적용 순서는 결제 수단에 따라 상이할 수 있습니다
  ※행사카드로 미결제시 최종 할인가는 상이할 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 20% + SNOWPLAN 4020원 추가 할인 란 멘시온 리제르바/
  란 멘시온 그란 리제르바 14,900/19,900원
  (각 750ml)

  SNOWPLAN 10% 립케어/바디보습케어 43종
  (상품별 상이) ※점별 운영상품 상이
  ※바세린/니베아/뉴트로지나 外

  SNOWPLAN 3000원 할인 다우니 섬유유연제 용기 각9,900원
  (각 2L, 핑크/퍼플/블루)
  ※상품 패키지 점별 상이

  20% + SNOWPLAN 2000원 추가 할인 3M 테이프클리너 12,520원
  롱핸들팩(대형)
  (핸들1P+리필 1P) ※입점점에 한함
  ※해당상품에 한함 ※점별 재고 상이

  2개 이상 구매시 50% + SNOWPLAN 10% 추가 할인 네오플렘 퓨어락 밀폐용기 (상품별 상이) 각1,980~5,580원

   1+1 요리하다 날개살로만 훈제오리(350g/냉장/국내산) 13,900원

  물가안정 제주갈치 2,900원
  (마리/해동/국산)
  ※기간 : 9/19(목)~9/22(일)

  가지(3입/봉/국산) 2,790원

  제주 황토밭 하우스 감귤 7,990원
  
  1+1 오뚜기 후랑크 핫도그/삼립 그릭슈바인 핫도그 8,980/6,980원

  1+1 씨제이 햇반 곤약밥 4종(각 150g×3입) ※교차구매 가능 각8,980원

  1+1 상온/냉장 커피음료 71종 각1,400~3,980원
  
  롯데/신한/NH농협카드 2000원 나무야 깨끗한
  무향 3겹 화장지(27m×30롤) 9,900원
  ※페이지 하단 카드할인 세부내용 참고

  히말라야 핑크솔트 센서티브 칫솔(4입) 6,990원

  롯데/신한/NH농협카드 1+1 퍼실 기능성 용기/리필 30,900/20,900원
  (상품별 상이, 실내건조/하이진젤/그린파워)
  ※용기/리필 각 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
  이번주 핫프라이스

  요리 해방 치트키!
  쉽게 즐기는 간편 요리 '특가'

  냉동 밀키트/냉동 국탕 90종
  (상품별 상이)
  롯데/신한/NH농협카드 2개 이상 구매시 50% 각2,975~9,490원
  ※1개 구매시 각 5,950~18,980원 ※교차구매 가능 ※기간 : 9/19(목) ~ 9/25(수) ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
  </div>
  `,
  `
  <div>
  L.POINT 30% 한우 구이류(1등급)
  각 100g/냉장/국내산 한우고기

  L.POINT 30% 미국산 우삼겹(업진살)/
  차돌박이/부채살(탑블레이드)
  품목별 규격 상이/냉동/미국산

  2개 이상 구매시 1000원 할인 추억의 한입 삼겹살/목심 각8,900원

  2개 이상 구매시 50% 할인 요리하다 흑돼지 불고기 각10,450원

  L.POINT 20% + 롯데/신한/NH농협카드 10% 서해안 햇꽃게 972원

  L.POINT 35% 내가 만드는 연어(횟감용/구이용) 3,770/3,640원

  절단 낙지 1,090원

  손질 오징어 2,750원

  8개 구매시 제스프리 골드키위 9,920원

  청양 고추 3,990원

  데일리 믹스 샐러드 3,990원

  고단백 혼합 15곡 8,990원

  1+1 CJ 명가김 재래/파래김 각7,980원

  롯데/신한/NH농협카드 20% 소떡소떡 꼬치(1개)/
  타코야끼(1팩) ※원산지 별도표기 1,992/3,992원
  ※페이지 하단 카드할인 세부내용 참고

  큰 치킨 10,990원

  큰 초밥 14,990원

  김밥&유부&롤 한판세트 10,990원

  1500원 할인 올리브 치아바타(4입/원산지 별도표기) 5,900원

  하나사면 하나더 1+1

  1+1 오뚜기 생쫄면(2인) 6,980원

  1+1 삼립 하이면 포차우동 각5,980원

  1+1 풀무원 크로엣지 페퍼로니/ 스위트콘&치즈 각9,980원

  1+1 마니커 치킨너겟 골드(1kg) 12,980원

  롯데/신한/NH농협카드 1+1 동원 퀴진
  크리스피 크런치 치킨 3종 각8,980원
  (상품별 상이) ※순살/치킨봉/양념순살
  ※기간 : 9/19(목) ~ 9/22(일) ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1 샘표 토장(1.9kg) 19,900원

  1+1 풀무원 반듯한식
  상온 국물요리 6종 각7,480원
  (상품별 규격 상이) ※교차구매 가능
  ※대파양지 육개장 450g 外 5종

  1+1  서울 유기농 우유(700ml) 4,980원

  1+1 델몬트 드링크 5종 각2,990원

  1+1 풀무원 다논 요거톡 4종 각2,980원

  1+1 삼육 검은콩과 호두 아몬드/삼육 두유 A,B(각 190ml×16입) 16,480/14,450원

  1+1 웅진 하늘보리/옥수수 수염차/광명찾은 결명자차/헛개차 각2,380원

  1+1 허쉬 초콜릿칩 쿠키 2종 각4,790원

  1+1 켈로그 컵시리얼 4종(각 40g) ※교차구매 가능 각1,600원

  1+1 COOWY 망고 큐브 젤리(370g) 9,980원

  2개 이상 구매시 더 싸게 

  2개 이상 구매시 3900원 할인 CJ 육공육 직화후랑크/ CJ 더건강한 베이컨 각5,000원

  2개 이상 구매시 50% CJ 비비고 만두 4종(상품별 상이) 각2,990~3,490원

  2개 이상 구매시 30% 청정원 안주야 5종(상품별 상이) 각5,936~8,330원

  2개 이상 구매시 1000원 할인 청정원 파스타소스 14종(상품별 규격 상이) 각4,980~7,980원

  2개 이상 구매시 50% 서울 인기치즈 6종 각2,290~3,395원
  (상품별 규격 상이)
  ※ 서울 까요까요 딸기/
  샴피뇽 깜보졸라 外 4종

  2개 이상 구매시 20% 풀무원 다논 각5,584원
  그릭요거트 3종(각 90g×8입)
  ※플레인/블루베리/달지않은 플레인

  3개 이상 구매시 10% 코카콜라 캔 번들 7종 각3,087~3,753원
  (215ml×6입/210ml×6입)
  ※ 코카콜라/코카콜라 제로/환타 오렌지/
  환타 제로 파인애플/스프라이트/
  갈아만든 배/갈배 사이다

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  오뚜기 해당상품
  2개 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉧ "을 확인하세요 
  
  롯데 단독
  오뚜기 카레치킨, 열김치우동,
  스파이시마요 포테이토피자, 진짬뽕만두(상품별 규격상이) 7,980~9,980원

  1000원 할인 롯데 의성마늘
  빅그릴비엔나/
  담백한 베이컨 각7,980원
  (300g×2/160g×2)

  고래사 생선살듬뿍 사각어묵(240g) 2,820원

  2+1 삼양 불닭소스 4종 각4,200원
  (상품별 규격상이) ※교차구매 가능
  ※불닭/핵불닭/까르보불닭/불닭마요소스

  1600원 할인 팔도 비빔장 시그니처(380g) 3,980원

  빙그레 요플레 기획 5,980원

  1920원 할인 고래사 빨간꼬치어묵&부산물떡(616g) 7,980원

  동원 소와나무
  순수우유 기획
  (900ml×2입) 3,980원

  L.POINT 4000원 할인 매일 상하 체다슬라이스 치즈 기획(360g) 5,980원

  1180원 할인 코카콜라/코카콜라 제로 각2,300원

  아임리얼 당근/케일 각8,980원

  공구핫딜 크라운 카라멜콘과땅콩/
  카라멜콘메이플 20%중량업 기획 각1,980원

  500원 할인 아미카 감자칩 멀티팩 2종 각3,480원

  L.POINT 6000원 할인 사푸토 스트링치즈 2종 각8,980원

  20% 페레로 누텔라 초코스프레드 4,320/5,600원

  L.POINT 1000원 할인 내추럴 박스
  유기농 NFC
  레몬즙 100%(500ml) 6,990원

  L.POINT 3000원 할인 오늘좋은 다이어트 2종 11,900/14,900원
 
  가을특가 리빙플렉스 리빙플렉스 브랜드 합산
  롯데/신한/NH농협카드
  2만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉣ "을 확인하세요
  ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50% 할인 주방/천연세제 전품목(상품별 상이) 각2,450~6,450원
  ※동일 브랜드간 교차구매 가능 ※일부품목 제외
  
  2개 이상 50% 할인 홈스타/브레프 전품목(상품별 상이) 각2,250~4,900원
  ※일부품목 제외 ※동일 브랜드간 교차구매 가능
  
  2개 이상 50% 할인 액츠 실내건조 용기(3L)/리필(2.2L) 각7,950/4,950원
  
  2개 이상 50% 할인 스카트 물티슈 5종(상품별 상이) 각2,150~2,500원
  
  2개 이상 60% 할인 홈스타 맥스클리너/ 세정티슈 6종(상품별 상이) 각3,920원
  
  2개 이상 20% 할인 울세제 8종(상품별 상이) ※점별 취급상품 상이 각4,400~7,920원
  
  2개 이상 50% 할인 해피바스 메종드퍼퓸
  바디워시(각 900g) 각6,450원
  ※플로럴 그레이스/파우더리 블랑
  ※점별 운영상품 상이
  
  2개 이상 50% 할인 온더바디 리얼모이스처
  바디워시(각 900g) 각4,450원
  ※피오니/웜코튼/코코넛아몬드 향
  ※점별 운영상품 상이
  
  2개 이상 60% 할인 바세린 클렌징 폼(각 150ml) 각3,160원
  ※모이스쳐/딥클린 ※점별 운영상품 상이

  유한킴벌리 행사상품
  3만 5천원 이상 구매시
  글라스쿡 밀폐용기 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※증정장소 : '도와드리겠습니다'센터
  ※롯데마트몰 사은품 증정 제외
  ※구분자 " ㉪ "을 확인하세요 ※사은품 소진시 행사 종료

  2개 이상 50% 할인 유한킴벌리 최대 60종(상품별 상이) 각1,300~11,950원
  ※ 교차구매 가능 ※점별 운영상품 상이
  
  2개 이상 50% 할인 크리넥스
  울트라클린 3겹 각16,100원
  화장지(30m×30롤)
  ※유한킴벌리 사은품 행사 미진행 품목
  
  쏘피 안심숙면팬티
  2만 5천원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉦ "을 확인하세요
  
  2개 이상 50% 할인 쏘피 안심숙면 팬티 12종(상품별 상이) 각4,750~7,950원

  케라시스 브랜드 상품
  2만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉵ "을 확인하세요

  1+1 케라시스 케라틴 본드
  샴푸/트리트먼트 각12,900원
  (각 750ml) ※딥리페어/볼륨
  ※교차구매 가능 ※점별 운영상품 상이

  메디힐 브랜드 상품
  3만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉺ "을 확인하세요  

  할인 메디힐 하이드라 수딩/
  퍼밍 마스크팩(각 20매) 각10,900원
  
  2개 이상 구매시 3500원 닥터그루트 밀도케어/스칼프솔루션 각15,500~25,500원
  총 8종(상품별 상이) ※점별 운영상품 상이 

  가그린 오리지널/제로/스트롱 각7,900원

  신상품 런칭 기념 1개 이상 구매시
  세타필 미니어처 키트 증정!
  ※증정품 한정수량 ※조기소진 시 행사종료

  세타필 페이셜
  수분크림(기획)
  (수분크림 48g
  +바디로션 118ml 증정) 각22,900원
  ※점별 운영상품 상이

  18000원 할인 그린핑거 힘센보습
  인텐시브 2종 각9,900원
  (로션 280ml/크림 300ml)
  ※점별 운영상품 상이

  2개 이상 50% 하기스 맥스드라이/매직컴포트/
  네이처메이드 29종(상품별 상이) 각15,300~26,400원
  ※기간 : 9/19(목)~9/25(수) ※점별 운영상품 상이

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  가을특가 리빙 플렉스
  락앤락 브랜드 저장용품
  (밀폐용기/물병/도시락/보온병)
  5만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※'도와드리겠습니다'센터 증정
  ※구분자 " ㉱ "를 확인하세요

  L.POINT 30% 락앤락 브랜드 저장용품 각1,400~25,130원
  (상품별 상이) ※밀폐용기/물병/도시락/보온병
  ※일부품목 제외(프라이팬, 냄비류) ※행사상품에 한함 ※점별 재고 상이

  1+1 3M 황토숯 고무장갑/다목적 수세미/그물망사 수세미 1+1 기획팩 각3,190~4,990원

  L.POINT 50% 해피콜 코어센서 티타늄 각23,950~32,450원
  IH프라이팬 5종(상품별 상이)
  ※점별 입점상품 상이 ※행사상품에 한함

  30% 프라카(상품별 상이) 각3,430~18,830원

  20% 좋은부탄 캠핑에디션(4P) 5,430원

  40% 룸바이홈 순면침구&
  스칼럽워싱 스프레드 15,540~35,940원
  (S 150×200cm/Q 180×220cm)
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  20% 3M 테이프클리너리필기획(6입)/
  테이프클리너팩(중형/대형) 11,880/11,920/15,390원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  50% 아이리스 시스템 수납함 각15,450원

  푸마/휠라 맨투맨, 조거팬츠 ※입점점에 한함 각39,900/41,900원

  심리스 멀티프/마리엔느 스카프(상품별 상이) 각7,000~16,900원
  
  4000원 할인 네파 슬리퍼 3종(케인/알파/버킷)  15,900~17,800원
 
  리빙 카테고리 상품
  1만 5천원 이상 구매시
  오늘좋은
  장바구니
  (소형) 증정!
  ※오늘좋은 리빙용품에 한함
  (키친웨어/언더웨어/패브릭/수납데코/보수용품)
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1개)
  ※구분자 "♣"를 확인하세요
  ※ 영수증 지참 후
  '도와드리겠습니다'센터에서 수령
  선착순
  2만명
  한정
 
  2개 이상 구매시 50% 오늘좋은 스퀘어 메모리폼 베개 외(상품별 상이) 각9,950~14,950원
  
  30% 오늘좋은 고중량
  세면타월(각 6P) 각17,430원
  ※그레이/라이트브라운/라이트블루
  ※입점점에 한함 ※해당상품에 한함
  ※점별 재고 상이
  
  2개 이상 구매시 30% 오늘좋은 건전지(상품별 상이) 각2,730/6,930원
  
  30% 오늘좋은 4륜 롱바디 박스카트/슬라이딩 박스카트(상품별 상이) 20,930/27,930원
  
  2개 이상 구매시 20% 오늘좋은 순면 긴팔파자마 각23,920원

  토이저러스 30%
  ※기간 : 9/5(목)~9/25(수) ※점별 재고수량 상이
  ※매장에 더많은 행사상품이 준비되어 있습니다

  최대 30% 헬로카봇 80종 각14,000~131,600원

  10000원 할인 코디열쇠로 말하는 신데렐라 미미 옷장 39,900원

  콜리올리 반려동물 식/용품 40% 할인

  20% 콜리올리 깔끔하게 사용하는 1회용 패드 3종(상품별 상이) 각9,520~19,120원 

  40% 콜리올리 말랑한/단단한 슬라이스 5종(각 350g) 각7,740원

  세계맥주 6캔 구매시 12000원 골라담기

  6캔 구매시 세계맥주 골라담기(35종/상품별 용량 상이) 12,000원

  L.POINT 28100원 와일드터키 켄터키 스피릿(750ml) 89,900원

  하이트제로 전용잔 패키지 12,500원

  요리하다 오늘좋은 온가족 먹거리 추천!

  2개 이상 구매시 15% 요리하다 대만식 우육탕면(500g)/
  사천식 우삼겹 마라탕면(565g)/
  중화식 우삼겹짬뽕(565g)/
  중화식 불맛짜장면(660g) 각6,792원

  L.POINT 5000원 요리하다 마라샹궈(598g)/관동식 스키야키(1,025g) 각11,900원

  2+1 오늘좋은 커리케찹(300g)/트러플향마요, 커리마요(각 300m) 각2,990원

  오늘좋은 먹태칩(50g)/옥수수똘뱅이(195g) 1,000/2,000원

  공구핫딜은 롯데마트와 슈퍼가
  공동 기획한
  알뜰 한정 상품 입니다

  공구핫딜 팔도 왕뚜껑 5+1기획(6개입) 5,550원

  공구핫딜 해태 홈런볼 초코 15% 중량업 기획(148g) 3,280원

  공구핫딜 키친타월 8,900원

  공구핫딜 케라시스 염색약 기획 2종 8,900원

  공구핫딜 해피콜 IH 착 프라이팬 16,900원

  공구핫딜 쿤달 퍼퓸 디퓨저 2+1 기획(200ml×2개+500ml) 19,900원

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
    <div>
  롯데마트 매장에서 SNOWPLAN 멤버 전용 스노우 특가 상품을 찾아보세요!
  SNOW PLAN 가을 페스타 9/19(목) ~ 9/30(월)

  롯데마트 GO 앱을 다운받고 가입하셨다면, 스노우플랜 멤버입니다!
  스노우특가란?
  스노우플랜 멤버를 위한 특별할인가로,
  결제시 롯데마트 GO App의
  바코드를 꼭 스캔해주세요
  ※핸드폰번호로 적립시, 스노우특가 적용 불가

   매일 선착순 1천명 한정! 9/19(목)~9/30(월)
  12일간, 매일매일!
  10,000원
  할인쿠폰 증정

  매일 9/19(목)~9/30(월) ※19,26일 제외
  5,000원
  할인쿠폰 증정 5,000
  ※5만원 이상 구매시 사용 가능

  9/19 목 9/20 금 9/21 토 SNOWPLAN 50% 캐나다산 삼겹살/목심 각990원
  (각 100g/냉장/캐나다산 돼지고기)
  ※조기 품절될 수 있습니다 ※1인 2팩 구매제한

  40% + SNOWPLAN 3000원 추가할인 청정원 고추장 2종 각14,760원
  (각 2kg, 찰고추장/태양초 고추장)

  SNOWPLAN 30% 동원 간편한끼 훈제연어 6,930원
  (150g/냉장/연어:칠레산)

  SNOWPLAN 500원 할인 햇 밤 고구마 6,490원
  (1.5kg/박스/국산)

  SNOWPLAN 1000원 할인 스테비아 대추방울 토마토/
  대추방울스윗마토 각4,990원
  (각 500g/팩/원산지 별도표기)

  SNOWPLAN 500원 할인 동원 통그릴비엔나(300g×2) 7,480원

  1200원 할인 + SNOWPLAN 500원 추가할인 동원 소화가 잘되는 우유(2.3L) 6,180원

  SNOWPLAN 5% + 롯데/신한/NH농협카드 10% 추가할인
  동서 카누 미니 3종 각27,189원
  (각 0.9g×150입, 마일드/다크/라이트)
  ※실제 할인적용 순서는 결제 수단에 따라 상이할 수 있습니다
  ※행사카드로 미결제시 최종 할인가는 상이할 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 20% + SNOWPLAN 4020원 추가 할인 란 멘시온 리제르바/
  란 멘시온 그란 리제르바 14,900/19,900원
  (각 750ml)

  SNOWPLAN 10% 립케어/바디보습케어 43종
  (상품별 상이) ※점별 운영상품 상이
  ※바세린/니베아/뉴트로지나 外

  SNOWPLAN 3000원 할인 다우니 섬유유연제 용기 각9,900원
  (각 2L, 핑크/퍼플/블루)
  ※상품 패키지 점별 상이

  20% + SNOWPLAN 2000원 추가 할인 3M 테이프클리너 12,520원
  롱핸들팩(대형)
  (핸들1P+리필 1P) ※입점점에 한함
  ※해당상품에 한함 ※점별 재고 상이

  2개 이상 구매시 50% + SNOWPLAN 10% 추가 할인 네오플렘 퓨어락 밀폐용기 (상품별 상이) 각1,980~5,580원

   1+1 요리하다 날개살로만 훈제오리(350g/냉장/국내산) 13,900원

  물가안정 제주갈치 2,900원
  (마리/해동/국산)
  ※기간 : 9/19(목)~9/22(일)

  가지(3입/봉/국산) 2,790원

  제주 황토밭 하우스 감귤 7,990원
  
  1+1 오뚜기 후랑크 핫도그/삼립 그릭슈바인 핫도그 8,980/6,980원

  1+1 씨제이 햇반 곤약밥 4종(각 150g×3입) ※교차구매 가능 각8,980원

  1+1 상온/냉장 커피음료 71종 각1,400~3,980원
  
  롯데/신한/NH농협카드 2000원 나무야 깨끗한
  무향 3겹 화장지(27m×30롤) 9,900원
  ※페이지 하단 카드할인 세부내용 참고

  히말라야 핑크솔트 센서티브 칫솔(4입) 6,990원

  롯데/신한/NH농협카드 1+1 퍼실 기능성 용기/리필 30,900/20,900원
  (상품별 상이, 실내건조/하이진젤/그린파워)
  ※용기/리필 각 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
  이번주 핫프라이스

  요리 해방 치트키!
  쉽게 즐기는 간편 요리 '특가'

  냉동 밀키트/냉동 국탕 90종
  (상품별 상이)
  롯데/신한/NH농협카드 2개 이상 구매시 50% 각2,975~9,490원
  ※1개 구매시 각 5,950~18,980원 ※교차구매 가능 ※기간 : 9/19(목) ~ 9/25(수) ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
  </div>
  `,
  `
  <div>
  L.POINT 30% 한우 구이류(1등급)
  각 100g/냉장/국내산 한우고기

  L.POINT 30% 미국산 우삼겹(업진살)/
  차돌박이/부채살(탑블레이드)
  품목별 규격 상이/냉동/미국산

  2개 이상 구매시 1000원 할인 추억의 한입 삼겹살/목심 각8,900원

  2개 이상 구매시 50% 할인 요리하다 흑돼지 불고기 각10,450원

  L.POINT 20% + 롯데/신한/NH농협카드 10% 서해안 햇꽃게 972원

  L.POINT 35% 내가 만드는 연어(횟감용/구이용) 3,770/3,640원

  절단 낙지 1,090원

  손질 오징어 2,750원

  8개 구매시 제스프리 골드키위 9,920원

  청양 고추 3,990원

  데일리 믹스 샐러드 3,990원

  고단백 혼합 15곡 8,990원

  1+1 CJ 명가김 재래/파래김 각7,980원

  롯데/신한/NH농협카드 20% 소떡소떡 꼬치(1개)/
  타코야끼(1팩) ※원산지 별도표기 1,992/3,992원
  ※페이지 하단 카드할인 세부내용 참고

  큰 치킨 10,990원

  큰 초밥 14,990원

  김밥&유부&롤 한판세트 10,990원

  1500원 할인 올리브 치아바타(4입/원산지 별도표기) 5,900원

  하나사면 하나더 1+1

  1+1 오뚜기 생쫄면(2인) 6,980원

  1+1 삼립 하이면 포차우동 각5,980원

  1+1 풀무원 크로엣지 페퍼로니/ 스위트콘&치즈 각9,980원

  1+1 마니커 치킨너겟 골드(1kg) 12,980원

  롯데/신한/NH농협카드 1+1 동원 퀴진
  크리스피 크런치 치킨 3종 각8,980원
  (상품별 상이) ※순살/치킨봉/양념순살
  ※기간 : 9/19(목) ~ 9/22(일) ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1 샘표 토장(1.9kg) 19,900원

  1+1 풀무원 반듯한식
  상온 국물요리 6종 각7,480원
  (상품별 규격 상이) ※교차구매 가능
  ※대파양지 육개장 450g 外 5종

  1+1  서울 유기농 우유(700ml) 4,980원

  1+1 델몬트 드링크 5종 각2,990원

  1+1 풀무원 다논 요거톡 4종 각2,980원

  1+1 삼육 검은콩과 호두 아몬드/삼육 두유 A,B(각 190ml×16입) 16,480/14,450원

  1+1 웅진 하늘보리/옥수수 수염차/광명찾은 결명자차/헛개차 각2,380원

  1+1 허쉬 초콜릿칩 쿠키 2종 각4,790원

  1+1 켈로그 컵시리얼 4종(각 40g) ※교차구매 가능 각1,600원

  1+1 COOWY 망고 큐브 젤리(370g) 9,980원

  2개 이상 구매시 더 싸게 

  2개 이상 구매시 3900원 할인 CJ 육공육 직화후랑크/ CJ 더건강한 베이컨 각5,000원

  2개 이상 구매시 50% CJ 비비고 만두 4종(상품별 상이) 각2,990~3,490원

  2개 이상 구매시 30% 청정원 안주야 5종(상품별 상이) 각5,936~8,330원

  2개 이상 구매시 1000원 할인 청정원 파스타소스 14종(상품별 규격 상이) 각4,980~7,980원

  2개 이상 구매시 50% 서울 인기치즈 6종 각2,290~3,395원
  (상품별 규격 상이)
  ※ 서울 까요까요 딸기/
  샴피뇽 깜보졸라 外 4종

  2개 이상 구매시 20% 풀무원 다논 각5,584원
  그릭요거트 3종(각 90g×8입)
  ※플레인/블루베리/달지않은 플레인

  3개 이상 구매시 10% 코카콜라 캔 번들 7종 각3,087~3,753원
  (215ml×6입/210ml×6입)
  ※ 코카콜라/코카콜라 제로/환타 오렌지/
  환타 제로 파인애플/스프라이트/
  갈아만든 배/갈배 사이다

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  오뚜기 해당상품
  2개 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉧ "을 확인하세요 
  
  롯데 단독
  오뚜기 카레치킨, 열김치우동,
  스파이시마요 포테이토피자, 진짬뽕만두(상품별 규격상이) 7,980~9,980원

  1000원 할인 롯데 의성마늘
  빅그릴비엔나/
  담백한 베이컨 각7,980원
  (300g×2/160g×2)

  고래사 생선살듬뿍 사각어묵(240g) 2,820원

  2+1 삼양 불닭소스 4종 각4,200원
  (상품별 규격상이) ※교차구매 가능
  ※불닭/핵불닭/까르보불닭/불닭마요소스

  1600원 할인 팔도 비빔장 시그니처(380g) 3,980원

  빙그레 요플레 기획 5,980원

  1920원 할인 고래사 빨간꼬치어묵&부산물떡(616g) 7,980원

  동원 소와나무
  순수우유 기획
  (900ml×2입) 3,980원

  L.POINT 4000원 할인 매일 상하 체다슬라이스 치즈 기획(360g) 5,980원

  1180원 할인 코카콜라/코카콜라 제로 각2,300원

  아임리얼 당근/케일 각8,980원

  공구핫딜 크라운 카라멜콘과땅콩/
  카라멜콘메이플 20%중량업 기획 각1,980원

  500원 할인 아미카 감자칩 멀티팩 2종 각3,480원

  L.POINT 6000원 할인 사푸토 스트링치즈 2종 각8,980원

  20% 페레로 누텔라 초코스프레드 4,320/5,600원

  L.POINT 1000원 할인 내추럴 박스
  유기농 NFC
  레몬즙 100%(500ml) 6,990원

  L.POINT 3000원 할인 오늘좋은 다이어트 2종 11,900/14,900원
 
  가을특가 리빙플렉스 리빙플렉스 브랜드 합산
  롯데/신한/NH농협카드
  2만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉣ "을 확인하세요
  ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50% 할인 주방/천연세제 전품목(상품별 상이) 각2,450~6,450원
  ※동일 브랜드간 교차구매 가능 ※일부품목 제외
  
  2개 이상 50% 할인 홈스타/브레프 전품목(상품별 상이) 각2,250~4,900원
  ※일부품목 제외 ※동일 브랜드간 교차구매 가능
  
  2개 이상 50% 할인 액츠 실내건조 용기(3L)/리필(2.2L) 각7,950/4,950원
  
  2개 이상 50% 할인 스카트 물티슈 5종(상품별 상이) 각2,150~2,500원
  
  2개 이상 60% 할인 홈스타 맥스클리너/ 세정티슈 6종(상품별 상이) 각3,920원
  
  2개 이상 20% 할인 울세제 8종(상품별 상이) ※점별 취급상품 상이 각4,400~7,920원
  
  2개 이상 50% 할인 해피바스 메종드퍼퓸
  바디워시(각 900g) 각6,450원
  ※플로럴 그레이스/파우더리 블랑
  ※점별 운영상품 상이
  
  2개 이상 50% 할인 온더바디 리얼모이스처
  바디워시(각 900g) 각4,450원
  ※피오니/웜코튼/코코넛아몬드 향
  ※점별 운영상품 상이
  
  2개 이상 60% 할인 바세린 클렌징 폼(각 150ml) 각3,160원
  ※모이스쳐/딥클린 ※점별 운영상품 상이

  유한킴벌리 행사상품
  3만 5천원 이상 구매시
  글라스쿡 밀폐용기 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※증정장소 : '도와드리겠습니다'센터
  ※롯데마트몰 사은품 증정 제외
  ※구분자 " ㉪ "을 확인하세요 ※사은품 소진시 행사 종료

  2개 이상 50% 할인 유한킴벌리 최대 60종(상품별 상이) 각1,300~11,950원
  ※ 교차구매 가능 ※점별 운영상품 상이
  
  2개 이상 50% 할인 크리넥스
  울트라클린 3겹 각16,100원
  화장지(30m×30롤)
  ※유한킴벌리 사은품 행사 미진행 품목
  
  쏘피 안심숙면팬티
  2만 5천원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉦ "을 확인하세요
  
  2개 이상 50% 할인 쏘피 안심숙면 팬티 12종(상품별 상이) 각4,750~7,950원

  케라시스 브랜드 상품
  2만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉵ "을 확인하세요

  1+1 케라시스 케라틴 본드
  샴푸/트리트먼트 각12,900원
  (각 750ml) ※딥리페어/볼륨
  ※교차구매 가능 ※점별 운영상품 상이

  메디힐 브랜드 상품
  3만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉺ "을 확인하세요  

  할인 메디힐 하이드라 수딩/
  퍼밍 마스크팩(각 20매) 각10,900원
  
  2개 이상 구매시 3500원 닥터그루트 밀도케어/스칼프솔루션 각15,500~25,500원
  총 8종(상품별 상이) ※점별 운영상품 상이 

  가그린 오리지널/제로/스트롱 각7,900원

  신상품 런칭 기념 1개 이상 구매시
  세타필 미니어처 키트 증정!
  ※증정품 한정수량 ※조기소진 시 행사종료

  세타필 페이셜
  수분크림(기획)
  (수분크림 48g
  +바디로션 118ml 증정) 각22,900원
  ※점별 운영상품 상이

  18000원 할인 그린핑거 힘센보습
  인텐시브 2종 각9,900원
  (로션 280ml/크림 300ml)
  ※점별 운영상품 상이

  2개 이상 50% 하기스 맥스드라이/매직컴포트/
  네이처메이드 29종(상품별 상이) 각15,300~26,400원
  ※기간 : 9/19(목)~9/25(수) ※점별 운영상품 상이

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  가을특가 리빙 플렉스
  락앤락 브랜드 저장용품
  (밀폐용기/물병/도시락/보온병)
  5만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※'도와드리겠습니다'센터 증정
  ※구분자 " ㉱ "를 확인하세요

  L.POINT 30% 락앤락 브랜드 저장용품 각1,400~25,130원
  (상품별 상이) ※밀폐용기/물병/도시락/보온병
  ※일부품목 제외(프라이팬, 냄비류) ※행사상품에 한함 ※점별 재고 상이

  1+1 3M 황토숯 고무장갑/다목적 수세미/그물망사 수세미 1+1 기획팩 각3,190~4,990원

  L.POINT 50% 해피콜 코어센서 티타늄 각23,950~32,450원
  IH프라이팬 5종(상품별 상이)
  ※점별 입점상품 상이 ※행사상품에 한함

  30% 프라카(상품별 상이) 각3,430~18,830원

  20% 좋은부탄 캠핑에디션(4P) 5,430원

  40% 룸바이홈 순면침구&
  스칼럽워싱 스프레드 15,540~35,940원
  (S 150×200cm/Q 180×220cm)
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  20% 3M 테이프클리너리필기획(6입)/
  테이프클리너팩(중형/대형) 11,880/11,920/15,390원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  50% 아이리스 시스템 수납함 각15,450원

  푸마/휠라 맨투맨, 조거팬츠 ※입점점에 한함 각39,900/41,900원

  심리스 멀티프/마리엔느 스카프(상품별 상이) 각7,000~16,900원
  
  4000원 할인 네파 슬리퍼 3종(케인/알파/버킷)  15,900~17,800원
 
  리빙 카테고리 상품
  1만 5천원 이상 구매시
  오늘좋은
  장바구니
  (소형) 증정!
  ※오늘좋은 리빙용품에 한함
  (키친웨어/언더웨어/패브릭/수납데코/보수용품)
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1개)
  ※구분자 "♣"를 확인하세요
  ※ 영수증 지참 후
  '도와드리겠습니다'센터에서 수령
  선착순
  2만명
  한정
 
  2개 이상 구매시 50% 오늘좋은 스퀘어 메모리폼 베개 외(상품별 상이) 각9,950~14,950원
  
  30% 오늘좋은 고중량
  세면타월(각 6P) 각17,430원
  ※그레이/라이트브라운/라이트블루
  ※입점점에 한함 ※해당상품에 한함
  ※점별 재고 상이
  
  2개 이상 구매시 30% 오늘좋은 건전지(상품별 상이) 각2,730/6,930원
  
  30% 오늘좋은 4륜 롱바디 박스카트/슬라이딩 박스카트(상품별 상이) 20,930/27,930원
  
  2개 이상 구매시 20% 오늘좋은 순면 긴팔파자마 각23,920원

  토이저러스 30%
  ※기간 : 9/5(목)~9/25(수) ※점별 재고수량 상이
  ※매장에 더많은 행사상품이 준비되어 있습니다

  최대 30% 헬로카봇 80종 각14,000~131,600원

  10000원 할인 코디열쇠로 말하는 신데렐라 미미 옷장 39,900원

  콜리올리 반려동물 식/용품 40% 할인

  20% 콜리올리 깔끔하게 사용하는 1회용 패드 3종(상품별 상이) 각9,520~19,120원 

  40% 콜리올리 말랑한/단단한 슬라이스 5종(각 350g) 각7,740원

  "Move People Through the wonders of sport"
  스포츠의 경이로움으로 사람들을 움직인다
  세상의 모든 스포츠 데카트론

  40000원  남성용 방수 중목 등산화(MH500) 89,900원

  70000원 남성용 3in1 자켓 99,000원

  28000원 3인용 프레쉬 텐트 109,000원

  11000원 3인용 원터치 암막텐트 169,000원

  3100원 아동 핑크 캐릭터 인라인 스케이트 79,900원
 
  56000원 28인치 성인 하이브리드 자전거 399,000원

  요리하다 오늘좋은 온가족 먹거리 추천!

  2개 이상 구매시 15% 요리하다 대만식 우육탕면(500g)/
  사천식 우삼겹 마라탕면(565g)/
  중화식 우삼겹짬뽕(565g)/
  중화식 불맛짜장면(660g) 각6,792원

  L.POINT 5000원 요리하다 마라샹궈(598g)/관동식 스키야키(1,025g) 각11,900원

  2+1 오늘좋은 커리케찹(300g)/트러플향마요, 커리마요(각 300m) 각2,990원

  오늘좋은 먹태칩(50g)/옥수수똘뱅이(195g) 1,000/2,000원

  공구핫딜은 롯데마트와 슈퍼가
  공동 기획한
  알뜰 한정 상품 입니다

  공구핫딜 팔도 왕뚜껑 5+1기획(6개입) 5,550원

  공구핫딜 해태 홈런볼 초코 15% 중량업 기획(148g) 3,280원

  공구핫딜 키친타월 8,900원

  공구핫딜 케라시스 염색약 기획 2종 8,900원

  공구핫딜 해피콜 IH 착 프라이팬 16,900원

  공구핫딜 쿤달 퍼퓸 디퓨저 2+1 기획(200ml×2개+500ml) 19,900원

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
    <div>
  롯데마트 매장에서 SNOWPLAN 멤버 전용 스노우 특가 상품을 찾아보세요!
  SNOW PLAN 가을 페스타 9/19(목) ~ 9/30(월)

  롯데마트 GO 앱을 다운받고 가입하셨다면, 스노우플랜 멤버입니다!
  스노우특가란?
  스노우플랜 멤버를 위한 특별할인가로,
  결제시 롯데마트 GO App의
  바코드를 꼭 스캔해주세요
  ※핸드폰번호로 적립시, 스노우특가 적용 불가

   매일 선착순 1천명 한정! 9/19(목)~9/30(월)
  12일간, 매일매일!
  10,000원
  할인쿠폰 증정

  매일 9/19(목)~9/30(월) ※19,26일 제외
  5,000원
  할인쿠폰 증정 5,000
  ※5만원 이상 구매시 사용 가능

  9/19 목 9/20 금 9/21 토 SNOWPLAN 50% 캐나다산 삼겹살/목심 각990원
  (각 100g/냉장/캐나다산 돼지고기)
  ※조기 품절될 수 있습니다 ※1인 2팩 구매제한

  40% + SNOWPLAN 3000원 추가할인 청정원 고추장 2종 각14,760원
  (각 2kg, 찰고추장/태양초 고추장)

  SNOWPLAN 30% 동원 간편한끼 훈제연어 6,930원
  (150g/냉장/연어:칠레산)

  SNOWPLAN 500원 할인 햇 밤 고구마 6,490원
  (1.5kg/박스/국산)

  SNOWPLAN 1000원 할인 스테비아 대추방울 토마토/
  대추방울스윗마토 각4,990원
  (각 500g/팩/원산지 별도표기)

  SNOWPLAN 500원 할인 동원 통그릴비엔나(300g×2) 7,480원

  1200원 할인 + SNOWPLAN 500원 추가할인 동원 소화가 잘되는 우유(2.3L) 6,180원

  SNOWPLAN 5% + 롯데/신한/NH농협카드 10% 추가할인
  동서 카누 미니 3종 각27,189원
  (각 0.9g×150입, 마일드/다크/라이트)
  ※실제 할인적용 순서는 결제 수단에 따라 상이할 수 있습니다
  ※행사카드로 미결제시 최종 할인가는 상이할 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 20% + SNOWPLAN 4020원 추가 할인 란 멘시온 리제르바/
  란 멘시온 그란 리제르바 14,900/19,900원
  (각 750ml)

  SNOWPLAN 10% 립케어/바디보습케어 43종
  (상품별 상이) ※점별 운영상품 상이
  ※바세린/니베아/뉴트로지나 外

  SNOWPLAN 3000원 할인 다우니 섬유유연제 용기 각9,900원
  (각 2L, 핑크/퍼플/블루)
  ※상품 패키지 점별 상이

  20% + SNOWPLAN 2000원 추가 할인 3M 테이프클리너 12,520원
  롱핸들팩(대형)
  (핸들1P+리필 1P) ※입점점에 한함
  ※해당상품에 한함 ※점별 재고 상이

  2개 이상 구매시 50% + SNOWPLAN 10% 추가 할인 네오플렘 퓨어락 밀폐용기 (상품별 상이) 각1,980~5,580원

   1+1 요리하다 날개살로만 훈제오리(350g/냉장/국내산) 13,900원

  물가안정 제주갈치 2,900원
  (마리/해동/국산)
  ※기간 : 9/19(목)~9/22(일)

  가지(3입/봉/국산) 2,790원

  제주 황토밭 하우스 감귤 7,990원
  
  1+1 오뚜기 후랑크 핫도그/삼립 그릭슈바인 핫도그 8,980/6,980원

  1+1 씨제이 햇반 곤약밥 4종(각 150g×3입) ※교차구매 가능 각8,980원

  1+1 상온/냉장 커피음료 71종 각1,400~3,980원
  
  롯데/신한/NH농협카드 2000원 나무야 깨끗한
  무향 3겹 화장지(27m×30롤) 9,900원
  ※페이지 하단 카드할인 세부내용 참고

  히말라야 핑크솔트 센서티브 칫솔(4입) 6,990원

  롯데/신한/NH농협카드 1+1 퍼실 기능성 용기/리필 30,900/20,900원
  (상품별 상이, 실내건조/하이진젤/그린파워)
  ※용기/리필 각 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원 합니다.
  이번주 핫프라이스

  요리 해방 치트키!
  쉽게 즐기는 간편 요리 '특가'

  냉동 밀키트/냉동 국탕 90종
  (상품별 상이)
  롯데/신한/NH농협카드 2개 이상 구매시 50% 각2,975~9,490원
  ※1개 구매시 각 5,950~18,980원 ※교차구매 가능 ※기간 : 9/19(목) ~ 9/25(수) ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
  </div>
  `,
  `
  <div>
  L.POINT 30% 한우 구이류(1등급)
  각 100g/냉장/국내산 한우고기

  L.POINT 30% 미국산 우삼겹(업진살)/
  차돌박이/부채살(탑블레이드)
  품목별 규격 상이/냉동/미국산

  2개 이상 구매시 1000원 할인 추억의 한입 삼겹살/목심 각8,900원

  2개 이상 구매시 50% 할인 요리하다 흑돼지 불고기 각10,450원

  L.POINT 20% + 롯데/신한/NH농협카드 10% 서해안 햇꽃게 972원

  L.POINT 35% 내가 만드는 연어(횟감용/구이용) 3,770/3,640원

  절단 낙지 1,090원

  손질 오징어 2,750원

  8개 구매시 제스프리 골드키위 9,920원

  청양 고추 3,990원

  데일리 믹스 샐러드 3,990원

  고단백 혼합 15곡 8,990원

  1+1 CJ 명가김 재래/파래김 각7,980원

  롯데/신한/NH농협카드 20% 소떡소떡 꼬치(1개)/
  타코야끼(1팩) ※원산지 별도표기 1,992/3,992원
  ※페이지 하단 카드할인 세부내용 참고

  큰 치킨 10,990원

  큰 초밥 14,990원

  김밥&유부&롤 한판세트 10,990원

  1500원 할인 올리브 치아바타(4입/원산지 별도표기) 5,900원

  하나사면 하나더 1+1

  1+1 오뚜기 생쫄면(2인) 6,980원

  1+1 삼립 하이면 포차우동 각5,980원

  1+1 풀무원 크로엣지 페퍼로니/ 스위트콘&치즈 각9,980원

  1+1 마니커 치킨너겟 골드(1kg) 12,980원

  롯데/신한/NH농협카드 1+1 동원 퀴진
  크리스피 크런치 치킨 3종 각8,980원
  (상품별 상이) ※순살/치킨봉/양념순살
  ※기간 : 9/19(목) ~ 9/22(일) ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1 샘표 토장(1.9kg) 19,900원

  1+1 풀무원 반듯한식
  상온 국물요리 6종 각7,480원
  (상품별 규격 상이) ※교차구매 가능
  ※대파양지 육개장 450g 外 5종

  1+1  서울 유기농 우유(700ml) 4,980원

  1+1 델몬트 드링크 5종 각2,990원

  1+1 풀무원 다논 요거톡 4종 각2,980원

  1+1 삼육 검은콩과 호두 아몬드/삼육 두유 A,B(각 190ml×16입) 16,480/14,450원

  1+1 웅진 하늘보리/옥수수 수염차/광명찾은 결명자차/헛개차 각2,380원

  1+1 허쉬 초콜릿칩 쿠키 2종 각4,790원

  1+1 켈로그 컵시리얼 4종(각 40g) ※교차구매 가능 각1,600원

  1+1 COOWY 망고 큐브 젤리(370g) 9,980원

  2개 이상 구매시 더 싸게 

  2개 이상 구매시 3900원 할인 CJ 육공육 직화후랑크/ CJ 더건강한 베이컨 각5,000원

  2개 이상 구매시 50% CJ 비비고 만두 4종(상품별 상이) 각2,990~3,490원

  2개 이상 구매시 30% 청정원 안주야 5종(상품별 상이) 각5,936~8,330원

  2개 이상 구매시 1000원 할인 청정원 파스타소스 14종(상품별 규격 상이) 각4,980~7,980원

  2개 이상 구매시 50% 서울 인기치즈 6종 각2,290~3,395원
  (상품별 규격 상이)
  ※ 서울 까요까요 딸기/
  샴피뇽 깜보졸라 外 4종

  2개 이상 구매시 20% 풀무원 다논 각5,584원
  그릭요거트 3종(각 90g×8입)
  ※플레인/블루베리/달지않은 플레인

  3개 이상 구매시 10% 코카콜라 캔 번들 7종 각3,087~3,753원
  (215ml×6입/210ml×6입)
  ※ 코카콜라/코카콜라 제로/환타 오렌지/
  환타 제로 파인애플/스프라이트/
  갈아만든 배/갈배 사이다

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  오뚜기 해당상품
  2개 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉧ "을 확인하세요 
  
  롯데 단독
  오뚜기 카레치킨, 열김치우동,
  스파이시마요 포테이토피자, 진짬뽕만두(상품별 규격상이) 7,980~9,980원

  1000원 할인 롯데 의성마늘
  빅그릴비엔나/
  담백한 베이컨 각7,980원
  (300g×2/160g×2)

  고래사 생선살듬뿍 사각어묵(240g) 2,820원

  2+1 삼양 불닭소스 4종 각4,200원
  (상품별 규격상이) ※교차구매 가능
  ※불닭/핵불닭/까르보불닭/불닭마요소스

  1600원 할인 팔도 비빔장 시그니처(380g) 3,980원

  빙그레 요플레 기획 5,980원

  1920원 할인 고래사 빨간꼬치어묵&부산물떡(616g) 7,980원

  동원 소와나무
  순수우유 기획
  (900ml×2입) 3,980원

  L.POINT 4000원 할인 매일 상하 체다슬라이스 치즈 기획(360g) 5,980원

  1180원 할인 코카콜라/코카콜라 제로 각2,300원

  아임리얼 당근/케일 각8,980원

  공구핫딜 크라운 카라멜콘과땅콩/
  카라멜콘메이플 20%중량업 기획 각1,980원

  500원 할인 아미카 감자칩 멀티팩 2종 각3,480원

  L.POINT 6000원 할인 사푸토 스트링치즈 2종 각8,980원

  20% 페레로 누텔라 초코스프레드 4,320/5,600원

  L.POINT 1000원 할인 내추럴 박스
  유기농 NFC
  레몬즙 100%(500ml) 6,990원

  L.POINT 3000원 할인 오늘좋은 다이어트 2종 11,900/14,900원
 
  가을특가 리빙플렉스 리빙플렉스 브랜드 합산
  롯데/신한/NH농협카드
  2만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉣ "을 확인하세요
  ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50% 할인 주방/천연세제 전품목(상품별 상이) 각2,450~6,450원
  ※동일 브랜드간 교차구매 가능 ※일부품목 제외
  
  2개 이상 50% 할인 홈스타/브레프 전품목(상품별 상이) 각2,250~4,900원
  ※일부품목 제외 ※동일 브랜드간 교차구매 가능
  
  2개 이상 50% 할인 액츠 실내건조 용기(3L)/리필(2.2L) 각7,950/4,950원
  
  2개 이상 50% 할인 스카트 물티슈 5종(상품별 상이) 각2,150~2,500원
  
  2개 이상 60% 할인 홈스타 맥스클리너/ 세정티슈 6종(상품별 상이) 각3,920원
  
  2개 이상 20% 할인 울세제 8종(상품별 상이) ※점별 취급상품 상이 각4,400~7,920원
  
  2개 이상 50% 할인 해피바스 메종드퍼퓸
  바디워시(각 900g) 각6,450원
  ※플로럴 그레이스/파우더리 블랑
  ※점별 운영상품 상이
  
  2개 이상 50% 할인 온더바디 리얼모이스처
  바디워시(각 900g) 각4,450원
  ※피오니/웜코튼/코코넛아몬드 향
  ※점별 운영상품 상이
  
  2개 이상 60% 할인 바세린 클렌징 폼(각 150ml) 각3,160원
  ※모이스쳐/딥클린 ※점별 운영상품 상이

  유한킴벌리 행사상품
  3만 5천원 이상 구매시
  글라스쿡 밀폐용기 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※증정장소 : '도와드리겠습니다'센터
  ※롯데마트몰 사은품 증정 제외
  ※구분자 " ㉪ "을 확인하세요 ※사은품 소진시 행사 종료

  2개 이상 50% 할인 유한킴벌리 최대 60종(상품별 상이) 각1,300~11,950원
  ※ 교차구매 가능 ※점별 운영상품 상이
  
  2개 이상 50% 할인 크리넥스
  울트라클린 3겹 각16,100원
  화장지(30m×30롤)
  ※유한킴벌리 사은품 행사 미진행 품목
  
  쏘피 안심숙면팬티
  2만 5천원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉦ "을 확인하세요
  
  2개 이상 50% 할인 쏘피 안심숙면 팬티 12종(상품별 상이) 각4,750~7,950원

  케라시스 브랜드 상품
  2만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉵ "을 확인하세요

  1+1 케라시스 케라틴 본드
  샴푸/트리트먼트 각12,900원
  (각 750ml) ※딥리페어/볼륨
  ※교차구매 가능 ※점별 운영상품 상이

  메디힐 브랜드 상품
  3만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※결제시 계산대에서 수령
  ※구분자 " ㉺ "을 확인하세요  

  할인 메디힐 하이드라 수딩/
  퍼밍 마스크팩(각 20매) 각10,900원
  
  2개 이상 구매시 3500원 닥터그루트 밀도케어/스칼프솔루션 각15,500~25,500원
  총 8종(상품별 상이) ※점별 운영상품 상이 

  가그린 오리지널/제로/스트롱 각7,900원

  신상품 런칭 기념 1개 이상 구매시
  세타필 미니어처 키트 증정!
  ※증정품 한정수량 ※조기소진 시 행사종료

  세타필 페이셜
  수분크림(기획)
  (수분크림 48g
  +바디로션 118ml 증정) 각22,900원
  ※점별 운영상품 상이

  18000원 할인 그린핑거 힘센보습
  인텐시브 2종 각9,900원
  (로션 280ml/크림 300ml)
  ※점별 운영상품 상이

  2개 이상 50% 하기스 맥스드라이/매직컴포트/
  네이처메이드 29종(상품별 상이) 각15,300~26,400원
  ※기간 : 9/19(목)~9/25(수) ※점별 운영상품 상이

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  가을특가 리빙 플렉스
  락앤락 브랜드 저장용품
  (밀폐용기/물병/도시락/보온병)
  5만원 이상 구매시
  5,000원
  롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※'도와드리겠습니다'센터 증정
  ※구분자 " ㉱ "를 확인하세요

  L.POINT 30% 락앤락 브랜드 저장용품 각1,400~25,130원
  (상품별 상이) ※밀폐용기/물병/도시락/보온병
  ※일부품목 제외(프라이팬, 냄비류) ※행사상품에 한함 ※점별 재고 상이

  1+1 3M 황토숯 고무장갑/다목적 수세미/그물망사 수세미 1+1 기획팩 각3,190~4,990원

  L.POINT 50% 해피콜 코어센서 티타늄 각23,950~32,450원
  IH프라이팬 5종(상품별 상이)
  ※점별 입점상품 상이 ※행사상품에 한함

  30% 프라카(상품별 상이) 각3,430~18,830원

  20% 좋은부탄 캠핑에디션(4P) 5,430원

  40% 룸바이홈 순면침구&
  스칼럽워싱 스프레드 15,540~35,940원
  (S 150×200cm/Q 180×220cm)
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  20% 3M 테이프클리너리필기획(6입)/
  테이프클리너팩(중형/대형) 11,880/11,920/15,390원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  50% 아이리스 시스템 수납함 각15,450원

  푸마/휠라 맨투맨, 조거팬츠 ※입점점에 한함 각39,900/41,900원

  심리스 멀티프/마리엔느 스카프(상품별 상이) 각7,000~16,900원
  
  4000원 할인 네파 슬리퍼 3종(케인/알파/버킷)  15,900~17,800원
 
  리빙 카테고리 상품
  1만 5천원 이상 구매시
  오늘좋은
  장바구니
  (소형) 증정!
  ※오늘좋은 리빙용품에 한함
  (키친웨어/언더웨어/패브릭/수납데코/보수용품)
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1개)
  ※구분자 "♣"를 확인하세요
  ※ 영수증 지참 후
  '도와드리겠습니다'센터에서 수령
  선착순
  2만명
  한정
 
  2개 이상 구매시 50% 오늘좋은 스퀘어 메모리폼 베개 외(상품별 상이) 각9,950~14,950원
  
  30% 오늘좋은 고중량
  세면타월(각 6P) 각17,430원
  ※그레이/라이트브라운/라이트블루
  ※입점점에 한함 ※해당상품에 한함
  ※점별 재고 상이
  
  2개 이상 구매시 30% 오늘좋은 건전지(상품별 상이) 각2,730/6,930원
  
  30% 오늘좋은 4륜 롱바디 박스카트/슬라이딩 박스카트(상품별 상이) 20,930/27,930원
  
  2개 이상 구매시 20% 오늘좋은 순면 긴팔파자마 각23,920원

  토이저러스 30%
  ※기간 : 9/5(목)~9/25(수) ※점별 재고수량 상이
  ※매장에 더많은 행사상품이 준비되어 있습니다

  최대 30% 헬로카봇 80종 각14,000~131,600원

  10000원 할인 코디열쇠로 말하는 신데렐라 미미 옷장 39,900원

  콜리올리 반려동물 식/용품 40% 할인

  20% 콜리올리 깔끔하게 사용하는 1회용 패드 3종(상품별 상이) 각9,520~19,120원 

  40% 콜리올리 말랑한/단단한 슬라이스 5종(각 350g) 각7,740원

  세계맥주 6캔 구매시 12000원 골라담기

  6캔 구매시 세계맥주 골라담기(35종/상품별 용량 상이) 12,000원

  L.POINT 28100원 와일드터키 켄터키 스피릿(750ml) 89,900원

  하이트제로 전용잔 패키지 12,500원

  요리하다 오늘좋은 온가족 먹거리 추천!

  2개 이상 구매시 15% 요리하다 대만식 우육탕면(500g)/
  사천식 우삼겹 마라탕면(565g)/
  중화식 우삼겹짬뽕(565g)/
  중화식 불맛짜장면(660g) 각6,792원

  L.POINT 5000원 요리하다 마라샹궈(598g)/관동식 스키야키(1,025g) 각11,900원

  2+1 오늘좋은 커리케찹(300g)/트러플향마요, 커리마요(각 300m) 각2,990원

  오늘좋은 먹태칩(50g)/옥수수똘뱅이(195g) 1,000/2,000원

  공구핫딜은 롯데마트와 슈퍼가
  공동 기획한
  알뜰 한정 상품 입니다

  공구핫딜 팔도 왕뚜껑 5+1기획(6개입) 5,550원

  공구핫딜 해태 홈런볼 초코 15% 중량업 기획(148g) 3,280원

  공구핫딜 키친타월 8,900원

  공구핫딜 케라시스 염색약 기획 2종 8,900원

  공구핫딜 해피콜 IH 착 프라이팬 16,900원

  공구핫딜 쿤달 퍼퓸 디퓨저 2+1 기획(200ml×2개+500ml) 19,900원

  ※정상가란? 2024년 8월 29일(목) ~ 9월 18일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202409_3";
const category = [];

const data = {
  L301: {
    title: "스마트전단지-전점301",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L302: {
    title: "스마트전단지-전점302",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L303: {
    title: "스마트전단지-제타플렉스 잠실점303",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L304: {
    title: "스마트전단지-제타플렉스 잠실점304",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L305: {
    title: "스마트전단지-제타플렉스 서울역점305",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L306: {
    title: "스마트전단지-제타플렉스 서울역점306",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
