//링크
export const LEAFLET_URL = {
  a1: "https://www.lotteon.com/p/display/shop/seltDpShop/12919?mall_no=4",
  a2: "http://toysrus.lottemart.com",
  chu2020: "https://www.lotteon.com/p/display/shop/seltDpShop/29638",
  main: "http://www.lottemart.com",
};

export const IMAGE_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet`;

/* 지난 전단지 reload */
export const leafletState = {
  // openDate는 오픈날짜로 변경(매주 목요일)
  MART: {
    openDate: "20240918",
    thisWeek: [
      201, //전점 GO(~9/18)
      203, //제타플렉스 GO(~9/18)
      718, //맥스 GO(~9/18)

      903, //전점 추석 사전예약(~9/6)
      793, //맥스 추석 사전예약(~9/6)
      910, //슈퍼 추석 사전예약(~9/6)
      905, //전점 본세트(~9/17)
      809, //슈퍼 본세트(~9/17)
      791, //맥스 본세트(~9/17)
      111, //원주점(~9/18)
      221, //원주점(~9/18)

      ////////////////////////////////////
      202, //전점 LMS(~9/18)
      204, //제타플렉스 LMS(~9/18)
      719, //맥스 LMS(~9/18)

      904, //전점 추석 사전예약 LMS(~9/6)
      794, //맥스 추석 사전예약 LMS(~9/6)
      909, //슈퍼 추석 사전예약 LMS(~9/6)
      920, //슈퍼(백)일산점 추석 사전예약 LMS(~9/6)
      906, //전점 본세트 LMS(~9/17)
      810, //슈퍼 본세트 LMS(~9/17)
      792, //맥스 본세트 LMS(~9/17)
      112, //원주점(~9/18)
      222, //원주점(~9/18)

      205, //제타플렉스 서울역점 GO(~9/18)
      206, //제타플렉스 서울역점 LMS(~9/18)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
    willThisWeek: [
      301, //전점 GO(~9/25)
      303, //제타플렉스 GO(~9/25)
      738, //맥스 GO(~10/02)

      903, //전점 추석 사전예약(~9/6)
      793, //맥스 추석 사전예약(~9/6)
      910, //슈퍼 추석 사전예약(~9/6)
      905, //전점 본세트(~9/17)
      809, //슈퍼 본세트(~9/17)
      791, //맥스 본세트(~9/17)
      111, //원주점(~9/18)
      221, //원주점(~9/18)
      331, //원주점(~9/25)
      ////////////////////////////////////
      302, //전점 LMS(~9/25)
      304, //제타플렉스 LMS(~9/25)
      739, //맥스 LMS(~10/02)

      904, //전점 추석 사전예약 LMS(~9/6)
      794, //맥스 추석 사전예약 LMS(~9/6)
      909, //슈퍼 추석 사전예약 LMS(~9/6)
      920, //슈퍼(백)일산점 추석 사전예약 LMS(~9/6)
      906, //전점 본세트 LMS(~9/17)
      810, //슈퍼 본세트 LMS(~9/17)
      792, //맥스 본세트 LMS(~9/17)
      112, //원주점(~9/18)
      222, //원주점(~9/18)
      332, //원주점(~9/25)

      305, //제타플렉스 서울역점 GO(~9/25)
      306, //제타플렉스 서울역점 LMS(~9/25)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
  },
  // 롯데슈퍼 대표 전단
  // 성수연 담당 요청 (24.03.28) 슈퍼 LMS 추가
  SUPER: {
    openDate: "20240918",
    thisWeek: [
      209, //슈퍼(~9/18)
      210, //슈퍼 LMS (~9/18)
    ],
    willThisWeek: [
      309, //슈퍼(~9/25)
      310, //슈퍼 LMS (~9/25)
    ],
  },
  // 롯데슈퍼 추가 전단(설,명절 등)
  /*SUPER_THEME: {
    openDate: "20230831",
    thisWeek: [505],
    willThisWeek: [105],
  },*/
};
